import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { userRoutes } from '../Routes';
import Header from './Header'
import { ProductContext } from '../store';

const Content = () => {
	const location						= useLocation(),
		mainContent						= useRef(null),
		{
			campaignPageFlag
		}								= useContext(ProductContext)

	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	const getRoutes = () => {
		return userRoutes.map((prop, key) => {
			return (
				<Route
					exact
					path={prop.path}
					element={prop.component}
					key={key}
				/>
			);
		});
	};

	return (
		<div className='contentMainWrapper' ref={mainContent}>
			<Routes>
				{getRoutes()}
				<Route path="*" element={<Navigate to="/user/dashboard" replace />} />
			</Routes>
		</div>
	);
}

export default Content;