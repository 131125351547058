import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PostData } from '../../../services/FetchData';
import { Formik } from 'formik';
import * as Yup from "yup";
import { Button, Form, Input, InputGroup, Label, Spinner } from 'reactstrap';

const ForgotPassword = (props) => {

	const [isUser, setIsUser]		= useState(false)
	const [message, setMessage]	= useState("")
	const [noUser, setNoUser]		= useState(false)

	useEffect(() => {
		document.title	= "Forgot Password | RoundSales App"
		return () => {
			document.title	= "RoundSales App"
		}
	}, [])

	const requestPasswordLink = (values, actions) => {
		PostData( 'ms1', 'api/v1/user/forgot-password', values, false, false).then(result => {
			if (result !== 'Invalid' && result !== undefined ) {
				if (result.status === "success") {
					setIsUser(true)
					setNoUser(false)
					setMessage(result.message)
				} else if( result.status === "failure" ) {
					setNoUser(true)
					setIsUser(false)
					setMessage(result.message.message)
				} else {
					setNoUser(true)
					setIsUser(false)
					setMessage(result.message.message)
					
				}
			}
			actions.setSubmitting(false);
		});
	};

	return (
		<>
			<div className='d-flex-col xs mb-3'>
				<h3 className='bold'>Forgot your password?</h3>
				<div className="text-light">No worries, we'll send you reset instructions</div>
			</div>
			<div className='authFormWrapper'>
				<div>
					<Formik
						initialValues={{ email: "", }}
						onSubmit={(values, actions) => {
							requestPasswordLink(values, actions)
						}}
						validationSchema={Yup.object().shape({
							email: Yup.string().email("Please enter a valid email address").required("Email Required"),
						})}
					>
						{props => {
							const {
								values,
								touched,
								errors,
								isSubmitting,
								handleChange,
								handleBlur,
								handleSubmit
							} = props;
							return (
								<Form role="form" onSubmit={handleSubmit} novalidate>
									<div className="position-relative">
										<Label className="input-group-alternative-label" >
											Email
										</Label>
										<InputGroup className="input-group-alternative">
											<Input
												placeholder="Email adress"
												type="email"
												name="email"
												value={values.email}
												onChange={handleChange}
												onBlur={handleBlur}
												className={"col" || (errors.email && touched.email && "error")}
											/>
										</InputGroup>
										{errors.email && touched.email && (
											<div className="input-feedback">{errors.email}</div>
										)}
										{isUser && <p className="success-message"> {`${message} to ${values.email}`} </p>}
										{noUser && <p className="input-feedback"> {`${message}`} </p>}
									</div>
									<div className="d-flex justify-content-center">
										{isSubmitting ? (
											<div>
												<Button className="cta authCta" color="primary" type="button" disabled>
													<Spinner
														className="white mr-1"
														size="sm"
														color="light"
													/>
													<span className="ml-2 d-inline-block">Loading</span>
												</Button>
											</div>
										) : (
											<Button className="cta authCta" color="primary" type="submit">
												Request password link
											</Button>
										)}
									</div>
								</Form>)
						}}
					</Formik>
				</div>
			</div>
			<div className='authFooterLinkWrapper'>
				<p className='mb-0'>Want to login into your account? <Link to='/auth/login' className='text-cta'>Login Here</Link></p>
			</div>
		</>
	);
}

export default ForgotPassword;