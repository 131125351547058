import React, { useRef, useState, useEffect, useContext } from 'react';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { createRoot } from 'react-dom/client';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Link, Navigate, useParams } from 'react-router-dom';
import VariableDropdown from '../../../components/VariableDropdown';
import LeadDropdown from '../../../components/LeadDropdown';
import { ProductContext } from '../../../store';
import { PostData } from '../../../services/FetchData';
import SmallSkeleton from '../../../components/Skeleton/SmallSkeleton';
import DetailSkeleton from '../../../components/Skeleton/DetailSkeleton';
import ViewLeadDropdown from '../../../components/ViewLeadDropdown';
import RSToolTip from '../../../components/RSToolTip';
import TimeAgo from 'timeago-react';
import LeadScheduleToolTip from '../../../components/LeadScheduleToolTip';

function Leads(props) {
	const [searchText, setSearchText] = useState(""),
		[importNewLeadOption, setImportNewLeadOption] = useState(false),
		[rightSideModal, setRightSideModal] = useState(false),
		[rightSideModalValue, setRightSideModalValue] = useState(null),
		[newLeadValue, setNewLeadValue] = useState({}),
		[importModalFlag, setImportModalFlag] = useState(false),
		[importCurrentTab, setImportCurrentTab] = useState('csv'),
		[importStep, setImportStep] = useState(1),
		inputFileRef = useRef(null),
		[fileUploaded, setFileUploaded] = useState(null),
		[fileUploadRowCount, setFileUploadRowCount] = useState(0),
		[progressValue, setProgressValue] = useState(0),
		[duplicateCampaignCount, setDuplicateCampaignCount] = useState(0),
		[duplicateOtherCampaignCount, setDuplicateOtherCampaignCount] = useState(0),
		[invalidEmailCount, setInvalidEmailCount] = useState(0),
		[unsubscribeEmailCount, setUnsubscribeEmailCount] = useState(0),
		[slicedData, setSlicedData] = useState([]),
		[fileLoadedFlag, setFileLoadedFlag] = useState(false),
		[uploadSuccessFlag, setUploadSuccessFlag] = useState(false),
		[importDataColumn, setImportDataColumn] = useState([]),
		[importDuplicateOption, setImportDuplicateOption] = useState(null),
		[importLoaderApi, setImportLoaderApi] = useState(false),
		[localCustomVariable] = useState(["firstName", "lastName", "companyName", "icebreaker", "linkedinUrl", "picture", "email", "companyDomain", "phone"]),
		progressValueRef = useRef(0),
		progressInterRef = useRef(null),
		[redirectFlag, setRedirectFlag] = useState(false),
		[importOptionLoader, setImportOptionLoader] = useState(true),
		[leadsLoader, setLeadsLoader] = useState(true),
		[importOptions, setImportOptions] = useState([]),
		[leads, setLeads] = useState([]),
		[deleteImportModal, setDeleteImportModal] = useState(false),
		[deleteImportLoaderApi, setDeleteImportLoaderApi] = useState(false),
		[deleteImportOptionIndex, setDeleteImportOptionIndex] = useState(null),
		[leadsEmailArray, setLeadsEmailArray] = useState([]),
		[addNewLeadError, setAddNewLeadError] = useState(false),
		[addNewLeadLoaderApi, setAddNewLeadLoaderApi] = useState(false),
		[deleteWarningModal, setDeleteWarningModal] = useState(false),
		[deleteLoaderApi, setDeleteLoaderApi] = useState(false),
		[deleteLeadIndex, setDeleteLeadIndex] = useState(null),
		[viewLead, setViewLead] = useState(null),
		[viewLeadStatusLoaderApi, setViewLeadStatusLoaderApi]	= useState(false),
		[viewLeadTabSelected, setViewLeadTabSelected]	= useState('infos'),
		[viewLeadIndex, setViewLeadIndex]	= useState(null),
		[editFieldKey, setEditFieldKey]	= useState(''),
		[viewLeadActivities, setViewLeadActivities]	= useState([]),
		[leadOtherCampaigns, setLeadOtherCampaigns]	= useState([]),
		[emailModal, setEmailModal]	= useState(false),
		[modalEmailDetail, setModalEmailDetail]	= useState(null),
		[leadActivityLoader, setLeadActivityLoader]	= useState(false),
		[leadNotes, setLeadNotes]	= useState([]),
		[leadNote, setLeadNote]	= useState(""),
		[leadNotesLoaderApi, setLeadNotesLoaderApi]	= useState(false),
		[leadSentSchedules, setLeadSentSchedules]	= useState([]),
		[leadCurrentSchedule, setLeadCurrentSchedule]	= useState({}),
		[leadAwaitingSchedule, setLeadAwaitingSchedule]	= useState([]),
		[leadUnsubscribeModal, setLeadUnsubscribeModal]	= useState(false),
		[unsubscribeLeadLoaderApi, setUnsubscribeLeadLoaderApi]	= useState(false),
		{
			id,
			//			"*": wildcard
		} = useParams(),
		{
			campaignLeads,
			currentTeamId,
			setCampaignLeads,
			setReviewLeads,
			campaignDetails,
			teamUserData
		} = useContext(ProductContext);

	useEffect(() => {
		getCampaignImportOption()
		getCampaignLeads()
		getReviewLeads()
	}, [])

	useEffect(() => {
		if (campaignLeads.length === 0) {
			setRedirectFlag(true)
		}
	}, [campaignLeads])

	useEffect(() => {
		let localEmailLeadsArray = []
		if (leads.length > 0) {
			localEmailLeadsArray = leads.map((obj) => obj.email)
		}
		setLeadsEmailArray(localEmailLeadsArray);
	}, [leads])

	useEffect(() => {
		if( viewLeadIndex !== null ){
			getLeadActivities()
			getLeadNotes()
			getLeadOtherCampaigns()
			getLeadSchedule()
		}else{
			setViewLeadActivities([])
			setLeadNotes([])
			setLeadOtherCampaigns([])
			setLeadSentSchedules([])
			setLeadAwaitingSchedule([])
			setLeadCurrentSchedule({})
		}
	}, [viewLeadIndex])

	useEffect(() => {
		if (fileUploaded) {
			continueFileUpload()
			progressInterRef.current = setInterval(() => {
				if (progressValueRef.current <= 90) {
					progressValueRef.current += 5;
					setProgressValue(progressValueRef.current)
				} else {
					clearInterval(progressInterRef.current)
				}
			}, 500);
			return () => clearInterval(progressInterRef.current);
		}
	}, [fileUploaded, progressInterRef, progressValueRef, setProgressValue]);

	const getCampaignImportOption = () => {
		let data = { team_id: currentTeamId, campaign_id: id }
		PostData("ms1", "api/v1/campaign/get-import-options", data).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					setImportOptions(result.data.importOptions)
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setImportOptionLoader(false)
		})
	}

	const getCampaignLeads = () => {
		let data = { team_id: currentTeamId, campaign_id: id }
		PostData("ms1", "api/v1/campaign/get-leads", data).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					setLeads(result.data.leads)
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setLeadsLoader(false)
		})
	}

	const getReviewLeads = () => {
		let leadReviewData = { team_id: currentTeamId, campaign_id: id }
		PostData("ms1", "api/v1/campaign/get-review-leads", leadReviewData).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					setReviewLeads(result.data.reviewLeads)
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
		})
	}

	const toggleRightSideModal = () => {
		//setAddNewLead((prevstate) => !prevstate);
		setViewLead(null)
		setViewLeadIndex(null)
		setViewLeadActivities([])
		setEditFieldKey(null)
		setRightSideModal(false)
		setViewLeadTabSelected("infos")
		setLeadNote("")
		setLeadNotesLoaderApi(false)
	}

	const addNewLead = () => {
		setRightSideModalValue("addNewLead")
		setRightSideModal(true)
	}

	const viewLeadFunc = (lead, index) => {
		setRightSideModalValue("viewLead")
		setViewLead(lead)
		setViewLeadIndex(index)
		setRightSideModal(true)
	}

	const getLeadActivities = () => {
		setLeadActivityLoader(true)
		PostData("ms1", "api/v1/campaign/lead-activities", {lead_id: leads[viewLeadIndex]._id, campaign_id: id, team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setViewLeadActivities(result.data.activities)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setLeadActivityLoader(false)
		})
	}

	const getLeadNotes = () => {
		PostData("ms1", "api/v1/campaign/lead-notes", {lead_id: leads[viewLeadIndex]._id, campaign_id: id, team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setLeadNotes(result.data.lead_notes)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const getLeadOtherCampaigns = () => {
		PostData("ms1", "api/v1/campaign/find-a-lead-other-campaign", {email: leads[viewLeadIndex].email, campaign_id: id, team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					let leadOtherCampaignsLocal	= [],
						localResult	= result.data.campaignNames
					for( let i = 0; i < localResult.length; i++ ){
						leadOtherCampaignsLocal.push(localResult[i].name); 
					}
					setLeadOtherCampaigns(leadOtherCampaignsLocal)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const getLeadSchedule = () => {
		if( leads[viewLeadIndex].status === "Running" || leads[viewLeadIndex].status === "Paused" ){
			PostData("ms1", "api/v1/campaign/lead-schedules", {lead_id: leads[viewLeadIndex]._id, campaign_id: id, team_id: currentTeamId}).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						let localResult	= result.data.leadSchedules,
							noEmailSent	= true,
							leadSentSchedulesLocal	= [],
							leadCurrentScheduleLocal	= {},
							leadAwaitingScheduleLocal	= [];
						for( let i = 0; i < localResult.length; i++ ){
							if( typeof localResult[i].done !== "undefined" && localResult[i].done ){
								leadSentSchedulesLocal.push(localResult[i])
								noEmailSent	= false
							}else if( typeof localResult[i].done !== "undefined" && !localResult[i].done && ( typeof localResult[i].schedule_date !== "undefined" || noEmailSent ) ){
								leadCurrentScheduleLocal	= localResult[i]
								noEmailSent	= false
							}else if( typeof localResult[i].done !== "undefined" && !localResult[i].done && typeof localResult[i].schedule_date === "undefined" ){
								leadAwaitingScheduleLocal.push(localResult[i])
							}
						}
						setLeadSentSchedules(leadSentSchedulesLocal)
						setLeadCurrentSchedule(leadCurrentScheduleLocal)
						setLeadAwaitingSchedule(leadAwaitingScheduleLocal)
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
			})
		}
	}

	const changeNewLeadValue = (e) => {
		setNewLeadValue({
			...newLeadValue,
			[e.target.name]: e.target.value
		})
		if (e.target.name === "email" && leadsEmailArray.indexOf(e.target.value.toLowerCase()) !== -1) {
			setAddNewLeadError(true)
		} else {
			setAddNewLeadError(false)
		}
	}

	const clearNewValue = (name) => {
		setNewLeadValue({
			...newLeadValue,
			[name]: ""
		})
	}

	const createNewLead = () => {
		setAddNewLeadLoaderApi(true)
		PostData("ms1", "api/v1/campaign/add-new-lead", { values: newLeadValue, team_id: currentTeamId, campaign_id: id }).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					toggleRightSideModal()
					getCampaignLeads()
					getReviewLeads()
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setAddNewLeadLoaderApi(false)
		})
	}

	const toggleImportModal = () => {
		if (!importLoaderApi) {
			setImportModalFlag((prevState) => !prevState);
		}
	}

	const fileUpload = (event) => {
		let file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const content = e.target.result;

				// Split the content by lines (assuming it's a text file)
				const lines = content.split('\n');

				// Calculate the number of rows
				let numberOfRows = 0;
				for (let i = 1; i < lines.length; i++) {
					if (lines[i] !== "") {
						numberOfRows++
					}
				}
				// Update the state with the number of rows
				setFileUploadRowCount(numberOfRows);
			};

			// Read the file as text
			reader.readAsText(file);
			progressValueRef.current = 0
			setFileUploaded(file)
		}
		event.target.value = ""
	}

	const createFileUpload = () => {
		let inputFile = React.createElement('input', { type: 'file', name: 'myfile', id: 'myfile', ref: inputFileRef, accept: ".csv", onChange: (event) => { fileUpload(event) } })
		const container = document.getElementById('uploadFile');
		const root = createRoot(container); // createRoot(container!) if you use TypeScript
		root.render(inputFile);
		setTimeout(() => {
			inputFileRef.current.click()
		}, 200);
	}

	const openLeadModal = (type) => {
		setImportModalFlag(true)
		setImportStep(1)
		setImportCurrentTab(type)
	}

	const continueFileUpload = async () => {
		if (fileUploaded) {
			const formData = new FormData()
			formData.append('csv_file', fileUploaded)
			formData.append('campaign_id', id)
			formData.append('team_id', currentTeamId)
			PostData("ms1", "api/v1/campaign/import-csv-lead", formData, true).then((result) => {
				if (result !== "Invalid" && result !== undefined) {
					if (result.status === "success") {
						setDuplicateCampaignCount(result.data.duplicateInCurrentCampaign)
						setDuplicateOtherCampaignCount(result.data.duplicateOtherCampaign)
						setFileUploadRowCount(result.data.dataCount)
						setInvalidEmailCount(result.data.invalidEmailCount)
						setUnsubscribeEmailCount(result.data.unsubscribeEmailCount)
						let tempData = result.data.data,
							localSlicedData = {},
							localImportDataColumn = [];
						for (let i = 0; i < tempData.length; i++) {
							let tempDataObjectKeys = Object.keys(tempData[i]).filter((obj) => obj.trim());
							for (let j = 0; j < tempDataObjectKeys.length; j++) {
								if (typeof localSlicedData[tempDataObjectKeys[j]] === "undefined") {
									localImportDataColumn.push(tempDataObjectKeys[j]);
									localSlicedData[tempDataObjectKeys[j]] = { key: tempDataObjectKeys[j], values: tempData[i][tempDataObjectKeys[j]] }
									const index = localCustomVariable.findIndex(element => element.toLowerCase() === tempDataObjectKeys[j].replace(/\s/g, '').toLowerCase());
									localSlicedData[tempDataObjectKeys[j]]["db_key"] = index !== -1 ? localCustomVariable[index] : "customVariable"
								} else {
									localSlicedData[tempDataObjectKeys[j]]["values"] += ", " + tempData[i][tempDataObjectKeys[j]]
								}
							}
						}
						setImportDataColumn(localImportDataColumn)
						setSlicedData(localSlicedData)
						if (progressInterRef.current) {
							clearInterval(progressInterRef.current)
							progressInterRef.current = null
						}
						progressValueRef.current = 100;
						setProgressValue(100)
						if (result.data.dataCount !== result.data.duplicateInCurrentCampaign) {
							setUploadSuccessFlag(true)
						}
					} else if (result.status === "failure") {
						toast.error(result.message)
					} else {
						toast.error(result.message.message)
					}
				}
				//setFileUploaded(null)
			})
		}
	}

	const gotoImportStep = (step) => {
		setImportStep(step)
	}

	const removeFileUploaded = () => {
		setFileLoadedFlag(false)
		setFileUploadRowCount(0)
		setDuplicateCampaignCount(0)
		setDuplicateOtherCampaignCount(0)
		setInvalidEmailCount(0)
		setUnsubscribeEmailCount(0)
		setFileUploaded(null)
		setProgressValue(0)
		progressValueRef.current = 0
		setSlicedData({})
		setUploadSuccessFlag(false)
	}

	const setVariableDBKey = (dbKey, index) => {
		let localTempData = { ...slicedData }
		localTempData[index]["db_key"] = dbKey
		setSlicedData(localTempData)
	}

	const toggleAllImportColumn = (e) => {
		if (e.target.checked) {
			setImportDataColumn(Object.keys(slicedData))
		} else {
			setImportDataColumn([])
		}
	}

	const toggleImportColumn = (e, key) => {
		if (e.target.checked) {
			setImportDataColumn([...importDataColumn, key])
		} else {
			let localImportDataColumn = [...importDataColumn],
				indexToSplice = localImportDataColumn.indexOf(key);
			if (indexToSplice !== -1) {
				localImportDataColumn.splice(indexToSplice, 1)
				setImportDataColumn(localImportDataColumn)
			}
		}
	}

	const validateImportStep2 = () => {
		let localSlicedData = { ...slicedData },
			localSlicedDataObjKey = Object.keys(localSlicedData),
			dbKeyValue = [];
		for (let i = 0; i < localSlicedDataObjKey.length; i++) {
			if (importDataColumn.indexOf(localSlicedDataObjKey[i]) !== -1) {
				dbKeyValue.push(localSlicedData[localSlicedDataObjKey[i]].db_key)
			}
		}
		if (dbKeyValue.indexOf("email") !== -1) {
			gotoImportStep(3)
		} else {
			toast.error("Without email column you can't create a lead")
		}
	}

	const importFileUploadLead = () => {
		if (fileUploaded) {
			setImportLoaderApi(true)
			const formData = new FormData()
			formData.append('csv_file', fileUploaded)
			formData.append('campaign_id', id)
			formData.append('team_id', currentTeamId)
			formData.append('import_column', JSON.stringify(importDataColumn))
			formData.append('import_column_map', JSON.stringify(slicedData))
			formData.append('duplicate_import_option', importDuplicateOption)
			formData.append('import_lead_count', importDuplicateOption === "campaign" ? (fileUploadRowCount - duplicateCampaignCount - invalidEmailCount - unsubscribeEmailCount) : (fileUploadRowCount - duplicateOtherCampaignCount - invalidEmailCount - unsubscribeEmailCount))
			PostData("ms1", "api/v1/campaign/import-csv-lead-final-step", formData, true).then((result) => {
				if (result !== "Invalid" && result !== undefined) {
					if (result.status === "success") {
						setFileUploaded(null)
						setImportLoaderApi(false)
						setCampaignLeads(result.data.leadData)
						toggleImportModal()
						getCampaignImportOption()
						getCampaignLeads()
						getReviewLeads()
					} else if (result.status === "failure") {
						toast.error(result.message)
					} else {
						toast.error(result.message.message)
					}
				}
				//setFileUploaded(null)
			})
		}
	}

	const initiatedDeleteImportOption = (index) => {
		setDeleteImportOptionIndex(index)
		setDeleteImportModal(true)
	}

	const toggleDeleteWarningModal = () => {
		setDeleteImportModal((prevState) => !prevState)
	}

	const deleteImportOptions = () => {
		setDeleteImportLoaderApi(true)
		let deleteImportData = { team_id: currentTeamId, campaign_id: id, import_option_id: importOptions[deleteImportOptionIndex].id }
		PostData("ms1", "api/v1/campaign/delete-import-option", deleteImportData).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					let localImportOptions = [...importOptions]
					localImportOptions.splice(deleteImportOptionIndex, 1)
					setImportOptions(localImportOptions)
					getCampaignLeads()
					getReviewLeads()
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setDeleteImportLoaderApi(false)
			setDeleteImportOptionIndex(null)
			setDeleteImportModal(false)
		})
	}

	const toggleLeadDeleteModal = () => {
		setDeleteWarningModal(prevState => !prevState)
	}

	const leadDropdownClickEvent = (index, funcName) => {
		switch(funcName){
			case "delete":
				onDeleteLead(index)
				break;
			case "pause":
				pauseLead(index)
				break;
		}
	}

	const onDeleteLead = (index) => {
		setDeleteLeadIndex(index)
		toggleLeadDeleteModal()
	}

	const deleteLead = () => {
		setDeleteLoaderApi(true)
		let deleteLeadData = { campaign_id: id, team_id: currentTeamId, lead_id: leads[deleteLeadIndex]._id }
		PostData("ms1", "api/v1/campaign/delete-lead", deleteLeadData).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					toast.success(result.message)
					let localLeads = [...leads];
					localLeads.splice(deleteLeadIndex, 1)
					setLeads(localLeads)
					getCampaignImportOption()
					toggleRightSideModal()
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
				setDeleteLeadIndex(null)
				toggleLeadDeleteModal()
				setDeleteLoaderApi(false)
			}
		})
	}

	const viewLeadClickEvent = (funcName) => {
		switch(funcName){
			case "unsubscribeLead":
				setLeadUnsubscribeModal(true)
				break;
			case "deleteLead":
				setDeleteLeadIndex(viewLeadIndex)
				toggleLeadDeleteModal()
				break;
		}
	}

	const viewLeadStatusChange = () => {
		pauseLead(viewLeadIndex)
	}

	const setEditFieldKeyFunc	= (key) => {
		if( key === "email" && viewLead.status !== "To review" ){
			return
		}
		setEditFieldKey(key)
	}

	const changeViewLeadValue = (value, key) => {
		setViewLead({
			...viewLead,
			[key]: value
		})
	}

	const updateViewLeadValue = () => {
		let updateLeadKey		= editFieldKey,
			updateLeadValue	=	viewLead[editFieldKey] ? viewLead[editFieldKey] : ""
		if( updateLeadKey === "email" ){
			for( let i = 0; i < leads.length; i++ ){
				if( leads[i]["_id"] !== viewLead["_id"] && leads[i]["email"] === viewLead["email"] ){
					setViewLead({
						...viewLead,
						[updateLeadKey]: leads[viewLeadIndex]["email"]
					})
					setEditFieldKey(null)
					return
				}
			}
		}
		if( typeof leads[viewLeadIndex] !== "undefined" && leads[viewLeadIndex][editFieldKey] !== updateLeadValue ){
			PostData("ms1", "api/v1/campaign/update-lead", {key: updateLeadKey, value: updateLeadValue, lead_id: viewLead._id, campaign_id: id, team_id: currentTeamId}).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						let leadsLocal	= [...leads]
						leadsLocal[viewLeadIndex][updateLeadKey]	= updateLeadValue
						setLeads(leadsLocal)
						toast.success("Lead updated")
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
			})
		}
		setEditFieldKey(null)
	}

	const toggleEmailModal	= () => {
		setEmailModal(false)
		setModalEmailDetail({})
	}

	const loadParticularEmail	= (index, type) => {
		if( type === "activities" ){
			setModalEmailDetail(viewLeadActivities[index])
		}else if( type === "email_sent" ){
			setModalEmailDetail(viewLeadActivities[index])
		}else if( type === "email_replied" ){
			setModalEmailDetail(viewLeadActivities[index])
		}
		setEmailModal(true)
	}

	const addLeadNotes	= () => {
		setLeadNotesLoaderApi(true)
		let data	= {campaign_id: id, team_id: currentTeamId, notes: leadNote, lead_id: viewLead._id}
		PostData("ms1", "api/v1/campaign/add-lead-notes", data).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setLeadNotes([
						result.data.notes,
						...leadNotes
					])
					setLeadNote("")
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setLeadNotesLoaderApi(false)
		})
	}

	const toggleLeadUnsubscribeModal = () => {
		setLeadUnsubscribeModal((prevState) => !prevState)
	}

	const unsubscribeLead = () => {
		setUnsubscribeLeadLoaderApi(true)
		let data	= {campaign_id: id, team_id: currentTeamId, lead_id: viewLead._id}
		PostData("ms1", "api/v1/campaign/unsubscribe-lead", data).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setViewLead({
						...viewLead,
						status: "Unsubscribed"
					})
					let leadsLocal	= [...leads]
					leadsLocal[viewLeadIndex]["status"]	= "Unsubscribed"
					setLeads(leadsLocal)
					toast.success("lead has been added to unsubscribe list")
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			toggleLeadUnsubscribeModal()
			setUnsubscribeLeadLoaderApi(false)
		})
	}

	const pauseLead = (index) => {
		if( leads[index].status === "Running" || leads[index].status === "Paused" ){
			setViewLeadStatusLoaderApi(true)
			let data	= {campaign_id: id, team_id: currentTeamId, lead_id: leads[index]._id}
			PostData("ms1", "api/v1/campaign/pause-lead", data).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						let status	= "Paused"
						if( leads[index]["status"] === "Paused" ){
							status	= "Running"
						}
						let leadsLocal	= [...leads]
						leadsLocal[index]["status"]	= status
						setLeads(leadsLocal)
						if( viewLead ){
							setViewLead({
								...viewLead,
								status
							})
						}
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
				setViewLeadStatusLoaderApi(false)
			})
		}
	}

	if (redirectFlag) {
		return <Navigate to={"/user/campaign/" + id + "/leads/edit"} replace />
	}

	return (
		<>
			<div className={`campaignEditContentWrapper tabLeads`}>
				<div className='center'>
					<div className='campaignTabLeads list'>
						<div className='d-flex-col'>
							<div className='d-flex-col xs'>
								<h2 className="bold">Import history</h2>
								<span className="text-light">Manage lead imports into your campaign</span>
							</div>
							{importOptionLoader ?
								<SmallSkeleton />
								: (importOptions.length > 0) &&
								<div className='rsTable importHistory'>
									<div className='tableRows'>
										{importOptions.map((importOption, index) => {
											return (
												<div className='tableRow'>
													<div className='tableRowItem importIcon'>
														{importOption.type === "from CSV" &&
															<i className="fa-solid fa-file-csv"></i>
														}
													</div>
													<div className='tableRowItem importMethod'>Import {importOption.type}</div>
													<div className='tableRowItem importSource'>{importOption.name}</div>
													<div className='tableRowItem importDate'>Date: {format(new Date(importOption.createdAt), "dd/MM/yyyy (hh:mm aaaa)")}</div>
													<div className='tableRowItem importInfo'>{importOption.leads_count} leads imported</div>
													<div className='tableRowItem d-flex-row align-items-center justify-content-end sm importOptions'></div>
													<div className='tableRowItem importActions'>
														<button className='btn btn-tertiary btn-ico rsRollbackCSV' onClick={() => initiatedDeleteImportOption(index)}>
															<i className="fa-solid fa-trash"></i>
														</button>
													</div>
												</div>
											)
										})}
									</div>
								</div>
							}
						</div>
						<h2 className="bold">Leads imported ({leads.length})</h2>
						<div className='d-flex-col leadsList'>
							<div className='d-flex-col sm'>
								<div className='d-flex-row align-items-center'>
									<div className='rsSearchInput rsSearchLead'>
										<i className="fa-solid fa-magnifying-glass"></i>
										<Input type='text' placeholder='Search by email, name, firstname, phone number...' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
										<i className="fa-solid fa-circle-xmark rsClearSearch" onClick={() => setSearchText('')}></i>
									</div>
									<span className="flex-1"></span>
									<Dropdown isOpen={importNewLeadOption} toggle={() => setImportNewLeadOption((prevstate) => !prevstate)} direction={"down"} className='dropdownTrigger'>
										<DropdownToggle tag="div" className='rsDropdown'>
											<button className='btn btn-primary md'>
												<i className="fa-solid fa-file-import"></i>
												Import new leads
											</button>
										</DropdownToggle>
										<DropdownMenu className='dropdownImportNewLeads menuDropdownItems'>
											<div className='menuDropdownItem' onClick={() => addNewLead()}>
												<i className="fa-solid fa-user-plus"></i>
												<div className="text">Add one lead</div>
											</div>
											<hr />
											<div className='menuDropdownItem' onClick={() => openLeadModal("csv")}>
												<i className="fa-solid fa-file-csv"></i>
												<div className="text">From CSV</div>
											</div>
											<div className='menuDropdownItem disabled'>
												<i className="fa-regular fa-hand"></i>
												<div className='text'>Emails manually</div>
											</div>
											<div className='menuDropdownItem disabled'>
												<i className="fa-solid fa-database"></i>
												<div className='text'>From CRM</div>
											</div>
											<div className='menuDropdownItem disabled'>
												<i className="fa-solid fa-bullhorn"></i>
												<div className='text'>From an existing campaign</div>
											</div>
											<div className='menuDropdownItem disabled'>
												<img src={require("../../../assets/images/zapier.svg").default} alt="zapier" style={{ width: "20px" }} />
												<div className='text'>With Zapier</div>
											</div>
											<div className='menuDropdownItem disabled'>
												<i className="fa-brands fa-linkedin"></i>
												<div className='text'>From linkedin extension</div>
											</div>
											<div className='menuDropdownItem disabled'>
												<i className="fa-solid fa-server"></i>
												<div className='text'>From API</div>
											</div>
										</DropdownMenu>
									</Dropdown>
								</div>
							</div>
							<div className='rsTable leadsTable flex-1'>
								{leadsLoader ?
									<DetailSkeleton />
									:
									<>
										<div className='header'>
											<div className='headerItem stickyStart'>
												<div className='stickyContent'>
													<span>Firstname, Name, Email</span>
												</div>
											</div>
											<div className='headerItem'>
												<span>Status</span>
											</div>
											<div className='headerItem'>
												<span>Phone number</span>
											</div>
											<div className='headerItem'>
												<span>Linkedin</span>
											</div>
											<div className='headerItem'>
												<span>company name</span>
											</div>
											<div className='headerItem stickyEnd'>
												<div className='stickyContent'><i className="fa-solid fa-ellipsis opacity-0"></i></div>
											</div>
										</div>
										{leads.length > 0 ?
											<div className='tableRows'>
												{leads.map((lead, index) => {
													return (
														<div className='tableRow rsLayoutRight' key={index} onClick={() => viewLeadFunc(lead, index)}>
															<div className='tableRowItem stickyStart'>
																<div className='stickyContent'>
																	<div className='leadSummary sm d-flex-row'>
																		<div className='leadPictureAndState'>
																			{(lead.firstName || lead.lastName) &&
																				<div className={`leadPicture sm avatarPrimary${parseInt(lead.firstName.concat(lead.lastName).length % 10)}`}>
																					{lead.firstName && lead.firstName.substr(0, 1)}{lead.lastName && lead.lastName.substr(0, 1)}
																				</div>
																			}
																		</div>
																		<div className='leadContact'>
																			<div className='leadHeader'>
																				<div className='leadName'>
																					<div className='leadNameOverflow'>{lead.firstName && lead.firstName} {lead.lastName && leads.lastName}</div>
																					{/* <div className='rsTag leadEmailStatus sm tagSuccess100'>
																						<span>deliverable</span>
																					</div> */}
																				</div>
																				<div className='leadIcons'></div>
																			</div>
																			<p className='leadListEmail'>{lead.email}</p>
																		</div>
																	</div>
																</div>
															</div>
															<div className='tableRowItemFlex columnStatus'>
																<div className={`toggleLeadState ${lead.status.toLowerCase()}`}>
																	{lead.status === "Unsubscribed" ?
																		<>
																			<i className="fa-solid fa-user-xmark"></i> {lead.status}
																		</>
																		:
																		<>{lead.status}</>
																	}
																</div>
															</div>
															<div className='tableRowItem columnPhone'>{lead.phone}</div>
															<div className="tableRowItem columnLinkedinURL">
																{lead.linkedinUrl &&
																	<a href={lead.linkedinUrl} rel="noreferrer" target="_blank">{lead.linkedinUrl}</a>
																}
															</div>
															<div className='tableRowItem columnCompanyName'>{lead.companyName}</div>
															<div className='tableRowItem moreMenu stickyEnd'>
																<div className='stickyContent'>
																	<LeadDropdown index={index} lead={leads[index]} onClick={(index, funcName) => leadDropdownClickEvent(index, funcName)} />
																</div>
															</div>
														</div>
													)
												})}
											</div>
											:
											<div className="emptyPlaceholder">
												<div>
													<img src={require("./../../../assets/images/no-leads.png")} className='img-fluid' alt="No campaign found" />
													<div className="rsInfoMsg disabled">
														<div className="flex-1">Your campaign has currently no lead</div>
													</div>
												</div>
											</div>
										}
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={`rsRightModal rightLayout ${rightSideModal ? "open" : ""}`}>
				{rightSideModalValue === "addNewLead" ?
					<div className='d-flex-col rightLayoutContent leadCreateLayoutRight'>
						<div className='titleContainer align-items-center'>
							<div className='d-flex-row align-items-center rightLayoutContentLeft'>
								<div className='title'>
									<h1><i className="fa-solid fa-user-plus"></i> Add one lead</h1>
								</div>
							</div>
							<div className='d-flex-row align-items-center'>
								<button className="btn btn-secondary btn-ico sm closeLayoutRight" onClick={() => toggleRightSideModal()}>
									<i className="fa-solid fa-xmark"></i>
								</button>
							</div>
						</div>
						<div className='d-flex-col flex-1 content'>
							<div className='textEdit rsEmailAddress'>
								<label>Email address</label>
								<div className='inputWrapper'>
									<input type="text" className='rsUiEdit' placeholder='' value={newLeadValue.email} onChange={(e) => changeNewLeadValue(e)} name="email" />
									<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("email")}></i>
									<div className='rsTextEditBorder'></div>
								</div>
							</div>
							<div className='textEdit'>
								<label>Phone number</label>
								<div className='inputWrapper'>
									<Input type="text" className='rsUiEdit' placeholder='' value={newLeadValue.phone} onChange={(e) => changeNewLeadValue(e)} name="phone" disabled={addNewLeadError} />
									<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("phone")}></i>
									<div className='rsTextEditBorder'></div>
								</div>
							</div>
							<div className='textEdit'>
								<label>Linkedin profile</label>
								<div className='inputWrapper'>
									<Input type="text" className='rsUiEdit' placeholder='' value={newLeadValue.linkedinUrl} onChange={(e) => changeNewLeadValue(e)} name="linkedinUrl" disabled={addNewLeadError} />
									<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("linkedinUrl")}></i>
									<div className='rsTextEditBorder'></div>
								</div>
							</div>
							<div className='textEdit'>
								<label>First name</label>
								<div className='inputWrapper'>
									<Input type="text" className='rsUiEdit' placeholder='' value={newLeadValue.firstName} onChange={(e) => changeNewLeadValue(e)} name="firstName" disabled={addNewLeadError} />
									<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("firstName")}></i>
									<div className='rsTextEditBorder'></div>
								</div>
							</div>
							<div className='textEdit'>
								<label>Last name</label>
								<div className='inputWrapper'>
									<Input type="text" className='rsUiEdit' placeholder='' value={newLeadValue.lastName} onChange={(e) => changeNewLeadValue(e)} name="lastName" disabled={addNewLeadError} />
									<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("lastName")}></i>
									<div className='rsTextEditBorder'></div>
								</div>
							</div>
							<div className='textEdit'>
								<label>Company name</label>
								<div className='inputWrapper'>
									<Input type="text" className='rsUiEdit' placeholder='' value={newLeadValue.companyName} onChange={(e) => changeNewLeadValue(e)} name="companyName" disabled={addNewLeadError} />
									<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("companyName")}></i>
									<div className='rsTextEditBorder'></div>
								</div>
							</div>
							<div className='textEdit'>
								<label>Company domain</label>
								<div className='inputWrapper'>
									<Input type="text" className='rsUiEdit' placeholder='' value={newLeadValue.companyDomain} onChange={(e) => changeNewLeadValue(e)} name="companyDomain" disabled={addNewLeadError} />
									<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("companyDomain")}></i>
									<div className='rsTextEditBorder'></div>
								</div>
							</div>
							<div className='textEdit'>
								<label>Photo URL</label>
								<div className='inputWrapper'>
									<Input type="text" className='rsUiEdit' placeholder='' value={newLeadValue.picture} onChange={(e) => changeNewLeadValue(e)} name="picture" disabled={addNewLeadError} />
									<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("picture")}></i>
									<div className='rsTextEditBorder'></div>
								</div>
							</div>
						</div>
						<div className='footer'>
							<Button color='primary' className='addNewLead' disabled={addNewLeadError || addNewLeadLoaderApi} onClick={() => createNewLead()}>
								{addNewLeadLoaderApi &&
									<Spinner size={"sm"} />
								}
								Add this lead
							</Button>
						</div>
					</div>
				:	( rightSideModalValue === "viewLead" && viewLead ) &&
					<div className='d-flex-col rightLayoutContent leadLayoutRight'>
						<div className='titleContainer align-items-center'>
							<div className='d-flex-row align-items-center rightLayoutContentLeft'>
								<div className='title'>
									<div className='leadSummary d-flex-row'>
										<div className='leadPictureAndState'>
											{(viewLead.firstName || viewLead.lastName) &&
												<div className={`leadPicture avatarPrimary${parseInt(leads[viewLeadIndex].firstName.concat(leads[viewLeadIndex].lastName).length % 10)}`}>
													{viewLead.firstName && viewLead.firstName.substr(0, 1)}{viewLead.lastName && viewLead.lastName.substr(0, 1)}
												</div>
											}
										</div>
										<div className='leadContact'>
											<div className='leadHeader'>
												<div className='leadName'>
													<div className='leadNameOverflow'>{viewLead.firstName && viewLead.firstName} {viewLead.lastName && viewLead.lastName}</div>
													{/* <div className='rsTag leadEmailStatus sm tagSuccess100'>
														<span>deliverable</span>
													</div> */}
												</div>
												<div className='leadIcons'></div>
											</div>
											<p className='leadListEmail'>{viewLead.email}</p>
										</div>
									</div>
								</div>
							</div>
							<div className='d-flex-row align-items-center'>
								<div>
									<ViewLeadDropdown lead={viewLead} onClick={(funcName) => viewLeadClickEvent(funcName)} />
								</div>
								<button className="btn btn-secondary btn-ico sm closeLayoutRight" onClick={() => toggleRightSideModal()}>
									<i className="fa-solid fa-xmark"></i>
								</button>
							</div>
						</div>
						<div className='d-flex-col leadLayoutRightNext'>
							{leadOtherCampaigns.length > 0 &&
								<div className='rsInfoMsg info'>
									<div className='flex-1'>
										<div>
											This lead is already present in &nbsp;
											<RSToolTip item={{ placement: 'bottom', text: `${leadOtherCampaigns.join("<br />")}` }} id="otherCampaignLeadError">
												<span className='link-underlined bold'>{leadOtherCampaigns.length} other campaigns</span>
											</RSToolTip>
										</div>
									</div>
								</div>
							}
							<div className="d-flex-col sm leadCampaignProgression">
								<div className='d-flex-row align-items-center justify-content-between'>
									<h4 className='bold'>{campaignDetails && campaignDetails.name}</h4>
									<div className='leadState'>
										{(viewLead.status === "Running" || viewLead.status === "Paused" ) ?
											<div className="rsSwitch customControl customSwitch rsCampaignsTogglePlayPause playPause">
												<Label className="customControlLabel hasLabel">
													<Input type="checkbox" disabled={viewLeadStatusLoaderApi} checked={(viewLead.status && viewLead.status === "Running" )} onChange={() => viewLeadStatusChange()} />
													<span className="switchDisplay"></span>
													<span className="rsLabel">Run lead</span>
												</Label>
											</div>
										: ( viewLead.status === "Ended" || viewLead.status === "Interested" || viewLead.status === "Unsubscribed" ) ?
											<>
												<i className="fa-solid fa-circle-check success"></i>
												<span>Lead ended</span>
											</>
										:
											<>
												<i className="fa-regular fa-clock error"></i>
												<span>Awaiting review this lead</span>
											</>
										}
									</div>
								</div>
								{(viewLead.status === "Running" || viewLead.status === "Paused" ) &&
									<div className='d-flex-row campaignTaskContainer'>
										{leadSentSchedules.length > 0 &&
											<LeadScheduleToolTip type="done" innerClass="taskWrapper" tasks={leadSentSchedules}>
												<ul className='doneTasks'>
													{leadSentSchedules.map((sent, index) => {
														return( 
															<li className='task success'>
																<i className="fa-regular fa-envelope-open" aria-hidden="true"></i>
															</li>
														)
													})}
												</ul>
												<i className="separator fa-solid fa-caret-right"></i>
											</LeadScheduleToolTip>
										}
										{Object.keys(leadCurrentSchedule).length > 0 &&
											<LeadScheduleToolTip type="current" innerClass="currentTask task" tasks={leadCurrentSchedule}>
												<i className="fa-regular fa-envelope-open"></i> Send mail
											</LeadScheduleToolTip>
										}
										{leadAwaitingSchedule.length > 0 &&
											<LeadScheduleToolTip type="future" innerClass="taskWrapper" tasks={leadAwaitingSchedule}>
												<i className="separator fa-solid fa-caret-right"></i>
												<ul className="futureTasks">
													<li className="task"><i className="fa-regular fa-envelope-open" aria-hidden="true"></i></li>
												</ul>
												{leadAwaitingSchedule.length > 1 &&
													<div className="task wrapperRight"> +{(leadAwaitingSchedule.length - 1)} </div>
												}
											</LeadScheduleToolTip>
										}
									</div>
								}
							</div>
							<div className='leadTabs'>
								<div className={`leadTab ${viewLeadTabSelected === "infos" ? "selected" : ""}`} onClick={() => setViewLeadTabSelected("infos")}>Infos</div>
								<div className={`leadTab ${viewLeadTabSelected === "activities" ? "selected" : ""}`} onClick={() => setViewLeadTabSelected("activities")}>Activities</div>
								<div className={`leadTab ${viewLeadTabSelected === "notes" ? "selected" : ""}`} onClick={() => setViewLeadTabSelected("notes")}>Notes</div>
							</div>
							<div className='leadTabContent'>
								{viewLeadTabSelected === "infos" ?
									<div className='d-flex-col leadForm'>
										<div className='d-flex-col noGap leadFormSection'>
											<div className='leadFormSectionHeader'>
												<h4 className='bold'>General information</h4>
											</div>
											<div className='d-flex-row leadFormSectionContent leadGeneralInfo'>
												<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "firstName" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("firstName")}>
													<label className="bold">First name</label>
													<div className='d-flex-row sm align-items-center'>
														{editFieldKey === "firstName" ?
															<Input type='text' placeholder='First name' value={viewLead.firstName} onChange={(e) => changeViewLeadValue(e.target.value, "firstName")} onBlur={() => updateViewLeadValue()} />
														:
															<Label className={`flex-1 fieldValue ${viewLead.firstName ?  "filled" : ""}`}>{viewLead.firstName ? viewLead.firstName : "Add first name"}</Label>
														}
													</div>
												</div>
												<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "lastName" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("lastName")}>
													<label className="bold">Last name</label>
													<div className='d-flex-row sm align-items-center'>
														{editFieldKey === "lastName" ?
															<Input type='text' placeholder='Last name' value={viewLead.lastName} onChange={(e) => changeViewLeadValue(e.target.value, "lastName")} onBlur={() => updateViewLeadValue()} />
														:
															<Label className={`flex-1 fieldValue ${viewLead.lastName ?  "filled" : ""}`}>{viewLead.lastName ? viewLead.lastName : "Add last name"}</Label>
														}
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex-col noGap leadFormSection'>
											<div className='leadFormSectionHeader'>
												<h4 className='bold'>Contact infos</h4>
											</div>
											<div className='d-flex-col leadFormSectionContent'>
												<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "email" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("email")}>
													<label className="bold">Email address</label>
													<div className='d-flex-row sm align-items-center'>
														{editFieldKey === "email" ?
															<Input type='text' placeholder='Email Address' value={viewLead.email} onChange={(e) => changeViewLeadValue(e.target.value, "email")} onBlur={() => updateViewLeadValue()} />
														:
															<Label className={`flex-1 fieldValue ${viewLead.email ?  "filled" : ""}`}>{viewLead.email ? viewLead.email : "Add email address"}</Label>
														}
													</div>
												</div>
												<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "phone" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("phone")}>
													<label className="bold">Phone number</label>
													<div className='d-flex-row sm align-items-center'>
														{editFieldKey === "phone" ?
															<Input type='text' placeholder='Phone number' value={viewLead.phone} onChange={(e) => changeViewLeadValue(e.target.value, "phone")} onBlur={() => updateViewLeadValue()} />
														:
															<Label className={`flex-1 fieldValue ${viewLead.phone ?  "filled" : ""}`}>{viewLead.phone ? viewLead.phone : "Add phone number"}</Label>
														}
													</div>
												</div>
												<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "linkedinUrl" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("linkedinUrl")}>
													<label className="bold">Linkedin profile</label>
													<div className='d-flex-row sm align-items-center'>
														{editFieldKey === "linkedinUrl" ?
															<Input type='text' placeholder='Linkedin profile' value={viewLead.linkedinUrl} onChange={(e) => changeViewLeadValue(e.target.value, "linkedinUrl")} onBlur={() => updateViewLeadValue()} />
														:
															<Label className={`flex-1 fieldValue ${viewLead.linkedinUrl ?  "filled" : ""}`}>{viewLead.linkedinUrl ? viewLead.linkedinUrl : "Add linkedin profile"}</Label>
														}
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex-col noGap leadFormSection'>
											<div className='leadFormSectionHeader'>
												<h4 className='bold'>Additional Information</h4>
											</div>
											<div className='d-flex-col leadFormSectionContent'>
												<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "companyName" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("companyName")}>
													<label className="bold">Company name</label>
													<div className='d-flex-row sm align-items-center'>
														{editFieldKey === "companyName" ?
															<Input type='text' placeholder='Company name' value={viewLead.companyName} onChange={(e) => changeViewLeadValue(e.target.value, "companyName")} onBlur={() => updateViewLeadValue()} />
														:
															<Label className={`flex-1 fieldValue ${viewLead.companyName ?  "filled" : ""}`}>{viewLead.companyName ? viewLead.companyName : "Add company name"}</Label>
														}
													</div>
												</div>
												<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "companyDomain" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("companyDomain")}>
													<label className="bold">Company domain</label>
													<div className='d-flex-row sm align-items-center'>
														{editFieldKey === "companyDomain" ?
															<Input type='text' placeholder='Company domain' value={viewLead.companyDomain} onChange={(e) => changeViewLeadValue(e.target.value, "companyDomain")} onBlur={() => updateViewLeadValue()} />
														:
															<Label className={`flex-1 fieldValue ${viewLead.companyDomain ?  "filled" : ""}`}>{viewLead.companyDomain ? viewLead.companyDomain : "Add company domain"}</Label>
														}
													</div>
												</div>
												<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "photo" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("photo")}>
													<label className="bold">[Photo]</label>
													<div className='d-flex-row sm align-items-center'>
														{editFieldKey === "photo" ?
															<Input type='text' placeholder='[Photo]' value={viewLead.photo} onChange={(e) => changeViewLeadValue(e.target.value, "photo")} onBlur={() => updateViewLeadValue()} />
														:
															<Label className={`flex-1 fieldValue ${viewLead.photo ?  "filled" : ""}`}>{viewLead.photo ? viewLead.photo : "Add [Photo]"}</Label>
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								: viewLeadTabSelected === "activities" ? 
									<div className='activities'>
										{leadActivityLoader ?
											<DetailSkeleton />
										:
											<>
												{viewLeadActivities.length > 0 ?
													<>
														{viewLeadActivities.map((activity, index) => {
															return(
																<article className="d-flex-row justify-content-center align-items-center activity compact" key={index}>
																	{activity.type === "Email sent" ?
																		<img className="activityTypeIcon colorSent" src={require("./../../../assets/images/sent.svg").default} alt="Sent" />
																	: activity.type === "Email opened" ?
																		<img className="activityTypeIcon colorOpened" src={require("./../../../assets/images/opened.svg").default} alt="Opened" />
																	: activity.type === "Link clicked" ?
																		<img className="activityTypeIcon colorLinkClicked" src={require("./../../../assets/images/clicked.svg").default} alt="Clicked" />
																	: activity.type === "Email replied" ?
																		<img className="activityTypeIcon colorReplied" src={require("./../../../assets/images/replied.svg").default} alt="Replied" />
																	: activity.type === "Interested" &&
																		<img className="activityTypeIcon colorInterested" src={require("./../../../assets/images/interested.svg").default} alt="Interested" />
																	}
																	<div className="activityContainer">
																		<div className="d-flex-col noGap align-items-start activityCenter">
																			<div className="activityLeadName">
																				<span className="rsLayoutRight rsActivitySelected">{activity.to_email}</span>
																			</div>
																			<div className="d-flex-row sm activityStep">
																				<span className="sequence">
																					<Link to={`/user/campaign/${campaignDetails.uuid}/sequence/edit`}>Step {parseInt(activity.step) + 1}</Link>
																				</span>
																				<div className="grey activityDate">
																					<span className="tippy fromNow">
																						<TimeAgo
																							datetime={activity.created_at}
																						/>
																					</span>
																				</div>
																			</div>
																			<div className="activityMessage">{activity.type}</div>
																			{activity.type === "Email sent" ?
																				<Button className="btn btn-tertiary rsShowActivityMessage" onClick={() => loadParticularEmail(index, "activities")}><i className="far fa-eye"></i><span>Show email sent</span></Button>
																			: activity.type === "Email replied" &&
																				<Button className="btn btn-tertiary rsShowActivityMessage" onClick={() => loadParticularEmail(index, "activities")}><i className="far fa-eye"></i><span>Show email replay</span></Button>
																			}
																		</div>
																	</div>
																</article>
															)
														})}
													</>
												:
													<div className="emptyActivities">
														<img src={require("./../../../assets/images/empty-box.svg").default} alt="empty-box" />
														<span>No activities found</span>
													</div>
												}
											</>
										}
									</div>
								: viewLeadTabSelected === "notes" &&
									<div className='d-flex-col'>
										<h4 className="bold leadFormSectionHeader">Add a new note</h4>
										<div className='rsRichTextArea'>
											<div className='textEdit'>
												<div className='inputWrapper'>
													<textarea className="rsUiEdit" rows="6" placeholder="What note do you want to add?" spellcheck="false" value={leadNote} onChange={(e) => setLeadNote(e.target.value)}></textarea>
													<div className="rsTextEditBorder"></div>
												</div>
											</div>
											<div className='toolbar'>
												<Button color='primary' onClick={() => addLeadNotes()} disabled={leadNotesLoaderApi}>
													{leadNotesLoaderApi &&
														<Spinner size={"sm"} />
													}
													Publish
												</Button>
											</div>
										</div>
										<div>
											{leadNotes.length > 0 &&
												leadNotes.map((note, index) => {
													return(
														<div key={index} className='note d-flex-col sm'>
															<div className="d-flex-row sm align-items-center">
																{teamUserData[note.user_id] &&
																	<>
																		<div className={`avatar uiUserPicture avatarPrimary${((teamUserData[note.user_id].id - 1) % 10)}`}>
																			{teamUserData[note.user_id].profile_picture ?
																				<img src={teamUserData[note.user_id].profile_picture} alt={teamUserData[note.user_id].first_name} className='picture' />
																				:
																				<>
																					{teamUserData[note.user_id].first_name.substr(0, 1)}{teamUserData[note.user_id].last_name && teamUserData[note.user_id].last_name.substr(0, 1)}
																				</>
																			}
																		</div>
																		<div className="name">
																			{teamUserData[note.user_id].first_name} {teamUserData[note.user_id].last_name ? teamUserData[note.user_id].last_name : ""}
																			<span className="text-inactive"> · <span><TimeAgo datetime={note.created_at} /></span></span>
																		</div>
																	</>
																}
															</div>
															<div className="d-flex-row noteContent textLight">
																{note.notes}
															</div>
														</div>
													)
												})
											}
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				}
			</div>
			<div id='uploadFile'></div>
			{importModalFlag &&
				<Modal size='xl' isOpen={importModalFlag} toggle={toggleImportModal} className='tabStyleModal campaignLeadsImportModal' backdrop={"static"}>
					<Button className='btnModalClose' onClick={() => toggleImportModal()}>
						<i className="fa-solid fa-xmark"></i>
					</Button>
					<ModalBody>
						<div className={`modalLeft ${importStep === 3 ? "d-none" : ""}`}>
							<div className='modalLeftHeading'>Import leads</div>
							<div className='modalLeftItemsWrapper'>
								<div className={`modalLeftItemWrap cursorPointer ${importCurrentTab === "csv" ? "active" : ""}`} onClick={() => setImportCurrentTab("csv")}>
									<i className="fa-solid fa-file-csv"></i>
									Import from CSV
								</div>
								<div className={`modalLeftItemWrap cursorPointer disabled ${importCurrentTab === "manually" ? "active" : ""}`} onClick={() => setImportCurrentTab("manually")}>
									<i className="fa-regular fa-hand"></i>
									Import emails manually
								</div>
								<div className={`modalLeftItemWrap disabled cursorPointer ${importCurrentTab === "crm" ? "active" : ""}`}>
									<i className="fa-solid fa-database"></i>
									Import from CRM
								</div>
								<div className={`modalLeftItemWrap disabled cursorPointer ${importCurrentTab === "campaign" ? "active" : ""}`}>
									<i className="fa-solid fa-bullhorn"></i>
									From an existing campaign
								</div>
								<div className={`modalLeftItemWrap disabled cursorPointer ${importCurrentTab === "linkedin" ? "active" : ""}`}>
									<img src={require('../../../assets/images/linkedin.svg').default} alt='Linkedin' />
									Import leads from LinkedIn
								</div>
								<div className={`modalLeftItemWrap disabled cursorPointer ${importCurrentTab === "zapier" ? "active" : ""}`}>
									<img src={require('../../../assets/images/zapier.svg').default} alt='Linkedin' />
									Import from Zapier
								</div>
								<div className={`modalLeftItemWrap disabled cursorPointer ${importCurrentTab === "api" ? "active" : ""}`}>
									<i className="fa-solid fa-gear"></i>
									Import from API
								</div>
							</div>
						</div>
						<div className='modalRight'>
							{importCurrentTab === "csv" &&
								importStep === 1 ?
								<>
									<div className='modalTop'>
										<div className='title'>
											1/3
											<img src={require('../../../assets/images/circle-filled-25.svg').default} className='importProgression' alt='Step Progression' />
											Upload a CSV
										</div>
									</div>
									<div className='modalCenter'>
										<div className='d-flex-col lg campaignLeadsImportCSV'>
											{fileUploaded ?
												<div className={`rsImportArea`}>
													<img src={require('../../../assets/images/csv-filigrane.svg').default} className='importAreaFiligrane' alt='CSV import icon' />
													<div>
														<label className="btn btn-tertiary importButton pointer-events-none">
															<input type="file" className="d-none" accept=".csv" name="csv-file" disabled={fileUploaded} />
															Click to upload
														</label>
													</div>
													<div className="text-inactive">Only .csv files are accepted - Maximum 10,000 leads</div>
												</div>
												:
												<div className={`rsImportArea allowed`} onClick={() => createFileUpload()}>
													<img src={require('../../../assets/images/csv-filigrane.svg').default} className='importAreaFiligrane' alt='CSV import icon' />
													<div>
														<label className="btn btn-tertiary importButton pointer-events-none">
															<input type="file" className="d-none" accept=".csv" name="csv-file" disabled={fileUploaded} />
															Click to upload
														</label>
													</div>
													<div className="text-inactive">Only .csv files are accepted - Maximum 10,000 leads</div>
												</div>
											}
											<div className="d-flex-col">
												{!fileUploaded ?
													<div className="fakeSection height100Pixels"></div>
													:
													<>
														{(duplicateCampaignCount > 0 || invalidEmailCount > 0 || unsubscribeEmailCount > 0) &&
															<div className='importSummaryIssues'>
																<div className='rsInfoMsg warning'>
																	<div className='flex-1'>
																		<div className='d-flex-row'>
																			<div className='d-flex-col noGap flex-1'>
																				{duplicateCampaignCount > 0 &&
																					<span><b>{duplicateCampaignCount}</b> lead{duplicateCampaignCount > 1 ? "s is " : " are "} already in this campaign</span>
																				}
																				{invalidEmailCount > 0 &&
																					<span><b>{invalidEmailCount}</b> lead{invalidEmailCount > 1 ? "s" : ""} have an invalid email address</span>
																				}
																				{unsubscribeEmailCount > 0 &&
																					<span><b>{unsubscribeEmailCount}</b> lead{unsubscribeEmailCount > 1 ? "s is " : " are "} in unsubscribe list</span>
																				}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														}
														<div className='uiSection importCSVData shadow mvp'>
															<div className='content'>
																<i className="fa-solid fa-trash rsImportDataClear" disabled={fileLoadedFlag} onClick={() => removeFileUploaded()}></i>
																<div className='d-flex-row'>
																	<button className='btn btn-ico btn-primary btn-success pointer-events-none'>
																		<i className="fa-solid fa-check"></i>
																	</button>
																	<div className='d-flex-col xs flex-1'>
																		<h4 className="importLeadsFileName">{fileUploaded.name}</h4>
																		<div className="importLeadsCount">{fileUploadRowCount} lead{fileUploadRowCount.length > 1 ? "s" : ""} found</div>
																		<div className='d-flex-row sm align-items-center'>
																			<div className='importProgressContainer flex-1'>
																				<div className='importProgressBar' style={{ width: `${progressValue}%` }}></div>
																			</div>
																			<div className='importProgressPercentage'>{progressValue}%</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</>
												}
											</div>
										</div>
									</div>
									<div className='d-flex-row sm modalButtons align-items-center justify-content-end'>
										<span className="flex-1"></span>
										{!uploadSuccessFlag &&
											<span className='buttonInfoText'>
												<i className="fa-solid fa-circle-info"></i>
												Upload a CSV to start
											</span>
										}
										<Button color='primary' onClick={() => gotoImportStep(2)} disabled={(!uploadSuccessFlag || ((duplicateCampaignCount + invalidEmailCount + unsubscribeEmailCount) === fileUploadRowCount))}>Continue</Button>
									</div>
								</>
								: importStep === 2 ?
									<>
										<div className='modalTop'>
											<div className='title'>
												2/3
												<img src={require('../../../assets/images/circle-filled-70.svg').default} className='importProgression' alt='Step Progression' />
												Set up imported custom variables
											</div>
										</div>
										<div className='modalCenter'>
											<div className='d-flex-col lg campaignLeadsImportCSV'>
												<div className='d-flex-col csvPreview'>
													{(duplicateCampaignCount > 0 || invalidEmailCount > 0 || unsubscribeEmailCount > 0) &&
														<div className='importSummaryIssues'>
															<div className='rsInfoMsg warning'>
																<div className='flex-1'>
																	<div className='d-flex-row'>
																		<div className='d-flex-col noGap flex-1'>
																			{duplicateCampaignCount > 0 &&
																				<span><b>{duplicateCampaignCount}</b> lead{duplicateCampaignCount > 1 ? "s is " : " are "} already in this campaign</span>
																			}
																			{invalidEmailCount > 0 &&
																				<span><b>{invalidEmailCount}</b> lead{invalidEmailCount > 1 ? "s" : ""} have an invalid email address</span>
																			}
																			{unsubscribeEmailCount > 0 &&
																				<span><b>{unsubscribeEmailCount}</b> lead{unsubscribeEmailCount > 1 ? "s is " : " are "} in unsubscribe list</span>
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													}
													<div className='rsTable importCSVTable d-flex-col sm'>
														<div className='header'>
															<div className='headerItem checkbox'>
																<div className="checkbox rsCheckbox">
																	<Label className="js-prevent-label-bounce-event-click">
																		<Input type='checkbox' checked={importDataColumn.length === Object.keys(slicedData).length} onChange={(e) => toggleAllImportColumn(e)} />
																	</Label>
																</div>
															</div>
															<div className='headerItem'>Column name</div>
															<div className='headerItem'>variable roundsales type</div>
															<div className='headerItem'>Samples</div>
														</div>
														<div className='tableRows'>
															{Object.keys(slicedData).map((key, index) => {
																return (
																	<React.Fragment key={index}>
																		{key.trim() &&
																			<div className='tableRow checked'>
																				<div className='tableRowItem checkbox'>
																					<div className="checkbox rsCheckbox">
																						<Label className="js-prevent-label-bounce-event-click">
																							<Input type='checkbox' checked={importDataColumn.indexOf(key) !== -1} onChange={(e) => toggleImportColumn(e, key)} />
																						</Label>
																					</div>
																				</div>
																				<div className='tableRowItem'>{key}</div>
																				<div className='tableRowItemFlex'>
																					<VariableDropdown active={slicedData[key].db_key} index={key} onChange={(db_key, index) => setVariableDBKey(db_key, index)} />
																				</div>
																				<div className='tableRowItem'>{slicedData[key].values}</div>
																			</div>
																		}
																	</React.Fragment>
																)
															})}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex-row sm modalButtons align-items-center justify-content-end'>
											<span className="flex-1"></span>
											<Button color='secondary' onClick={() => gotoImportStep(1)}>Previous</Button>
											<Button color='primary rsContinue' onClick={() => validateImportStep2()}>Continue</Button>
										</div>
									</>
									:
									<>
										<div className='modalTop'>
											<div className='title'>
												3/3
												<img src={require('../../../assets/images/circle-filled-99.svg').default} className='importProgression' alt='Step Progression' />
												Manage duplicates
											</div>
										</div>
										<div className='modalCenter'>
											<div className='d-flex-col lg campaignLeadsImportCsv'>
												{duplicateCampaignCount > 0 &&
													<div className='importSummaryIssues'>
														<div className='rsInfoMsg warning'>
															<div className='flex-1'>
																<div className='d-flex-row'>
																	<div className='d-flex-col noGap flex-1'>
																		<span><b>{duplicateCampaignCount}</b> lead{duplicateOtherCampaignCount > 1 ? "s is " : " are "} already in this campaign</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												}
												<div className='d-flex-row'>
													<div className='uiSection width40Percents mvp'>
														<div className='content'>
															<div className='d-flex-col'>
																<div className='d-flex-row align-items-center'>
																	<div className='checkbox rsRadio rsDeDuplicateChange'>
																		<label>
																			<input type='radio' onChange={() => setImportDuplicateOption("campaign")} name="deduplicate" className='rsDeDuplicateChange' checked={importDuplicateOption === "campaign"} />
																		</label>
																	</div>
																	<div className='d-flex-col xs flex-1'>
																		<div className='d-flex-row'>
																			<span className='flex-1 bold'>Don't import leads that are already in this campaign</span>
																		</div>
																		<span className="text-light">{duplicateCampaignCount === 0 ? "All leads will be imported" : `${duplicateCampaignCount} lead${duplicateCampaignCount > 1 ? "s" : ""} will not be imported`}</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className='uiSection flex-1 mvp'>
														<div className='content'>
															<div className='d-flex-col'>
																<div className='d-flex-row align-items-center'>
																	<div className='checkbox rsRadio rsDeDuplicateChange'>
																		<label>
																			<input type='radio' onChange={() => setImportDuplicateOption("otherCampaign")} name="deduplicate" className='rsDeDuplicateChange' checked={importDuplicateOption === "otherCampaign"} />
																		</label>
																	</div>
																	<div className='d-flex-col xs flex-1'>
																		<div className='d-flex-row'>
																			<span className='flex-1 bold'>Don't import leads that are already in your roundsales team (no matter the campaign)</span>
																		</div>
																		<span className="text-light">{duplicateOtherCampaignCount === 0 ? "All leads will be imported" : `${duplicateOtherCampaignCount} lead${duplicateOtherCampaignCount > 1 ? "s" : ""} will not be imported`}</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex-row sm modalButtons align-items-center justify-content-end'>
											<span className="flex-1"></span>
											<Button color='secondary' disabled={importLoaderApi} onClick={() => gotoImportStep(2)}>Previous</Button>
											<Button color='primary' disabled={!importDuplicateOption || importLoaderApi} onClick={() => importFileUploadLead()}>
												{importLoaderApi &&
													<Spinner size={"sm"} />
												}
												Import {importDuplicateOption === "campaign" ? (fileUploadRowCount - duplicateCampaignCount - invalidEmailCount - unsubscribeEmailCount) : (fileUploadRowCount - duplicateOtherCampaignCount - invalidEmailCount - unsubscribeEmailCount)}  leads
											</Button>
										</div>
									</>
							}
						</div>
					</ModalBody>
				</Modal>
			}
			{deleteImportModal &&
				<Modal isOpen={deleteImportModal} className='deleteErrorModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation alertColor paddingRight10"></i>Permanently delete leads from this import</h2>
							<p>Roll back this import and delete all leads from your campaign? This action can't be undone.</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={deleteImportLoaderApi} onClick={() => toggleDeleteWarningModal()}>No, do not delete</Button>
							<Button className='md okWarningButton btn-danger' color='primary' disabled={deleteImportLoaderApi} onClick={() => deleteImportOptions()}>
								{deleteImportLoaderApi &&
									<Spinner size={"sm"} />
								}
								<i className="fa-solid fa-trash"></i>  Yes, delete {importOptions[deleteImportOptionIndex].leads_count} lead{importOptions[deleteImportOptionIndex].leads_count > 1 ? "s" : ""}
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}

			{deleteWarningModal &&
				<Modal isOpen={deleteWarningModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation warningColor paddingRight10"></i>Warning</h2>
							<p>Delete 1 lead from the campaign?</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={deleteLoaderApi} onClick={() => toggleLeadDeleteModal()}>Cancel</Button>
							<Button className='md okWarningButton btn-warning' color='primary' disabled={deleteLoaderApi} onClick={() => deleteLead()}>
								{deleteLoaderApi &&
									<Spinner size={"sm"} />
								}
								Ok
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
			{leadUnsubscribeModal &&
				<Modal isOpen={leadUnsubscribeModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation warningColor paddingRight10"></i>Warning</h2>
							<p>Are you sure you want to add this lead to the unsubscribed list? This action can’t be undone.</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={unsubscribeLeadLoaderApi} onClick={() => toggleLeadUnsubscribeModal()}>Cancel</Button>
							<Button className='md okWarningButton btn-warning' color='primary' disabled={unsubscribeLeadLoaderApi} onClick={() => unsubscribeLead()}>
								{unsubscribeLeadLoaderApi &&
									<Spinner size={"sm"} />
								}
								Ok
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
			{emailModal &&
				<Modal isOpen={emailModal} toggle={() => toggleEmailModal()} centered={true} className='activityMessageModal'>
					<ModalBody>
						<Button className='btnModalClose' onClick={() => toggleEmailModal ()}>
							<i className="fa-solid fa-xmark"></i>
						</Button>
						<div className="activityMessageTitle">{modalEmailDetail.type}</div>
						<div className="d-flex-col sm emailHeaders">
							<div>
								<span className="bold">From: </span> {modalEmailDetail.type !== "Email replied" ? modalEmailDetail.from_name : modalEmailDetail.to_name } <span className="grey">&lt;{modalEmailDetail.type !== "Email replied" ? modalEmailDetail.from_email : modalEmailDetail.to_email }&gt;</span>
							</div>
							<div>
								<span className="bold">To: </span> {modalEmailDetail.type !== "Email replied" ? modalEmailDetail.to_name : modalEmailDetail.from_name } <span className="grey">&lt;{modalEmailDetail.type !== "Email replied" ? modalEmailDetail.to_email : modalEmailDetail.from_email }&gt;</span>
							</div>
							<div>
								<span className="bold">Subject: {modalEmailDetail.subject}</span>
							</div>
						</div>
						<div className="activityMessageBody">
							<div dangerouslySetInnerHTML={{__html:modalEmailDetail.message}}></div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color='primary' onClick={() => toggleEmailModal()}>
							<span className='closeSpan'>Close</span>
						</Button>
					</ModalFooter>
				</Modal>
			}
		</>
	);
}

export default Leads;