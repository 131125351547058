import React from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function UserActionDropdown({ userIndex, teamIndex, onClick }) {
	const [stepMoreOption, setStepMoreOption] = useState(false)

	const onClickEvent = (type) => {
		setStepMoreOption(false)
		onClick(userIndex, teamIndex, type)
	}
	
	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => { setStepMoreOption((prevstate) => !prevstate); }} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btnAction'>
				<i className="fa fa-ellipsis"></i>
			</DropdownToggle>
			<DropdownMenu className='campaignFilterDropdownMenu'>
				<div>
					<div className='dropdownRSMenuItems'>
						<div className='dropdownRSMenuItem rsDuplicateCampaign' onClick={() => onClickEvent("edit")}>
							<i className="fa-solid fa-pen"></i>
							<div className="text">Edit</div>
						</div>
						<div className='dropdownRSMenuItem alert-300 separator rsDeleteCampaign' onClick={() => onClickEvent("delete")}>
							<i className="fa-solid fa-trash"></i>
							<div className="text">Remove user</div>
						</div>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default UserActionDropdown;