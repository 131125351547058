import React from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function CampaignRowDropdown(props) {
	const [stepMoreOption, setStepMoreOption] = useState(false)
	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => { setStepMoreOption((prevstate) => !prevstate); }} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btnAction'>
				<i className="fa fa-ellipsis"></i>
			</DropdownToggle>
			<DropdownMenu className='campaignFilterDropdownMenu'>
				<div>
					<div className='dropdownRSMenuItems'>
						{/* <div className='dropdownRSMenuItem rsShareSequence'>
								<i className="fa fa-share-nodes"></i>
								<div className="text">Share link</div>
						</div> */}
						<div className='dropdownRSMenuItem rsDuplicateCampaign' onClick={() => props.onDuplicate(props.index)}>
							<i className="fa-regular fa-copy"></i>
							<div className="text">Duplicate</div>
						</div>
						<div className='dropdownRSMenuItem rsArchiveCampaign' onClick={() => { setStepMoreOption(false); props.onArchive(props.index)}} >
							<i className="fa-solid fa-box-archive"></i>
							<div className="text">
								{props.campaign.archive_status ? "Unarchive" : "Archive"}
							</div>
						</div>
						<div className='dropdownRSMenuItem alert-300 separator rsDeleteCampaign' onClick={() => props.onDelete(props.index)}>
							<i className="fa-solid fa-trash"></i>
							<div className="text">Delete Campaign</div>
						</div>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default CampaignRowDropdown;