import { appURL, ms1URL, ms2URL, ms3URL, ms4URL, ms5URL } from "./AppUrl";
const mainPath = appURL;
const Path = pathValue => {
	switch (pathValue) {
		case "ms1":
			//return `http://localhost:8081/`
			return ms1URL;
		case "ms2":
			//return `https://micro2.${mainPath}`;
			return ms2URL
		case "ms3":
			//return `https://micro3.${mainPath}`;
			return ms3URL
		case "ms4":
			//return `https://micro4.${mainPath}`;
			return ms4URL
		case "ms5":
			//return `https://micro4.${mainPath}`;
			return ms5URL
		case "ms6":
			return `https://micro4.${mainPath}`;
		case "ms9":
			return `https://micro9.${mainPath}`;
		default:
			//return `http://localhost:8081/`
			return `https://micro1.${mainPath}`;
	}
};
export default Path;