import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Label, Spinner } from 'reactstrap';
import { PostData } from './../../../services/FetchData'
import { ProductContext } from '../../../store';
import { toast } from 'react-toastify';
import DetailSkeleton from '../../../components/Skeleton/DetailSkeleton';
import moment from 'moment/moment';
import Papa from 'papaparse';

const Index = (props) => {
	const [pageLoader, setPageLoader]	= useState(false),
		[unsubscriberList, setUnsubscriberList]	= useState([]),
		[emailOrDomain, setEmailOrDomain]	= useState(""),
		[emailOrDomainLoaderApi, setEmailOrDomainLoaderApi]	= useState(false),
		[removeLoaderApi, setRemoveLoaderApi]	= useState(false),
		[downloadLoader, setDownloadLoader]	= useState(false),
		[selectedUnsubscribers, setSelectedUnsubscribers]	= useState([]),
		{
			currentTeamId,
			teamUserData
		}	= useContext(ProductContext)
	
	useEffect(() => {
		getUnsubscribersList()
	}, [])

	const getUnsubscribersList	= () => {
		setPageLoader(true)
		PostData("ms1", "api/v1/team/get-unsubscriber", {team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setUnsubscriberList(result.data.unsubscribers)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setPageLoader(false)
		})
	}

	const addUnsubscriberUsers = () => {
		const emailPattern = /(?:\S+@\S+\.\S+)|(@\S+\.\S+)/;
		let emailArray	= emailOrDomain.split('\n').filter(line => line.trim() !== '').filter(line => emailPattern.test(line))
		if( emailArray.length === 0 ){
			setEmailOrDomain("");
			toast.error("No valid email or domains")
		}else{
			setEmailOrDomainLoaderApi(true)
			PostData("ms1", "api/v1/team/add-unsubscriber", {team_id: currentTeamId, email_or_domain: emailArray}).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						setEmailOrDomain("");
						toast.success(`${emailArray.length} entries are added to unsubscriber list`);
						getUnsubscribersList()
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
				setEmailOrDomainLoaderApi(false)
			})
		}
	}

	const selectUnsubscriber = (subscriberIndex) => {
		let subscriberId	= unsubscriberList[subscriberIndex].id,
			selectedUnsubscribersLocal	= [...selectedUnsubscribers]
		if( selectedUnsubscribersLocal.indexOf(subscriberId) !== -1 ){
			selectedUnsubscribersLocal.splice(selectedUnsubscribersLocal.indexOf(subscriberId), 1)
		}else{
			selectedUnsubscribersLocal.push(subscriberId)
		}
		setSelectedUnsubscribers(selectedUnsubscribersLocal)
	}

	const removeUnsubscriber = () => {
		if( selectedUnsubscribers.length === 0 ){
			toast.error("Please select the unsubscriber and try again")
		}else{
			setRemoveLoaderApi(true)
			let data	= {team_id: currentTeamId, unsubscriber_ids: selectedUnsubscribers}
			PostData("ms1", "api/v1/team/remove-unsubscriber", data).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						setSelectedUnsubscribers([])
						getUnsubscribersList()
						toast.success(result.message)						
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
				setRemoveLoaderApi(false)
			})
		}
	}

	const downloadCsv = () => {
		setDownloadLoader(true);
		let csvData	= [["value", "source", "created at"]]
		for( let i = 0; i < unsubscriberList.length; i++ ){
			csvData.push([unsubscriberList[i].email_or_domain, unsubscriberList[i].unsubscribe_type, unsubscriberList[i].createdAt])
		}
		// Create CSV content
		const csvContent = Papa.unparse(csvData);

		// Create a Blob and generate a URL for it
		const blob = new Blob([csvContent], { type: 'text/csv' });
		const url = URL.createObjectURL(blob);
  
		// Create a temporary link and trigger a click to download the file
		const a = document.createElement('a');
		a.href = url;
		a.download = 'unsubscribed.csv';
		document.body.appendChild(a);
		a.click();
		// Cleanup
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
		setDownloadLoader(false);
	}

	return (
		<>
			<div className='contentBottomWrapper paperBackground'>
				<div className='contentBottomTopWrapper'>
					<div className='d-flex-row align-items-center'>
						<div className='title'>
							<div className='d-flex-col lg'>
								<div className='d-flex-row'>
									<h1 className='bold'>Unsubscribe</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='contentBottomMainContainer'>
					<div className='contentBottomMainCenter'>
						<div className='unsubscribes d-flex-col lg'>
							<div className='unsubscribesForm'>
								<p><span className="semiBold">Emails or domains (MUST start with "@") to buried</span><br /><span className="textLight semiBold">We will never send any emails to these email addresses or domains</span></p>
								<div className='textareaScrollController'>
									<div className='textareaContainer'>
										<div className='textareaLineCount'>
											{emailOrDomain.split('\n').map((_, index) => (
												<span key={index + 1}>
													{index + 1}
												</span>
											))}
										</div>
										<div className='textEdit rsEditUnsubscribe'>
											<div className='inputWrapper'>
												<textarea
													rows={15}
													value={emailOrDomain}
													onChange={(e) => setEmailOrDomain(e.target.value)}
													spellcheck="false"
													className='rsTextEdit'
													placeholder="email@example.com
@domain.com"
												/>
											</div>
										</div>
									</div>
								</div>
								<Button onClick={() => addUnsubscriberUsers()} disabled={emailOrDomain.split('\n').filter(line => line.trim() !== '').length <= 0} color="primary">
									{emailOrDomainLoaderApi &&
										<Spinner size={"sm"} />
									}
									Unsubscribe {emailOrDomain.split('\n').filter(line => line.trim() !== '').length} emails/domains
								</Button>
							</div>
							<div className='unsubscribeList d-flex-col'>								
								{pageLoader ?
									<DetailSkeleton />
								: unsubscriberList.length > 0 &&
									<>
										<div className='d-flex-row justify-content-end'>
											<div className='d-flex-row sm'>
												<Button color='primary' className='btn-danger' onClick={() => removeUnsubscriber()} disabled={selectedUnsubscribers.length === 0 || removeLoaderApi}>
													{removeLoaderApi &&
														<Spinner size={"sm"} />
													}
													Remove {selectedUnsubscribers.length} selected items
												</Button>
												<Button className='downloadCsv' onClick={() => downloadCsv()}>
													{downloadLoader &&
														<Spinner size={"sm"} />
													}
													<i className="fa-solid fa-download"></i> Export to CSV
												</Button>
											</div>
										</div>
										<div className='rsTable d-flex-col sm'>
											<div className='header'>
												<div className='headerItem'></div>
												<div className='headerItem'>Email/domain unsubscribe</div>
												<div className='headerItem'>Details</div>
												<div className='headerItem'>Date & time</div>
											</div>
											<div className='tableRows'>
												{unsubscriberList.map((unsubscriber, index) => {
													return (
														<div className='tableRow'>
															<div className='tableRowItem'>
																<div className="checkbox rsCheckbox">
																	<Label className="js-prevent-label-bounce-event-click">
																		<Input type='checkbox' disabled={unsubscriber.unsubscribe_type !== "user"} checked={selectedUnsubscribers.indexOf(unsubscriber.id) !== -1} onChange={()=>selectUnsubscriber(index)} />
																	</Label>
																</div>
															</div>
															<div className='tableRowItem'>{unsubscriber.email_or_domain}</div>
															<div className='tableRowItem'>
																{unsubscriber.unsubscribe_type === "lead" ?
																	<>Manually unsubscribed by the lead</>
																: unsubscriber.unsubscribe_type === "user" &&
																	<>Manually unsubscribed by {teamUserData[unsubscriber.user_id] ? ( teamUserData[unsubscriber.user_id].first_name +""+( teamUserData[unsubscriber.user_id].last_name ? teamUserData[unsubscriber.user_id].last_name : "" ) ) : ""}</>
																}
															</div>
															<div className='tableRowItem'>
																{moment(unsubscriber.createdAt).format("DD/MM/YYYY hh:mm:ss a")}
															</div>
														</div>
													)
												})}												
											</div>
										</div>
									</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>			
		</>
	);
}

export default Index;