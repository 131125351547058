import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/index.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ProductProvider from "./store/ProductProvider";
import { MsalProvider } from '@azure/msal-react';
import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

const root = ReactDOM.createRoot(document.getElementById('root'));

const msalConfig = {
	// MSAL configuration
	auth: {
		clientId: '9c6d84c9-36ca-4ea4-b1e3-29cde970d311',
		authority: 'https://login.microsoftonline.com/f8cdef31-a31e-4b4a-93e4-5f571e91255a',
		redirectUri: 'http://localhost:3000', // The redirect URI configured in your Azure AD app
	},
	cache: {
	  cacheLocation: 'localStorage',
	  storeAuthStateInCookie: true,
	},
	// system: {
	// 	 loggerOptions: {
	// 		  /**
	// 			* Below you can configure MSAL.js logs. For more information, visit:
	// 			* https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
	// 			*/
	// 		  loggerCallback: (level, message, containsPii) => {
	// 				if (containsPii) {
	// 					 return;
	// 				}
	// 				switch (level) {
	// 					 case LogLevel.Error:
	// 						  console.error(message);
	// 						  return;
	// 					 case LogLevel.Info:
	// 						  console.info(message);
	// 						  return;
	// 					 case LogLevel.Verbose:
	// 						  console.debug(message);
	// 						  return;
	// 					 case LogLevel.Warning:
	// 						  console.warn(message);
	// 						  return;
	// 				}
	// 		  }
	// 	 }
	// }
};

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
	<MsalProvider instance={msalInstance}>
		<GoogleOAuthProvider clientId='220662935025-ki05en9q3l47q9vrk9hblaadnjpbbpdf.apps.googleusercontent.com'>
			<ProductProvider>
				<App />
			</ProductProvider>
		</GoogleOAuthProvider>
	</MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
