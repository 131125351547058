import ChangePassword from "./Views/Pages/Auth/ChangePassword";
import ForgotPassword from "./Views/Pages/Auth/ForgotPassword";
import Login from "./Views/Pages/Auth/Login";
import Signup from "./Views/Pages/Auth/Signup";
import Dashboard from "./Views/Pages/Dashboard";
import Campaigns from "./Views/Pages/Campaigns/Index";
import SequenceCreate from "./Views/Pages/Campaigns/SequenceCreate";
import SequenceEdit from "./Views/Pages/Campaigns/SequenceEdit";
import LeadsEdit from "./Views/Pages/Campaigns/LeadsEdit";
import Leads from "./Views/Pages/Campaigns/Leads";
import Review from "./Views/Pages/Campaigns/Review";
import Logout from "./Views/Pages/Logout";
import CampaignReports from "./Views/Pages/Reports/Campaign"
import UnsubscribeIndex from "./Views/Pages/Unsubscribe/Index"
import CockpitTeam	from "./Views/Pages/Cockpit/Teams"
import ReportIndex from "./Views/Pages/Reports/Index"

export const authRoutes = [
	{
		path: "/login",
		name: "Login",
		component: <Login />,
		layout: "/auth"
	},
	{
		path: "/signup",
		name: "Register",
		component: <Signup />,
		layout: "/auth"
	},
	{
		path: "/forgot-password",
		name: "Forgot Password",
		component: <ForgotPassword />,
		layout: "/auth"
	},
	{
		path: "/change-password",
		name: "Change Password",
		component: <ChangePassword />,
		layout: "/auth"
	}
]

export const userRoutes = [
	{
		path: "/dashboard",
		name: "Dashboard",
		component: <Dashboard />,
		layout: "/user",
		icon: "dashboard",
		type: "mainMenu"
	},
	{
		path: "/campaigns",
		name: "Campaigns",
		component: <Campaigns />,
		layout: "/user",
		icon: "campaign",
		type: "mainMenu",
		category: "Main Menu"
	},	
	{
		path: "/reports",
		name: "Reports",
		component: <ReportIndex />,
		layout: "/user",
		icon: "show_chart",
		type: "mainMenu",
		category: "Main Menu",
		// submenu:[
		// 	{
		// 		path: "/reports",
		// 		name: "Overview",
		// 		component: <ReportIndex />,
		// 		layout: "/user",
		// 	}
		// ]
	},	
	// {
	// 	path: "/templates",
	// 	name: "Templates",
	// 	component: <Dashboard />,
	// 	layout: "/user",
	// 	icon: "fa-solid fa-wand-magic-sparkles",
	// 	type: "mainMenu",
	// 	category: "Main Menu"
	// },
	{
		path: "/unsubscribe",
		name: "Unsubscribe",
		component: <UnsubscribeIndex />,
		layout: "/user",
		icon: "Unsubscribe",
		type: "mainMenu",
		category: "Main Menu",
		// itemClass: "space"
	},
	{
		path: "/tasks",
		name: "Tasks",
		component: <Dashboard />,
		layout: "/user",
		icon: "task_alt",
		type: "mainMenu",
		category: "Main Menu"
	},
	{
		path: "/plans-and-billing",
		name: "Plans & Billing",
		component: <Dashboard />,
		layout: "/user",
		icon: "payments",
		type: "mainMenu",
		category: "Settings"
	},
	{
		path: "/settings/teams",
		name: "Team settings",
		component: <CockpitTeam />,
		layout: "/user",
		icon: "manage_accounts",
		type: "mainMenu",
		category: "Settings",
		// submenu:[
		// 	{
		// 		path: "/settings/teams",
		// 		name: "Teams",
		// 		component: <CockpitTeam />,
		// 		layout: "/user",
		// 	}
		// ]
	},
	// {
	// 	path: "/cockpit/team",
	// 	name: "Cockpit",
	// 	component: <CockpitTeam />,
	// 	layout: "/user",
	// 	icon: "fa-solid fa-gauge-high",
	// 	type: "mainMenu",
	// 	category: "Settings",
	// 	submenu:[
	// 		{
	// 			path: "/cockpit/team",
	// 			name: "Teams",
	// 			component: <CockpitTeam />,
	// 			layout: "/user",
	// 		}
	// 	]
	// },
	{
		path: "/reports/campaign/:id",
		name: "Reports",
		component: <CampaignReports />,
		layout: "/user",
	},
	{
		path: "/logut",
		name: "Logout",
		component: <Logout />,
		layout: "/user"
	}

]

export const campaignRoutes = [
	{
		path: "/sequence/generate",
		name: "Campaign",
		component: <SequenceCreate />,
		layout: "/user"
	},
	{
		path: "/sequence/edit",
		name: "Campaign",
		component: <SequenceEdit />,
		layout: "/user"
	},
	{
		path: "/leads/edit",
		name: "Campaign",
		component: <LeadsEdit />,
		layout: "/user"
	},
	{
		path: "/leads",
		name: "Campaign",
		component: <Leads />,
		layout: "/user"
	},
	{
		path: "/review",
		name: "Campaign",
		component: <Review />,
		layout: "/user"
	}
]