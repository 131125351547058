import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ProductContext } from '../../../store';
import { Spinner } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { PostData } from '../../../services/FetchData';
import { toast } from 'react-toastify';

function SequenceCreate(props) {
	const {
		id,
		"*": wildcard
	} = useParams(),
	[stepOption, setStepOption]	= useState('steps'),
	[redirectFlag, setRedirectFlag]	= useState(false),
	[createLoaderApi, setCreateLoaderApi]	= useState(false),
	{
		sequenceDetails,
		setSequenceDetails,
		currentTeamId
	}	= useContext(ProductContext)

	useEffect(() => {
		if( sequenceDetails.length > 0 ){
			setRedirectFlag(true)
		}
	}, [sequenceDetails])

	const createSequence = (type) => {
		setCreateLoaderApi(true)
		if(  type === "email" ){
			let newStepData	= {
									uuid: uuidv4(),
									type: 'email_template',
									ab_testing_flag: false,
									delay_time: 0,
									sender_mail_account_id: '',
									order: 0,
									campaign_id: id,
									team_id: currentTeamId,
									actions: [
										{
											subject: '',
											message: '<p><br></p>',
										}
									]
								}
			PostData("ms1", "api/v1/campaign/create-sequence", newStepData ).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						setSequenceDetails([
							...sequenceDetails,
							result.data.sequence
						])
						setRedirectFlag(true)
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
				setCreateLoaderApi(false)
			});
		}
	}
	
	if( redirectFlag ){
		return <Navigate to={"/user/campaign/"+id+"/sequence/edit"} replace />
	}

	return (
		<div className='campaignEditContentWrapper tabSequence backgroundDotted centered'>
			<div className='center fullWidth'>
				<div className='sequenceEditor backgroundDotted newSequenceMenu'>
					<div className='createManuallyContainer'>
						<div className={`firstStepContainer position-relative ${createLoaderApi ? "overlayLoadingWrapper" : ""}`}>
							{createLoaderApi &&
								<div className='loaderSpinner'>
									<Spinner size={"lg"} color='white' />
								</div>
							}
							<div className='d-flex-row'>
								<div>
									<h2>Build my campaign manually</h2>
									<span className="subtitleFirstStep">Start by choosing your sequence's first step</span>
								</div>
								<div className='spacer'></div>
								<div className='d-flex-row align-items-center'>
									<button className={`btn rsSwitchBox rsToggleStepsConditions secondary ${stepOption !== "steps" ? "checked" : ""} `}>
										<a className="textLabel semiBold" onClick={() => setStepOption('steps')}>Steps</a>
										<a className="textLabel semiBold" onClick={() => setStepOption('conditions')} >Conditions</a>
									</button>
								</div>
							</div>
							<div className='newStepMenu padded'>
								{stepOption !==  'steps' &&
									<div className='d-flex-col align-items-center bold textLight'>
										Add conditions to your sequence and create decisions branches to get the best results possible
									</div>
								}
								{stepOption === 'steps' ?
									<div className='d-flex-col'>
										<span className="subtitle">Automatic Steps</span>
										<div>
											<div className='rowTypes'>
												<div className='stepLabel relative rsAddStep' onClick={() => createSequence("email")}>
													<div className='channelIcon email'>
														<i className="fa-regular fa-envelope"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Email</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'>Send automatic email</span>
														</span>
													</div>
												</div>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon linkedinVisit linkedin'>
														<i className="fa-brands fa-linkedin"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Linkedin</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'>Visit profile</span>
														</span>
													</div>
												</div>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon linkedinInvite linkedin'>
														<i className="fa-brands fa-linkedin"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Linkedin</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'>Invite only</span>
														</span>
													</div>
												</div>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon linkedinInvite linkedin'>
														<i className="fa-brands fa-linkedin"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Linkedin</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'>Send Message</span>
														</span>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex-row marginBlockSteps'>
											<div className='d-flex-col flex-1'>
												<span className="subtitle">Manual execution</span>
												<div className='rowTypes'>
													<div className='stepLabel relative rsAddStep disabled'>
														<div className='channelIcon phone'>
															<i className="fa-solid fa-phone"></i>
														</div>
														<div className='desc'>
															<span className='labelType'>Call</span>
															<span className='descriptionType'>
																<span className='descriptionTypeLabel'>Create a task</span>
															</span>
														</div>
													</div>
													<div className='stepLabel relative rsAddStep disabled'>
														<div className='channelIcon manual'>
															<i className="fa-solid fa-list-check"></i>
														</div>
														<div className='desc'>
															<span className='labelType'>Manual task</span>
															<span className='descriptionType'>
																<span className='descriptionTypeLabel'>Create a task</span>
															</span>
														</div>
													</div>
												</div>
											</div>
											<div className='d-flex-col flex-1'>
												<span className="subtitle">Other steps</span>
												<div className='rowTypes'>
													<div className='stepLabel relative rsAddStep disabled'>
														<div className='channelIcon api'>
															<i className="fa-solid fa-server"></i>
														</div>
														<div className='desc'>
															<span className='labelType'>API</span>
															<span className='descriptionType'>
																<span className='descriptionTypeLabel'>Call an api</span>
															</span>
														</div>
													</div>
													<div className='stepLabel relative rsAddStep disabled'>
														<div className='channelIcon sendToAnotherCampaign'>
															<i className="fa-solid fa-share"></i>
														</div>
														<div className='desc'>
															<span className='labelType'>Send to another campaign</span>
															<span className='descriptionType'>
																<span className='descriptionTypeLabel'></span>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								:
									<div className='d-flex-col conditionsList'>
										<div className='d-flex-col'>
											<div className='d-flex-row'>
												<div className='d-flex-col flex-1'>
													<span className="subtitle">Lead information</span>
													<div className='rowTypes'>
														<div className='stepLabel relative rsAddStep disabled'>
															<div className='channelIcon conditional'>
																<i className="fa-solid fa-code-branch"></i>
															</div>
															<div className='desc'>
																<span className='labelType'>Has email address</span>
																<span className='descriptionType'>
																	<span className='descriptionTypeLabel'></span>
																</span>
															</div>
														</div>
														<div className='stepLabel relative rsAddStep disabled'>
															<div className='channelIcon conditional'>
																<i className="fa-solid fa-code-branch"></i>
															</div>
															<div className='desc'>
																<span className='labelType'>Has Linkedin URL</span>
																<span className='descriptionType'>
																	<i className="fa-brands fa-linkedin"></i>
																	<span className='descriptionTypeLabel'>Linkedin</span>
																</span>
															</div>
														</div>
														<div className='stepLabel relative rsAddStep disabled'>
															<div className='channelIcon conditional'>
																<i className="fa-solid fa-code-branch"></i>
															</div>
															<div className='desc'>
																<span className='labelType'>Has phone number</span>
																<span className='descriptionType'>
																	<span className='descriptionTypeLabel'></span>
																</span>
															</div>
														</div>
														<div className='stepLabel relative rsAddStep disabled'>
															<div className='channelIcon conditional'>
																<i className="fa-solid fa-code-branch"></i>
															</div>
															<div className='desc'>
																<span className='labelType'>Custom condition</span>
																<span className='descriptionType'>
																	<span className='descriptionTypeLabel'></span>
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex-col'>
											<span className="subtitle">Lead actions</span>
											<div className='rowTypes'>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon conditional'>
														<i className="fa-solid fa-code-branch"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Opened email</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'></span>
														</span>
													</div>
												</div>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon conditional'>
														<i className="fa-solid fa-code-branch"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Clicked on link in email</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'></span>
														</span>
													</div>
												</div>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon conditional'>
														<i className="fa-solid fa-code-branch"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Unsubscribe from email</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'></span>
														</span>
													</div>
												</div>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon conditional'>
														<i className="fa-solid fa-code-branch"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Accepted invite</span>
														<span className='descriptionType'>
															<i className="fa-brands fa-linkedin"></i>
															<span className='descriptionTypeLabel'>Linkedin</span>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SequenceCreate;