import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function ScheduleTimeDropdown({value, index, onClick}) {
	const [stepMoreOption, setStepMoreOption]	= useState(false),
	[timeOptions]	= useState({
									"00:00": "0:00 AM",
									"00:30": "0:30 AM",
									"01:00": "1:00 AM",
									"01:30": "1:30 AM",
									"02:00": "2:00 AM",
									"02:30": "2:30 AM",
									"03:00": "3:00 AM",
									"03:30": "3:30 AM",
									"04:00": "4:00 AM",
									"04:30": "4:30 AM",
									"05:00": "5:00 AM",
									"05:30": "5:30 AM",
									"06:00": "6:00 AM",
									"06:30": "6:30 AM",
									"07:00": "7:00 AM",
									"07:30": "7:30 AM",
									"08:00": "8:00 AM",
									"08:30": "8:30 AM",
									"09:00": "9:00 AM",
									"09:30": "9:30 AM",
									"10:00": "10:00 AM",
									"10:30": "10:30 AM",
									"11:00": "11:00 AM",
									"11:30": "11:30 AM",
									"12:00": "12:00 PM",
									"12:30": "12:30 PM",
									"13:00": "1:00 PM",
									"13:30": "1:30 PM",
									"14:00": "2:00 PM",
									"14:30": "2:30 PM",
									"15:00": "3:00 PM",
									"15:30": "3:30 PM",
									"16:00": "4:00 PM",
									"16:30": "4:30 PM",
									"17:00": "5:00 PM",
									"17:30": "5:30 PM",
									"18:00": "6:00 PM",
									"18:30": "6:30 PM",
									"19:00": "7:00 PM",
									"19:30": "7:30 PM",
									"20:00": "8:00 PM",
									"20:30": "8:30 PM",
									"21:00": "9:00 PM",
									"21:30": "9:30 PM",
									"22:00": "10:00 PM",
									"22:30": "10:30 PM",
									"23:00": "11:00 PM",
									"23:30": "11:30 PM",
									"23:59": "11:59 PM",
								});

	const onClickEvent	= ( value ) => {
		setStepMoreOption(false)
		onClick(index, value)
	}

	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => setStepMoreOption((prevstate) => !prevstate)} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="div" className='rsDropdown rsAssignStepMultiple emailSender'>
				<button className={`btn btnDropdown rsDropdownToggle`} disabled={typeof value !== "undefined" ? false : true}>
					<div className='dropdownValue'>
						{timeOptions[value]}
					</div>
				</button>
			</DropdownToggle>
			<DropdownMenu className='dropdownPicker'>
				{Object.keys(timeOptions).map((timeOption, key) => {
					return(
						<div key={key} className='uiDropdownItem rsSelectItem' onClick={() => onClickEvent(timeOption)}>
							{timeOptions[timeOption]}
						</div>
					)
				})}				
			</DropdownMenu>
		</Dropdown>
	);
}

export default ScheduleTimeDropdown;