import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const ReviewSequenceSkeleton = (props) => {
	return (
		<React.Fragment>
			<SkeletonTheme baseColor="#f7fafc" highlightColor="#eff1ff">
				<div className='reviewStart d-block'>
					<Skeleton height={20} />
				</div>
				<div className="stepVerticalSeparator d-flex-col noGap">
					<div className="stepVerticalSeparatorLine"></div>
				</div>
				<div className="rsTreeReviewSequence d-flex-col noGap">
					<div className="d-flex-col noGap relative rsTreeEditReviewStep rsLayoutRight">
						<div className='d-flex-row align-items-center reviewTitle relative sm d-block'>
							<Skeleton height={30} />
						</div>
						<div className="reviewContent">  
							<div className="viewEmail d-flex-col flex-1">
								<div className="d-flex-col">
									<Skeleton height={30} />
									<Skeleton height={30} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="stepVerticalSeparator d-flex-col noGap">
					<div className="stepVerticalSeparatorLine"></div>
				</div>
				<div className="reviewDelay d-block">
					<Skeleton height={30} />
				</div>
				<div className="stepVerticalSeparator d-flex-col noGap">
					<div className="stepVerticalSeparatorLine"></div>
				</div>
				<div className="rsTreeReviewSequence d-flex-col noGap">
					<div className="d-flex-col noGap relative rsTreeEditReviewStep rsLayoutRight">
						<div className='d-flex-row align-items-center reviewTitle relative sm d-block'>
							<Skeleton height={30} />
						</div>
						<div className="reviewContent">  
							<div className="viewEmail d-flex-col flex-1">
								<div className="d-flex-col">
									<Skeleton height={30} />
									<Skeleton height={30} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</SkeletonTheme>
		</React.Fragment>
	);
}

export default ReviewSequenceSkeleton;