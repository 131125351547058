import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { ProductContext } from '../../../store';
import { Link, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { PostData } from '../../../services/FetchData';
import { toast } from 'react-toastify';
import DetailSkeleton from '../../../components/Skeleton/DetailSkeleton';
import TimeAgo from 'timeago-react';
import CampaignRowDropdown from '../../../components/CampaignRowDropdown';
import RSToolTip from '../../../components/RSToolTip';

function Index(props) {
	const //[searchText, setSearchText]						= useState(''),
		// [campaignStatusFlag, setCampaignStatusFlag]		= useState(false),
		[campaignLoader, setCampaignLoader]					= useState(true),
		[createCampaignLoader, setCreateCampaignLoader]	= useState(false),
		[campaignReports, setCampaignReports]	= useState({}),
		// [campaignStatusFilter, setCampaignStatusFilter]	= useState(["Solomon"]),
		// [senderFilterFlag, setSenderFilterFlag]			= useState(false),
		// [senderFilter, setSenderFilter]						= useState([]),
		// [groupFilterFlag, setGroupFilterFlag]				= useState(false),
		// [groupFilter, setGroupFilter]							= useState([]),
		// [creatorFilterFlag, setCreatorFilterFlag]			= useState(false),
		// [creatorFilter, setCreatorFilter]					= useState([]),
		[campaigns, setCampaigns]								= useState([]),
		[campaignEncryptId, setCampaignEncryptId]			= useState(null),
		[campaignStatusLoader, setCampaignStatusLoader]	= useState([]),
		[deleteCampaignModal, setDeleteCampaignModal]	= useState(false),
		[deleteCampaignLoaderAPI, setDeleteCampaignLoaderAPI]	= useState(false),
		[deleteCampaignId, setDeleteCampaignId]	= useState(null),
		{
			//userData,
			currentTeamId,
			setCurrentTeamId,
			teamUserData
		}																= useContext(ProductContext)
	
	useEffect(() => {
		if( currentTeamId ){
			getCampaigns()
		}else{
			if( Cookies.get("_team_id") ){
				setCurrentTeamId(Cookies.get("_team_id"))
			}
		}
	}, [currentTeamId])

	const getCampaigns = () => {
		setCampaignLoader(true)
		PostData("ms1", "api/v1/campaign/get-team-campaigns", {team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setCampaigns(result.data.campaigns);
					let campaignReportsResult	= result.data.campaignReports,
						campaignReportsTemp	= {};
					for( let i = 0; i < campaignReportsResult.length; i++ ){
						campaignReportsTemp[campaignReportsResult[i]._id]	= campaignReportsResult[i].count
					}
					setCampaignReports(campaignReportsTemp)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else if( result.status === "api_error" ){
					toast.error(result.message.message)
				}
			}
			setCampaignLoader(false)
		})
	}

	// const toogleSenderFilter = () => {
	// 	setSenderFilterFlag((prevState) => !prevState)
	// }

	// const toggleGroupFilter = () => {
	// 	setGroupFilter((prevState) => !prevState)
	// }

	// const toggleCreatorFilter = () => {
	// 	setCreatorFilterFlag((prevState) => !prevState)
	// }

	const createNewCampaign = () => {
		setCreateCampaignLoader(true)
		PostData("ms1", "api/v1/campaign/create-campaign", {team_id: currentTeamId, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Calcutta" ? "Asia/Kolkata" : Intl.DateTimeFormat().resolvedOptions().timeZone}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setCampaignEncryptId(result.data.campaign.uuid);
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const duplicateCampaign = (index) => {
		PostData("ms1", "api/v1/campaign/duplicate-campaign", {team_id: currentTeamId, campaign_id: campaigns[index].uuid}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setCampaignEncryptId(result.data.campaign.uuid);
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}
	
	const initiateDeleteCampaign = (index) => {
		setDeleteCampaignId(campaigns[index].uuid)
		setDeleteCampaignLoaderAPI(false)
		setDeleteCampaignModal(true)
	}

	const deleteCampaign	= () => {
		setDeleteCampaignLoaderAPI(true)
		PostData("ms1", "api/v1/campaign/delete-campaign", {team_id: currentTeamId, campaign_id: deleteCampaignId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					toast.success(result.message)
					toggleDeleteWarningModal()
					getCampaigns()
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const toggleDeleteWarningModal	= () => {
		setDeleteCampaignId(null)
		setDeleteCampaignLoaderAPI(false)
		setDeleteCampaignModal(false)
	}

	const archiveCampaign = (index) => {
		PostData("ms1", "api/v1/campaign/update-archive-status", {team_id: currentTeamId, campaign_id: campaigns[index].uuid, archive_status: !campaigns[index].archive_status}).then( result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setCampaigns(prevItems => [
						...prevItems.slice(0, index),
						{ ...prevItems[index], archive_status: !campaigns[index].archive_status },
						...prevItems.slice(index + 1),
					 ])
					 toast.success(result.message)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const toggleCampaignStatus = (e, index) => {
		setCampaignStatusLoader([...campaignStatusLoader, campaigns[index].id])
		PostData("ms1", "api/v1/campaign/update-status", {team_id: currentTeamId, campaign_id: campaigns[index].uuid, status: e.target.checked}).then( result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setCampaigns(prevItems => [
						...prevItems.slice(0, index),
						{ ...prevItems[index], status: !e.target.checked },
						...prevItems.slice(index + 1),
					 ])			
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			let campaignStatusLoaderLocal	= [...campaignStatusLoader],
				loaderIndex	= campaignStatusLoaderLocal.indexOf(campaigns[index].id);
			setCampaignStatusLoader([
				...campaignStatusLoader.slice(0, loaderIndex),
				...campaignStatusLoaderLocal.slice(index+1)
			])
		})
	}

	const gotoCampaignDetail = (index)  => {
		if( !campaigns[index].archive_status ){
			setCampaignEncryptId(campaigns[index].uuid);
		}
	}

	if( campaignEncryptId ){
		return <Navigate to={"/user/campaign/"+campaignEncryptId+"/sequence/generate"} replace />
	}

	return (
		<React.Fragment>
			<div className='contentBottomWrapper paperBackground'>
				<div className='contentBottomTopWrapper'>
					<div className='d-flex-row align-items-center'>
						<div className='title'>
							<h1>Campaigns</h1>
						</div>
					</div>
				</div>
				<div className='contentBottomMainContainer'>
					<div className='contentBottomMainCenter'>
						<div className='d-flex-col campaignsList fullWidth'>
							<div className='d-flex-col sm'>
								{/* <div className='rsSearchInput'>
									<i className="fa-solid fa-magnifying-glass"></i>
									<Input type='text' placeholder='Search a campaign...' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
									<i className="fa-solid fa-circle-xmark rsClearSearch" onClick={() => setSearchText('')}></i>
								</div> */}
								<div className='d-flex-row sm align-items-center'>
									{/* <Dropdown isOpen={campaignStatusFlag} toggle={toggleCampaignStatus} direction={"down"} className='dropdownTrigger'>
										<DropdownToggle tag="div" className='campaignFilter'>
											{campaignStatusFilter.length === 0 ?
												<div>Status: All</div>
												:
												<>
													<div>Status</div>
													<div><span className='pastille'>{campaignStatusFilter.length}</span></div>
												</>
											}
											{campaignStatusFlag ?
												<i className="fa-solid fa-chevron-up"></i>
												:
												<i className="fa-solid fa-chevron-down"></i>
											}
										</DropdownToggle>
										<DropdownMenu className='campaignFilterDropdownMenu'>
											<div>
												<div className='campaignFilters'>
													<div className='items'>
														<div className='item'>
															<div className="checkbox rsCheckbox rsCheckFilter">
																<Label className="js-prevent-label-bounce-event-click">
																	<Input type='checkbox' />
																</Label>
															</div>
															<i className="fa-solid fa-pen-ruler"></i>
															Drafts
														</div>
														<div className='item'>
															<div className="checkbox rsCheckbox rsCheckFilter">
																<Label className="js-prevent-label-bounce-event-click">
																	<Input type='checkbox' />
																</Label>
															</div>
															<i className="fa-solid fa-spinner"></i>
															Running
														</div>
														<div className='item'>
															<div className="checkbox rsCheckbox rsCheckFilter">
																<Label className="js-prevent-label-bounce-event-click">
																	<Input type='checkbox' />
																</Label>
															</div>
															<i className="fa-solid fa-check"></i>
															Ended
														</div>
														<div className='item'>
															<div className="checkbox rsCheckbox rsCheckFilter">
																<Label className="js-prevent-label-bounce-event-click">
																	<Input type='checkbox' />
																</Label>
															</div>
															<i className="fa-solid fa-pause"></i>
															Paused
														</div>
														<div className='item'>
															<div className="checkbox rsCheckbox rsCheckFilter">
																<Label className="js-prevent-label-bounce-event-click">
																	<Input type='checkbox' />
																</Label>
															</div>
															<i className="fa-solid fa-circle-exclamation"></i>
															In error
														</div>
														<div className='item'>
															<div className="checkbox rsCheckbox rsCheckFilter">
																<Label className="js-prevent-label-bounce-event-click">
																	<Input type='checkbox' />
																</Label>
															</div>
															<i className="fa-solid fa-box"></i>
															Archived
														</div>
													</div>
													<div className='clearFilters'>
														Clear filter
													</div>
												</div>
											</div>
										</DropdownMenu>
									</Dropdown>
									<Dropdown isOpen={senderFilterFlag} toggle={toogleSenderFilter} direction={"down"} className='dropdownTrigger'>
										<DropdownToggle tag="div" className='campaignFilter'>
											{senderFilter.length === 0 ?
												<div>Sender: All</div>
												:
												<>
													<div>Sender</div>
													<div><span className='pastille'>{senderFilter.length}</span></div>
												</>
											}
											{senderFilterFlag ?
												<i className="fa-solid fa-chevron-up"></i>
												:
												<i className="fa-solid fa-chevron-down"></i>
											}
										</DropdownToggle>
										<DropdownMenu className='campaignFilterDropdownMenu'>
											<div>
												<div className='campaignFilters'>
													<div className='rsSearchInput'>
														<i className="fa-solid fa-magnifying-glass"></i>
														<Input type='text' placeholder='Search a sender...' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
														<i className="fa-solid fa-circle-xmark rsClearSearch" onClick={() => setSearchText('')}></i>
													</div>
													<div className='items'>
														<div className='item'>
															<div className="checkbox rsCheckbox rsCheckFilter">
																<Label className="js-prevent-label-bounce-event-click">
																	<Input type='checkbox' />
																</Label>
															</div>
															<div className={`avatar uiUserPicture sm avatarPrimary${((userData.id) - 1 % 10)}`}>
																{userData.profile_picture ?
																	<img src={userData.profile_picture} alt={userData.first_name} className='picture' />
																	:
																	<>
																		{userData.first_name.substr(0, 1)}{userData.last_name && userData.last_name.substr(0, 1)}
																	</>
																}
															</div>
															{userData.first_name} {userData.last_name && userData.last_name}
														</div>
													</div>
													<div className='clearFilters'>
														Clear filter
													</div>
												</div>
											</div>
										</DropdownMenu>
									</Dropdown>
									<Dropdown isOpen={groupFilterFlag} toggle={toggleGroupFilter} direction={"down"} className='dropdownTrigger'>
										<DropdownToggle tag="div" className='campaignFilter'>
											{groupFilter.length === 0 ?
												<div>Group: All</div>
												:
												<>
													<div>Group</div>
													<div><span className='pastille'>{senderFilter.length}</span></div>
												</>
											}
											{groupFilterFlag ?
												<i className="fa-solid fa-chevron-up"></i>
												:
												<i className="fa-solid fa-chevron-down"></i>
											}
										</DropdownToggle>
										<DropdownMenu className='campaignFilterDropdownMenu'>
											<div>
												<div className='campaignFilters'>
													<div className='rsSearchInput'>
														<i className="fa-solid fa-magnifying-glass"></i>
														<Input type='text' placeholder='Search a group...' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
														<i className="fa-solid fa-circle-xmark rsClearSearch" onClick={() => setSearchText('')}></i>
													</div>
													<div className='items'>
													</div>
													<div className='clearFilters'>
														Clear filter
													</div>
												</div>
											</div>
										</DropdownMenu>
									</Dropdown>
									<Dropdown isOpen={creatorFilterFlag} toggle={toggleCreatorFilter} direction={"down"} className='dropdownTrigger'>
										<DropdownToggle tag="div" className='campaignFilter'>
											{creatorFilter.length === 0 ?
												<div>Creators: All</div>
												:
												<>
													<div>Creators</div>
													<div><span className='pastille'>{senderFilter.length}</span></div>
												</>
											}
											{creatorFilterFlag ?
												<i className="fa-solid fa-chevron-up"></i>
												:
												<i className="fa-solid fa-chevron-down"></i>
											}
										</DropdownToggle>
										<DropdownMenu className='campaignFilterDropdownMenu'>
											<div>
												<div className='campaignFilters'>
													<div className='rsSearchInput'>
														<i className="fa-solid fa-magnifying-glass"></i>
														<Input type='text' placeholder='Search a creators...' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
														<i className="fa-solid fa-circle-xmark rsClearSearch" onClick={() => setSearchText('')}></i>
													</div>
													<div className='items'>
														<div className='item'>
															<div className="checkbox rsCheckbox rsCheckFilter">
																<Label className="js-prevent-label-bounce-event-click">
																	<Input type='checkbox' />
																</Label>
															</div>
															<div className={`avatar uiUserPicture sm avatarPrimary${((userData.id - 1) % 10)}`}>
																{userData.profile_picture ?
																	<img src={userData.profile_picture} alt={userData.first_name} className='picture' />
																	:
																	<>
																		{userData.first_name.substr(0, 1)}{userData.last_name && userData.last_name.substr(0, 1)}
																	</>
																}
															</div>
															{userData.first_name} {userData.last_name && userData.last_name}
														</div>
													</div>
													<div className='clearFilters'>
														Clear filter
													</div>
												</div>
											</div>
										</DropdownMenu>
									</Dropdown>
									{(campaignStatusFilter.length > 0 || senderFilter.length > 0 || groupFilter.length > 0 || creatorFilter.length > 0) &&
										<Button className='btn-tertiary' color='link'>Clear all</Button>
									} */}
									<div className="spacer"></div>
									<Button color='primary' className='rsCreateCampaign md' disabled={createCampaignLoader} onClick={() => createNewCampaign()}>
										{createCampaignLoader &&
											<Spinner size={"sm"} />
										}
										<i className="fa-solid fa-wand-magic-sparkles"></i>
										Create a new campaign
									</Button>
								</div>
							</div>
							<div className='rsTable campaignsList'>
								<div className="header">
									<div className="headerItem relative">
										Status
									</div>
									<div className="headerItem rsSortable">Name</div>
									<div className="headerItem">Leads ended</div>
									<div className="headerItem">Sender</div>
									<div className="headerItem">Tag</div>
									<div className="headerItem rsSortable" data-sort="down" data-column="createdBy">Creator</div>
									<div className="headerItem rsSortable" data-sort="down" data-column="createdAt">Created at</div>
									<div className="headerItem">Actions</div>
								</div>
								<div className='tableRows'>
									{campaignLoader ? 
										<DetailSkeleton />
									:
										<>
											{campaigns.length > 0 ?
												campaigns.map((campaign, index) => {
													return (
														<div className={`tableRow campaignEdit ${campaign.archive_status ? "archived" : "" }`} key={index} onClick={() => gotoCampaignDetail(index)}>
															<div className="campaignStateCampaignSettingsModal">
																{campaign.archive_status ?
																	<i className="cursor-pointer fa-solid fa-box archiveIcon textInactive"></i>
																: ( campaign.running_status === "running" && campaign.status ) ?
																	<RSToolTip item={{ placement: 'bottom', text: 'Right now, we are out of your schedules.' }} id={`campaignStatusToolTip${campaign.id}`}>
																		<i className="cursor-pointer fa-solid fa-calendar runningIcon textInactive"></i>
																	</RSToolTip>
																: ( campaign.running_status === "running" && !campaign.status ) ?
																	<i className="cursor-pointer fa-solid fa-pause pauseIcon textInactive"></i>
																: ( campaign.running_status === "in error" ) ?
																	<i className="cursor-pointer fa-solid fa-triangle-exclamation alertColor"></i>
																:
																	<i className="cursor-pointer fa-solid fa-pen-ruler draftIcon textInactive"></i>
																}
																<div className="spacer"></div>
																{!campaign.archive_status &&
																	<div className="rsSwitch customControl customSwitch rsCampaignsTogglePlayPause playPause" onClick={(e) => e.stopPropagation()}>
																		<Label className="customControlLabel">
																			<Input type="checkbox" checked={campaign.status} disabled={campaignStatusLoader.indexOf(campaign.id) !== -1} onChange={(e) => {toggleCampaignStatus( e, index)}} />
																			<span className="switchDisplay"></span>
																			<span className="rsLabel"></span>
																		</Label>
																	</div>
																}
															</div>
															<div className="tableRowItem campaignName">
																<div className='name'>{campaign.name}</div>
															</div>
															<div className='tableRowItemFlex campaignLeadsCount'>
																<span>
																	{/* <img src={require("./../../../assets/images/circle-filled-0.svg").default} className='img-fluid' /> */}
																	{campaign.leads_ended_count}/{campaign.leads_count}
																</span>
															</div>
															<div className={`campaignSenders ${ campaign.sender_user_id ? "" : "empty"}`}>
																{(campaign.sender_user_id && teamUserData[campaign.sender_user_id]) &&
																	<div className={`avatar uiUserPicture avatarPrimary${((campaign.sender_user_id - 1) % 10)}`}>
																		{teamUserData[campaign.sender_user_id].profile_picture ?
																			<img src={teamUserData[campaign.sender_user_id].profile_picture} alt={teamUserData[campaign.sender_user_id].first_name} className='picture' />
																			:
																			<>
																				{teamUserData[campaign.sender_user_id].first_name.substr(0, 1)}{teamUserData[campaign.sender_user_id].last_name && teamUserData[campaign.sender_user_id].last_name.substr(0, 1)}
																			</>
																		}
																	</div>
																}
															</div>
															<div className='tableRowItem campaignGroups d-flex-row sm'>
																{/* <span className='group'>Solomon</span> */}
															</div>
															<div className='tableRowItem campaignCreatedBy d-flex-row sm'>
																{(campaign.created_user_id && teamUserData[campaign.created_user_id]) &&
																	<div className={`avatar uiUserPicture avatarPrimary${((campaign.created_user_id - 1) % 10)}`}>
																		{teamUserData[campaign.created_user_id].profile_picture ?
																			<img src={teamUserData[campaign.created_user_id].profile_picture} alt={teamUserData[campaign.created_user_id].first_name} className='picture' />
																			:
																			<>
																				{teamUserData[campaign.created_user_id].first_name.substr(0, 1)}{teamUserData[campaign.created_user_id].last_name && teamUserData[campaign.created_user_id].last_name.substr(0, 1)}
																			</>
																		}
																	</div>
																}
															</div>
															<div className='tableRowItem campaignCreatedAt'>
																<span className='fromNow'>
																	<TimeAgo
																		datetime={campaign.createdAt}
																	/>
																</span>
															</div>
															<div className='tableRowItemFlex campaignActions'>
																{(typeof campaignReports[campaign.id] !== "undefined" && campaignReports[campaign.id] > 0 ) ?
																	<Link to={`/user/reports/campaign/${campaign.uuid}`} className='btn btnAction rsCampaignReports'>
																		<i className="fa fa-chart-simple"></i>
																	</Link>
																:
																	<button className="btn btnAction rsCampaignReports" disabled aria-expanded="false">
																		<i className="fa fa-chart-simple"></i>
																	</button>
																}
																<div onClick={(e) => {e.stopPropagation();}}>
																	<CampaignRowDropdown
																		index={index}
																		campaign={campaign}
																		onDuplicate={(campaignId) => duplicateCampaign(campaignId)}
																		onArchive={(campaignId) => archiveCampaign(campaignId)}
																		onDelete={(campaignId) => initiateDeleteCampaign(campaignId)}
																	/>
																</div>
															</div>
														</div>
													)
												})
											:
												<div className="emptyPlaceholder">
													<div>
														<img src={require("./../../../assets/images/paper-plane-grey.png")} className='img-fluid' alt="No campaign found" />
														<div className="rsInfoMsg disabled">
															<div className="flex-1">No campaigns found.</div>
														</div>
													</div>
												</div>
											}
										</>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{deleteCampaignModal &&
				<Modal isOpen={deleteCampaignModal} className='deleteErrorCampaignModal' centered={true} size='lg'>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation alertColor paddingRight10"></i>Are you sure you want to delete this campaign?</h2>
							<p>Deleting a campaign is permanent, and cannot be recovered</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={deleteCampaignLoaderAPI} onClick={() => toggleDeleteWarningModal()}>No, keep this campaign</Button>
							<Button className='md okWarningButton btn-danger' color='primary' disabled={deleteCampaignLoaderAPI} onClick={() => deleteCampaign()}>
								{deleteCampaignLoaderAPI &&
									<Spinner size={"sm"} />
								}
								Yes, permanently delete this campaign
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
		</React.Fragment>
	);
}

export default Index;