import React, { useContext, useEffect, useRef, useState } from 'react';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledDropdown } from 'reactstrap';
import { PostData } from '../services/FetchData';
import { Link, Navigate } from 'react-router-dom';
import TimeAgo from 'timeago-react';
import Cookies from 'js-cookie';
import { ProductContext } from '../store';
import RSToolTip from './RSToolTip';
import SignatureEditor from './EmailEditor/SignatureEditor';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';


const Header = () => {
	const { instance, accounts, inProgress } = useMsal(),
		[loading, setLoading] = useState(false),
		[warningModal, setWarningModal] = useState(false),
		{
			userData,
			setUserData,
			accountSettingFlag,
			setAccountSettingFlag,
			accountSettingTab,
			setAccountSettingTab,
			googleUser,
			setGoogleUser,
			userMailAccount,
			setUserMailAccount,
			teamMailAccountData,
			setTeamMailAccountData,
			teamLimits,
			setTeamLimits,
			emailSignature,
			setEmailSignature,
			autoCloseClass,
			setAutoCloseClass,
			leftHoverClass,
		} = useContext(ProductContext),
		[notificationData, setNotificationData] = useState([]),
		[notCount, setNotCount] = useState(0),
		[gotoNotify, setGotoNotify] = useState(false),
		[redirectLogout, setRedirectLogout] = useState(false),
		[userDataLocal, setUserDataLocal] = useState({}),
		[errors, setErrors] = useState({}),
		[oldPassword, setOldPassword] = useState(""),
		[newPassword, setNewPassword] = useState(""),
		[oldPasswordShown, setOldPasswordShown] = useState(false),
		[newPasswordShown, setNewPasswordShown] = useState(false),
		[saveApiLoader, setSaveApiLoader]	= useState(false),
		[deleteWarningModal, setDeleteWarningModal]	= useState(false),
		[disconnectApiLoader, setDisconnectApiLoader]	= useState(false),
		[profileImageApiLoader, setProfileImageApiLoader]	= useState(false),
		[deleteProfileApiLoader, setDeleteProfileApiLoader]	= useState(false),
		[teamLimitsLocal, setTeamLimitsLocal]	= useState({}),
		[emailSignatureLocal, setEmailSignatureLocal]	= useState({}),
		[accountSettingHeadings] = useState({
			'account': "My account",
			'emailProvider': "Email provider",
			'limits': "Limits",
			'signature': "Email signature"
		}),
		intervalIdRef = useRef(null),
		profileFileUploadRef	= useRef(null);

	useEffect(() => {
		if (Cookies.get('_round_sales_token')) {
			//getNotification();
		}
		intervalIdRef.current = setInterval(() => {
			//getNotification()
		}, 20000)
		return () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current)
			}
		}
	}, [])

	useEffect(() => {
		setUserDataLocal({ ...userData })
	}, [userData])

	useEffect(() => {
		if( googleUser ){
			connectGoogleAccount()
		}
	}, [googleUser])

	const getNotification = () => {
		const limit = {
			limit: 5
		}
		setLoading(true)
		PostData('ms1', 'api/v1/notification/getusernotification', limit).then((response) => {
			if (response !== 'invalid') {
				if (response.status === "success") {
					setNotificationData(response.data.notification)
					setNotCount(response.data.new_arrival)
					setLoading(false)
				} else if (response.status === "server_error") {
					setNotificationData([])
					setLoading(false)
				}
			}
		});
	}

	const openNotification = () => {
		const status = {
			status: true
		}
		setLoading(true)
		PostData('ms1', 'api/v1/notification/notifymarkasread', status).then((response) => {
			if (response !== 'invalid') {
				if (response.status === "success") {
					setLoading(false)
					setNotCount(0)
				} else if (response.status === "server_error") {
					setLoading(false)
				}
			}
		});
	}

	const notificationClick = (id) => {
		const status = {
			notificationId: id
		}
		PostData('ms1', 'api/v1/notification/markasread', status).then((response) => {
			if (response !== 'invalid') {
				if (response.status === "success") {
					setGotoNotify(true)
				} else if (response.status === "server_error") {
					setGotoNotify(false)
				}
			}
		});
	}

	const toggleAutoClose = () => {
		if( autoCloseClass ){
			setAutoCloseClass(null)
		}else{
			setAutoCloseClass('autoClose')
		}
	}

	const logOut = () => {
		if (window.RvBotView) {
			window.RvBotView.destory()
		}
		Cookies.remove('_round_sales_token');
		clearInterval(intervalIdRef.current);
		intervalIdRef.current = null
		setRedirectLogout(true);
	}

	const toggleOpenSettings = () => {
		setAccountSettingFlag(!accountSettingFlag);
	}

	const handleChange = (value, index) => {
		setUserDataLocal({
			...userDataLocal,
			[index]: value
		})
		if ( index === "first_name" ){
			if (value === "") {
				setErrors({
					...errors,
					first_name: "Please enter the first name"
				})
			} else {
				setErrors({
					...errors,
					first_name: null
				})
			}
		}else if( index === "last_name" ){
			if ( value === "" ) {
				setErrors({
					...errors,
					last_name: "Please enter the last name"
				})
			} else {
				setErrors({
					...errors,
					last_name: null
				})
			}
		}
	}

	const handlePassword = (value, index) => {
		if (index === "old_password") {
			setOldPassword(value)
			if( newPassword !== "" && value === "" ){
				setErrors({
					...errors,
					old_password: "Password field is required"
				})
			}else if( value !== "" && value.length < 8 ){
				setErrors({
					...errors,
					old_password: "Password is Too short min 8 char"
				})
			}else {
				let updateObject	= {
					...errors,
					old_password: null
				}
				if( newPassword === "" ){
					updateObject.new_password	= null
				}
				setErrors(updateObject)
			}
		}

		if (index === "new_password") {
			setNewPassword(value)
			if( oldPassword !== "" && value === "" ){
				setErrors({
					...errors,
					new_password: "Password field is required"
				})
			}else if( value !== "" && value.length < 8 ){
				setErrors({
					...errors,
					new_password: "Password is Too short min 8 char"
				})
			}else{
				let updateObject	= {
					...errors,
					new_password: null
				}
				if( oldPassword === "" ){
					updateObject.old_password	= null
				}
				setErrors(updateObject)
			}
		}
	}	

	const login = useGoogleLogin({
		onSuccess: (codeResponse) => {
			setGoogleUser(codeResponse)
		},
		onError: (error) => console.log('Login Failed:', error),
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly',
	})

	const uploadProfilePicture = (event) => {
		// Validate file type (allow only images)
		const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
		if (!allowedImageTypes.includes(event.target.files[0].type)) {
			toast.warn("Only JPEG, PNG, and GIF images are allowed.")
			profileFileUploadRef.current.value = null;
			return
		}
		setProfileImageApiLoader(true)
		const formData = new FormData()
		formData.append('profile_picture', event.target.files[0])
		PostData('ms1', 'api/v1/user/upload-profile-picture', formData, true).then((result) => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setUserData({
						...userData,
						profile_picture: result.data.profile_picture
					})
				}else if( result.status === "failure" ) {
					toast.warn(result.message)
				}else if( result.status === "api_error" ){
					toast.warn(result.message.message)
				}
			}
			setProfileImageApiLoader(false)
			profileFileUploadRef.current.value = null;
		})
	}

	const removeProfilePicture = () => {
		setDeleteProfileApiLoader(true)
		PostData("ms1", "api/v1/user/remove-profile-picture").then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setUserData({
						...userData,
						profile_picture: null
					})
					toast.info(result.message)
				}else if( result.status === "failure" ) {
					toast.warn(result.message)
				}else if( result.status === "api_error" ){
					toast.warn(result.message.message)
				}
			}
			setDeleteProfileApiLoader(false)
		})
	}

	const saveChanges = () => {
		setSaveApiLoader(true)
		if( accountSettingTab === "account"  ){
			accountSaveChanges()
		} else if ( accountSettingTab === "limits" ){
			updateTeamLimit()
		}else if( accountSettingTab === "signature" ){
			updateEmailSignature()
		}
	}

	const accountSaveChanges = () => {
		let errorFlag	= false
		if( userDataLocal.first_name === "" ){
			errorFlag	= true
			setErrors({
				...errors,
				first_name: "First name is required"
			})
		}else{
			setErrors({
				...errors,
				first_name: null
			})
		}
		if( userDataLocal.last_name === "" ){
			errorFlag	= true
			setErrors({
				...errors,
				first_name: "Last name is required"
			})
		}else{
			setErrors({
				...errors,
				first_name: null
			})
		}

		if( oldPassword || newPassword ){
			if( oldPassword === "" ){
				errorFlag	= true
				setErrors({
					...errors,
					old_password: "Password filed is required"
				})
			}else if( oldPassword.length < 8 ){
				errorFlag	= true
				setErrors({
					...errors,
					old_password: "Password is Too short min 8 char"
				})
			}else{
				setErrors({
					...errors,
					old_password:null
				})
			}

			if( newPassword === "" ){
				errorFlag	= true
				setErrors({
					...errors,
					new_password: "Password filed is required"
				})
			}else if( newPassword.length < 8 ){
				errorFlag	= true
				setErrors({
					...errors,
					new_password: "Password is Too short min 8 char"
				})
			}else{
				setErrors({
					...errors,
					new_password:null
				})
			}
		}

		if( errorFlag ){
			setSaveApiLoader(false)
			return
		}else{
			PostData("ms1", "api/v1/user/update-profile", {...userDataLocal, new_password: newPassword, old_password: oldPassword}).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						setNewPassword(null)
						setOldPassword(null)
						setUserData({
							...userData,
							first_name: userDataLocal.first_name,
							last_name: userDataLocal.last_name,
							gdpr_check: userDataLocal.gdpr_check
						})
						toast.info(result.message)
					}else if( result.status === "failure" ){
						toast.warn(result.message)
					}else if( result.status === "api_error" ){
						toast.warn(result.message.message)
					}
				}
				setSaveApiLoader(false)
			})
		}
	}

	const updateTeamLimit = () => {
		PostData("ms1", "api/v1/team/update-limit", {...teamLimitsLocal}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setTeamLimits({
						...teamLimitsLocal
					})
					toast.info(result.message)
				}else if( result.status === "failure" ){
					toast.warn(result.message)
				}else if( result.status === "api_error" ){
					toast.warn(result.message.message)
				}
			}
			setSaveApiLoader(false)
		})
	}

	const updateEmailSignature = () => {
		PostData("ms1", "api/v1/team/update-email-signature", {...emailSignatureLocal}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setEmailSignature({
						...emailSignatureLocal
					})
					toast.info(result.message)
				}else if( result.status === "failure" ){
					toast.warn(result.message)
				}else if( result.status === "api_error" ){
					toast.warn(result.message.message)
				}
			}
			setSaveApiLoader(false)
		})
	}

	const connectGoogleAccount = () => {
		let param = {
			code: googleUser.code,
			frontend_url: window.location.protocol+"//"+window.location.host
		}
		PostData('ms1', 'api/v1/mail-accounts/gmail-account', param).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setUserMailAccount(result.data)
					setTeamMailAccountData({
						...teamMailAccountData,
						[result.data.user_id]:{
							...result.data
						}
					})					
					toast.info(result.message)
				}else if( result.status === "failure" ){
					toast.warn(result.message)
				}else if( result.status === "api_error" ){
					toast.warn(result.message.message)
				}
			}
			setGoogleUser(null)
		})
	}

	const disconnectUserMailAccount = () => {
		setDeleteWarningModal(false)
		setDisconnectApiLoader(true)
		let url	= "api/v1/mail-accounts/gmail-disconnect";
		if( userMailAccount.mail_type === "outlook" ){
			url	= "api/v1/mail-accounts/outlook-disconnect";
		}
		PostData('ms1', url).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setWarningModal(false)
					setUserMailAccount(null)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else if( result.status === "api_error" ){
					toast.error(result.message.message)
				}
			}
			setDisconnectApiLoader(false)
		})
	}

	const disconnectAccountWarning	= () => {
		setDeleteWarningModal((prevstate) => !prevstate);
	}

	const handleMicrosoftLogin = () => {
		instance.loginPopup({ scopes: ['openid', 'profile', 'offline_access', 'User.Read', 'Mail.Read'] })
		.then((response) => {
			console.log('Login response:', response);
		 })
		 .catch((loginError) => {
			console.error('Login error:', loginError);
		 });
	};

	const updateLimitValue = ( index, value ) => {
		setTeamLimitsLocal({
			...teamLimitsLocal,
			[index]: teamLimitsLocal[index] + value
		})
	}

	const saveEmailSignatureLocal = (value) => {
		setEmailSignatureLocal({
			...emailSignatureLocal,
			email_signature: value
		})
	}

	const toggleWarningModal = () => {
		setWarningModal((prevState) => !prevState)
	}

	const openWarningModal	= () => {
		setWarningModal(true)
	}

	const refreshChecks = () => {
		PostData("ms1", "api/v1/mail-accounts/recheck-dns").then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setUserMailAccount(result.data)
					setTeamMailAccountData({
						...teamMailAccountData,
						[result.data.user_id]:{
							...result.data
						}
					})
					toggleWarningModal()
					toast.success(result.message)
				}else if( result.status === "failure" ){
					setUserMailAccount(result.data)
					setTeamMailAccountData({
						...teamMailAccountData,
						[result.data.user_id]:{
							...result.data
						}
					})
					toast.success(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	if (redirectLogout) {
		return <Navigate to="/auth/login" replace />
	}

	return (
		<>
			<div className='contentHeadLeftWrapper'>
				<div className={`logoHeaderWrapper ${ autoCloseClass ? "" : "repeatIcon"}`}>
					<svg fill="currentcolor" viewBox="0 0 24 24" width={22} xmlns="http://www.w3.org/2000/svg" onClick={() => toggleAutoClose()}>
						<path d="M18 6h2v12h-2zm-2 5H7.414l4.293-4.293-1.414-1.414L3.586 12l6.707 6.707 1.414-1.414L7.414 13H16z"/>
					</svg>
					<Link to="/user/dashboard">
						<div className='appLogo'>
							{/* {(!autoCloseClass && !leftHoverClass) || (autoCloseClass && leftHoverClass) || ( !autoCloseClass && leftHoverClass ) ? */}
								<img src={require('./../assets/images/brand/roundsales-white-logo.png')} className='img-fluid' alt='Logo' width={160} />
							{/* : */}
								{/* <img src={require('./../assets/images/brand/round-sales-icon.png')} className='img-fluid' alt="Logo" /> */}
							{/* } */}
						</div>
					</Link>					
				</div>
			</div>
			<div className='contentHeadRightWrapper'>
				<UncontrolledDropdown>
					<DropdownToggle nav className="nav-link-icon custom" onClick={() => openNotification()}>
						<i className="fa-regular fa-bell fs-16"></i>
						{notCount !== null && notCount !== 0 ? <Badge className='badge-success'>{notCount}</Badge> : ""}

					</DropdownToggle>
					<DropdownMenu
						aria-labelledby="navbar-default"
						className="dropdown-menu notificationDropDown"
					>
						<div className='notificationWrapper'>
							{notificationData.length === 0 ?
								<DropdownItem tag="div" className='noNotificationContainer' >
									<img src={require('./../assets/images/star.png')} alt="No Content" className='img-fluid' />
									<div className='noNotificationMessageContainer'>
										<span><i className="fa-solid fa-circle-info"></i>You don’t have any notification</span>
									</div>
								</DropdownItem>
								:
								<>
									{notificationData.map((notification, index) => {
										return <div key={index} onClick={() => notificationClick(notification.notificationId)} className={notification.readStatus ? "notification-drop-wrap" : "active notification-drop-wrap"}>
											<DropdownItem to='/user/conversations' tag={Link} >
												<Row className='align-items-center'>
													<Col className="">
														<div className="d-flex justify-content-between align-items-center">
															<div>
																<h4 className="mb-0 text-sm">{notification.title}</h4>
															</div>
															<div className="text-right text-muted">
																<small>
																	<TimeAgo
																		datetime={notification.createdAt}
																	/>
																</small>
															</div>
														</div>
														<p className="text-sm mb-0">{notification.description}</p>
													</Col>
												</Row>
											</DropdownItem>
										</div>
									})}
									<DropdownItem divider />
									<DropdownItem to="/user/notification" className='text-center' tag={Link}>View all Notification</DropdownItem>
								</>

							}
						</div>
					</DropdownMenu>
				</UncontrolledDropdown>
				<UncontrolledDropdown>
					<DropdownToggle className="pr-0" nav>
						<div className='user'>
							<div className={`avatar uiUserPicture avatarPrimary${((userData.id - 1) % 10)}`}>
								{userData.profile_picture ?
									<img src={userData.profile_picture} alt={userData.first_name} className='picture' />
									:
									<>
										{userData.first_name.substr(0, 1)}{userData.last_name && userData.last_name.substr(0, 1)}
									</>
								}
							</div>
						</div>
					</DropdownToggle>
					<DropdownMenu className="dropdown-menu-arrow" >
						<div className='userDropDownMenu'>
							<div className="dropdownSubMenu">
								<div className="dropdownMenuItemUser">
									<div className={`avatar uiUserPicture avatarPrimary${((userData.id - 1) % 10)}`}>
										{userData.profile_picture ?
											<img src={userData.profile_picture} alt={userData.first_name} className='picture' />
											:
											<>
												{userData.first_name.substr(0, 1)}{userData.last_name && userData.last_name.substr(0, 1)}
											</>
										}
									</div>
									<div className="user">
										<div className="name">{userData.first_name} {userData.last_name && userData.last_name}</div>
										<div className="user-login">{userData.email}</div>
									</div>
								</div>
							</div>
							<div className='dropdownSubMenu'>
								<span className='dropdownMenuItemUser' onClick={() => toggleOpenSettings()}>
									<i className="fa-solid fa-sliders"></i>
									<div className='name'>Account Settings</div>
								</span>
							</div>
							<div className='dropdownSubMenu'>
								<span className='dropdownMenuItemUser logout' onClick={(e) => logOut()}>
									<div className='name'>Logout</div>
									<i className="fa-solid fa-arrow-right-from-bracket"></i>
								</span>
							</div>
						</div>
					</DropdownMenu>
				</UncontrolledDropdown>
			</div>
			{accountSettingFlag &&
				<Modal isOpen={accountSettingFlag} toggle={toggleOpenSettings} className={"accountSettingModal"} centered={true}>
					<ModalBody>
						<div className='accountSettingModalLeftWrapper'>
							<div className="modalLeftHeading">Account Settings</div>
							<div className="modalLeftItemsWrapper">
								<div className={`modalLeftItemWrap cursorPointer ${accountSettingTab === "account" ? "active" : ""}`} onClick={() => setAccountSettingTab("account")}>
									<div className={`avatar uiUserPicture sm avatarPrimary${((userData.id - 1) % 10)}`}>
										{userData.profile_picture ?
											<img src={userData.profile_picture} alt={userData.first_name} className='picture' />
											:
											<>
												{userData.first_name.substr(0, 1)}{userData.last_name && userData.last_name.substr(0, 1)}
											</>
										}
									</div> My account
								</div>
								<div className={`modalLeftItemWrap cursorPointer ${accountSettingTab === "emailProvider" ? "active" : ""}`} onClick={() => setAccountSettingTab("emailProvider")}>
									<i className="fa-solid fa-server"></i> Email provider
								</div>
								<div className={`modalLeftItemWrap cursorPointer ${accountSettingTab === "limits" ? "active" : ""}`} onClick={() => { if( accountSettingTab !== "limits" ) { setTeamLimitsLocal({...teamLimits}) } setAccountSettingTab("limits")}}>
									<i className="fa-solid fa-list-ol"></i> Limits
								</div>
								<div className={`modalLeftItemWrap cursorPointer ${accountSettingTab === "signature" ? "active" : ""}`} onClick={() => { if( accountSettingTab !== "signature" ) { setEmailSignatureLocal({...emailSignature}) } setAccountSettingTab("signature")}}>
									<i className="fa-solid fa-signature"></i> Email Signature
								</div>
							</div>
						</div>
						<div className='accountSettingModalRightWrapper'>
							<div className='userSettingModalRightTopWrap'>
								<div className='modalRightTitle'>
									{accountSettingHeadings[accountSettingTab]}
									{accountSettingTab === "emailProvider" &&
										<RSToolTip item={{ placement: 'bottom', text: 'An email provider, or email host, is the company that provides you with email services.<br />  E.g. Gmail, Outlook.' }} id="emailProvider">
											<i className="fa-solid fa-circle-info"></i>
										</RSToolTip>
									}
								</div>
								<Button className='btnModalClose' onClick={() => toggleOpenSettings()}>
									<i className="fa-solid fa-xmark"></i>
								</Button>
							</div>
							<div className='userSettingModalCenterWrap'>
								{accountSettingTab === "account" ?
									<div className='d-flex-col lg'>
										<div className='d-flex-col'>
											<h4>Profile Photo</h4>
											<div className='d-flex-row align-items-center profilePicture'>
												{profileImageApiLoader ?
													<div className='loader'>
														<div className='spinBorder'></div>
													</div>
												:
													<div className={`avatar uiUserPicture xl avatarPrimary${((userData.id - 1) % 10)}`}>
														{userData.profile_picture ?
															<img src={userData.profile_picture} alt={userData.first_name} className='picture' />
															:
															<>
																{userData.first_name.substr(0, 1)}{userData.last_name && userData.last_name.substr(0, 1)}
															</>
														}
													</div>
												}
												<Button color='primary' disabled={profileImageApiLoader ? true : false}>
													<Input type="file" ref={profileFileUploadRef} onChange={(e) => uploadProfilePicture(e)} className="rsInputFile" accept='image/jpeg,image/png,image/gif'></Input>
													Upload a photo
												</Button>
												<Button className='textCta d-flex-row sm' color='danger' outline disabled={( userData.profile_picture && !deleteProfileApiLoader) ? false : true} onClick={() => removeProfilePicture()}>
													{deleteProfileApiLoader &&
														<Spinner size={"sm"} />
													}
													<i className="fa-regular fa-trash-can"></i>
													<span>Delete picture</span>
												</Button>
											</div>
										</div>
										<div className='accountIdentityContainer d-flex-col sm'>
											<div className='accountIdentity d-flex-col lg'>
												<h4>Personal info</h4>
												<div className='d-flex-row accountIdentityName'>
													<div className={`position-relative ${(errors.first_name && "error_field")}`}>
														<Label className="input-group-alternative-label" >First Name</Label>
														<InputGroup className="input-group-alternative">
															<Input
																placeholder="First Name"
																type="text"
																name="first_name"
																value={userDataLocal.first_name}
																onChange={(e) => handleChange(e.target.value, "first_name")}
																onBlur={(e) => handleChange(e.target.value, "first_name")}
																className={(errors.first_name)}
															/>
														</InputGroup>
														{errors.first_name && (
															<>
																<i className='fa-solid fa-circle-xmark errorIcon'></i>
																<div className="input-feedback">{errors.first_name}</div>
															</>
														)}
													</div>
													<div className={`position-relative ${(errors.last_name && "error_field")}`}>
														<Label className="input-group-alternative-label" >Last Name</Label>
														<InputGroup className="input-group-alternative">
															<Input
																placeholder="Last Name"
																type="text"
																name="last_name"
																value={userDataLocal.last_name}
																onChange={(e) => handleChange(e.target.value, "last_name")}
																onBlur={(e) => handleChange(e.target.value, "last_name")}
																className={(errors.last_name)}
															/>
														</InputGroup>
														{errors.last_name && (
															<>
																<i className='fa-solid fa-circle-xmark errorIcon'></i>
																<div className="input-feedback">{errors.last_name}</div>
															</>
														)}
													</div>
												</div>
												<div className='d-flex-row'>
													<div className={`position-relative`}>
														<Label className="input-group-alternative-label" >Email</Label>
														<InputGroup className="input-group-alternative">
															<Input
																placeholder="Email"
																type="text"
																name="email"
																disabled
																value={userDataLocal.email}
																className='disabled'
															/>
														</InputGroup>
													</div>
												</div>
											</div>
										</div>
										<hr />
										<div className='d-flex-col lg w-75'>
											<div>
												<h4>Set a new password</h4>
												<div className='textMuted'>It is recommended to change password regularly</div>
											</div>
											<div>
												<div className={`position-relative passwordField ${(errors.old_password && "error_field")}`}>
													<Label className="input-group-alternative-label" >Your current password</Label>
													<InputGroup className="input-group-alternative">
														<Input
															placeholder="Old Password"
															type={oldPasswordShown ? "text" : "password"}
															name="old_password"
															value={oldPassword}
															onChange={(e) => handlePassword(e.target.value, "old_password")}
															onBlur={(e) => handlePassword(e.target.value, "old_password")}
															className={(errors.old_password)}
															autoComplete={false}
														/>
														<InputGroupText>
															<div className="showPasswordWrapper" onClick={() => setOldPasswordShown(!oldPasswordShown)}>
																{oldPasswordShown ?
																	<span className="material-icons-outlined">visibility</span>
																	:
																	<span className="material-icons-outlined">visibility_off</span>
																}

															</div>
														</InputGroupText>
													</InputGroup>
													{errors.old_password && (
														<>
															<i className='fa-solid fa-circle-xmark errorIcon'></i>
															<div className="input-feedback">{errors.old_password}</div>
														</>
													)}
												</div>
											</div>
											<div>
												<div className={`position-relative passwordField ${(errors.new_password && "error_field")}`}>
													<Label className="input-group-alternative-label" >Your current password</Label>
													<InputGroup className="input-group-alternative">
														<Input
															placeholder="New Password"
															type={newPasswordShown ? "text" : "password"}
															name="new_password"
															value={newPassword}
															onChange={(e) => handlePassword(e.target.value, "new_password")}
															onBlur={(e) => handlePassword(e.target.value, "new_password")}
															className={(errors.new_password)}
															autoComplete={false}
														/>
														<InputGroupText>
															<div className="showPasswordWrapper" onClick={() => setNewPasswordShown(!newPasswordShown)}>
																{newPasswordShown ?
																	<span className="material-icons-outlined">visibility</span>
																	:
																	<span className="material-icons-outlined">visibility_off</span>
																}

															</div>
														</InputGroupText>
													</InputGroup>
													{errors.new_password && (
														<>
															<i className='fa-solid fa-circle-xmark errorIcon'></i>
															<div className="input-feedback">{errors.new_password}</div>
														</>
													)}
												</div>
											</div>
										</div>
										<hr />
										<div className='d-flex-col'>
											<h4>GDPR</h4>
											<div className='d-flex-row'>
												<div className='checkbox gdpr-compliance'>
													<Label htmlFor='gdpr'>
														<Input type='checkbox' name='gdpr' id="gdpr" checked={userDataLocal.gdpr_check} onChange={() => handleChange(!userDataLocal.gdpr_check, "gdpr_check") } />
														<span>I certify that I'm responsible for my prospects' data collection and processing<br />in accordance with local privacy regulations.</span>
													</Label>
												</div>
											</div>
										</div>
									</div>
									: accountSettingTab === "emailProvider" ?
										<div className='d-flex-col lg settingEmailProvider'>
											<div className='d-flex-col xs'>
												<span>Your email provider will be used to send emails.</span>
												<span>Connect yours to get started.</span>
											</div>
											<div className='d-flex-col'>
												<h4 className="bold">Connect with Google or Office 365 provider</h4>
												{!userMailAccount &&
													<div className='d-flex-row'>
														<div className="uiSection flex-1 gmailProvider shadow mvp">
															<div className="content">
																<div className="flex-1 align-self-end">
																	<div className="d-flex-row align-items-center justify-content-end">
																		<span className="flex-1"></span>
																		<Button className='jsConnectGmail' color='primary' onClick={() => login()}>Connect</Button>
																	</div>
																</div>
															</div>
														</div>
														<div className="uiSection flex-1 outlookProvider shadow mvp">
															<div className="content">
																<div className="flex-1 align-self-end">
																	<div className="d-flex-row align-items-center justify-content-end">
																		<span className="flex-1"></span>
																		<Button className='jsConnectOutlook' onClick={() => handleMicrosoftLogin()} color='primary'>Connect</Button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												}
											</div>
											{userMailAccount &&												
												<div className={`uiSection ${userMailAccount.mail_type === "gmail" ? "gmailProvider" : "outlookProvider"} ${!userMailAccount.status ? "errorWarning" : ""} shadow mvp`}>
													<div className='content'>
														<div className='flex-1 align-self-end'>
															<div className='d-flex-row align-items-center justify-content-end active'>
																<span className='flex-1'>
																	You are using {userMailAccount.mail_type === "gmail" ? "Google" : "Outlook"} email provider with the account:  
																	<b> {userMailAccount.email}</b>
																	{!userMailAccount.status &&
																		<div className='mt-2'>
																			<button className='btn btn-tertiary btn-warning' onClick={() => openWarningModal()}>
																				<i className="fa-solid fa-triangle-exclamation"></i>
																				<span>Show warning</span>
																			</button>
																		</div>
																	}
																</span>
																<Button className="btn btn-danger md rsUsersGoogleUnlink" disabled={disconnectApiLoader} onClick={() => disconnectAccountWarning()}>
																	{disconnectApiLoader &&
																		<Spinner size={"sm"} />
																	}
																	Disconnect
																</Button>
															</div>
														</div>
													</div>
												</div>
											}
										</div>
										: accountSettingTab === "limits" ?
											<div className='d-flex-col'>
												<div className='d-flex-row'>
													<h4>Global limits for your account.</h4>
												</div>
												<div className='uiSection sm mvp'>
													<div className='content'>
														<div className='d-flex-row'>
															<span className='d-flex align-self-center flex-1 ml-2'>
																Maximum number of emails sent by RoundSales in 24h &nbsp; &nbsp;
																<RSToolTip item={{ placement: 'bottom', text: 'How many emails can I send per day ?' }} id="maximumEmailSent">
																	<i className="fa-solid fa-circle-info"></i>
																</RSToolTip>
															</span>
															<InputGroup className='uiStepper'>
																<Button className='btn-stepper btn-ico rsModifyStep' disabled={teamLimitsLocal.email_sent <= 0}  onClick={() => updateLimitValue("email_sent", -1)} ><i className='fa-solid fa-minus'></i></Button>
																<Input type='number' className='rsEmailLimit' name='emailsLimit' min="0" value={teamLimitsLocal.email_sent} />
																<Button className='btn-stepper btn-ico rsModifyStep' onClick={() => updateLimitValue("email_sent", 1)} ><i className='fa-solid fa-plus'></i></Button>
															</InputGroup>
														</div>
													</div>
												</div>
												<div className='uiSection sm mvp'>
													<div className='content'>
														<div className='d-flex-row'>
															<span className='d-flex align-self-center flex-1 ml-2'>
																Maximum number of LinkedIn invites sent by RoundSales in 24h
															</span>
															<InputGroup className='uiStepper'>
																<Button disabled className='btn-stepper btn-ico rsModifyStep' onClick={() => updateLimitValue("linkedin_invites", -1)}><i className='fa-solid fa-minus'></i></Button>
																<Input type='number' className='rsEmailLimit' name='linkedinInvites' min="0" value={teamLimitsLocal.linkedin_invites} disabled />
																<Button className='btn-stepper btn-ico rsModifyStep' disabled  onClick={() => updateLimitValue("linkedin_invites", 1)}><i className='fa-solid fa-plus'></i></Button>
															</InputGroup>
														</div>
													</div>
												</div>
												<div className='uiSection sm mvp'>
													<div className='content'>
														<div className='d-flex-row'>
															<span className='d-flex align-self-center flex-1 ml-2'>
																Maximum number of LinkedIn messages sent by RoundSales in 24h
															</span>
															<InputGroup className='uiStepper'>
																<Button className='btn-stepper btn-ico rsModifyStep' disabled  onClick={() => updateLimitValue("linkedin_messages", -1)}><i className='fa-solid fa-minus'></i></Button>
																<Input type='number' className='rsEmailLimit' name='linkedinMessages' min="0" value={teamLimitsLocal.linkedin_messages} disabled />
																<Button className='btn-stepper btn-ico rsModifyStep' disabled  onClick={() => updateLimitValue("linkedin_messages", 1)}><i className='fa-solid fa-plus'></i></Button>
															</InputGroup>
														</div>
													</div>
												</div>
												<div className='uiSection sm mvp'>
													<div className='content'>
														<div className='d-flex-row'>
															<span className='d-flex align-self-center flex-1 ml-2'>
																Maximum number of LinkedIn visits made by RoundSales in 24h
															</span>
															<InputGroup className='uiStepper'>
																<Button className='btn-stepper btn-ico rsModifyStep' disabled  onClick={() => updateLimitValue("linkedin_visits", -1)}><i className='fa-solid fa-minus'></i></Button>
																<Input type='number' className='rsEmailLimit' name='linkedinVisits' min="0" value={teamLimitsLocal.linkedin_visits} disabled/>
																<Button className='btn-stepper btn-ico rsModifyStep' disabled  onClick={() => updateLimitValue("linkedin_visits", 1)}><i className='fa-solid fa-plus'></i></Button>
															</InputGroup>
														</div>
													</div>
												</div>
											</div>
											: accountSettingTab === "signature" &&
											<div className='emailSignature'>
												<h4>This signature will be used when you put <code>{"{{signature}}"}</code> custom variable in an email template.</h4>
												<div className='signatureTextEditor rsTextEditor'>
													<SignatureEditor
														html={emailSignatureLocal.email_signature}
														onChange={(value) => saveEmailSignatureLocal(value)}
													/>
												</div>
											</div>
								}
							</div>
							{accountSettingTab !== "emailProvider" &&
								<div className='d-flex-row modalButtons justify-content-end'>
									<Button color='primary' className='accountSettingSaveBtn' disabled={saveApiLoader} onClick={() => saveChanges()}>
										{saveApiLoader &&
											<Spinner size={"sm"} />
										}
										Save Changes
									</Button>
								</div>
							}
						</div>
					</ModalBody>
				</Modal>
			}
			{deleteWarningModal &&
				<Modal isOpen={deleteWarningModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'>Unlink {userMailAccount.mail_type === "gmail" ? "Google" : "Outlook" } account</h2>
							<p>Unlinking your {userMailAccount.mail_type === "gmail" ? "Google" : "Outlook" } account will stop all campaigns for that account. Are you sure?</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' onClick={() => disconnectAccountWarning()}>Cancel</Button>
							<Button className='md okWarningButton' color='primary' onClick={() => disconnectUserMailAccount()}>Ok</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
			{(warningModal && userMailAccount ) &&
				<Modal isOpen={warningModal} className='settingsUpperModal provider fullHeight' centered={true}>
					<Button className='btnModalClose' onClick={() => toggleWarningModal()}>
						<i className="fa-solid fa-xmark"></i>
					</Button>
					<ModalBody>
						<div className='modalSettingsWrapper'>
							<div className='modalSettingsHeader'>
								<h3 className="bold">Email address</h3>
								<div className="text-light">Used to send emails steps in your RoundSales campaigns.</div>
							</div>
							<div className='modalSettingsScrollableContent'>
								<div className="integrationBlock">
									<div className="left">
										<div className={`avatar uiUserPicture lg2 avatarPrimary${((userMailAccount.id - 1) % 10)}`}>
											{userMailAccount.profile_url ?
												<img src={userMailAccount.profile_url} alt={userMailAccount.name} className='picture' />
												:
												<>
													{userMailAccount.name.substr(0, 1)}
												</>
											}
										</div>
										<div className="header">
											<div className="title">{userMailAccount.name} - {userMailAccount.email}</div>
											<div className="subtitle uppercase">Gmail</div>
										</div>
									</div>
									<div className="right">
										<div className="rsTag tagWarning100">
											<i className="fa-solid fa-triangle-exclamation" aria-hidden="true"></i>
											<span>Emails are paused</span>
										</div>
										<button className="btn btn-danger btnAction" onClick={() => disconnectUserMailAccount()}>
											<i className="fa-solid fa-trash"></i>
										</button>
									</div>
								</div>
								<div className='d-flex-col'>
									<div className='rsNewInfoMsg warning'>
										<div className="header message">
											<div>
												<div className="title">Emails are paused</div>
												<div className="text-light uppercase">You have some DNS settings errors on your email</div>
											</div>
										</div>
										<div className='content'>
											<p>RoundSales paused your emails to protect your deliverability. Your DNS settings for your domain must be fixed so your emails can be un-paused.</p>
											{userMailAccount.spf_error &&
												<p className='mb-0'>{userMailAccount.spf_error}</p>
											}
											{userMailAccount.dmrac_error &&
												<p className='mb-0'>{userMailAccount.dmrac_error}</p>
											}
											<br />
											<p className='mb-0'><a href='https://support.google.com/a/answer/2466563?hl=en&ref_topic=2759254&sjid=17017181422216474845-AP' className='link-underlined' target='_blank'>How to set up your DMARC?</a></p>
											<p className='mb-0'><a href='https://support.google.com/a/answer/10684623?hl=en&ref_topic=10685331&sjid=17017181422216474845-AP' className='link-underlined' target='_blank'>How to set up your SPF?</a></p>
											<p className='mb-0'><a href='https://support.google.com/a/answer/180504?hl=en&ref_topic=2752442&sjid=17017181422216474845-AP' className='link-underlined' target='_blank'>How to set up your DKIM?</a></p>
											<div className='mt-4'>
												<Button color='primary' onClick={() => refreshChecks()}>
													<i className="fa-solid fa-arrows-rotate"></i> Refresh checks
												</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>
				</Modal>
			}
		</>
	)
}

export default Header;