import React, { useState, useContext, useEffect } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap';
import { ProductContext } from '../store';

function MultipleSelectUserEmail({placeholder, senderUserId, onClick, disabled}) {
	const [stepMoreOption, setStepMoreOption]	= useState(false),
		{
			teamUserData,
			teamMailAccountData
		}	= useContext(ProductContext);
	
	const setCampaignSenderId	= ( userId ) => {
		setStepMoreOption(false)
		onClick(userId)
	}

	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => setStepMoreOption((prevstate) => !prevstate)} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="div" className='rsDropdown rsAssignStepMultiple emailSender'>
				<button className={`btn btnDropdown rsDropdownToggle ${ ( Object.keys(teamMailAccountData).length === 0 || (Object.keys(teamMailAccountData).length > 0 && senderUserId && !teamMailAccountData[senderUserId]) ) ? "alert" : ""}`} disabled={typeof disabled === "undefined" ? false : disabled}>
					<div className='dropdownValue'>
						{senderUserId ?
							<>
								<div className='sendersPicture'>
									<div className={`avatar uiUserPicture avatarPrimary${((senderUserId - 1) % 10)}`}>
										{teamMailAccountData[senderUserId] ?
											<>
												{teamMailAccountData[senderUserId].profile_url ?
													<img src={teamMailAccountData[senderUserId].profile_url} alt={teamMailAccountData[senderUserId].name} className='picture' />
													:
													<>
														{teamMailAccountData[senderUserId].name.substr(0, 1)}
													</>
												}
											</>
										: teamUserData[senderUserId] &&
											<>
												{teamUserData[senderUserId].profile_url ?
													<img src={teamUserData[senderUserId].profile_picture} alt={teamUserData[senderUserId].name} className='picture' />
													:
													<>
														{teamUserData[senderUserId].first_name.substr(0, 1)}{teamUserData[senderUserId].last_name && teamUserData[senderUserId].last_name.substr(0, 1)}
													</>
												}
											</>
										}
									</div>							
								</div>
								<span>
									{teamMailAccountData[senderUserId] ?
										<>
											{teamMailAccountData[senderUserId].name} ({teamMailAccountData[senderUserId].email})
										</>
									: ( teamUserData[senderUserId] ) &&
										<>
											{teamUserData[senderUserId].first_name} {teamUserData[senderUserId].last_name && teamUserData[senderUserId].last_name.substr(0, 1)} ({teamUserData[senderUserId].email})
										</>
									}
								</span>
							</>
						:
							<span className='placeholder'>{placeholder ? placeholder : "Select a sender ..."}</span>
						}
					</div>
				</button>
			</DropdownToggle>
			<DropdownMenu className='dropdownPicker'>
				{Object.keys(teamUserData).length > 0 &&
					<>
						{Object.keys(teamUserData).map((userId, index) => {
							return (
								<React.Fragment key={index}>
									<div className='uiDropdownHeader multiSenderHeader'>
										<div className={`avatar uiUserPicture sm avatarPrimary${((userId - 1) % 10)}`}>
											{teamUserData[userId].profile_picture ?
												<img src={teamUserData[userId].profile_picture} alt={teamUserData[userId].first_name} className='picture' />
												:
												<>
													{teamUserData[userId].first_name && teamUserData[userId].first_name.substr(0, 1)}{teamUserData[userId].last_name && teamUserData[userId].last_name.substr(0, 1)}
												</>
											}
										</div>
										{teamUserData[userId].first_name} {teamUserData[userId].last_name && teamUserData[userId].last_name}
									</div>
									<div className='uiDropdownItem rsSelectItem' onClick={() => setCampaignSenderId(userId)}>
										{/* <div className="checkbox rsCheckbox">
											<Label className="js-prevent-label-bounce-event-click">
												<Input type='checkbox' />
											</Label>
										</div> */}
										{teamMailAccountData[userId] ?
											<>
												{teamMailAccountData[userId].name} ({teamMailAccountData[userId].email})
											</>
										:
											<>
												{teamUserData[userId].first_name} {teamUserData[userId].last_name && teamUserData[userId].last_name} ({teamUserData[userId].email}) (No provider)
											</>
										}
										
									</div>
								</React.Fragment>
							)
						})}
					</>
				}
				{/* <div className='uiDropdownHeader multiSenderHeader'>
					<div className={`avatar uiUserPicture sm avatarPrimary${((userData.id - 1) % 10)}`}>
						{userData.profile_picture ?
							<img src={userData.profile_picture} alt={userData.first_name} className='picture' />
							:
							<>
								{userData.first_name && userData.first_name.substr(0, 1)}{userData.last_name && userData.last_name.substr(0, 1)}
							</>
						}
					</div>
					{userData.first_name} {userData.last_name && userData.last_name}
				</div>
				<div className='uiDropdownItem rsSelectItem'>
					<div className="checkbox rsCheckbox">
						<Label className="js-prevent-label-bounce-event-click">
							<Input type='checkbox' />
						</Label>
					</div>
					{userData.first_name} {userData.last_name && userData.last_name} ({userData.email}) (No provider)
				</div>
				<div className='uiDropdownHeader multiSenderHeader'>
					<div className={`avatar uiUserPicture sm avatarPrimary${((userData.id - 1) % 10)}`}>
						{userData.profile_picture ?
							<img src={userData.profile_picture} alt={userData.first_name} className='picture' />
							:
							<>
								{userData.first_name && userData.first_name.substr(0, 1)}{userData.last_name && userData.last_name.substr(0, 1)}
							</>
						}
					</div>
					{userData.first_name} {userData.last_name && userData.last_name}
				</div>
				<div className='uiDropdownItem rsSelectItem'>
					<div className="checkbox rsCheckbox">
						<Label className="js-prevent-label-bounce-event-click">
							<Input type='checkbox' />
						</Label>
					</div>
					{userData.first_name} {userData.last_name && userData.last_name} ({userData.email}) (No provider)
				</div> */}
			</DropdownMenu>
		</Dropdown>
	);
}

export default MultipleSelectUserEmail;