import React, { useRef, useState, useContext, useEffect } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import LeadsReviewDropdown from '../../../components/LeadsReviewDropdown';
import LeadReviewDropdown from '../../../components/LeadReviewDropdown';
import LeadReviewAllDropdown from '../../../components/LeadReviewAllDropdown';
import MultipleSelectUserEmail from '../../../components/MultipleSelectUserEmail';
import EmailEditor from '../../../components/EmailEditor/EmailEditor';
import { ProductContext } from '../../../store';
import { PostData } from "../../../services/FetchData"
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ReviewLeadSkeleton from '../../../components/Skeleton/ReviewLeadSkeleton';
import ReviewSequenceSkeleton from '../../../components/Skeleton/ReviewSequenceSkeleton';
import LeadReviewSequenceDropdown from '../../../components/LeadReviewSequenceDropdown';


function Review(props) {
	const [searchText, setSearchText]	= useState(''),
		[sequenceZoom, setSequenceZoom]	= useState(1),
		scrollableDivRef	= useRef(null),
		[isScrolling, setIsScrolling]	= useState(false),
		[startX, setStartX] = useState(0),
		[startY, setStartY] = useState(0),
		[scrollLeft, setScrollLeft] = useState(0),
		[scrollTop, setScrollTop] = useState(0),
		[reviewLeadSteps, setReviewLeadSteps]	= useState(false),
		[highlightVariable, setHighlightVariable]	= useState(null),
		[customVariables]	= useState({"Email": "email", "First name": "firstName", "Last name": "lastName", "Picture": "picture", "Phone": "phone", "Linkedin url": "linkedinUrl", "Company name": "companyName", "Company domain": "companyDomain", "Icebreaker" : "icebreaker", "Signature": "signature", "Sender Name": "sender.name"}),
		[loadCustomVariable, setLoadCustomVariable]	= useState(false),
		[reviewLeadLoader, setReviewLeadLoader]	= useState(true),
		[activeLeadIndex, setActiveLeadIndex]	= useState(0),
		[leadsSelected, setLeadsSelected]	= useState([]),
		[leadSequenceIndex, setLeadSequenceIndex]	= useState(null),
		[reviewAllWarningModal, setReviewAllWarningModal]	= useState(false),
		[reviewAllLoaderApi, setReviewAllLoaderApi]	= useState(false),
		[deleteWarningModal, setDeleteWarningModal]	= useState(false),
		[deleteLeadIndex, setDeleteLeadIndex]	= useState(null),
		[deleteLoaderApi, setDeleteLoaderApi]	= useState(false),
		[reviewButtonLoaderApi, setReviewButtonLoaderApi]	= useState(false),
		[reviewLeadsContents, setReviewLeadsContent]	= useState([]),
		{
			campaignDetails,
			emailSignature,
			sequenceDetails,
			currentTeamId,
			reviewLeads,
			setReviewLeads,
			teamMailAccountData,
			teamUserData,
			setCampaignDetails,
		} = useContext(ProductContext),
		{
			id,
			"*": wildcard
		} = useParams();
	
	useEffect(() => {
		getReviewLeads()
	}, [])

	const getReviewLeads	= () => {
		setReviewLeadLoader(true)
		let leadReviewData	= {team_id: currentTeamId, campaign_id: id}
		PostData("ms1", "api/v1/campaign/get-review-leads", leadReviewData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setReviewLeads(result.data.reviewLeads)
					let localReviewLeads	= [...result.data.reviewLeads];
					for( let i = 0; i < localReviewLeads.length; i++ ){
						let currentLead	= localReviewLeads[i],
							currentLeadSequence	= [],
							replaceKeys				= Object.values(customVariables),
							variationIndex			= ( i + 2 ) % 2;
						for( let j = 0; j < sequenceDetails.length; j++ ){
							let currentSequence		= sequenceDetails[j],
								currentActionIndex	= 0,
								variationKey			= "",
								leadCurrentSequence	= {type: currentSequence.type, delay_time: currentSequence.delay_time};
							if( currentLeadSequence.sender_mail_account_id ){
								leadCurrentSequence["sender_step_user_id"]	= currentLeadSequence.sender_mail_account_id
							}
							if( currentSequence.ab_testing_flag && !currentSequence.ab_test_winner ){
								currentActionIndex	= variationIndex
								variationKey			= variationIndex
							}else if( currentSequence.ab_testing_flag && currentSequence.ab_test_winner ){
								if( currentSequence.ab_test_winner === "A" ){
									currentActionIndex	= 0
								}else{
									currentActionIndex	= 1
								}
							}
							if( currentSequence.type === "email_template" ){
								let subject	= currentSequence.actions[currentActionIndex].subject,
									message	= currentSequence.actions[currentActionIndex].message;
								for( let k = 0; k < replaceKeys.length; k++  ){
									if( replaceKeys[k] !== "sender.name" && replaceKeys[k] !== "signature" ){
										const placeholder = `{{${replaceKeys[k]}}}`,
											value	= currentLead[replaceKeys[k]] ? currentLead[replaceKeys[k]] : "";
										subject = subject.split(placeholder).join(value);
										message = message.split(placeholder).join(value);
									}else if( replaceKeys[k] === "signature" ){
										message	= message.split(`{{signature}}`).join(emailSignature.email_signature)
									}else if( replaceKeys[k] === "sender.name" ){
										let senderName = "";
										if( teamMailAccountData[campaignDetails.sender_user_id]  ){
												senderName	= teamMailAccountData[campaignDetails.sender_user_id].name;
										}else if( teamUserData[campaignDetails.sender_user_id] ){
											senderName	= teamUserData[campaignDetails.sender_user_id].first_name
											if( teamUserData[campaignDetails.sender_user_id].last_name ){
												senderName += " "+teamUserData[campaignDetails.sender_user_id].last_name
											}
										}
										message	= message.split(`{{sender.name}}`).join(senderName)
									}
								}
								leadCurrentSequence["subject"]	= subject
								leadCurrentSequence["message"]	= message
								if( variationKey !== ""){
									leadCurrentSequence["variation_key"]	= variationKey
								}
							}
							currentLeadSequence.push(leadCurrentSequence)
						}
						if( typeof localReviewLeads[i].sequences === "undefined"){
							localReviewLeads[i].sequences	= []
						}
						localReviewLeads[i].sequences	= currentLeadSequence
					}
					setReviewLeadsContent(localReviewLeads)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setReviewLeadLoader(false)
		})
	}
	
	const zoomIn = () => {
		if (sequenceZoom > 0.4) {
			setSequenceZoom((prevstate) => prevstate - 0.1);
		}
	}

	const zoomOut = () => {
		if (sequenceZoom < 2) {
			setSequenceZoom((prevstate) => prevstate + 0.1);
		}
	}

	const handleScrollDivMouseDown = (e) => {
		setIsScrolling(true);
		setStartX(e.clientX);
		setStartY(e.clientY);
		setScrollLeft(scrollableDivRef.current.scrollLeft);
		setScrollTop(scrollableDivRef.current.scrollTop);
		// Disable text selection
		scrollableDivRef.current.style.userSelect = 'none';
	};

	const handleScrollDivMouseMove = (e) => {
		if (!isScrolling) return;
		const x = e.clientX;
		const y = e.clientY;
		const deltaX = x - startX;
		const deltaY = y - startY;
		scrollableDivRef.current.scrollLeft = scrollLeft - deltaX;
		scrollableDivRef.current.scrollTop = scrollTop - deltaY;
	};

	const handleScrollDivMouseUp = () => {
		setIsScrolling(false);
		// Re-enable text selection
		scrollableDivRef.current.style.userSelect = 'auto';
	};

	const toggleReviewLeadSteps = () => {
		setReviewLeadSteps((prevstate) => !prevstate)
	}

	const reviewSequenceDropdownClicked = ( leadIndex, index, type ) => {
		let localReviewLeads	= [...reviewLeadsContents],
			currentLead			= localReviewLeads[leadIndex],
			currentSequence	= sequenceDetails[index],
			replaceKeys			= Object.values(customVariables),
			variationIndex		= 0
		if( type === "usethisa" ){
			variationIndex	= 0
		}else if( type === "usethisb" ){
			variationIndex	= 1
		}else if( type === "reset" ){
			variationIndex	= ( leadIndex + 2 ) % 2
		}
		if( currentSequence.type === "email_template" ){
			let subject	= currentSequence.actions[variationIndex].subject,
				message	= currentSequence.actions[variationIndex].message;
			for( let k = 0; k < replaceKeys.length; k++  ){
				if( replaceKeys[k] !== "sender.name" && replaceKeys[k] !== "signature" ){
					const placeholder = `{{${replaceKeys[k]}}}`,
						value	= currentLead[replaceKeys[k]] ? currentLead[replaceKeys[k]] : "";
					subject = subject.split(placeholder).join(value);
					message = message.split(placeholder).join(value);
				}else if( replaceKeys[k] === "signature" ){
					message	= message.split(`{{signature}}`).join(emailSignature.email_signature)
				}else if( replaceKeys[k] === "sender.name" ){
					let senderName = "";
					if( teamMailAccountData[campaignDetails.sender_user_id]  ){
							senderName	= teamMailAccountData[campaignDetails.sender_user_id].name;
					}else if( teamUserData[campaignDetails.sender_user_id] ){
						senderName	= teamUserData[campaignDetails.sender_user_id].first_name
						if( teamUserData[campaignDetails.sender_user_id].last_name ){
							senderName += " "+teamUserData[campaignDetails.sender_user_id].last_name
						}
					}
					message	= message.split(`{{sender.name}}`).join(senderName)
				}
			}
			localReviewLeads[leadIndex].sequences[index].subject			= subject
			localReviewLeads[leadIndex].sequences[index].message			= message
			localReviewLeads[leadIndex].sequences[index].variation_key	= variationIndex			
		}
		if( type === "usethisa" || type === "usethisb" ){
			localReviewLeads[leadIndex].sequences[index].reset	= true
		}else if( typeof localReviewLeads[leadIndex].sequences[index].reset !== "undefined" ){
			delete localReviewLeads[leadIndex].sequences[index].reset
		}
		setReviewLeadsContent(localReviewLeads)
	}

	const addLeadsSelected	= (e, id) => {
		if( e.target.checked ){
			let localLeadsSelected	= [...leadsSelected];
			localLeadsSelected.push(id)
			setLeadsSelected(localLeadsSelected)
		}else{
			let localLeadsSelected	= [...leadsSelected],
				removeIndex	= localLeadsSelected.indexOf(id);
			if( removeIndex !== -1 ){
				localLeadsSelected.splice(removeIndex, 1)
			}
			setLeadsSelected(localLeadsSelected)
		}
	}

	const loadParticularLeadSequence = (index) => {
		setLeadSequenceIndex(index)
		setReviewLeadSteps(true)
	}

	const updateLeadEmailSequence = ( key, value, leadIndex, sequenceIndex) => {
		let localReviewLeads	= [...reviewLeadsContents];
		localReviewLeads[leadIndex].sequences[sequenceIndex][key]	= value
		localReviewLeads[leadIndex].sequences[sequenceIndex].reset	= true
		setReviewLeadsContent(localReviewLeads)
	}

	const gotoPreviousStep	= (value) => {
		if( value !== 0 ){
			setLeadSequenceIndex(value - 1)
		}
	}

	const gotoNextStep	= (value) => {
		if( (value + 1) !== sequenceDetails.length ){
			setLeadSequenceIndex(value+1)
		}
	}

	const leadsReviewDropdownClick = (type) => {
		if( type === "skip" ){
			onSkipLeads()
		}else if( type === "delete" ){
			onDeleteLeads()
		}
	}

	const leadReviewDropdownClick = (type, index) => {
		if( type === "skip" ){
			onSkipLead(index)
		}else if( type === "delete" ){
			onDeleteLead(index)
		}else if( type === "review" ){
			onReviewLead(index)
		}
	}

	const toggleReviewAllWarningModal = () => {
		setReviewAllWarningModal((prevState) => !prevState)
	}

	const selectOrUnselectAllLeads = (e) => {
		if( reviewLeadsContents.length > 0 ){
			if( e.target.checked ){
				let leadIds	= reviewLeadsContents.map((obj) => obj._id)
				setLeadsSelected(leadIds)
			}else{
				setLeadsSelected([])
			}
		}
	}

	const toggleLeadDeleteModal = () => {
		setDeleteWarningModal(prevState => !prevState)
	}

	const onDeleteLead	= (index) => {
		setDeleteLeadIndex(index)
		toggleLeadDeleteModal()
	}

	const deleteLead	= () => {		
		if( deleteLeadIndex ){
			setDeleteLoaderApi(true)
			let deleteLeadData	= {campaign_id: id, team_id: currentTeamId, lead_id: reviewLeads[deleteLeadIndex]._id}
			PostData("ms1", "api/v1/campaign/delete-lead", deleteLeadData).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						toast.success(result.message)
						let localReviewLeads	= [...reviewLeadsContents];
						localReviewLeads.splice(deleteLeadIndex, 1)
						setReviewLeadsContent(localReviewLeads)
						if( activeLeadIndex >= localReviewLeads.length && localReviewLeads.length !== 0 ){					
							setActiveLeadIndex(localReviewLeads.length -1)
						}else if( localReviewLeads.length === 0 ){
							setActiveLeadIndex(null)
						}
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
					setDeleteLeadIndex(null)
					toggleLeadDeleteModal()
					setDeleteLoaderApi(false)
				}
			})
		}else if( leadsSelected.length > 0 ){
			setDeleteLoaderApi(true)
			let deleteLeadData	= {campaign_id: id, team_id: currentTeamId, lead_ids: leadsSelected}
			PostData("ms1", "api/v1/campaign/delete-leads", deleteLeadData).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						toast.success(result.message)
						let localReviewLeads	= [...reviewLeadsContents];
						if( leadsSelected.length === localReviewLeads.length ){
							localReviewLeads	= []
						}else{
							const deletedReviewLeadsIndex = localReviewLeads.reduce((accumulator, currentValue, currentIndex) => {
								if ( leadsSelected.indexOf(currentValue._id) !== -1 ) {
									accumulator.push(currentIndex);
								}
								return accumulator;
							}, [leadsSelected]);
							if( deletedReviewLeadsIndex.length > 0 ){
								localReviewLeads = localReviewLeads.filter((element, index) => !deletedReviewLeadsIndex.includes(index));
							}
						}
						setReviewLeadsContent(localReviewLeads)						
						if( activeLeadIndex >= localReviewLeads.length && localReviewLeads.length !== 0 ){					
							setActiveLeadIndex(localReviewLeads.length -1)
						}else if( localReviewLeads.length === 0 ){
							setActiveLeadIndex(null)
						}
						setLeadsSelected([])
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
					setDeleteLeadIndex(null)
					toggleLeadDeleteModal()
					setDeleteLoaderApi(false)
				}
			})
		}
	}

	const onSkipLead = (index) => {
		let skipLeadData	= { campaign_id: id, team_id: currentTeamId, lead_id: reviewLeads[index]._id }
		PostData("ms1", "api/v1/campaign/skip-lead", skipLeadData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					toast.success(result.message)
					let localReviewLeads	= [...reviewLeadsContents];
					localReviewLeads.splice(index, 1)
					setReviewLeadsContent(localReviewLeads)
					if( activeLeadIndex >= localReviewLeads.length && localReviewLeads.length !== 0 ){					
						setActiveLeadIndex(localReviewLeads.length -1)
					}else if( localReviewLeads.length === 0 ){
						setActiveLeadIndex(null)
					}
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const onReviewLead = (index) => {
		let reviewLeadData	= { campaign_id: id, team_id: currentTeamId, lead_id: reviewLeads[index]._id, sequences: reviewLeads[index].sequences }
		PostData("ms1", "api/v1/campaign/review-lead", reviewLeadData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					toast.success(result.message)
					let localReviewLeads	= [...reviewLeadsContents];
					localReviewLeads.splice(index, 1)
					setReviewLeadsContent(localReviewLeads)
					setCampaignDetails({
						...campaignDetails,
						running_status: result.data.running_status
					});
					if( activeLeadIndex >= localReviewLeads.length && localReviewLeads.length !== 0 ){
						setActiveLeadIndex(localReviewLeads.length -1)
					}else if( localReviewLeads.length === 0 ){
						setActiveLeadIndex(null)
					}
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const onSkipLeads = () => {
		if( leadsSelected.length > 0 ){
			let skipLeadsData	= { campaign_id: id, team_id: currentTeamId, lead_ids: leadsSelected }
			PostData("ms1", "api/v1/campaign/skip-leads", skipLeadsData).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						toast.success(result.message)
						let localReviewLeads	= [...reviewLeadsContents];
						if( leadsSelected.length === localReviewLeads.length ){
							localReviewLeads	= []
						}else{
							const deletedReviewLeadsIndex = localReviewLeads.reduce((accumulator, currentValue, currentIndex) => {
								if (leadsSelected.indexOf(currentValue._id) ) {
									accumulator.push(currentIndex);
								}
								return accumulator;
							}, [leadsSelected]);
							if( deletedReviewLeadsIndex.length > 0 ){
								localReviewLeads = localReviewLeads.filter((element, index) => !deletedReviewLeadsIndex.includes(index));
							}
						}
						setReviewLeadsContent(localReviewLeads)						
						if( activeLeadIndex >= localReviewLeads.length && localReviewLeads.length !== 0 ){					
							setActiveLeadIndex(localReviewLeads.length -1)
						}else if( localReviewLeads.length === 0 ){
							setActiveLeadIndex(null)
						}
						setLeadsSelected([])
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
			})
		}
	}

	const onDeleteLeads	= () => {
		setDeleteLeadIndex(null)
		toggleLeadDeleteModal()
	}

	const reviewSelectedLeads	= () => {
		if( leadsSelected.length > 0 ){
			setReviewButtonLoaderApi(true)
			let selecetedLeads	= reviewLeadsContents.filter( (element) => leadsSelected.indexOf(element._id) !== -1 )
			let reviewLeadsData	= {team_id: currentTeamId, campaign_id: id, review_leads: selecetedLeads}
			PostData("ms1", "api/v1/campaign/review-leads", reviewLeadsData).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						let localReviewLeads	= [...reviewLeadsContents];
						if( leadsSelected.length === localReviewLeads.length ){
							localReviewLeads	= []
						}else{
							const deletedReviewLeadsIndex = localReviewLeads.reduce((accumulator, currentValue, currentIndex) => {
								if (leadsSelected.indexOf(currentValue._id) ) {
									accumulator.push(currentIndex);
								}
								return accumulator;
							}, [leadsSelected]);
							if( deletedReviewLeadsIndex.length > 0 ){
								localReviewLeads = localReviewLeads.filter((element, index) => !deletedReviewLeadsIndex.includes(index));
							}
						}
						setCampaignDetails({
							...campaignDetails,
							running_status: result.data.running_status
						});
						setReviewLeadsContent(localReviewLeads)						
						if( activeLeadIndex >= localReviewLeads.length && localReviewLeads.length !== 0 ){					
							setActiveLeadIndex(localReviewLeads.length -1)
						}else if( localReviewLeads.length === 0 ){
							setActiveLeadIndex(null)
						}
						setLeadsSelected([])
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
				setReviewButtonLoaderApi(false)
			})
		}
	}

	const reviewAllLead = () => {
		setReviewAllLoaderApi(true)
		let reviewLeadsData	= {team_id: currentTeamId, campaign_id: id, review_leads: reviewLeads}
		PostData("ms1", "api/v1/campaign/review-leads", reviewLeadsData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setCampaignDetails({
						...campaignDetails,
						running_status: result.data.running_status
					});
					setReviewLeadsContent([])
					setActiveLeadIndex(null)
					setLeadsSelected([])
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			toggleReviewAllWarningModal()
			setReviewAllLoaderApi(false)
		})
	}

	return (
		<>
			<div className={`campaignEditContentWrapper tabReview backgroundDotted`}>
				<div className='left'>
					<div className='d-flex-col sm top'>
						<div className='d-flex-row editorTabReviewLeftTop'>
							<div className='leadReviewMain'>
								<div className="checkbox rsCheckbox rsSelectAllLeads">
									<Label className="jsPreventLabelBounceEventClick">
										<Input type='checkbox' checked={reviewLeadsContents.length !== 0 && leadsSelected.length === reviewLeadsContents.length} onChange={(e) => selectOrUnselectAllLeads(e)} />
									</Label>
								</div>
								<span className='displayNumberSelectedLeads'>{leadsSelected.length === reviewLeadsContents.length ? `All leads selected (${leadsSelected.length})` : `${leadsSelected.length} Lead${ leadsSelected.length > 1 ? "s" : "" } selected`}</span>
								<div className='rsSearchInput rsSearchLead'>
									<i className="fa-solid fa-magnifying-glass"></i>
									<Input type='text' placeholder='Search by email, name, firstname, phone number...' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
									<i className="fa-solid fa-circle-xmark rsClearSearch" onClick={() => setSearchText('')}></i>
								</div>
							</div>
							<div className='leadReviewActions'>
								{/* <button className="btn btnAction rsToggleSearch">
									<i className="fa-solid fa-magnifying-glass"></i>
								</button> */}
								<LeadsReviewDropdown leadsCount={leadsSelected.length} onClick={(type) => leadsReviewDropdownClick(type)} />
							</div>
						</div>
					</div>
					<div className='content'>
						<div className='d-flex-col editorTabReviewLeft'>							
							{reviewLeadLoader ?
								<div className='d-flex-col sm listLeads relative'>
									<ReviewLeadSkeleton />
									<ReviewLeadSkeleton />
									<ReviewLeadSkeleton />
									<ReviewLeadSkeleton />
								</div>
							:
								<>
									{reviewLeadsContents.length > 0 ?
										<div className='d-flex-col sm listLeads relative'>
											{reviewLeadsContents.map((reviewLead, index) => {
												return (
													<div className={`d-flex-row align-items-center leadLeft rsShowLeadReview ${activeLeadIndex === index ? "active" : ""}`} onClick={() => setActiveLeadIndex(index)}>
														<div className="checkbox rsCheckbox rsSelectLead">
															<Label className="jsPreventLabelBounceEventClick">
																<Input type='checkbox' checked={leadsSelected.indexOf(reviewLead._id) !== -1} onChange={(e) => addLeadsSelected(e, reviewLead._id)} />
															</Label>
														</div>
														<div className='leadSummary leadReviewSummaryCard d-flex-row md pointer displayEye'>
															<div className='leadPictureAndState'>
																{/* <i className="fa-regular fa-eye hoverLayer"></i> */}
																{(reviewLead.firstName || reviewLead.lastName) &&
																	<div className={`leadPicture avatarPrimary${parseInt(reviewLead.firstName.concat(reviewLead.lastName).length % 10)}`}>
																		{reviewLead.firstName && reviewLead.firstName.substr(0, 1)}{reviewLead.lastName && reviewLead.lastName.substr(0, 1)}
																	</div>
																}
															</div>
															<div className='leadContact'>
																<div className='leadHeader'>
																	<span className='leadName'>
																		<span className='leadNameOverflow'>{reviewLead.firstName && reviewLead.firstName} {reviewLead.lastName && reviewLead.lastName}</span>
																		{/* <div className='rsTag leadEmailStatus sm tagSuccess100'>
																			<span>deliverable</span>
																		</div> */}
																	</span>
																	<span className='leadIcons'></span>
																</div>
																<p className='leadListEmail'>{reviewLead.email}</p>
															</div>
														</div>
														<LeadReviewDropdown index={index} onClick={(type, index) => leadReviewDropdownClick(type, index)} />
													</div>
												)
											})}
											{/* <div className='d-flex-row align-items-center leadLeft rsShowLeadReview'>
												<div className="checkbox rsCheckbox rsSelectLead">
													<Label className="jsPreventLabelBounceEventClick">
														<Input type='checkbox' />
													</Label>
												</div>
												<div className='leadSummary leadReviewSummaryCard d-flex-row md pointer displayEye'>
													<div className='leadPictureAndState'>
														<i className="fa-regular fa-eye hoverLayer"></i>
														<div className="leadPicture avatarPrimary7">MP</div>
													</div>
													<div className='leadContact'>
														<div className='leadHeader'>
															<span className='leadName'>
																<span className='leadNameOverflow'>Manoj PC</span>
																<div className='rsTag leadEmailStatus sm tagSuccess100'>
																	<span>deliverable</span>
																</div>
															</span>
															<span className='leadIcons'></span>
														</div>
														<p className='leadListEmail'>manoj.pc@innoppl.com</p>
													</div>
												</div>
												<LeadReviewDropdown />
											</div>
											<div className='d-flex-row align-items-center leadLeft rsShowLeadReview'>
												<div className="checkbox rsCheckbox rsSelectLead">
													<Label className="jsPreventLabelBounceEventClick">
														<Input type='checkbox' />
													</Label>
												</div>
												<div className='leadSummary leadReviewSummaryCard d-flex-row md pointer displayEye'>
													<div className='leadPictureAndState'>
														<i className="fa-regular fa-eye hoverLayer"></i>
														<div className="leadPicture avatarPrimary7">SI</div>
													</div>
													<div className='leadContact'>
														<div className='leadHeader'>
															<span className='leadName'>
																<span className='leadNameOverflow'>Solomon Israel</span>
																<div className='rsTag leadEmailStatus sm tagSuccess100'>
																	<span>deliverable</span>
																</div>
															</span>
															<span className='leadIcons'></span>
														</div>
														<p className='leadListEmail'>solomon.i@innoppl.com</p>
													</div>
												</div>
												<LeadReviewDropdown />
											</div> */}
										</div>
									:
										<div className="emptyLeads">
											<img src={require("./../../../assets/images/all-leads-ended.svg").default} alt="All leads ended" className='img-fluid' />
											<h1>Congrats!<br />All leads are reviewed</h1>
											<span className="subtitle">No leads are waiting to be reviewed</span>
										</div>
									}
								</>
							}
						</div>
					</div>
					<div className='bottom'>
						<div className='d-flex-col'>
							<div className='d-flex-row align-items-center'>
								<div className="rsSwitch customControl customSwitch rsToggleAutoReview">
									<Label className="customControlLabel">
										<Input type="checkbox" checked={campaignDetails.auto_review} />
										<span className="switchDisplay"></span>
										<span className="rsLabel"></span>
									</Label>
								</div>
								<span>Auto review</span>
							</div>
							<div className='input-group campaignEditReviewLeftBottom'>
								<Button color='primary' className='reviewAllButton' onClick={() => reviewSelectedLeads()} disabled={leadsSelected.length === 0 || reviewButtonLoaderApi}>
									{reviewButtonLoaderApi &&
										<Spinner size={"sm"} />
									}
									Review {leadsSelected.length} lead{leadsSelected.length > 1 ? "s" : "" }
								</Button>
								<LeadReviewAllDropdown leadCount={reviewLeadsContents.length} onClick={() => toggleReviewAllWarningModal()} />
							</div>
						</div>
					</div>
				</div>
				<div className='center'>
					<div className='rsTreeNavigation'>
						<div className='containerActions'>
							<div className='rsAction rsZoom minus' onClick={() => zoomIn()}>
								<i className="fa-solid fa-magnifying-glass-minus"></i>
							</div>
							<div className='rsAction rsZoom plus' onClick={() => zoomOut()}>
								<i className="fa-solid fa-magnifying-glass-plus"></i>
							</div>
						</div>
					</div>
					<div
						className='rsTreeReview'
						ref={scrollableDivRef}
						onMouseDown={handleScrollDivMouseDown}
						onMouseMove={handleScrollDivMouseMove}
						onMouseUp={handleScrollDivMouseUp}
					>
						<div className={`reviewOverlay rsCloseReview ${reviewLeadSteps ? "show" : ""}`}></div>
						<div className='d-flex-col noGap reviewSteps' style={{transform: `scale(${sequenceZoom})`}}>
							{reviewLeadLoader ?
								<ReviewSequenceSkeleton />
							:
								<>
									{( reviewLeadsContents.length > 0 && sequenceDetails.length > 0  ) &&
										<>
											<div className='reviewStart'>
												<div className='title'>Sequence start</div>
											</div>
											<div className="stepVerticalSeparator d-flex-col noGap">
												<div className="stepVerticalSeparatorLine"></div>
											</div>
											<div className="rsTreeReviewSequence d-flex-col noGap">
												{sequenceDetails && sequenceDetails.map((step, index) => {
													return (
														<React.Fragment key={index}>
															<div className="stepVerticalSeparatorLine"></div>
															{step.delay_time > 0 &&
																<>
																	<div className="reviewDelay">
																		<div className="channelIcon delay">
																			<i className="fa-regular fa-clock"></i>
																		</div>
																		<span className="text">Wait for <span className="colored">{step.delay_time} day(s)</span></span>
																	</div>
																	<div className="stepVerticalSeparatorLine"></div>
																</>
															}
															<div className="d-flex-col noGap relative rsTreeEditReviewStep rsLayoutRight">
																<div className='d-flex-row align-items-center reviewTitle relative sm'>
																	<div className='stepNumber'>{index + 1}.</div>
																	{typeof reviewLeads[activeLeadIndex].sequences[index].variation_key !== "undefined" &&
																		<>
																			{reviewLeads[activeLeadIndex].sequences[index].variation_key === 0 ?
																				<svg className="a letter" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path className="border" d="M1 4C1 3.02892 1.00212 2.40121 1.06431 1.9387C1.12262 1.50496 1.21677 1.36902 1.29289 1.29289C1.36902 1.21677 1.50496 1.12262 1.9387 1.06431C2.40121 1.00212 3.02892 1 4 1H28.3091C29.4291 1 30.162 1.00238 30.695 1.0779C31.2002 1.14948 31.3415 1.26444 31.4155 1.35115C31.4894 1.43787 31.5806 1.59555 31.5715 2.10575C31.5618 2.64401 31.4484 3.36809 31.2714 4.47397L28.5514 21.474C28.4187 22.3033 28.3321 22.8333 28.2192 23.221C28.1142 23.5818 28.019 23.6965 27.9434 23.7609C27.8678 23.8254 27.7396 23.9013 27.3667 23.948C26.9661 23.9982 26.429 24 25.5891 24H4C3.02892 24 2.40121 23.9979 1.9387 23.9357C1.50496 23.8774 1.36902 23.7832 1.29289 23.7071C1.21677 23.631 1.12262 23.495 1.06431 23.0613C1.00212 22.5988 1 21.9711 1 21V4Z" stroke="currentcolor" strokeWidth="2"></path>
																					<path className="letter" d="M13.7464 17H11.7776L15.3621 6.81818H17.6391L21.2286 17H19.2599L16.5404 8.90625H16.4608L13.7464 17ZM13.811 13.0078H19.1803V14.4893H13.811V13.0078Z" fill="currentcolor"></path>
																				</svg>
																			:
																				<svg className="b letter" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path className="border" d="M4.44855 3.52603C4.58125 2.69666 4.66791 2.16666 4.78076 1.77898C4.88579 1.41818 4.98099 1.30355 5.05659 1.23908C5.13219 1.17462 5.26042 1.09872 5.63329 1.05202C6.03393 1.00183 6.57096 1 7.41088 1H29C29.9711 1 30.5988 1.00212 31.0613 1.06431C31.495 1.12262 31.631 1.21677 31.7071 1.29289C31.7832 1.36902 31.8774 1.50496 31.9357 1.9387C31.9979 2.40121 32 3.02892 32 4V21C32 21.9711 31.9979 22.5988 31.9357 23.0613C31.8774 23.495 31.7832 23.631 31.7071 23.7071C31.631 23.7832 31.495 23.8774 31.0613 23.9357C30.5988 23.9979 29.9711 24 29 24H4.69088C3.57093 24 2.83802 23.9976 2.305 23.9221C1.79976 23.8505 1.65846 23.7356 1.58452 23.6488C1.51058 23.5621 1.41939 23.4045 1.42853 22.8942C1.43817 22.356 1.55161 21.6319 1.72855 20.526L4.44855 3.52603Z" stroke="currentcolor" strokeWidth="2"></path>
																					<path className="letter" d="M12.257 17V6.81818H16.3337C17.0828 6.81818 17.7075 6.92921 18.208 7.15128C18.7085 7.37334 19.0847 7.68158 19.3366 8.07599C19.5885 8.46709 19.7144 8.91785 19.7144 9.42827C19.7144 9.82599 19.6349 10.1757 19.4758 10.4773C19.3167 10.7756 19.0979 11.0208 18.8195 11.2131C18.5444 11.402 18.2296 11.5362 17.8749 11.6158V11.7152C18.2627 11.7318 18.6256 11.8411 18.9637 12.0433C19.3051 12.2455 19.5818 12.5289 19.7939 12.8935C20.0061 13.2547 20.1121 13.6856 20.1121 14.1861C20.1121 14.7263 19.9779 15.2086 19.7094 15.6328C19.4443 16.0537 19.0515 16.3868 18.5312 16.6321C18.0108 16.8774 17.3695 17 16.6072 17H12.257ZM14.4097 15.2401H16.1647C16.7646 15.2401 17.2021 15.1257 17.4772 14.897C17.7523 14.665 17.8898 14.3568 17.8898 13.9723C17.8898 13.6906 17.8219 13.442 17.686 13.2266C17.5501 13.0111 17.3562 12.8421 17.1043 12.7195C16.8557 12.5968 16.5591 12.5355 16.2144 12.5355H14.4097V15.2401ZM14.4097 11.0788H16.0056C16.3006 11.0788 16.5624 11.0275 16.7911 10.9247C17.0231 10.8187 17.2054 10.6695 17.338 10.4773C17.4739 10.285 17.5418 10.0547 17.5418 9.78622C17.5418 9.41832 17.4109 9.12169 17.1491 8.89631C16.8905 8.67093 16.5226 8.55824 16.0454 8.55824H14.4097V11.0788Z" fill="currentcolor"></path>
																				</svg>
																			}
																		</>
																	}
																	<div className='stepLabel relative'>
																		{step.type === "email_template" &&
																			<div className='channelIcon email'>
																				<i className="fa-regular fa-envelope"></i>
																			</div>
																		}
																		<div className='desc'>
																			<span className='labelType'>{step.type === "email_template" ? "Email" : ""}</span>
																			<span className='descriptionType'>
																				<span className="descriptionTypeLabel">{step.type === "email_template" ? "Send automatic email" : ""}</span>
																			</span>
																		</div>
																	</div>
																	<div className='spacer'></div>
																	<div className='multiSenders'>
																		{campaignDetails.sender_user_id &&
																			<div className={`avatar uiUserPicture avatarPrimary${((campaignDetails.sender_user_id - 1) % 10)}`}>
																				{teamMailAccountData[campaignDetails.sender_user_id] ?
																					<>
																						{teamMailAccountData[campaignDetails.sender_user_id].profile_url ?
																							<img src={teamMailAccountData[campaignDetails.sender_user_id].profile_url} alt={teamMailAccountData[campaignDetails.sender_user_id].name} className='picture' />
																							:
																							<>
																								{teamMailAccountData[campaignDetails.sender_user_id].name.substr(0, 1)}
																							</>
																						}
																					</>
																				: teamUserData[campaignDetails.sender_user_id] &&
																					<>
																						{teamUserData[campaignDetails.sender_user_id].profile_url ?
																							<img src={teamUserData[campaignDetails.sender_user_id].profile_picture} alt={teamUserData[campaignDetails.sender_user_id].name} className='picture' />
																							:
																							<>
																								{teamUserData[campaignDetails.sender_user_id].first_name.substr(0, 1)}{teamUserData[campaignDetails.sender_user_id].last_name && teamUserData[campaignDetails.sender_user_id].last_name.substr(0, 1)}
																							</>
																						}
																					</>
																				}
																			</div>
																		}
																	</div>
																	{typeof reviewLeads[activeLeadIndex].sequences[index].variation_key !== "undefined" &&
																		<LeadReviewSequenceDropdown currentLeadSequence={reviewLeads[activeLeadIndex].sequences[index]} index={index} leadIndex={activeLeadIndex} onClick={(activeLeadIndex, index, type) => reviewSequenceDropdownClicked( activeLeadIndex, index, type )}  />
																	}
																</div>
																{step.type === "email_template" &&
																	<div className="reviewContent" onClick={() => loadParticularLeadSequence(index)}>  
																		<div className="viewEmail d-flex-col flex-1">
																			<div className="d-flex-col">
																				<div className="text-light">
																					{reviewLeads[activeLeadIndex].sequences[index].subject}
																				</div>
																				<div className="messageSimulateEditor">
																					<div dangerouslySetInnerHTML={{__html:reviewLeads[activeLeadIndex].sequences[index].message}}></div>
																				</div>
																			</div>
																		</div>
																	</div>
																}
															</div>
														</React.Fragment>
													)
												})}
												{/* <div className="d-flex-col noGap relative rsTreeEditReviewStep rsLayoutRight">
													<div className='d-flex-row align-items-center reviewTitle relative sm'>
														<div className='stepNumber'>1.</div>
														<div className='stepLabel relative'>
															<div className='channelIcon email'>
																<i className="fa-regular fa-envelope"></i>
															</div>
															<div className='desc'>
																<span className='labelType'>Email</span>
																<span className='descriptionType'>
																	<span className="descriptionTypeLabel">Send automatic email</span>
																</span>
															</div>
														</div>
														<div className='spacer'></div>
														<div className='multiSenders'>
															<div className="avatar uiUserPicture avatarPrimary0">
																SI
															</div>
														</div>
													</div>
													<div className="reviewContent">  
														<div className="viewEmail d-flex-col flex-1">
															<div className="d-flex-col">
																<div className="text-light">
																	Manoj Pc Solomon
																</div>
																<div className="messageSimulateEditor">
																	Welcome where&nbsp;Manoj Pc
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="stepVerticalSeparatorLine"></div>
												<div className="reviewDelay">
													<div className="channelIcon delay">
														<i className="fa-regular fa-clock"></i>
													</div>
													<span className="text">Wait for <span className="colored">1 day(s)</span></span>
												</div>
												<div className="stepVerticalSeparatorLine"></div>
												<div className="d-flex-col noGap relative rsTreeEditReviewStep rsLayoutRight">
													<div className='d-flex-row align-items-center reviewTitle relative sm'>
														<div className='stepNumber'>1.</div>
														<div className='stepLabel relative'>
															<div className='channelIcon email'>
																<i className="fa-regular fa-envelope"></i>
															</div>
															<div className='desc'>
																<span className='labelType'>Email</span>
																<span className='descriptionType'>
																	<span className="descriptionTypeLabel">Send automatic email</span>
																</span>
															</div>
														</div>
														<div className='spacer'></div>
														<div className='multiSenders'>
															<div className="avatar uiUserPicture avatarPrimary0">
																SI
															</div>
														</div>
													</div>
													<div className="reviewContent">  
														<div className="viewEmail d-flex-col flex-1">
															<div className="d-flex-col">
																<div className="text-light">
																	Manoj Pc Solomon
																</div>
																<div className="messageSimulateEditor">
																	Welcome where&nbsp;Manoj Pc
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="stepVerticalSeparatorLine"></div>
												<div className="reviewDelay">
													<div className="channelIcon delay">
														<i className="fa-regular fa-clock"></i>
													</div>
													<span className="text">Wait for <span className="colored">1 day(s)</span></span>
												</div>
												<div className="stepVerticalSeparatorLine"></div>
												<div className="d-flex-col noGap relative rsTreeEditReviewStep rsLayoutRight">
													<div className='d-flex-row align-items-center reviewTitle relative sm'>
														<div className='stepNumber'>1.</div>
														<div className='stepLabel relative'>
															<div className='channelIcon email'>
																<i className="fa-regular fa-envelope"></i>
															</div>
															<div className='desc'>
																<span className='labelType'>Email</span>
																<span className='descriptionType'>
																	<span className="descriptionTypeLabel">Send automatic email</span>
																</span>
															</div>
														</div>
														<div className='spacer'></div>
														<div className='multiSenders'>
															<div className="avatar uiUserPicture avatarPrimary0">
																SI
															</div>
														</div>
													</div>
													<div className="reviewContent">  
														<div className="viewEmail d-flex-col flex-1">
															<div className="d-flex-col">
																<div className="text-light">
																	Manoj Pc Solomon
																</div>
																<div className="messageSimulateEditor">
																	Welcome where&nbsp;Manoj Pc
																</div>
															</div>
														</div>
													</div>
												</div> */}
											</div>
										</>
									}
								</>
							}
						</div>
					</div>
				</div>
			</div>
			<div className={`rsRightModal rightLayout layoutRightEditReviewStep ${reviewLeadSteps ? "open" : ""}`}>
				{(leadSequenceIndex !== null ) ?
					<>
						<div className='d-flex-col rightLayoutContent'>
							<div className='titleContainer align-items-start'>
								<div className='d-flex-row align-items-center rightLayoutContentLeft'>
									<button className="btn btn-secondary btn-ico sm closeLayoutRight" onClick={() => toggleReviewLeadSteps()}>
										<i className="fa-solid fa-xmark"></i>
									</button>
									<div className='title'>
										<div className='d-flex-row align-items-center reviewTitle relative sm'>
											<div className="input-group leadReviewStepPagination">
												<button className="btn btn-ico btn-secondary rsReviewPreviousStep" disabled={leadSequenceIndex === 0} onClick={() => gotoPreviousStep(leadSequenceIndex)}>
													<i className="fa-solid fa-chevron-up"></i>
												</button>
												<button className="btn btn-ico btn-secondary rsReviewNextStep" disabled={(leadSequenceIndex + 1) === sequenceDetails.length} onClick={() => gotoNextStep(leadSequenceIndex)}>
													<i className="fa-solid fa-chevron-down"></i>
												</button>
											</div>
											<div className='stepNumber'>{leadSequenceIndex + 1}.</div>
											{typeof reviewLeads[activeLeadIndex].sequences[leadSequenceIndex].variation_key !== "undefined" &&
												<>
													{reviewLeads[activeLeadIndex].sequences[leadSequenceIndex].variation_key === 0 ?
														<svg className="a letter" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path className="border" d="M1 4C1 3.02892 1.00212 2.40121 1.06431 1.9387C1.12262 1.50496 1.21677 1.36902 1.29289 1.29289C1.36902 1.21677 1.50496 1.12262 1.9387 1.06431C2.40121 1.00212 3.02892 1 4 1H28.3091C29.4291 1 30.162 1.00238 30.695 1.0779C31.2002 1.14948 31.3415 1.26444 31.4155 1.35115C31.4894 1.43787 31.5806 1.59555 31.5715 2.10575C31.5618 2.64401 31.4484 3.36809 31.2714 4.47397L28.5514 21.474C28.4187 22.3033 28.3321 22.8333 28.2192 23.221C28.1142 23.5818 28.019 23.6965 27.9434 23.7609C27.8678 23.8254 27.7396 23.9013 27.3667 23.948C26.9661 23.9982 26.429 24 25.5891 24H4C3.02892 24 2.40121 23.9979 1.9387 23.9357C1.50496 23.8774 1.36902 23.7832 1.29289 23.7071C1.21677 23.631 1.12262 23.495 1.06431 23.0613C1.00212 22.5988 1 21.9711 1 21V4Z" stroke="currentcolor" strokeWidth="2"></path>
															<path className="letter" d="M13.7464 17H11.7776L15.3621 6.81818H17.6391L21.2286 17H19.2599L16.5404 8.90625H16.4608L13.7464 17ZM13.811 13.0078H19.1803V14.4893H13.811V13.0078Z" fill="currentcolor"></path>
														</svg>
													:
														<svg className="b letter" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path className="border" d="M4.44855 3.52603C4.58125 2.69666 4.66791 2.16666 4.78076 1.77898C4.88579 1.41818 4.98099 1.30355 5.05659 1.23908C5.13219 1.17462 5.26042 1.09872 5.63329 1.05202C6.03393 1.00183 6.57096 1 7.41088 1H29C29.9711 1 30.5988 1.00212 31.0613 1.06431C31.495 1.12262 31.631 1.21677 31.7071 1.29289C31.7832 1.36902 31.8774 1.50496 31.9357 1.9387C31.9979 2.40121 32 3.02892 32 4V21C32 21.9711 31.9979 22.5988 31.9357 23.0613C31.8774 23.495 31.7832 23.631 31.7071 23.7071C31.631 23.7832 31.495 23.8774 31.0613 23.9357C30.5988 23.9979 29.9711 24 29 24H4.69088C3.57093 24 2.83802 23.9976 2.305 23.9221C1.79976 23.8505 1.65846 23.7356 1.58452 23.6488C1.51058 23.5621 1.41939 23.4045 1.42853 22.8942C1.43817 22.356 1.55161 21.6319 1.72855 20.526L4.44855 3.52603Z" stroke="currentcolor" strokeWidth="2"></path>
															<path className="letter" d="M12.257 17V6.81818H16.3337C17.0828 6.81818 17.7075 6.92921 18.208 7.15128C18.7085 7.37334 19.0847 7.68158 19.3366 8.07599C19.5885 8.46709 19.7144 8.91785 19.7144 9.42827C19.7144 9.82599 19.6349 10.1757 19.4758 10.4773C19.3167 10.7756 19.0979 11.0208 18.8195 11.2131C18.5444 11.402 18.2296 11.5362 17.8749 11.6158V11.7152C18.2627 11.7318 18.6256 11.8411 18.9637 12.0433C19.3051 12.2455 19.5818 12.5289 19.7939 12.8935C20.0061 13.2547 20.1121 13.6856 20.1121 14.1861C20.1121 14.7263 19.9779 15.2086 19.7094 15.6328C19.4443 16.0537 19.0515 16.3868 18.5312 16.6321C18.0108 16.8774 17.3695 17 16.6072 17H12.257ZM14.4097 15.2401H16.1647C16.7646 15.2401 17.2021 15.1257 17.4772 14.897C17.7523 14.665 17.8898 14.3568 17.8898 13.9723C17.8898 13.6906 17.8219 13.442 17.686 13.2266C17.5501 13.0111 17.3562 12.8421 17.1043 12.7195C16.8557 12.5968 16.5591 12.5355 16.2144 12.5355H14.4097V15.2401ZM14.4097 11.0788H16.0056C16.3006 11.0788 16.5624 11.0275 16.7911 10.9247C17.0231 10.8187 17.2054 10.6695 17.338 10.4773C17.4739 10.285 17.5418 10.0547 17.5418 9.78622C17.5418 9.41832 17.4109 9.12169 17.1491 8.89631C16.8905 8.67093 16.5226 8.55824 16.0454 8.55824H14.4097V11.0788Z" fill="currentcolor"></path>
														</svg>
													}
												</>
											}
											<div className='stepLabel relative'>
												{sequenceDetails[leadSequenceIndex].type === "email_template" &&
													<div className='channelIcon email'>
														<i className="fa-regular fa-envelope"></i>
													</div>
												}
												<div className='desc'>
													<span className='labelType'>{sequenceDetails[leadSequenceIndex].type === "email_template" && "Email"}</span>
													<span className='descriptionType'>
														<span className="descriptionTypeLabel">{sequenceDetails[leadSequenceIndex].type === "email_template" && "Send automatic email"}</span>
													</span>
												</div>
											</div>
											<div className="spacer"></div>
										</div>
									</div>
								</div>
							</div>
							{sequenceDetails[leadSequenceIndex].type === "email_template" &&
								<div className='d-flex-col reviewEmail'>
									<div className='d-flex-col sm'>
										<h4>Sender for email steps<sup className="alertColor">*</sup></h4>
										<div>
											<div>
												<MultipleSelectUserEmail senderUserId={campaignDetails.sender_user_id} disabled={true} placeholder={"Select a sender"} />
											</div>
										</div>
									</div>
									<div className='d-flex-col flex-1'>
										<div className='d-flex-row xs align-items-center'>
											<h4 className="bold relative">Email</h4>
										</div>
									</div>
									<div className='textEdit rsCampaignName'>
										<div className='inputWrapper'>
											<input type="text" className="rsTextEdit" placeholder={ leadSequenceIndex === 0 ? 'Subject' : "Leave the subject line field empty to use the previous email's subject line"}  name="email_subject" value={reviewLeads[activeLeadIndex].sequences[leadSequenceIndex].subject} onChange={(e) => updateLeadEmailSequence( "subject", e.target.value, activeLeadIndex, leadSequenceIndex)} />
											{/* <button className="btn btn-tertiary rsAddCustomVariable" onClick={() => setLoadCustomVariable((prevstate) => !prevstate)} aria-hidden="true"> <span>Add custom variable</span></button> */}
											<div className='rsTextEditBorder'></div>
											{loadCustomVariable &&
												<div className="attributeContainer">
													<ul>
														{Object.keys(customVariables).map((variableKey, index) => {
															return(
																<li className={`${index === highlightVariable ? "highlight" : ""} `} onMouseEnter={() => setHighlightVariable(index)}><b>{variableKey}</b> {`{{${customVariables[variableKey]}}}`}</li>
															)
														})}
													</ul>
												</div>
											}
										</div>
									</div>
									<div className='emailTextEditor signatureTextEditor rsTextEditor'>
										<EmailEditor 
											html={reviewLeads[activeLeadIndex].sequences[leadSequenceIndex].message}
											index={activeLeadIndex}
											variation={leadSequenceIndex}
											onChange={(value, index, variation) => updateLeadEmailSequence( "message", value, index, variation)}
										/>
									</div>
								</div>
							}
						</div>
					</>
				:
					<></>
				}
			</div>
			{reviewAllWarningModal &&
				<Modal isOpen={reviewAllWarningModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation warningColor paddingRight10"></i>Warning</h2>
							<p>Contact all leads at once? Confirm before sending your message to everyone.</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={reviewAllLoaderApi} onClick={() => toggleReviewAllWarningModal()}>Cancel</Button>
							<Button className='md okWarningButton btn-warning' color='primary' disabled={reviewAllLoaderApi} onClick={() => reviewAllLead()}>
								{reviewAllLoaderApi &&
									<Spinner size={"sm"} />
								}
								Ok
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
			{deleteWarningModal &&
				<Modal isOpen={deleteWarningModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation warningColor paddingRight10"></i>Warning</h2>
							<p>Delete {deleteLeadIndex ? "1" : leadsSelected.length} lead{deleteLeadIndex ? "" : leadsSelected.length > 1 ? "s" : "" } from the campaign?</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={deleteLoaderApi} onClick={() => toggleLeadDeleteModal()}>Cancel</Button>
							<Button className='md okWarningButton btn-warning' color='primary' disabled={deleteLoaderApi} onClick={() => deleteLead()}>
								{deleteLoaderApi &&
									<Spinner size={"sm"} />
								}
								Ok
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
		</>
	);
}

export default Review;