import CampaignTopBar from '../components/CampaignTopBar';
import { campaignRoutes } from '../Routes';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useState, useContext, useEffect } from 'react';
import { ProductContext } from '../store';
import { PostData } from '../services/FetchData';
import DetailSkeleton from '../components/Skeleton/DetailSkeleton';
import { toast } from 'react-toastify';
import Notification from '../components/Notification';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useGoogleLogin } from '@react-oauth/google';


function Campaign(props) {
	const [isLogged, setIsLogged] = useState(true),
		{ id, '*': wildcard } = useParams(),
		{
			setUserData,
			setUserLoader,
			userMailAccount,
			userLoader,
			setTeamsData,
			setCurrentTeamId,
			setPlansData,
			setUserMailAccount,
			teamMailAccountData,
			setTeamMailAccountData,
			setTeamLimits,
			setEmailSignature,
			setTeamUserData,
			setCampaignDetails,
			setSequenceDetails,
			loadMailAccountModal,
			setLoadMailAccountModal,
			setReviewLeads,
			googleUser,
			setGoogleUser,
			setCampaignLeads,
			setSchedules
		} = useContext(ProductContext)

	useEffect(() => {
		const Token = Cookies.get("_round_sales_token");
		if (!Token) {
			setIsLogged(false);
		} else {
			setUserLoader(true)
			getUsers()
			getTeamUsers()
			getReviewLeads()
		}
	}, [])

	useEffect(() => {
		PostData("ms1", "api/v1/campaign/get-details", { campaign_id: id, team_id: Cookies.get("_team_id") }).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					setCampaignDetails(result.data.campaign)
					setSequenceDetails(result.data.sequences)
					setCampaignLeads(result.data.leads)
					setSchedules(result.data.schedules);
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setUserLoader(false)
		})
	}, [id])

	useEffect(() => {
		if( googleUser ){
			connectGoogleAccount()
		}
	}, [googleUser])

	const getUsers = () => {
		PostData("ms1", "api/v1/user/get-user-detail", { team_id: Cookies.get("_team_id") }).then(result => {
			if (result !== "Invalid") {
				if (result.status === "success") {
					setUserData(result.data.user)
					let teamsTempData = result.data.teams,
						plansTempData = result.data.plans;
					if (teamsTempData.length > 0) {
						let teamLocalData = {}
						for (let i = 0; i < teamsTempData.length; i++) {
							teamLocalData[teamsTempData[i].id] = teamsTempData[i]
						}
						setTeamsData(teamLocalData)
						if (Cookies.get('_team_id')) {
							setCurrentTeamId(Cookies.get("_team_id"))
						} else {
							Cookies.set("_team_id", teamLocalData[0].id)
							setCurrentTeamId(teamLocalData[0].id)
						}
					}

					if (plansTempData.length > 0) {
						let plansLocalData = {}
						for (let i = 0; i < plansTempData.length; i++) {
							plansLocalData[plansTempData[i].id] = plansTempData[i]
						}
						setPlansData(plansLocalData);
					}
					setTeamLimits(result.data.limits)
					setEmailSignature(result.data.email_signature)
					setUserMailAccount(result.data.mailAccount)
				}
			}
		});
	}

	const getTeamUsers = () => {
		PostData("ms1", "api/v1/team/get-team-users", { team_id: Cookies.get("_team_id") }).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					let teamUserTempData = result.data.users,
						teamMailAccountTempData	= result.data.mailAccounts,
						teamUserLocalData = {},
						teamMailAccountLocalData	= {};
					for (let i = 0; i < teamUserTempData.length; i++) {
						teamUserLocalData[teamUserTempData[i].id] = teamUserTempData[i]
					}
					for (let i = 0; i < teamMailAccountTempData.length; i++ ){
						teamMailAccountLocalData[teamMailAccountTempData[i].user_id]	= teamMailAccountTempData[i]
					}
					setTeamUserData(teamUserLocalData)
					setTeamMailAccountData(teamMailAccountLocalData)
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
		})
	}

	const getReviewLeads	= () => {
		let leadReviewData	= {team_id: Cookies.get("_team_id"), campaign_id: id}
		PostData("ms1", "api/v1/campaign/get-review-leads", leadReviewData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setReviewLeads(result.data.reviewLeads)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const getRoutes = () => {
		return campaignRoutes.map((prop, key) => {
			return (
				<Route
					exact
					path={prop.path}
					element={prop.component}
					key={key}
				/>
			);
		});
	};

	const toggleMailAccountModal = () => {
		setLoadMailAccountModal(false)
	}

	const login = useGoogleLogin({
		onSuccess: (codeResponse) => {
			setGoogleUser(codeResponse)
		},
		onError: (error) => console.log('Login Failed:', error),
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly',
	});

	const connectGoogleAccount = () => {
		let param = {
			code: googleUser.code,
			frontend_url: "http://localhost:3000"//window.location.hostname
		}
		PostData('ms1', 'api/v1/mail-accounts/gmail-account', param).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setUserMailAccount(result.data)
					setTeamMailAccountData({
						...teamMailAccountData,
						[result.data.user_id]:{
							...result.data
						}
					})
					setGoogleUser(null)
					toggleMailAccountModal()
					toast.info(result.message)
				}else if( result.status === "failure" ){
					toast.warn(result.message)
				}else if( result.status === "api_error" ){
					toast.warn(result.message.message)
				}
			}
		})
	}

	if (!isLogged) {
		return <Navigate to="/auth/login" replace />
	}

	return (
		<>
			<div className='campaignEditPage'>
				{(userLoader) ?
					<DetailSkeleton />
					:
					<>
						<CampaignTopBar />
						<Routes>
							{getRoutes()}
							<Route path="*" element={<Navigate to="/user/dashboard" replace />} />
						</Routes>
						<div className='Solomon Welcomes your'></div>
					</>
				}
				<Notification />
			</div>
			{loadMailAccountModal &&
				<Modal isOpen={loadMailAccountModal} className={"onboardModal"} centered={true} backdrop="static" keyboard={false}>
					<ModalBody>
						<Button className='btnModalClose' onClick={() => toggleMailAccountModal()}>
							<i className="fa-solid fa-xmark"></i>
						</Button>
						<div className='header'>
							<h1 className='bold'>Connect your email provider</h1>
						</div>
						<div className='onboardModalContent'>
							<div className='d-flex-row emailSettingProvider'>
								<div className='connectAuth gmail'>
									<Button color='primary' className='md jsConnectGmail'  onClick={() => login()}>Connect</Button>
								</div>
								<div className='connectAuth outlook'>
									<Button color='primary' disabled className='md jsConnectOutlook'>Connect</Button>
								</div>
							</div>
							<div className='d-flex-row justify-content-end'>
								
							</div>
						</div>
					</ModalBody>
				</Modal>
			}
		</>
	)
}

export default Campaign;