import React, { useState } from "react";
import { ProductContext } from "./index";

const ProductProvider = props => {
	const [userData, setUserData]	= useState({}),
		[userLoader, setUserLoader]	= useState(true),
		[plansData, setPlansData]	= useState({}),
		[teamsData, setTeamsData]	= useState({}),
		[teamUserData, setTeamUserData]	= useState({}),
		[teamMailAccountData, setTeamMailAccountData]	= useState({}),
		[emailSignature, setEmailSignature]	= useState({}),
		[currentTeamId, setCurrentTeamId]	= useState(null),		
		[successFlag, setSuccessFlag]	= useState(false),
		[autoCloseClass, setAutoCloseClass]	= useState(null),
		[leftHoverClass, setLeftHoverClass]	= useState(null),
		[accountSettingFlag, setAccountSettingFlag]	= useState(false),
		[sequenceLoaderApi, setSequenceLoaderApi]	= useState(false),
		[accountSettingTab, setAccountSettingTab]	= useState('account'),
		[teamLimits, setTeamLimits]	= useState({}),
		[googleUser, setGoogleUser] = useState(''),
		[userMailAccount, setUserMailAccount] = useState(null),
		[campaignDetails, setCampaignDetails]	= useState({}),
		[loadMailAccountModal, setLoadMailAccountModal]	= useState(false),
		[sequenceDetails, setSequenceDetails]	= useState([]),
		[schedules, setSchedules]	= useState([]),
		[campaignLeads, setCampaignLeads]	= useState([]),
		[reviewLeads, setReviewLeads]		= useState([]);
	
	const store = {
		userData,
		setUserData,
		userLoader,
		setUserLoader,
		campaignDetails,
		setCampaignDetails,
		successFlag,
		setSuccessFlag,
		accountSettingFlag,
		setAccountSettingFlag,
		accountSettingTab,
		setAccountSettingTab,
		teamsData,
		setTeamsData,
		currentTeamId,
		setCurrentTeamId,
		plansData,
		setPlansData,
		googleUser,
		setGoogleUser,
		userMailAccount,
		setUserMailAccount,
		teamLimits,
		setTeamLimits,
		emailSignature,
		setEmailSignature,
		teamUserData,
		setTeamUserData,
		sequenceDetails,
		setSequenceDetails,
		sequenceLoaderApi,
		setSequenceLoaderApi,
		loadMailAccountModal,
		setLoadMailAccountModal,
		teamMailAccountData,
		setTeamMailAccountData,
		campaignLeads,
		setCampaignLeads,
		reviewLeads,
		setReviewLeads,
		schedules,
		setSchedules,
		leftHoverClass,
		setLeftHoverClass,
		autoCloseClass,
		setAutoCloseClass
	}
	return <ProductContext.Provider value={store}>{props.children}</ProductContext.Provider>;
}

export default ProductProvider;