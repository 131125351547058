import React, { useContext, useEffect, useState } from 'react';
import Content from '../components/Content';
import SideBar from '../components/SideBar';
import { ProductContext } from "../store";
import Notification from "./../components/Notification";
import { Navigate } from 'react-router-dom';
import { PostData } from '../services/FetchData';
import Cookies from 'js-cookie';
import DetailSkeleton from '../components/Skeleton/DetailSkeleton';
import { Button, Modal, ModalBody } from 'reactstrap';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import Header from '../components/Header';

const User = () => {
	const [isLogged, setIsLogged]		= useState(true),
		[onboardModal, setOnboardModal]	= useState(false),
		{
			userData,
			setUserData,
			setUserLoader,
			userLoader,
			setTeamsData,
			setCurrentTeamId,
			setPlansData,
			setUserMailAccount,
			setTeamMailAccountData,
			setTeamLimits,
			setEmailSignature,
			setTeamUserData,
			setGoogleUser
		}								= useContext(ProductContext);

	useEffect(() => {
		const Token = Cookies.get("_round_sales_token");
		if (!Token) {
			setIsLogged(false);				
		}else{
			getUsers()
			getTeamUsers()
		}
	},[])

	useEffect(() => {
		if( userData && userData['onboard_status'] === false ){
			setOnboardModal(true)
		}
	}, [userData])

	const getUsers = () => {
		PostData("ms1", "api/v1/user/get-user-detail", {team_id: Cookies.get("_team_id")}).then(result => {
			if (result !== "Invalid" && result !== undefined ) {
				if( result.status === "success" ){
					setUserData(result.data.user)
					let teamsTempData	= result.data.teams,
						plansTempData	= result.data.plans;
					if( teamsTempData.length > 0 ){
						let teamLocalData	= {}
						for( let i = 0; i < teamsTempData.length; i++ ){
							teamLocalData[teamsTempData[i].id]	= teamsTempData[i]
						}
						setTeamsData(teamLocalData)
						if( Cookies.get('_team_id') ){
							setCurrentTeamId(Cookies.get("_team_id"))
						}else{
							Cookies.set("_team_id", teamLocalData[0].id)
							setCurrentTeamId(teamLocalData[0].id)
						}
					}

					if( plansTempData.length > 0 ){
						let plansLocalData	= {}
						for( let i = 0; i < plansTempData.length; i++ ){
							plansLocalData[plansTempData[i].id]	= plansTempData[i]
						}
						setPlansData(plansLocalData);
					}
					setTeamLimits(result.data.limits)
					setEmailSignature(result.data.email_signature)
					setUserMailAccount(result.data.mailAccount)
					setUserLoader(false)
				}
			}
		});
	}

	const getTeamUsers = () => {
		PostData("ms1", "api/v1/team/get-team-users", { team_id: Cookies.get("_team_id") }).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					let teamUserTempData = result.data.users,
						teamMailAccountTempData	= result.data.mailAccounts,
						teamUserLocalData = {},
						teamMailAccountLocalData	= {};
					for (let i = 0; i < teamUserTempData.length; i++) {
						teamUserLocalData[teamUserTempData[i].id] = teamUserTempData[i]
					}
					for (let i = 0; i < teamMailAccountTempData.length; i++ ){
						teamMailAccountLocalData[teamMailAccountTempData[i].user_id]	= teamMailAccountTempData[i]
					}
					setTeamUserData(teamUserLocalData)
					setTeamMailAccountData(teamMailAccountLocalData)
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
		})
	}

	const toggleOnboardModal = () => {
		setOnboardModal(!onboardModal);
		PostData("ms1", "api/v1/user/update-onboard-status").then(result => {

		});
	}

	const login = useGoogleLogin({
		onSuccess: (codeResponse) => {
			setGoogleUser(codeResponse)
			toggleOnboardModal()
			setOnboardModal(false)
		},
		onError: (error) => console.log('Login Failed:', error),
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly',
	});

	if( !isLogged ){
		return <Navigate to="/auth/login" replace />
	}

	return (
		<>
			<div id="salesOuterWrapper">
				{( userLoader ) ?
					<DetailSkeleton />
				:
					<>
						<div className='contentHeadWrapper'>
							<Header />
						</div>
						<div className='userLayoutWrapper'>
								<SideBar />
								<Content />
								<Notification />
						</div>
					</>
				}
			</div>
			{onboardModal &&
				<Modal isOpen={onboardModal} className={"onboardModal"} centered={true} backdrop="static" keyboard={false}>
					<ModalBody>
						<Button className='btnModalClose' onClick={() => toggleOnboardModal()}>
							<i className="fa-solid fa-xmark"></i>
						</Button>
						<div className='onboardModalContent'>
							<div>
								<h2 className="bold">Almost there!</h2>
								<h1 className="bold secondary-color">Connect your email provider to send campaigns</h1>
		  					</div>
							<div className='d-flex-row emailSettingProvider'>
								<div className='connectAuth gmail'>
									<Button color='primary' className='md jsConnectGmail'  onClick={() => login()}>Connect</Button>
								</div>
								<div className='connectAuth outlook'>
									<Button color='primary' className='md jsConnectOutlook'>Connect</Button>
								</div>
							</div>
							<div className='d-flex-row justify-content-end'>
								<Button color="link" className='btn-tertiary skipButton skipEmailProvider' onClick={() => toggleOnboardModal()}>Do it later</Button>
							</div>
						</div>
					</ModalBody>
				</Modal>
			}
		</>
	)
}
export default User;