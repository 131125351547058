import React, { useContext, useEffect, useState } from 'react';
import DateRangeDropdown from '../../../components/DateRangeDropdown';
import RSToolTip from '../../../components/RSToolTip';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
 } from 'chart.js';
 import { Bar } from 'react-chartjs-2';
import UsersDropdown from '../../../components/UsersDropdown';
import CampaignsDropdown from '../../../components/CampaignsDropdown';
import { addDays, differenceInDays, eachDayOfInterval, endOfDay, format, isAfter, startOfDay, subDays } from 'date-fns';
import { PostData } from './../../../services/FetchData'
import { ProductContext } from '../../../store';
import { toast } from 'react-toastify';
import DetailSkeleton from '../../../components/Skeleton/DetailSkeleton';
import moment from 'moment';
 
 ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
 );

 export const options = {  
	responsive: true,
	legend: {
	  display: false, // Set to false to hide the legend
	},
	scales: {
	  x: {
		 stacked: true,
		 grid: {
			display: false, // Set to false to hide y-axis grid lines
		 },
	  },
	  y: {
		 stacked: true,
		 grid: {
			display: false, // Set to false to hide y-axis grid lines
		 },
	  },
	},
 };

const Index = (props) => {
	const [currentTab, setCurrentTab]	= useState("overview"),
		[startDate, setStartDate]	= useState(null),
		[endDate, setEndDate]	= useState(null),
		[labels, setLabels] = useState([]),
		[overviewDataSets, setOverviewDataSets]	= useState([]),
		[campaignDataSets, setCampaignDataSets]	= useState([]),
		[userDataSets, setUserDataSets]	= useState([]),
		[dateWiseReport, setDateWiseReport]	= useState({}),
		[uniqueSent, setUniqueSent]	= useState(0),
		[uniqueClicked, setUniqueClicked]	= useState(0),
		[uniqueOpened, setUniqueOpened]	= useState(0),
		[uniqueReplied, setUniqueReplied]	= useState(0),
		[userLoader, setUserLoader]	= useState(false),
		[campaignLoader, setCampaignLoader]	= useState(false),
		[selectionRange, setSelectionRange]	= useState({
			startDate: subDays(new Date(), 6),
			endDate: new Date(),
			key: 'selection',
		}),
		[userObject, setUserObject]	= useState({}),
		[selectedUser, setSelectedUser]	= useState([]),
		[selectedCampaign, setSelectedCampaign]	= useState([]),
		[campaignObject, setCampaignObject]	= useState({}),
		[teamReportLoader, setTeamReportLoader]	= useState(true),
		{
			currentTeamId
		}	= useContext(ProductContext);
	
	useEffect(() => {
		getUsers()
		getCampaigns()
	},[])
	
	useEffect(() => {		
		let startDateLocal	= new Date(selectionRange.startDate),
			endDateLocal	= new Date(selectionRange.endDate),
			labelLocals		= [],
			daysDifference = differenceInDays(endDateLocal, startDateLocal) + 1;
		setStartDate(startDateLocal)
		setEndDate(endDateLocal)
		if( daysDifference <= 31 ){
			const interval = { start: startDateLocal, end: endDateLocal };
			const daysInRange = eachDayOfInterval(interval);
			daysInRange.forEach((date) => {
				labelLocals.push(format(date, 'EEE MM/dd'));
			});
		}else{
			// const maxGroupSize = 31;
			// const groupSize = Math.min(maxGroupSize, Math.ceil(daysDifference / Math.ceil(daysDifference / maxGroupSize)));
			// for (let i = 0; i <= daysDifference; i += groupSize) {
			// 	const groupStartDate = addDays(startDate, i);
			// 	const groupEndDate = addDays(startDate, i + groupSize - 1);
			// 	labelLocals.push(format(groupStartDate, "MM/dd")+" - "+format(groupEndDate, "MM/dd"));
			// }

			const minGroups = 7;
			const minGroupSize = Math.ceil(daysDifference / minGroups);
			const groupSize = Math.min(minGroupSize, Math.ceil(daysDifference / Math.ceil(daysDifference / minGroupSize)));
			let currentStartDate = startDateLocal;
			while (isAfter(currentStartDate, endDateLocal) === false) {
				const groupEndDate = addDays(currentStartDate, groupSize - 1);
				const clampedEndDate = isAfter(groupEndDate, endDateLocal) ? endDateLocal : groupEndDate;
				labelLocals.push(format(currentStartDate, "MM/dd")+" - "+format(clampedEndDate, "MM/dd"));
				currentStartDate = addDays(clampedEndDate, 1);
			}
		}
		setLabels(labelLocals);
	}, [selectionRange])


	useEffect(() =>{
		if( startDate && endDate && campaignLoader && userLoader ){
			setSelectedCampaign(Object.keys(campaignObject))
			setSelectedUser(Object.keys(userObject))
			getTeamReport()
		}
	},[startDate, endDate, userLoader, campaignLoader]);

	useEffect(() => {
		if( Object.keys(dateWiseReport).length > 0 ){
			if( selectedUser.length > 0 ){
				let userIdKeys	= selectedUser,
				daysDifference = differenceInDays(endDate, startDate) + 1,
				startDateLocal	= new Date(startDate),
				endDateLocal	= new Date(endDate),
				userDataObject	= {};
				for(let i = 0; i < userIdKeys.length; i++){
					userDataObject[userIdKeys[i]]	= []
				}
				if( daysDifference <= 31 ){
					let interval = { start: startDateLocal, end: endDateLocal },
						daysInRange = eachDayOfInterval(interval);								
					daysInRange.forEach((date) => {
						let formatDate	= format(date, 'MM/dd/yyyy')
						if( typeof dateWiseReport[formatDate] !== "undefined" ){
							if( typeof dateWiseReport[formatDate]["userId"] !== "undefined" && Object.keys(dateWiseReport[formatDate]["userId"]).length > 0 ){
								let currentDateUserObject	= dateWiseReport[formatDate]["userId"],
									dateUserIds					= Object.keys(dateWiseReport[formatDate]["userId"]);
								for( let i = 0; i < userIdKeys.length; i++ ){
									let currentId	= userIdKeys[i];
									if( dateUserIds.indexOf(currentId) !== -1 ){
										userDataObject[currentId].push(currentDateUserObject[currentId])
									}else{
										userDataObject[currentId].push(0)
									}
								}
							}
						}else{
							for( let i = 0; i < userIdKeys.length; i++ ){
								let currentId	= userIdKeys[i];
								userDataObject[currentId].push(0)
							}
						}
					});			
				}else{
					const minGroups = 7;
					const minGroupSize = Math.ceil(daysDifference / minGroups);
					const groupSize = Math.min(minGroupSize, Math.ceil(daysDifference / Math.ceil(daysDifference / minGroupSize)));
					let currentStartDate = startDateLocal,
						index	= 0;
					while (isAfter(currentStartDate, endDateLocal) === false) {
						let groupEndDate = addDays(currentStartDate, groupSize - 1),
							clampedEndDate = isAfter(groupEndDate, endDateLocal) ? endDateLocal : groupEndDate,
							interval = { start: currentStartDate, end: clampedEndDate },
							daysInRange = eachDayOfInterval(interval);
						for( let i = 0; i < userIdKeys.length; i++ ){
							let currentId	= userIdKeys[i];
							userDataObject[currentId][index] = 0
						}
						// eslint-disable-next-line no-loop-func
						daysInRange.forEach((date) => {
							let formatDate	= format(date, 'MM/dd/yyyy')
							if( typeof dateWiseReport[formatDate] !== "undefined" ){
								if( typeof dateWiseReport[formatDate]["userId"] !== "undefined" && Object.keys(dateWiseReport[formatDate]["userId"]).length > 0 ){
									let currentDateUserObject	= dateWiseReport[formatDate]["userId"],
										dateUserIds					= Object.keys(dateWiseReport[formatDate]["userId"]);
									for( let i = 0; i < userIdKeys.length; i++ ){
										let currentId	= userIdKeys[i];
										if( dateUserIds.indexOf(currentId) !== -1 ){
											userDataObject[currentId][index] += currentDateUserObject[currentId]
										}
									}
								}
							}
						});
						currentStartDate = addDays(clampedEndDate, 1);
						index	+= 1;
					}
				}
				let userDataSetsLocal	= [],
					userDataObjectKeys		= Object.keys(userDataObject);
				for( let i = 0; i < userDataObjectKeys.length; i++ ){
					if( typeof userObject[userDataObjectKeys[i]] !== "undefined" ){
						let datesetInnerObject	= {
																label: userObject[userDataObjectKeys[i]].first_name + "" + (userObject[userDataObjectKeys[i]].last_name ? " " + userObject[userDataObjectKeys[i]].last_name : ""),
																data: userDataObject[userDataObjectKeys[i]],
																backgroundColor: generateColor(userObject[userDataObjectKeys[i]].first_name),
																borderRadius: 8
															}
						userDataSetsLocal.push(datesetInnerObject)
					}
				}
				setUserDataSets(userDataSetsLocal)
			}else{
				setUserDataSets([])
			}
		}
	}, [selectedUser, dateWiseReport])

	useEffect(() => {
		if( Object.keys(dateWiseReport).length > 0 ){
			if( selectedUser.length > 0 ){
				let campaignIdKeys	= selectedCampaign,
				daysDifference = differenceInDays(endDate, startDate) + 1,
				startDateLocal	= new Date(startDate),
				endDateLocal	= new Date(endDate),
				campaignDataObject	= {};
				for(let i = 0; i < campaignIdKeys.length; i++){
					campaignDataObject[campaignIdKeys[i]]	= []
				}
				if( daysDifference <= 31 ){
					let interval = { start: startDateLocal, end: endDateLocal },
						daysInRange = eachDayOfInterval(interval);								
					daysInRange.forEach((date) => {
						let formatDate	= format(date, 'MM/dd/yyyy')
						if( typeof dateWiseReport[formatDate] !== "undefined" ){
							if( typeof dateWiseReport[formatDate]["campaignId"] !== "undefined" && Object.keys(dateWiseReport[formatDate]["campaignId"]).length > 0 ){
								let currentDateUserObject	= dateWiseReport[formatDate]["campaignId"],
									dateUserIds					= Object.keys(dateWiseReport[formatDate]["campaignId"]);
								for( let i = 0; i < campaignIdKeys.length; i++ ){
									let currentId	= campaignIdKeys[i];
									if( dateUserIds.indexOf(currentId) !== -1 ){
										campaignDataObject[currentId].push(currentDateUserObject[currentId])
									}else{
										campaignDataObject[currentId].push(0)
									}
								}
							}
						}else{
							for( let i = 0; i < campaignIdKeys.length; i++ ){
								let currentId	= campaignIdKeys[i];
								campaignDataObject[currentId].push(0)
							}
						}
					});			
				}else{
					const minGroups = 7;
					const minGroupSize = Math.ceil(daysDifference / minGroups);
					const groupSize = Math.min(minGroupSize, Math.ceil(daysDifference / Math.ceil(daysDifference / minGroupSize)));
					let currentStartDate = startDateLocal,
						index	= 0;
					while (isAfter(currentStartDate, endDateLocal) === false) {
						let groupEndDate = addDays(currentStartDate, groupSize - 1),
							clampedEndDate = isAfter(groupEndDate, endDateLocal) ? endDateLocal : groupEndDate,
							interval = { start: currentStartDate, end: clampedEndDate },
							daysInRange = eachDayOfInterval(interval);
						for( let i = 0; i < campaignIdKeys.length; i++ ){
							let currentId	= campaignIdKeys[i];
							campaignDataObject[currentId][index] = 0
						}
						// eslint-disable-next-line no-loop-func
						daysInRange.forEach((date) => {
							let formatDate	= format(date, 'MM/dd/yyyy')
							if( typeof dateWiseReport[formatDate] !== "undefined" ){
								if( typeof dateWiseReport[formatDate]["campaignId"] !== "undefined" && Object.keys(dateWiseReport[formatDate]["campaignId"]).length > 0 ){
									let currentDateUserObject	= dateWiseReport[formatDate]["campaignId"],
										dateUserIds					= Object.keys(dateWiseReport[formatDate]["campaignId"]);
									for( let i = 0; i < campaignIdKeys.length; i++ ){
										let currentId	= campaignIdKeys[i];
										if( dateUserIds.indexOf(currentId) !== -1 ){
											campaignDataObject[currentId][index] += currentDateUserObject[currentId]
										}
									}
								}
							}
						});
						currentStartDate = addDays(clampedEndDate, 1);
						index	+= 1;
					}
				}
				let campaignDataSetsLocal	= [],
					campaignDataObjectKeys		= Object.keys(campaignDataObject);
				for( let i = 0; i < campaignDataObjectKeys.length; i++ ){
					if( typeof campaignObject[campaignDataObjectKeys[i]] !== "undefined" ){
						let datesetInnerObject	= {
																label: campaignObject[campaignDataObjectKeys[i]].name,
																data: campaignDataObject[campaignDataObjectKeys[i]],
																backgroundColor: generateColor(campaignObject[campaignDataObjectKeys[i]].name),
																borderRadius: 8
															}
						campaignDataSetsLocal.push(datesetInnerObject)
					}
				}
				setCampaignDataSets(campaignDataSetsLocal)
			}else{
				setCampaignDataSets([])
			}
		}
	}, [selectedCampaign, dateWiseReport])

	const getTeamReport = () => {
		let startDateLocal	= new Date(startDate),
				endDateLocal	= new Date(endDate);
		startDateLocal.setHours(0, 0, 0)
		endDateLocal.setHours(23, 59, 59)
		setTeamReportLoader(true)
		PostData("ms1", "api/v1/report/overview", {start_date: moment(startDateLocal).toString(), end_date: moment(endDateLocal).toString(), team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					let localReportDetails	= result.data.teamReport,
						dateWiseReportObject	= {},
						localUniqueSent		= {},
						localUniqueOpened		= {},
						localUniqueClicked	= {},
						localUniqueReplied	= {},
						daysDifference = differenceInDays(endDate, startDate) + 1;
					for( let i = 0; i < localReportDetails.length; i++ ){
						let currentReportDetail	= localReportDetails[i],
							campaignId				= currentReportDetail.campaign_id,
							userId					= currentReportDetail.userId ? currentReportDetail.userId : "",
							reportDate				= format(currentReportDetail.created_at, "MM/dd/yyyy");
						if( typeof dateWiseReportObject[reportDate] === "undefined" ){
							dateWiseReportObject[reportDate]	= {"emailSent": 0, "emailOpened": 0, "linkClicked": 0, "emailReplied": 0, "campaignId": {}, "userId": {}}
						}
						if( campaignId && typeof dateWiseReportObject[reportDate]["campaignId"][campaignId] === "undefined" ){
							dateWiseReportObject[reportDate]["campaignId"][campaignId]	= 0
						}
						if( userId && typeof dateWiseReportObject[reportDate]["userId"][userId] === "undefined" ){
							dateWiseReportObject[reportDate]["userId"][userId]	= 0
						}
						if( currentReportDetail.type === "Email sent" ){
							dateWiseReportObject[reportDate]["emailSent"]	= dateWiseReportObject[reportDate]["emailSent"]+1
							if( userId && typeof dateWiseReportObject[reportDate]["userId"][userId] !== "undefined" ){
								dateWiseReportObject[reportDate]["userId"][userId]	+= 1
							}
							if( campaignId && typeof dateWiseReportObject[reportDate]["campaignId"][campaignId] !== "undefined" ){
								dateWiseReportObject[reportDate]["campaignId"][campaignId]	+= 1
							}
							if( typeof localUniqueSent[currentReportDetail.sequence_schedule_id] === "undefined" ){
								localUniqueSent[currentReportDetail.sequence_schedule_id]	= 1
							}
						}
						if( currentReportDetail.type === "Email opened" ){
							if( typeof localUniqueOpened[currentReportDetail.sequence_schedule_id] === "undefined" ){
								localUniqueOpened[currentReportDetail.sequence_schedule_id]	= 1
								dateWiseReportObject[reportDate]["emailOpened"]	= dateWiseReportObject[reportDate]["emailOpened"]+1
								if( userId && typeof dateWiseReportObject[reportDate]["userId"][userId] !== "undefined" ){
									dateWiseReportObject[reportDate]["userId"][userId]	+= 1
								}
								if( campaignId && typeof dateWiseReportObject[reportDate]["campaignId"][campaignId] !== "undefined" ){
									dateWiseReportObject[reportDate]["campaignId"][campaignId]	+= 1
								}
							}
						}
						if( currentReportDetail.type === "Link clicked" ){
							if( typeof localUniqueClicked[currentReportDetail.sequence_schedule_id] === "undefined" ){
								localUniqueClicked[currentReportDetail.sequence_schedule_id]	= 1
								dateWiseReportObject[reportDate]["linkClicked"]	= dateWiseReportObject[reportDate]["linkClicked"]+1
								if( userId && typeof dateWiseReportObject[reportDate]["userId"][userId] !== "undefined" ){
									dateWiseReportObject[reportDate]["userId"][userId]	+= 1
								}
								if( campaignId && typeof dateWiseReportObject[reportDate]["campaignId"][campaignId] !== "undefined" ){
									dateWiseReportObject[reportDate]["campaignId"][campaignId]	+= 1
								}
							}
						}
						if( currentReportDetail.type === "Email replied" ){
							if( typeof localUniqueReplied[currentReportDetail.sequence_schedule_id] === "undefined" ){
								localUniqueReplied[currentReportDetail.sequence_schedule_id]	= 1
								dateWiseReportObject[reportDate]["emailReplied"]	= dateWiseReportObject[reportDate]["emailReplied"]+1
								if( userId && typeof dateWiseReportObject[reportDate]["userId"][userId] !== "undefined" ){
									dateWiseReportObject[reportDate]["userId"][userId]	+= 1
								}
								if( campaignId && typeof dateWiseReportObject[reportDate]["campaignId"][campaignId] !== "undefined" ){
									dateWiseReportObject[reportDate]["campaignId"][campaignId]	+= 1
								}
							}
						}
					}
					let emailSentData			= [],
						emailOpenedData		= [],
						emailRepliedData		= [],
						campaignDataObject	= {},
						userDataObject			= {},
						linkClickedData		= [],
						userIdKeys				= Object.keys(userObject),
						campaignIdKeys			= Object.keys(campaignObject);
					for(let i = 0; i < userIdKeys.length; i++){
						userDataObject[userIdKeys[i]]	= []
					}
					for(let i = 0; i < campaignIdKeys.length; i++){
						campaignDataObject[campaignIdKeys[i]]	= []
					}
					if( daysDifference <= 31 ){
						let interval = { start: startDateLocal, end: endDateLocal },
							daysInRange = eachDayOfInterval(interval);								
						daysInRange.forEach((date) => {
							let formatDate	= format(date, 'MM/dd/yyyy')
							if( typeof dateWiseReportObject[formatDate] !== "undefined" ){
								emailSentData.push(dateWiseReportObject[formatDate]["emailSent"])
								emailOpenedData.push(dateWiseReportObject[formatDate]["emailOpened"])
								emailRepliedData.push(dateWiseReportObject[formatDate]["emailReplied"])
								linkClickedData.push(dateWiseReportObject[formatDate]["linkClicked"])
								if( typeof dateWiseReportObject[formatDate]["campaignId"] !== "undefined" && Object.keys(dateWiseReportObject[formatDate]["campaignId"]).length > 0 ){
									let currentDateCampaignObject	= dateWiseReportObject[formatDate]["campaignId"],
										dateCampaignIds				= Object.keys(dateWiseReportObject[formatDate]["campaignId"]);
									for( let i = 0; i < campaignIdKeys.length; i++ ){
										let currentId	= campaignIdKeys[i];
										if( dateCampaignIds.indexOf(currentId) !== -1 ){
											campaignDataObject[currentId].push(currentDateCampaignObject[currentId])
										}else{
											campaignDataObject[currentId].push(0)
										}
									}
									
								}
								if( typeof dateWiseReportObject[formatDate]["userId"] !== "undefined" && Object.keys(dateWiseReportObject[formatDate]["userId"]).length > 0 ){
									let currentDateUserObject	= dateWiseReportObject[formatDate]["userId"],
										dateUserIds					= Object.keys(dateWiseReportObject[formatDate]["userId"]);
									for( let i = 0; i < userIdKeys.length; i++ ){
										let currentId	= userIdKeys[i];
										if( dateUserIds.indexOf(currentId) !== -1 ){
											userDataObject[currentId].push(currentDateUserObject[currentId])
										}else{
											userDataObject[currentId].push(0)
										}
									}
								}
							}else{
								emailSentData.push(0)
								emailOpenedData.push(0)
								emailRepliedData.push(0)
								linkClickedData.push(0)
								for( let i = 0; i < campaignIdKeys.length; i++ ){
									let currentId	= campaignIdKeys[i];
									campaignDataObject[currentId].push(0)
								}
								for( let i = 0; i < userIdKeys.length; i++ ){
									let currentId	= userIdKeys[i];
									userDataObject[currentId].push(0)
								}
							}
						});			
					}else{
						const minGroups = 7;
						const minGroupSize = Math.ceil(daysDifference / minGroups);
						const groupSize = Math.min(minGroupSize, Math.ceil(daysDifference / Math.ceil(daysDifference / minGroupSize)));
						let currentStartDate = startDateLocal,
							index	= 0;
						while (isAfter(currentStartDate, endDateLocal) === false) {
							let groupEndDate = addDays(currentStartDate, groupSize - 1),
								clampedEndDate = isAfter(groupEndDate, endDateLocal) ? endDateLocal : groupEndDate,
								emailSentGroup	= 0,
								emailRepliedGroup	= 0,
								emailOpenedGroup	= 0,
								linkClickedGroup	= 0,
								interval = { start: currentStartDate, end: clampedEndDate },
								daysInRange = eachDayOfInterval(interval);
							for( let i = 0; i < campaignIdKeys.length; i++ ){
								let currentId	= campaignIdKeys[i];
								campaignDataObject[currentId][index] = 0
							}
							for( let i = 0; i < userIdKeys.length; i++ ){
								let currentId	= userIdKeys[i];
								userDataObject[currentId][index] = 0
							}
							// eslint-disable-next-line no-loop-func
							daysInRange.forEach((date) => {
								let formatDate	= format(date, 'MM/dd/yyyy')
								if( typeof dateWiseReportObject[formatDate] !== "undefined" ){
									emailSentGroup	+= dateWiseReportObject[formatDate]["emailSent"]
									emailOpenedGroup	+= dateWiseReportObject[formatDate]["emailOpened"]
									emailRepliedGroup	+= dateWiseReportObject[formatDate]["emailReplied"]
									linkClickedGroup	+= dateWiseReportObject[formatDate]["linkClicked"]
									if( typeof dateWiseReportObject[formatDate]["campaignId"] !== "undefined" && Object.keys(dateWiseReportObject[formatDate]["campaignId"]).length > 0 ){
										let currentDateCampaignObject	= dateWiseReportObject[formatDate]["campaignId"],
											dateCampaignIds				= Object.keys(dateWiseReportObject[formatDate]["campaignId"]);
										for( let i = 0; i < campaignIdKeys.length; i++ ){
											let currentId	= campaignIdKeys[i];
											if( dateCampaignIds.indexOf(currentId) !== -1 ){
												campaignDataObject[currentId][index] += currentDateCampaignObject[currentId];
											}
										}
										
									}
									if( typeof dateWiseReportObject[formatDate]["userId"] !== "undefined" && Object.keys(dateWiseReportObject[formatDate]["userId"]).length > 0 ){
										let currentDateUserObject	= dateWiseReportObject[formatDate]["userId"],
											dateUserIds					= Object.keys(dateWiseReportObject[formatDate]["userId"]);
										for( let i = 0; i < userIdKeys.length; i++ ){
											let currentId	= userIdKeys[i];
											if( dateUserIds.indexOf(currentId) !== -1 ){
												userDataObject[currentId][index] += currentDateUserObject[currentId]
											}
										}
									}
								}
							});
							emailSentData.push(emailSentGroup)
							emailRepliedData.push(emailRepliedGroup)
							emailOpenedData.push(emailOpenedGroup)
							linkClickedData.push(linkClickedGroup)
							currentStartDate = addDays(clampedEndDate, 1);
							index	+= 1;
						}
					}

					console.log(userDataObject)
					console.log(campaignDataObject)

					let overviewDataSetsLocal	= [
																{
																	label: "Email sent",
																	data: emailSentData,
																	backgroundColor: "#94b5ff",
																	borderRadius: 8
																},
																{
																	label: "Email opened",
																	data: emailOpenedData,
																	backgroundColor: "#ffb992",
																	borderRadius: 8
																},
																{
																	label: "Email replied",
																	data: emailRepliedData,
																	backgroundColor: "#b1b4ff",
																	borderRadius: 8
																},
																{
																	label: "Link clicked",
																	data: linkClickedData,
																	backgroundColor: "#d3bcfc",
																	borderRadius: 8
																},
															],
						campaignDataSetsLocal	= [],
						userDataSetsLocal			= [],
						userDataObjectKeys		= Object.keys(userDataObject),
						campaignDataObjectKeys	= Object.keys(campaignDataObject);
					
					for( let i = 0; i < campaignDataObjectKeys.length; i++ ){
						if( typeof campaignObject[campaignDataObjectKeys[i]] !== "undefined" ){
							let datesetInnerObject	= {
																	label: campaignObject[campaignDataObjectKeys[i]].name,
																	data: campaignDataObject[campaignDataObjectKeys[i]],
																	backgroundColor: generateColor(campaignObject[campaignDataObjectKeys[i]].name),
																	borderRadius: 8
																}
							campaignDataSetsLocal.push(datesetInnerObject)
						}
					}

					for( let i = 0; i < userDataObjectKeys.length; i++ ){
						if( typeof userObject[userDataObjectKeys[i]] !== "undefined" ){
							let datesetInnerObject	= {
																	label: userObject[userDataObjectKeys[i]].first_name + "" + (userObject[userDataObjectKeys[i]].last_name ? " " + userObject[userDataObjectKeys[i]].last_name : ""),
																	data: userDataObject[userDataObjectKeys[i]],
																	backgroundColor: generateColor(userObject[userDataObjectKeys[i]].first_name),
																	borderRadius: 8
																}
							userDataSetsLocal.push(datesetInnerObject)
						}
					}

					setUniqueSent(Object.keys(localUniqueSent).length)
					setUniqueClicked(Object.keys(localUniqueClicked).length)
					setUniqueOpened(Object.keys(localUniqueOpened).length)
					setUniqueReplied(Object.keys(localUniqueReplied).length)
					setDateWiseReport(dateWiseReportObject)
					setOverviewDataSets(overviewDataSetsLocal)
					setCampaignDataSets(campaignDataSetsLocal)
					setUserDataSets(userDataSetsLocal)
					setTeamReportLoader(false)
				}else if( result.status === "failure"){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const generateColor = (value) => {
		// Simple hash function to convert the input value to a numeric hash
		let hash = 0;
		for (let i = 0; i < value.length; i++) {
		  hash = value.charCodeAt(i) + ((hash << 5) - hash);
		}
	 
		// Convert the hash to a hexadecimal color code
		const color = ((hash & 0x00FFFFFF) | 0x44000000).toString(16).toUpperCase().slice(1, 7);
	 
		return `#${color}`;
	}

	const getUsers	= () => {
		PostData("ms1", "api/v1/report/get-users", {team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setUserObject(result.data.userObject)
					setSelectedUser(Object.keys(result.data.userObject))
					setUserLoader(true)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const getCampaigns = () => {
		PostData("ms1", "api/v1/report/get-campaigns", {team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setCampaignObject(result.data.campaignObject);
					setSelectedCampaign(Object.keys(result.data.campaignObject))
					setCampaignLoader(true)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const updateSelectedUserFunc	= (ids) => {
		setSelectedUser(ids)
	}

	const updateSelectedCampaignFunc = (ids) => {
		setSelectedCampaign(ids)
	}

	const setSelectionRangeFunc	= (ranges) => {
		setSelectionRange(ranges)		
	}
	
	return (
		<div className='contentBottomWrapper paperBackground'>
			<div className='contentBottomTopWrapper'>
				<div className='d-flex-row align-items-center'>
					<div className='title'>
						<h1>Reports Overview</h1>
					</div>
				</div>
				<div className='d-flex-row align-items-end lg'>
					<div className='rsTabsWrapper'>
						<div className={`rsTab ${currentTab === "overview" ? "active" : ""}`} onClick={() => setCurrentTab("overview")}>Overview</div>
						<div className={`rsTab ${currentTab === "campaigns" ? "active" : ""}`} onClick={() => setCurrentTab("campaigns")}>Campaigns</div>
						<div className={`rsTab ${currentTab === "users" ? "active" : ""}`} onClick={() => setCurrentTab("users")}>Users</div>
					</div>
					<div className='rsTabButtons'>
						<div className='d-flex-row relative'>
							<DateRangeDropdown 
								onDateChange={(ranges) => setSelectionRangeFunc(ranges)}
							/>							
						</div>
					</div>
				</div>
			</div>
			<div className='contentBottomMainContainer'>
				<div className='contentBottomMainCenter'>
					{teamReportLoader ?
						<DetailSkeleton />
					:
						<div className='d-flex-col reports'>
							<div className='stats statsDetailed positive'>
								<div className='statColumn'>
									<div className='statLine emailChannel'>
										<div className='channelIcon email sm pointer rsFilterChannel statHead'>
											<i className="fa-regular fa-envelope"></i>
										</div>
									</div>
								</div>
								<div className='statColumn'>
									<div className='statLine emailChannel'>
										<RSToolTip item={{ placement: 'bottom', text: `Number of leads you contacted <br />click to show related activities` }} id="statCellEmailSent">
											<div className='colorSent statCell'>
												<ul className='statMetrics'>
													<li className='rsSelectType metric'>
														<span className="statMetricsPercent sent bg">{uniqueSent}</span>
														<span className="statMetricsValue sent color500 bg100">
															<img src={require("./../../../assets/images/sent.svg").default} className='img-fluid' alt='Sent' />
															Sent
														</span>
													</li>
												</ul>
											</div>
										</RSToolTip>
										<RSToolTip item={{ placement: 'bottom', text: `= Opened / Sent <br />click to show related activities` }} id="statCellEmailOpened">
											<div className='colorOpened statCell'>
												<ul className='statMetrics'>
													<li className='rsSelectType metric'>															
														<span className="statMetricsPercent opened bg">{uniqueSent > 0 ? parseFloat((uniqueOpened/uniqueSent) * 100).toFixed(2).replace(/\.0+$/,'') : 0}%</span>
														<span className="statMetricsValue opened color500 bg100">
															<img src={require("./../../../assets/images/opened.svg").default} className='img-fluid' alt='Sent' />
															{uniqueOpened} Opened
														</span>
													</li>
												</ul>
											</div>
										</RSToolTip>
										<RSToolTip item={{ placement: 'bottom', text: `= Clicked / Sent <br />click to show related activities` }} id="statCellLinkClicked">
											<div className='colorClicked statCell'>
												<ul className='statMetrics'>
													<li className='rsSelectType metric'>
														<span className="statMetricsPercent clicked bg">{uniqueSent > 0 ? parseFloat((uniqueClicked/uniqueSent) * 100).toFixed(2).replace(/\.0+$/,'') : 0}%</span>
														<span className="statMetricsValue clicked color500 bg100">
															<img src={require("./../../../assets/images/clicked.svg").default} className='img-fluid' alt='Clicked' />
															{uniqueClicked} Clicked
														</span>
													</li>
												</ul>
											</div>
										</RSToolTip>
										<RSToolTip item={{ placement: 'bottom', text: `= Replied / Sent <br />click to show related activities` }} id="statCellReplied">
											<div className='colorReplied statCell'>
												<ul className='statMetrics'>
													<li className='rsSelectType metric'>
														<span className="statMetricsPercent replied bg">{uniqueSent > 0 ? parseFloat((uniqueReplied/uniqueSent) * 100).toFixed(2).replace(/\.0+$/,'') : 0}%</span>
														<span className="statMetricsValue replied color500 bg100">
															<img src={require("./../../../assets/images/replied.svg").default} className='img-fluid' alt='Replied' />
															{uniqueReplied} Replied
														</span>
													</li>
												</ul>
											</div>
										</RSToolTip>
										<RSToolTip item={{ placement: 'bottom', text: `Manually mark your leads as "Interested" to get a clear idea of your campaigns performance<br />Mark as interested FAQ article <br />click to show related activities` }} id="statCellInterested">
											<div className='colorInterested statCell'>
												<ul className='statMetrics'>
													<li className='rsSelectType metric'>
														<span className="statMetricsPercent interested bg">0%</span>
														<span className="statMetricsValue interested color500 bg100">
															<img src={require("./../../../assets/images/interested.svg").default} className='img-fluid' alt='Interested' />
															0 Interested
														</span>
													</li>
												</ul>
											</div>
										</RSToolTip>
									</div>
								</div>
							</div>
							{currentTab === "users" ?
								<div className='d-flex-row'>
									<UsersDropdown userObject={userObject} selectedUser={selectedUser} updateSelectedUser={(userIds) => updateSelectedUserFunc(userIds)} />
								</div>
							: currentTab === "campaigns" &&
								<div className='d-flex-row'>
									<CampaignsDropdown campaignObject={campaignObject} selectedCampaign={selectedCampaign} updateSelectedCampaign= {(campaignIds) => updateSelectedCampaignFunc(campaignIds)} />
								</div>
							}
							<div className='uiSection mvp'>
								<div className='header'>
									<div className='title'>Overview Analytics</div>
								</div>
								<div className='content'>
									{currentTab === "overview" ?
										<Bar options={options} data={{labels, datasets:overviewDataSets}} />
									: currentTab === "users" ?
										<Bar options={options} data={{labels, datasets:userDataSets}} />
									: currentTab === "campaigns" &&
										<Bar options={options} data={{labels, datasets:campaignDataSets}} />
									}
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default Index;