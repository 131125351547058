import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Input, InputGroup } from 'reactstrap';

const DelayDropdown = ({value, index, step, onUpdate}) => {
	const [stepMoreOption, setStepMoreOption]	= useState(false),
		[localDelayTime, setLocalDelayTime]	= useState(value);

	useEffect(() => {
		setLocalDelayTime(value)
	}, [value])
	
	const increaseValue = () => {
		setLocalDelayTime((prevstate) => prevstate + 1)
	}

	const decreaseValue = () => {
		setLocalDelayTime((prevstate) => prevstate - 1)
	}

	const changeValue = (newValue) => {
		setLocalDelayTime(newValue)
	}

	const toggleOption = () => {
		if( stepMoreOption ){
			onUpdate(index, localDelayTime);
		}
		setStepMoreOption((prevstate) => !prevstate)
	}

	return (
		
		<Dropdown isOpen={stepMoreOption} toggle={() => toggleOption()} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="div" className='rsTreeDelayContent flex-1'>
				<div className='d-flex-row sm align-items-center'>
					<div className="channelIcon delay">
						<i className="fa-regular fa-clock"></i>
					</div>
					<span className='text flex-1'>
						Wait for <span className='primaryColor'> {localDelayTime} day{localDelayTime > 1 && "s"}</span>
					</span>
				</div>
			</DropdownToggle>
			<DropdownMenu className='campaignFilterDropdownMenu stepDropdownMenu withStepVariation'>
				<div>
					<div className='rsTreeDelayEditor'>
						<div className='d-flex-row sm align-items-center'>
							<span className='text'>Wait</span>
							<InputGroup className='uiStepper'>
								<Button className='btn-stepper btn-ico rsModifyStep' disabled={localDelayTime <= 1} onClick={() => decreaseValue()}><i className='fa-solid fa-minus'></i></Button>
								<Input type='number' className='rsEmailLimit' name='wait' min={1} max={365} value={localDelayTime} onChange={(e) => changeValue(e.target.value)} />
								<Button className='btn-stepper btn-ico rsModifyStep'disabled={localDelayTime >= 365} onClick={() => increaseValue()}><i className='fa-solid fa-plus'></i></Button>
							</InputGroup>
							<span className='text flex-1'>day</span>
						</div>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default DelayDropdown;