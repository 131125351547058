import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

function Logout(props) {
	const [redirectLogut, setRedirectLogout] = useState(false)
	useEffect(() => {
		if (window.RvBotView) {
			window.RvBotView.destory()
		}
		Cookies.remove('_round_sales_token');
		setRedirectLogout(true);
	}, [])
	
	if( redirectLogut ){
		return <Navigate to ="/auth/login" replace />
	}

	return (
		<div>

		</div>
	);
}

export default Logout;