import React, { useRef, useState, useEffect } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import UserRoleDropdown from '../../../components/UserRoleDropdown';
import UserActionDropdown from '../../../components/UserActionDropdown';
import { PostData } from './../../../services/FetchData';
import { toast } from 'react-toastify';
import DetailSkeleton from './../../../components/Skeleton/DetailSkeleton'
import { createRoot } from 'react-dom/client';

const Teams = (props) => {
	const [searchText, setSearchText]	= useState(""),
		[currentTab, setCurrentTab]	= useState("active"),
		[newUserData, setNewUserData]	= useState({}),
		[editTeamNameObject, setEditTeamNameObject]	= useState({}),
		[teamsDetails, setTeamsDetails]	= useState([]),
		[archiveTeamsDetails, setArchiveTeamsDetails]	= useState([]),
		[userMailAccounts, setUserMailAccounts]	= useState({}),
		[addUserTeamIndex, setAddUserTeamIndex]	= useState({}),
		[openTeamIds, setOpenTeamIds]	= useState([]),
		[editNameTeamIds, setEditNameTeamIds]	= useState([]),
		[createUserLoaderApi, setCreateUserLoaderApi]	= useState(false),
		[deleteWarningModal, setDeleteWarningModal]	= useState(false),
		[deleteLoaderApi, setDeleteLoaderApi]	= useState(false),
		[deleteUserData, setDeleteUserData]	= useState({}),
		[pageLoader, setPageLoader]	= useState(true),
		[createTeamUser, setCreateTeamUser]	= useState(false),
		[teamImageLoadIndex, setTeamImageLoadIndex]	= useState(null),
		inputFileRef	= useRef(null);
	
	useEffect(() => {
		getTeamsDetails()
		getTeamUserMailAccounts()
	},[])
	
	const toggleCreateUser	= () => {
		setCreateTeamUser((prevState) => !prevState);
	}

	const changeNewLeadValue = (e) => {		
		setNewUserData({
			...newUserData,
			[e.target.name]: e.target.value
		})		
	}

	const clearNewValue = (name) => {
		setNewUserData({
			...newUserData,
			[name]: ""
		})
	}

	const getTeamsDetails = () => {
		setPageLoader(true)
		PostData("ms1", "api/v1/team/get-teams-details").then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setTeamsDetails(result.data.teamsDetails)
					setArchiveTeamsDetails(result.data.archivedTeamsDetails)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setPageLoader(false)
		})
	}

	const getTeamUserMailAccounts = () => {
		PostData("ms1", "api/v1/team/get-teams-mail-accounts").then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setUserMailAccounts(result.data.mailAccountsObj)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const toggleTeamSection = (teamId) => {
		let openTeamLocalIds	= [...openTeamIds]
		if( openTeamLocalIds.indexOf(teamId) !== -1 ){
			openTeamLocalIds.splice( openTeamLocalIds.indexOf(teamId), 1 );
		}else{
			openTeamLocalIds.push(teamId)
		}
		setOpenTeamIds(openTeamLocalIds);
	}

	const openEditTeamName = (e, teamId, teamIndex) => {
		e.stopPropagation()
		let editNameTeamIdsLocal	= [...editNameTeamIds],
			editTeamNameObjectLocal	= {...editTeamNameObject}
		if( editNameTeamIdsLocal.indexOf(teamId) !== -1 ){
			editNameTeamIdsLocal.splice( editNameTeamIdsLocal.indexOf(teamId), 1 );
			delete editTeamNameObjectLocal[teamId];
		}else{
			editNameTeamIdsLocal.push(teamId)
			let teamName	= "";
			if( currentTab === "active" ){
				teamName	= teamsDetails[teamIndex].name
			}else{
				teamName	= archiveTeamsDetails[teamIndex].name
			}
			editTeamNameObjectLocal[teamId]	= {teamIndex, name: teamName}
		}
		setEditNameTeamIds(editNameTeamIdsLocal);
		setEditTeamNameObject(editTeamNameObjectLocal)
	}

	const addNewUserTeam = (e, index, teamId) => {
		e.stopPropagation()
		setNewUserData({})
		setAddUserTeamIndex({index, teamId})
		setCreateTeamUser(true)
	}

	const createUserInTeam = () => {
		if( !newUserData.firstName ){
			toast.error("First name filed is required")
			return
		}
		if( !newUserData.email ){
			toast.error("Email filed is required")
			return
		}
		if( !newUserData.password ){
			toast.error("Password filed is required")
			return
		}

		if( newUserData.password.length < 8 ){
			toast.error("Min 8 char required for password")
			return
		}

		let data	= {...newUserData, team_id: addUserTeamIndex.teamId}
		setCreateUserLoaderApi(true)
		PostData("ms1", "api/v1/team/create-user", data).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					let currentUser	= result.data.user						
					if( currentTab === "active" ){
						let teamsDetailsLocal	= [...teamsDetails]
						teamsDetailsLocal[addUserTeamIndex.index]["users"].push(currentUser)
						setTeamsDetails(teamsDetailsLocal)						
						setNewUserData({})
						setAddUserTeamIndex({})
						toggleCreateUser()
					}else{
						let archiveTeamsDetailsLocal	= [...archiveTeamsDetails]
						archiveTeamsDetailsLocal[addUserTeamIndex.index]["users"].push(currentUser)
						setArchiveTeamsDetails(archiveTeamsDetailsLocal)
					}
					toast.success(result.message)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setCreateUserLoaderApi(false)
		})
	}

	const onClickUserOption	= (userIndex, teamIndex, type) => {
		if( type === "edit" ){

		}else if( type === "delete" ){
			setDeleteUserData({userIndex, teamIndex});
			setDeleteWarningModal(true)
		}
	}

	const toggleDeleteWarning = () =>{
		setDeleteLoaderApi(false)
		setDeleteUserData({})
		setDeleteWarningModal((prevState) => !prevState)
	}

	const deleteUser = () => {
		setDeleteLoaderApi(true)
		let userId	= "",
			teamId	= "";
		if( currentTab === "active" ){
			teamId	= teamsDetails[deleteUserData["teamIndex"]].id
			userId	= teamsDetails[deleteUserData["teamIndex"]].users[deleteUserData["userIndex"]].id
		}else{
			teamId	= archiveTeamsDetails[deleteUserData["teamIndex"]].id
			userId	= archiveTeamsDetails[deleteUserData["teamIndex"]].users[deleteUserData["userIndex"]].id
		}
		PostData("ms1", "api/v1/team/remove-user", {user_id: userId, team_id: teamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					if( currentTab === "active" ){
						let localTeamDetails	= [...teamsDetails]
						localTeamDetails[deleteUserData["teamIndex"]].users.splice(deleteUserData["userIndex"], 1)
						setTeamsDetails(localTeamDetails)
					}else{
						let localArchiveTeamDetails	= [...archiveTeamsDetails]
						localArchiveTeamDetails[deleteUserData["teamIndex"]].users.splice(deleteUserData["userIndex"], 1)
						setArchiveTeamsDetails(localArchiveTeamDetails)
					}
				}
			}
			toggleDeleteWarning()
		})
	}

	const changeTeamName	= ( value, teamId) => {
		let editTeamNameObjectLocal	= {...editTeamNameObject}
		if( typeof editTeamNameObjectLocal[teamId] !== "undefined" ){
			editTeamNameObjectLocal[teamId].name = value
		}
		setEditTeamNameObject(editTeamNameObjectLocal)
	}

	const updateCurrentTeamName = (e, teamId) => {
		e.stopPropagation();
		let editTeamNameObjectLocal	= {...editTeamNameObject};
		if( typeof editTeamNameObjectLocal[teamId] !== "undefined" ){
			// if( !editTeamNameObjectLocal[teamId].name ){
			// 	toast.error("Name field is required")
			// 	return
			// }
			editTeamNameObjectLocal[teamId].loader	= true
			setEditTeamNameObject(editTeamNameObjectLocal);
			PostData("ms1", "api/v1/team/update-name", {team_id: teamId, name: editTeamNameObjectLocal[teamId].name}).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						if( currentTab === "active" ){
							let teamsDetailsLocal	= [...teamsDetails]
							teamsDetailsLocal[editTeamNameObjectLocal[teamId].teamIndex].name	= editTeamNameObjectLocal[teamId].name
							setTeamsDetails(teamsDetailsLocal)
						}else{
							let archiveTeamsDetailsLocal	= [...archiveTeamsDetails]
							archiveTeamsDetailsLocal[editTeamNameObjectLocal[teamId].teamIndex].name	= editTeamNameObjectLocal[teamId].name
							setArchiveTeamsDetails(archiveTeamsDetailsLocal)
						}
						let editNameTeamIdsLocal	= [...editNameTeamIds];
						editNameTeamIdsLocal.splice( editNameTeamIdsLocal.indexOf(teamId), 1 );
						delete editTeamNameObjectLocal[teamId];
						setEditNameTeamIds(editNameTeamIdsLocal)
						setEditTeamNameObject(editTeamNameObjectLocal);
						return
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
					editTeamNameObjectLocal[teamId].loader	= false
					setEditTeamNameObject(editTeamNameObjectLocal);
				}
			})
		}
	}

	const uploadTeamImage = (event) => {
		// Validate file type (allow only images)
		const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
		if (!allowedImageTypes.includes(event.target.files[0].type)) {
			toast.warn("Only JPEG, PNG, and GIF images are allowed.")
		}
		let teamId	= ""
		if( currentTab === "active" ){
			teamId	= teamsDetails[teamImageLoadIndex].id
		}else{
			teamId	= archiveTeamsDetails[teamImageLoadIndex].id
		}
		const formData = new FormData()
		formData.append('profile_picture', event.target.files[0])
		formData.append('team_id', teamId)
		PostData('ms1', 'api/v1/team/upload-profile-picture', formData, true).then((result) => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					if( currentTab === "active" ){
						let teamsDetailsLocal	= [...teamsDetails]
						teamsDetailsLocal[teamImageLoadIndex].profile_picture	= result.data.profile_picture
						setTeamsDetails(teamsDetailsLocal)
					}else{
						let archiveTeamsDetailsLocal	= [...archiveTeamsDetails]
						archiveTeamsDetailsLocal[teamImageLoadIndex].profile_picture	= result.data.profile_picture
						setArchiveTeamsDetails(archiveTeamsDetailsLocal)
					}
					toast.success(result.message)
				}else if( result.status === "failure" ) {
					toast.error(result.message)
				}else {
					toast.error(result.message.message)
				}
			}
			setTeamImageLoadIndex(null)
		})
	}

	const createFileUpload = (teamIndex) => {
		setTeamImageLoadIndex(teamIndex)
		let inputFile = React.createElement('input', { type: 'file', name: 'myfile', id: 'myfile', ref: inputFileRef, accept: "image/jpeg,image/png,image/gif", onChange: (event) => { uploadTeamImage(event) } })
		const container = document.getElementById('uploadFile');
		const root = createRoot(container); // createRoot(container!) if you use TypeScript
		root.render(inputFile);
		setTimeout(() => {
			inputFileRef.current.click()
		}, 200);
	}

	return (
		<>
			<div className='contentBottomWrapper paperBackground'>
				<div className='contentBottomTopWrapper'>
					<div className='d-flex-row align-items-center'>
						<div className='title'>
							<h1 className='bold'>Teams</h1>
							<div className="subTitle">Manage teams and add teammates</div>
						</div>
					</div>
				</div>
				<div className='contentBottomMainContainer'>
					<div className='contentBottomMainCenter'>
						<div className='teamPageWrapper'>
							<div className='rsTabsWrapper'>
								<div className={`rsTab ${currentTab === "active" ? "active" : ""}`} onClick={() => setCurrentTab("active")}>Active teams</div>
								<div className={`rsTab ${currentTab === "archived" ? "active" : ""}`} onClick={() => setCurrentTab("archived")}>Archived</div>
							</div>
							<div className='settingsTableSearchBar d-none'>
								<div className='search'>
									<div className='rsSearchInput'>
										<i className="fa-solid fa-magnifying-glass"></i>
										<Input type='text' placeholder='Search a user by email, first name, last name...' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
										<i className="fa-solid fa-circle-xmark rsClearSearch" onClick={() => setSearchText('')}></i>
									</div>
								</div>
							</div>
							{pageLoader ?
								<DetailSkeleton />
							:
								<>
									{currentTab === "active" ?
										teamsDetails.length === 0 ?
											<div className='noTeams'>
												<img src={require("./../../../assets/images/stop.svg").default} alt='Stop Team' />
												<div className='rsInfoMsg disabled'>
													<div className='flex-1'>You are not admin of any team</div>
												</div>
											</div>
										:
											<>
												{teamsDetails.map((teamDetail, index) => {
													return(
														<div className={`rsTable team agency settingsTable ${openTeamIds.indexOf(teamDetail.id) !== -1 ? "open" : ""} `} key={index}>
															<div className='preHeader' onClick={() => toggleTeamSection(teamDetail.id)}>
																<div className='d-flex-row align-items-center rsCollapseTable'>
																	{openTeamIds.indexOf(teamDetail.id) !== -1 ?
																		<i className="fa-solid fa-chevron-down"></i>
																	:
																		<i className="fa-solid fa-chevron-right"></i>
																	}
																	
																	<div className='avatar uiTeamPicture lg editable rsUpdatePicture avatarSecondary1' onClick={(e) => { e.stopPropagation();createFileUpload(index)}}>
																		{teamDetail.profile_picture ?
																			<img src={teamDetail.profile_picture} className='img-fluid picture' alt={teamDetail.name} />
																		:
																			<i className="fa-regular fa-building"></i>
																		}
																	</div>
																	{editNameTeamIds.indexOf(teamDetail.id) !== -1 ?
																		<div className='editWrapper'>
																			<div className='textEdit rsTeamName' onClick={(e) => e.stopPropagation()}>
																				<div className='inputWrapper'>
																					<Input type='text' className='rsUiEdit' disabled={editTeamNameObject[teamDetail.id].loader} placeholder={`Team's name`} value={editTeamNameObject[teamDetail.id].name} spellCheck={false} onClick={(e) => e.stopPropagation()} onChange={(e) => changeTeamName(e.target.value, teamDetail.id)} />
																					<i className="fa-solid fa-circle-xmark rsClear" onClick={(e) => { e.stopPropagation(); changeTeamName( "", teamDetail.id)}}></i>
																					<div className='rsTextEditBorder'></div>
																				</div>
																			</div>
																			<Button className="btn-tertiary btn btn-secondary rsTeamNameConfirm" disabled={editTeamNameObject[teamDetail.id].loader} onClick={(e) => updateCurrentTeamName(e, teamDetail.id)}>
																				{editTeamNameObject[teamDetail.id].loader &&
																					<Spinner size={"sm"} />
																				}
																				Confirm
																			</Button>
																		</div>
																	:
																		<>
																			<span className='pointerEventsNone'>{teamDetail.name}</span>
																			<div className='rsEditTeamName' onClick={(e) => openEditTeamName( e, teamDetail.id, index)}>
																				<i className="fa-solid fa-pen"></i>
																			</div>
																		</>
																	}
																	<span className='pointerEventsNone spacer'></span>
																	<Button className='btn-tertiary rsTeamAdd' onClick={(e) => addNewUserTeam(e, index, teamDetail.id)}>Add Users</Button>
																	<Button className='btnAction rsTeamArchive'>
																		<i className="fa-solid fa-box-archive"></i>
																	</Button>
																</div>
																<div className='d-flex-row licenses d-none'>
																	<div className='licensesGauge'>
																		<div className='gaugeFill'></div>
																		<div>
																			<span className='licensesCount'>1</span>/1 seats used
																		</div>
																	</div>
																</div>
															</div>
															<div className='header'>
																<div className='headerItem'>
																	<span>User</span>
																</div>
																<div className='headerItem'>
																	<span>Email provider</span>
																</div>
																<div className='headerItem'>
																	<span>Linkedin</span>
																</div>
																<div className='headerItem'>
																	<span>Role</span>
																</div>
																<div className='headerItem'>
																	<span>Seat</span>
																</div>
																<div className='headerItem'>
																	<span>Integrations</span>
																</div>
																<div className='headerItem'></div>
															</div>
															<div className='tableRows'>
																{teamDetail.users.map((user, userIndex) => {
																	return(
																		<div className='tableRow relative' key={userIndex}>
																			<div className='tableRowItemFlex user'>
																				<div className={`avatar uiUserPicture avatarPrimary${((user.id - 1) % 10)}`}>
																					{user.profile_picture ?
																						<img src={user.profile_picture} alt={user.first_name} className='picture' />
																						:
																						<>
																							{user.first_name.substr(0, 1)}{user.last_name && user.last_name.substr(0, 1)}
																						</>
																					}
																				</div>
																				<div className='desc'>
																					<div className='name'>{user.first_name} {user.last_name && user.last_name}</div>
																					<div className='userEmail'>{user.email}</div>
																				</div>
																			</div>
																			<div className='tableRowItem email'>
																				{typeof userMailAccounts[user.id] !== "undefined" ?
																					<>
																						{userMailAccounts[user.id].mail_type === "gmail" ?
																							<div className='emailProvider google'>Google</div>
																						:
																							<div className='emailProvider outlook'>Outlook</div>			
																						}
																						<div className='accountConnected on'>{userMailAccounts[user.id].email}</div>
																					</>
																				:
																					<span className='notConnected'>Not connected</span>
																				}
																			</div>
																			<div className='tableRowItem linkedin'>
																				<span className='notConnected'>Not connected</span>
																			</div>
																			<div className='tableRowItemFlex userRole'>
																				<UserRoleDropdown active={1} index={0} onChange={(role, userIndex, index) => console.log(role, userIndex, index)} />
																			</div>
																			<div className='tableRowItemFlex userLicense'>
																				<div className="rsSwitch customControl customSwitch rsCampaignsTogglePlayPause playPause">
																					<Label className="customControlLabel">
																						<Input type="checkbox" disabled={true} checked={true} onChange={() => console.log("SOLOMON")} />
																						<span className="switchDisplay"></span>
																						<span className="rsLabel"></span>
																					</Label>
																				</div>
																			</div>
																			<div className='tableRowItemFlex userCrms'>
																				<img className='crmIcon off' alt='Hubspot' src={require("./../../../assets/images/brand/hubspot-ico.png")} />
																				<img className='crmIcon off' alt='Pipedrive' src={require("./../../../assets/images/brand/pipedrive-ico.png")} />
																				<img className='crmIcon off' alt='Salesforce' src={require("./../../../assets/images/brand/salesforce-ico.png")} />
																				<img className='crmIcon off' alt='Aircall' src={require("./../../../assets/images/brand/aircall-ico.png")} />
																			</div>
																			<div className='tableRowItemFlex action'>
																				<div>{}
																					<UserActionDropdown teamIndex={index} userIndex={userIndex} onClick={(userIndex, teamIndex, type) => onClickUserOption(userIndex, teamIndex, type)} />
																				</div>
																			</div>
																		</div>
																	)
																})}
																
															</div>
														</div>
													)
												})}
											</>
									:
										archiveTeamsDetails.length === 0 ?
											<div className='noTeams'>
												<img src={require("./../../../assets/images/stop.svg").default} alt='Stop Team' />
												<div className='rsInfoMsg disabled'>
													<div className='flex-1'>You are not admin of any team</div>
												</div>
											</div>
										:
											<>
												{archiveTeamsDetails.map((teamDetail, index) => {
													return(
														<div className={`rsTable team agency settingsTable ${openTeamIds.indexOf(teamDetail.id) !== -1 ? "open" : ""} `} key={index}>
															<div className='preHeader' onClick={() => toggleTeamSection(teamDetail.id)}>
																<div className='d-flex-row align-items-center rsCollapseTable'>
																	{openTeamIds.indexOf(teamDetail.id) !== -1 ?
																		<i className="fa-solid fa-chevron-down"></i>
																	:
																		<i className="fa-solid fa-chevron-right"></i>
																	}
																	
																	<div className='avatar uiTeamPicture lg editable rsUpdatePicture avatarSecondary1'>
																		{teamDetail.profile_picture ?
																			<img src={teamDetail.profile_picture} className='img-fluid' alt={teamDetail.name} />
																		:
																			<i className="fa-regular fa-building"></i>
																		}
																	</div>
																	{editNameTeamIds.indexOf(teamDetail.id) !== -1 ?
																		<div className='editWrapper'>
																			<div className='textEdit rsTeamName'>
																				<div className='inputWrapper'>
																					<Input type='text' className='rsUiEdit' placeholder={`Team's name`} value={teamDetail.name} spellCheck={false} />
																					<i className="fa-solid fa-circle-xmark rsClear"></i>
																					<div className='rsTextEditBorder'></div>
																				</div>
																			</div>
																			<Button className="btn-tertiary btn btn-secondary rsTeamNameConfirm">Confirm</Button>
																		</div>
																	:
																		<>
																			<span className='pointerEventsNone'>{teamDetail.name}</span>
																			<div className='rsEditTeamName'>
																				<i className="fa-solid fa-pen"></i>
																			</div>
																		</>
																	}
																	<span className='pointerEventsNone spacer'></span>
																	<Button className='btn-tertiary rsTeamAdd' onClick={(e) => addNewUserTeam(e, index, teamDetail.id)}>Add Users</Button>
																	<Button className='btnAction rsTeamArchive'>
																		<i className="fa-solid fa-box-archive"></i>
																	</Button>
																</div>
																<div className='d-flex-row licenses d-none'>
																	<div className='licensesGauge'>
																		<div className='gaugeFill'></div>
																		<div>
																			<span className='licensesCount'>1</span>/1 seats used
																		</div>
																	</div>
																</div>
															</div>
															<div className='header'>
																<div className='headerItem'>
																	<span>User</span>
																</div>
																<div className='headerItem'>
																	<span>Email provider</span>
																</div>
																<div className='headerItem'>
																	<span>Linkedin</span>
																</div>
																<div className='headerItem'>
																	<span>Role</span>
																</div>
																<div className='headerItem'>
																	<span>Seat</span>
																</div>
																<div className='headerItem'>
																	<span>Integrations</span>
																</div>
																<div className='headerItem'></div>
															</div>
															<div className='tableRows'>
																{teamDetail.users.map((user, userIndex) => {
																	return(
																		<div className='tableRow relative' key={userIndex}>
																			<div className='tableRowItemFlex user'>
																				<div className={`avatar uiUserPicture avatarPrimary${((user.id - 1) % 10)}`}>
																					{user.profile_picture ?
																						<img src={user.profile_picture} alt={user.first_name} className='picture' />
																						:
																						<>
																							{user.first_name.substr(0, 1)}{user.last_name && user.last_name.substr(0, 1)}
																						</>
																					}
																				</div>
																				<div className='desc'>
																					<div className='name'>{user.first_name} {user.last_name && user.last_name}</div>
																					<div className='userEmail'>{user.email}</div>
																				</div>
																			</div>
																			<div className='tableRowItem email'>
																				{typeof userMailAccounts[user.id] !== "undefined" ?
																					<>
																						{userMailAccounts[user.id].mail_type === "gmail" ?
																							<div className='emailProvider google'>Google</div>
																						:
																							<div className='emailProvider outlook'>Outlook</div>			
																						}
																						<div className='accountConnected on'>{userMailAccounts[user.id].email}</div>
																					</>
																				:
																					<span className='notConnected'>Not connected</span>
																				}
																			</div>
																			<div className='tableRowItem linkedin'>
																				<span className='notConnected'>Not connected</span>
																			</div>
																			<div className='tableRowItemFlex userRole'>
																				<UserRoleDropdown active={1} index={0} onChange={(role, userIndex, index) => console.log(role, userIndex, index)} />
																			</div>
																			<div className='tableRowItemFlex userLicense'>
																				<div className="rsSwitch customControl customSwitch rsCampaignsTogglePlayPause playPause">
																					<Label className="customControlLabel">
																						<Input type="checkbox" disabled={true} checked={true} onChange={() => console.log("SOLOMON")} />
																						<span className="switchDisplay"></span>
																						<span className="rsLabel"></span>
																					</Label>
																				</div>
																			</div>
																			<div className='tableRowItemFlex userCrms'>
																				<img className='crmIcon off' alt='Hubspot' src={require("./../../../assets/images/brand/hubspot-ico.png")} />
																				<img className='crmIcon off' alt='Pipedrive' src={require("./../../../assets/images/brand/pipedrive-ico.png")} />
																				<img className='crmIcon off' alt='Salesforce' src={require("./../../../assets/images/brand/salesforce-ico.png")} />
																				<img className='crmIcon off' alt='Aircall' src={require("./../../../assets/images/brand/aircall-ico.png")} />
																			</div>
																			<div className='tableRowItemFlex action'>
																				<div>{}
																					<UserActionDropdown teamIndex={index} userIndex={userIndex} onClick={(userIndex, teamIndex, type) => onClickUserOption(userIndex, teamIndex, type)} />
																				</div>
																			</div>
																		</div>
																	)
																})}
																
															</div>
														</div>
													)
												})}
											</>
									}
								</>
							}
						</div>
					</div>
				</div>
			</div>
			<div id='uploadFile'></div>
			{createTeamUser &&
				<Modal toggle={toggleCreateUser} isOpen={createTeamUser} centered={false} className='personalizationModal teamMateModal' size='lg'>
					<ModalHeader>
						<h3 className="bold"><i className="fa-solid fa-user"></i> Add user</h3>
						<i className="closeModal fa-solid fa-xmark" onClick={() => toggleCreateUser()}></i>
					</ModalHeader>
					<ModalBody>
						<div className="d-flex-col flex-1">
							<div className="d-flex-col d-none sm licenseSummary">
								<div className="d-flex-row">
									<span className="flex-1">Your available seats</span>
									<span><span className="seatsUsed">1</span> / 1 seats used</span>
								</div>
								<div className="gaugeContainer">
									<div className="gaugeBar" style={{width:"100%"}}></div>
								</div>
							</div>
							<div className='d-flex-row mt-4'>
								<div className='textEdit flex-1'>
									<label>First name</label>
									<div className='inputWrapper'>
										<Input type="text" className='rsUiEdit' placeholder='' value={newUserData.firstName} onChange={(e) => changeNewLeadValue(e)} name="firstName" />
										<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("firstName")}></i>
										<div className='rsTextEditBorder'></div>
									</div>
								</div>
								<div className='textEdit flex-1'>
									<label>Last name</label>
									<div className='inputWrapper'>
										<Input type="text" className='rsUiEdit' placeholder='' value={newUserData.lastName} onChange={(e) => changeNewLeadValue(e)} name="lastName" />
										<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("lastName")}></i>
										<div className='rsTextEditBorder'></div>
									</div>
								</div>
							</div>
							<div className='textEdit'>
								<label>Email address</label>
								<div className='inputWrapper'>
									<Input type="text" className='rsUiEdit' placeholder='' value={newUserData.email} onChange={(e) => changeNewLeadValue(e)} name="email" />
									<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("email")}></i>
									<div className='rsTextEditBorder'></div>
								</div>
							</div>
							<div className='textEdit mb-5'>
								<label>Password</label>
								<div className='inputWrapper'>
									<Input type="password" className='rsUiEdit' placeholder='' value={newUserData.password} onChange={(e) => changeNewLeadValue(e)} name="password" />
									<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearNewValue("password")}></i>
									<div className='rsTextEditBorder'></div>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex-row justify-content-end'>
							<Button className='rsClose' onClick={() => toggleCreateUser()}>Cancel</Button>
							<Button className='createUserTeamBtn' color='primary' onClick={() => createUserInTeam()} disabled={createUserLoaderApi}>
								{createUserLoaderApi &&
									<Spinner size={"sm"} />
								}
								Create user
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
			{deleteWarningModal &&
				<Modal isOpen={deleteWarningModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation warningColor paddingRight10"></i>Warning</h2>
							<p>Are you sure you want to remove 
								{currentTab === "active" ?
									<> {(teamsDetails[deleteUserData["teamIndex"]] &&  teamsDetails[deleteUserData["teamIndex"]].users[deleteUserData["userIndex"]]) ? teamsDetails[deleteUserData["teamIndex"]].users[deleteUserData["userIndex"]].first_name : ""} </>
								:
									<> {(archiveTeamsDetails[deleteUserData["teamIndex"]] &&  archiveTeamsDetails[deleteUserData["teamIndex"]].users[deleteUserData["userIndex"]]) ? archiveTeamsDetails[deleteUserData["teamIndex"]].users[deleteUserData["userIndex"]].first_name : ""} </>
								}
								from the team 
								{currentTab === "active" ?
									<> {(teamsDetails[deleteUserData["teamIndex"]]) ? teamsDetails[deleteUserData["teamIndex"]].name : ""}</>
								:
									<> {(archiveTeamsDetails[deleteUserData["teamIndex"]]) ? archiveTeamsDetails[deleteUserData["teamIndex"]].name : ""}</>
								}
							</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={deleteLoaderApi} onClick={() => toggleDeleteWarning()}>Cancel</Button>
							<Button className='md okWarningButton btn-warning' color='primary' disabled={deleteLoaderApi} onClick={() => deleteUser()}>
								{deleteLoaderApi &&
									<Spinner size={"sm"} />
								}
								Ok
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
		</>
	);
}

export default Teams;