import React, { useEffect, useState } from 'react';
import { PostData } from '../../../services/FetchData';
import { toast } from 'react-toastify';
import { Navigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from "yup";
import { Button, Form, Input, InputGroup, InputGroupText, Label, Spinner } from 'reactstrap';
import DetailSkeleton from '../../../components/Skeleton/DetailSkeleton';

const ChangePassword = (props) => {
	const [showPassword, setShowPassword]									= useState(false)
	const [showPasswordConfirmation, setShowPasswordConfirmation]	= useState(false)
	const [userToken, setUserToken]											= useState(null)
	const [isSuccess, setIsSuccess]											= useState(false)
	const [failureFlag, setFailureFlag]										= useState(false)
	const location																	= useLocation();
	const [loader, setLoader]													= useState(false);

	useEffect(() => {
		setUserToken(location.search.split(/=(.+)/)[1] ? location.search.split(/=(.+)/)[1] : "")
	},[location])

	useEffect(() => {
		if( userToken ){
			PostData('ms1', 'api/v1/user/verify-password-token', {token: userToken}, false, false).then(result => {
				if( result !== "Invalid" && result !== undefined){
					if( result.status === "not_found_error" ){
						toast.warn("Invalid Token")
						setIsSuccess(true)
					}else if( result.status === "api_error" ){
						toast.warn("Password token expired, Please generate new request")
						setFailureFlag(true);
					}else{
						toast.error(result.message.message)
					}
				}
				setLoader(false)
			})
		}
	}, [userToken])

	useEffect(() => {
		document.title	= "Reset Password | RoundSales App"
		return () => {
			document.title	= "RoundSales App"
		}
	}, [])

	const resetPassword	= (values) => {
		const userData = {
			password: values.password,
			token: userToken,
		}		
		PostData("ms1", "api/v1/user/reset-password", userData, false, false).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if( result.status === "success" ){
					setIsSuccess(true)
					toast.success("Password Changed Successfully");
				}else if( result.status === "failure"){
					setIsSuccess(true)
					toast.error(result.message)
				} else {
					setIsSuccess(true)
					toast.error(result.message.message)
				}
			}
		});
	}

	if( userToken === "" || ( userToken && isSuccess ) ){
		return <Navigate replace to="/auth/login" />
	}
	if( failureFlag ){
		return <Navigate replace to="/auth/forgot-password" />
	}
	return (
		<>
			<div className='authFormWrapper'>
				<div>
					{loader ?
						<DetailSkeleton />
					:
						<>
							<div className='d-flex-col xs mb-4'>
								<h3 className='bold'>Set your new password</h3>
							</div>
							<Formik
								initialValues={{ passwordConfirmation: "", password: "" }}
								onSubmit={(values, { setSubmitting }) => {
									resetPassword(values)
								}}
								validationSchema={Yup.object().shape({
									password: Yup.string().required("Password Required"),
									passwordConfirmation: Yup.string().required("Confirm Password Required").oneOf([Yup.ref('password'), null], 'Passwords must match')
								})}
							>
								{props => {
									const {
										values,
										touched,
										errors,
										isSubmitting,
										handleChange,
										handleBlur,
										handleSubmit
									} = props;
									return (
										<Form role="form" onSubmit={handleSubmit} novalidate>
											<div className={`position-relative passwordField ${(errors.password && touched.password && "error_field")}`}>
												<Label>Password</Label>
												<InputGroup className="input-group-alternative">
													<Input
														placeholder="Password"
														type={showPassword ? "text" : "password"}
														name="password"
														value={values.password}
														onChange={handleChange}
														onBlur={handleBlur}
														className={(errors.password && touched.password && "error")}
													/>
													<InputGroupText>
														<div className="showPasswordWrapper" onClick={() => setShowPassword(!showPassword)}>
															{showPassword ?
																<span className="material-icons-outlined">visibility</span>
																:
																<span className="material-icons-outlined">visibility_off</span>
															}

														</div>
													</InputGroupText>
												</InputGroup>
												{errors.password && touched.password && (
													<>
														<i className='fa-solid fa-circle-xmark errorIcon'></i>
														<div className="input-feedback">{errors.password}</div>
													</>
												)}
											</div>
											<div className={`position-relative passwordField ${(errors.passwordConfirmation && touched.passwordConfirmation && "error_field")}`}>
												<Label>Confirm Password</Label>
												<InputGroup className="input-group-alternative">
													<Input
														placeholder="Confirm password"
														type={showPasswordConfirmation ? "text" : "password"}
														name="passwordConfirmation"
														value={values.passwordConfirmation}
														onChange={handleChange}
														onBlur={handleBlur}
														className={(errors.passwordConfirmation && touched.passwordConfirmation && "error")}
														autofill={false}
													/>
													<InputGroupText>
														<div className="showPasswordWrapper" onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}>
															{showPasswordConfirmation ?
																<span className="material-icons-outlined">visibility</span>
																:
																<span className="material-icons-outlined">visibility_off</span>
															}

														</div>
													</InputGroupText>
												</InputGroup>
												{errors.passwordConfirmation && touched.passwordConfirmation && (
													<>
														<i className='fa-solid fa-circle-xmark errorIcon'></i>
														<div className="input-feedback">{errors.passwordConfirmation}</div>
													</>
												)}
											</div>
											<div className="d-flex justify-content-center">
												{isSubmitting ? (
													<div>
														<Button className="cta authCta" color="primary" type="button" disabled>
															<Spinner
																className="white mr-1"
																size="sm"
																color="light"
															/>
															<span className="ml-2 d-inline-block">Loading</span>
														</Button>
													</div>
												) : (
													<Button className=" cta authCta" color="primary" type="submit">
														Submit
													</Button>
												)}
											</div>
										</Form>)
								}}
							</Formik>
						</>
					}
				</div>
			</div>
		</>
	);
}

export default ChangePassword;