import React from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function LeadReviewSequenceDropdown({currentLeadSequence, index, leadIndex, onClick}) {
   const [stepMoreOption, setStepMoreOption]	= useState(false)

	const onClickEvent = (type) => {
		setStepMoreOption(false)
		onClick(leadIndex, index, type)
	}

	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => {setStepMoreOption((prevstate) => !prevstate);}} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btnAction'>
				<i className="fa fa-ellipsis"></i>
			</DropdownToggle>
			<DropdownMenu className='campaignFilterDropdownMenu stepDropdownMenu reviewSequenceLead withReset'>
				<div>
					<div className='dropdownRSMenuItems'>
					    <div className='dropdownRSMenuItem usethisa' onClick={() => onClickEvent("usethisa")}>
							<i className="fa-solid fa-a"></i>
							<div className='text'>Use step A</div>
						</div>
						<div className='dropdownRSMenuItem usethisb' onClick={() => onClickEvent("usethisb")}>
							<i className="fa-solid fa-b"></i>
							<div className='text'>Use Step B</div>
						</div>
						{typeof currentLeadSequence.reset !== "undefined" &&
							<div className='dropdownRSMenuItem reset' onClick={() => onClickEvent("reset")}>
								<i className="fa-solid fa-rotate-left"></i>
								<div className="text">Reset Overrides</div>
							</div>
						}
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default LeadReviewSequenceDropdown;