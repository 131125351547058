import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function UserRoleDropdown({index, active, onChange}) {
	const [userRoleDropdown, setUserRoleDropdown]	= useState(false),
		[userRoles] = useState({ 1: "Admin", 2: "Member", 3: "Extern"}),
		[localActive, setLocalActive]	= useState(active)
	
	useEffect(() => {
		setLocalActive(active)
	}, [])
	
	const selectedUserRole = (key) => {
		setLocalActive(key)
		setUserRoleDropdown(false)
		onChange(key, index)
	}

	return (
		<Dropdown isOpen={userRoleDropdown} toggle={() => setUserRoleDropdown((prevstate) => !prevstate)} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="div" className='rsDropdown rsUserRole flex-1'>
				<button className='btn btnDropdown rsDropdownToggle'>
					<div className='dropdownValue'>
						<span>{userRoles[localActive]}</span>
					</div>
				</button>
			</DropdownToggle>
			<DropdownMenu className='dropdownPicker'>
				{Object.keys(userRoles).map((key, index) => {
					return (
						<div key={index} className='uiDropdownItem' onClick={() => selectedUserRole(key)}>{userRoles[key]}</div>
					)
				})}
			</DropdownMenu>
		</Dropdown>
	);
}

export default UserRoleDropdown;