import React from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function LeadDropdown({index, onClick, lead}) {
	const [stepMoreOption, setStepMoreOption] = useState(false)
	
	const onClickEvent = (funcName, e) => {
		e.stopPropagation()
		setStepMoreOption(false)
		onClick(index, funcName)
	}
	return (
		<Dropdown isOpen={stepMoreOption} toggle={(e) => { e.stopPropagation(); setStepMoreOption((prevstate) => !prevstate); }} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btnAction'>
				<i className="fa fa-ellipsis"></i>
			</DropdownToggle>
			<DropdownMenu className='campaignFilterDropdownMenu stepDropdownMenu'>
				<div>
					<div className='dropdownRSMenuItems'>
						{( lead.status === "Paused" || lead.status === "Running" ) &&
							<>
								{lead.status === "Paused" ?
									<div className={`dropdownRSMenuItem unpaused`} onClick={(e) => onClickEvent("pause", e)}>
										<i className="fa-solid fa-play"></i>
										<div className="text">Unpause</div>
									</div>
								:
									<div className={`dropdownRSMenuItem running`} onClick={(e) => onClickEvent("pause", e)}>
										<i className="fa-solid fa-pause"></i>
										<div className="text">Pause</div>
									</div>
								}
							</>
						}
						<div className={`dropdownRSMenuItem alert-300 rsDeleteLead ${( lead.status === "Paused" || lead.status === "Running" ) ? "separator" : ""} `} onClick={(e) => onClickEvent("delete", e)}>
							<i className="fa-solid fa-trash"></i>
							<div className="text">Delete this lead</div>
						</div>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default LeadDropdown;