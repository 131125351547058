import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { Navigate, useParams } from 'react-router-dom';

import StepDropdown from '../../../components/StepDropdown';
import StepVariationDropdown from '../../../components/StepVariationDropdown';
import DelayDropdown from '../../../components/DelayDropdown';
import MultipleSelectUserEmail from '../../../components/MultipleSelectUserEmail';
import EmailEditor from '../../../components/EmailEditor/EmailEditor';
import { ProductContext } from '../../../store';
import { PostData } from '../../../services/FetchData';
import { useCallback } from 'react';
import DetailSkeleton from '../../../components/Skeleton/DetailSkeleton';

function SequenceEdit(props) {
	const [sequenceStartDropdown, setSequenceStartDropdown] = useState(false),
		[fullscreenFlag, setFullscreenFlag] = useState(false),
		[loadNewContent, setLoadNewContent]	= useState(false),
		[interMediateContent, setInterMediateContent]	= useState(null),
		[redirectFlag, setRedirectFlag] = useState(false),
		[sequenceZoom, setSequenceZoom] = useState(1),
		scrollableDivRef = useRef(null),
		[isScrolling, setIsScrolling] = useState(false),
		[startX, setStartX] = useState(0),
		[startY, setStartY] = useState(0),
		[scrollLeft, setScrollLeft] = useState(0),
		[scrollTop, setScrollTop] = useState(0),
		[customVariables] = useState({ "Email": "email", "First name": "firstName", "Last name": "lastName", "Picture": "picture", "Phone": "phone", "Linkedin url": "linkedinUrl", "Company name": "companyName", "Company domain": "companyDomain", "Icebreaker": "icebreaker", "Signature": "signature" }),
		[highlightVariable, setHighlightVariable] = useState(null),
		[newItemIndex, setNewItemIndex] = useState(null),
		[openNewItemPopup, setOpenNewItemPopup] = useState(false),
		[stepOption, setStepOption] = useState('steps'),
		[loadCustomVariable, setLoadCustomVariable] = useState(false),
		[loaderApi, setLoaderApi] = useState(false),
		[editorLoadSpinner, setEditorLoadSpinner]	= useState(false),
		[deleteStepIndex, setDeleteStepIndex]	= useState(null),
		[deleteWarningModal, setDeleteWarningModal]	= useState(false),
		[deleteLoaderApi, setDeleteLoaderApi]	= useState(false),
		[abTestModal, setABTestModal]	= useState(false),
		[dontFillABContentLoader, setDontFillABContentLoader]	= useState(false),
		[fillABContentLoader, setFillABContentLoader]	= useState(false),
		[abTestStepIndex, setABTestStepIndex]	= useState(null),
		[deleteABTestModal, setDeleteABTestModal]	= useState(false),
		[deleteABTestValue, setDeleteABTestValue]	= useState({}),
		[deleteABTestLoader, setDeleteABTestLoader]	= useState(false),
		[activeSteps, setActiveSteps]	= useState({index: 0, variation: 0}),
		[currentSubject, setCurrentSubject]	= useState(null),
		[currentMessage, setCurrentMessage]	= useState(null),
		[firstTime, setFirstTime]	= useState(true),
		emailSubjectInputRef	= useRef(null),
		currentCursorPositionRef	= useRef(0),
		{
			sequenceDetails,
			setSequenceDetails,
			currentTeamId,
			setSequenceLoaderApi,
			setLoadMailAccountModal,
			teamMailAccountData,
			campaignDetails,
			setCampaignDetails,
			teamUserData,
			reviewLeads,
			setReviewLeads
		} = useContext(ProductContext),
		{
			id,
		} = useParams();
	
	useEffect(() => {
		getReviewLeads()
	},[])

	useEffect(() => {
		if (sequenceDetails.length === 0) {
			setRedirectFlag(true)
		}else{
			if( firstTime ){
				setCurrentSubject(sequenceDetails[0].actions[0].subject)
				setCurrentMessage(sequenceDetails[0].actions[0].message)
				setFirstTime(false)
			}
		}
	}, [sequenceDetails, firstTime])

	useEffect(() => {
		if (newItemIndex !== null) {
			setOpenNewItemPopup(true)
		} else {
			setOpenNewItemPopup(false)
		}
	}, [newItemIndex])

	useEffect(() => {
		if( loadNewContent ){
			setCurrentMessage(interMediateContent)
			setLoadNewContent(false)
			setInterMediateContent(null)
		}
	}, [loadNewContent, interMediateContent])

	const getReviewLeads	= () => {
		let leadReviewData	= {team_id: currentTeamId, campaign_id: id}
		PostData("ms1", "api/v1/campaign/get-review-leads", leadReviewData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setReviewLeads(result.data.reviewLeads)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const debounce = (func) => {
		let timer;
		return function (...args) {
			const context = this;
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				timer = null;
				func.apply(context, args);
			}, 1000);
		}
	}

	const zoomIn = () => {
		if (sequenceZoom > 0.4) {
			setSequenceZoom((prevstate) => prevstate - 0.1);
		}
	}

	const zoomOut = () => {
		if (sequenceZoom < 2) {
			setSequenceZoom((prevstate) => prevstate + 0.1);
		}
	}

	const handleScrollDivMouseDown = (e) => {
		setIsScrolling(true);
		setStartX(e.clientX);
		setStartY(e.clientY);
		setScrollLeft(scrollableDivRef.current.scrollLeft);
		setScrollTop(scrollableDivRef.current.scrollTop);
		// Disable text selection
		scrollableDivRef.current.style.userSelect = 'none';
	};

	const handleScrollDivMouseMove = (e) => {
		if (!isScrolling) return;
		const x = e.clientX;
		const y = e.clientY;
		const deltaX = x - startX;
		const deltaY = y - startY;
		scrollableDivRef.current.scrollLeft = scrollLeft - deltaX;
		scrollableDivRef.current.scrollTop = scrollTop - deltaY;
	};

	const handleScrollDivMouseUp = () => {
		setIsScrolling(false);
		// Re-enable text selection
		scrollableDivRef.current.style.userSelect = 'auto';
	};

	const openNewMenu = (index) => {
		setNewItemIndex(index)
	}

	const toggleOpenNewItemPopup = () => {
		setNewItemIndex(null)
	}

	const deleteDelayTime = (index) => {
		setSequenceLoaderApi(true)
		let updateSequenceData = { campaign_id: id, sequence_id: sequenceDetails[index].uuid, team_id: currentTeamId, values: [{ key: "delay_time", value: 0 }] }
		PostData("ms1", "api/v1/campaign/update-sequence", updateSequenceData).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					let finalStepData = [...sequenceDetails]
					finalStepData[index].delay_time = 0
					setSequenceDetails(finalStepData);
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setSequenceLoaderApi(false)
		})
	}

	const updateDelayTime = (index, value) => {
		setSequenceLoaderApi(true)
		let updateSequenceData = { campaign_id: id, sequence_id: sequenceDetails[index].uuid, team_id: currentTeamId, values: [{ key: "delay_time", value }] }
		PostData("ms1", "api/v1/campaign/update-sequence", updateSequenceData).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					let finalStepData = [...sequenceDetails]
					finalStepData[index].delay_time = value
					setSequenceDetails(finalStepData);
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setSequenceLoaderApi(false)
		})
	}

	const addNewStep = (type) => {
		setLoaderApi(true)
		setSequenceLoaderApi(true)
		let delay_time = 1
		if (newItemIndex === 0) {
			delay_time = 0
		}
		if (type === "email") {
			let newStepData = {
				uuid: uuidv4(),
				type: 'email_template',
				ab_testing_flag: false,
				delay_time,
				sender_mail_account_id: '',
				order: newItemIndex,
				campaign_id: id,
				team_id: currentTeamId,
				actions: [
					{
						subject: '',
						message: '<p><br></p>',
					}
				]
			}
			PostData("ms1", "api/v1/campaign/create-sequence", newStepData).then(result => {
				if (result !== "Invalid" && result !== undefined) {
					if (result.status === "success") {
						let finalStepData = [...sequenceDetails.slice(0, newItemIndex), result.data.sequence, ...sequenceDetails.slice(newItemIndex)]
						if (newItemIndex === 0 && finalStepData[newItemIndex + 1].delay_time === 0) {
							finalStepData[newItemIndex + 1].delay_time = 1
						}
						setSequenceDetails(finalStepData)
						setActiveSteps({index: newItemIndex, variation: 0})
						setCurrentSubject(finalStepData[newItemIndex].actions[0].subject)
						setInterMediateContent(finalStepData[newItemIndex].actions[0].message)
						setLoadNewContent(true)
						setNewItemIndex(null)
					} else if (result.status === "failure") {
						toast.error(result.message)
					} else {
						toast.error(result.message.message)
					}
				}
				setLoaderApi(false)
				setSequenceLoaderApi(false)
			});
		}
		// let newStepData	= {
		// 								id: uuidv4(),
		// 								type: 'email_template',
		// 								ab_testing_flag: false,
		// 								delay_time,
		// 								sender_email_id: '',
		// 								actions: [
		// 									{
		// 										subject: '',
		// 										message: '',
		// 									}
		// 								]
		// 							}
		// let finalStepData	= [...initialSteps.slice(0, newItemIndex), newStepData, ...initialSteps.slice(newItemIndex)]
		// if( newItemIndex === 0 && finalStepData[newItemIndex+1].delay_time === 0 ){
		// 	finalStepData[newItemIndex+1].delay_time = 1
		// }
		// setInitialSteps(finalStepData);
		// setNewItemIndex(null)
	}

	const stepOptionClick = (index, key) => {
		switch (key) {
			case "duplicate":
				duplicateSteps(index)
				break;
			case "ABTest":
				enableAbSteps(index)
				break;
			case "waitTime":
				enableWaitTime(index)
				break;
			case "deleteStep":
				deleteStep(index)
				break
			default:
				break;
		}
	}

	const duplicateSteps = (index) => {
		setSequenceLoaderApi(true)
		setEditorLoadSpinner(true)
		if( sequenceDetails[index].type === "email_template" ){
			let delay_time	= 1
			if( sequenceDetails[index].delay_time ){
				delay_time	= sequenceDetails[index].delay_time
			}
			let newStepData = {
										uuid: uuidv4(),
										type: 'email_template',
										ab_testing_flag: sequenceDetails[index].ab_testing_flag,
										delay_time: delay_time,
										sender_mail_account_id: sequenceDetails[index].sender_mail_account_id,
										order: index+1,
										campaign_id: id,
										team_id: currentTeamId,
										actions: [											
										]
									}
			for( let i = 0; i < sequenceDetails[index].actions.length; i++ ){
				newStepData.actions.push({...sequenceDetails[index].actions[i], uuid: uuidv4()})
			}
			PostData("ms1", "api/v1/campaign/create-sequence", newStepData).then(result => {
				if (result !== "Invalid" && result !== undefined) {
					if (result.status === "success") {
						let finalStepData = [...sequenceDetails.slice(0, index+1), result.data.sequence, ...sequenceDetails.slice(index+1)]
						for( let i = index+1; i < finalStepData.length; i++ ){
							finalStepData[i].order	+= 1
						}
						setSequenceDetails(finalStepData)
						setSequenceLoaderApi(false)
						setEditorLoadSpinner(false)
						setActiveSteps({index: index+1, variation: 0})
						setCurrentSubject(finalStepData[index+1].actions[0].subject)
						setInterMediateContent(finalStepData[index+1].actions[0].message)
						setLoadNewContent(true)
					} else if (result.status === "failure") {
						toast.error(result.message)
					} else {
						toast.error(result.message.message)
					}
				}
				setLoaderApi(false)
				setSequenceLoaderApi(false)
			});
		}
	}

	const enableAbSteps = (index) => {
		setABTestModal(true)
		setABTestStepIndex(index)
	}

	const enableWaitTime = (index) => {
		setSequenceLoaderApi(true)
		setEditorLoadSpinner(true)
		let updateSequenceData = { campaign_id: id, sequence_id: sequenceDetails[index].uuid, team_id: currentTeamId, values: [{ key: "delay_time", value: 1 }] }
		PostData("ms1", "api/v1/campaign/update-sequence", updateSequenceData).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					let finalStepData = [...sequenceDetails]
					finalStepData[index].delay_time = 1
					setSequenceDetails(finalStepData);
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setSequenceLoaderApi(false)
			setEditorLoadSpinner(false)
		})
	}

	const deleteStep = (index) => {
		setDeleteStepIndex(index)
		setDeleteWarningModal(true)
	}

	const toggleDeleteWarningModal	= () => {
		setDeleteStepIndex(null)
		setDeleteWarningModal(false)
	}

	const toggleABTestModal	= () => {
		setABTestStepIndex(null)
		setABTestModal(false)
	}

	const deleteSequenceStep = () => {
		setSequenceLoaderApi(true)
		setDeleteLoaderApi(true)
		let deleteSequenceData	= {team_id: currentTeamId, campaign_id: id, sequence_id: sequenceDetails[deleteStepIndex].uuid}
		PostData("ms1", "api/v1/campaign/delete-sequence", deleteSequenceData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					let finalStepData	= [...sequenceDetails]
					finalStepData.splice(deleteStepIndex, 1)
					setSequenceDetails(finalStepData)
					if( deleteStepIndex === 0 ){
						setActiveSteps({index: 0, variation: 0})
						setCurrentSubject(finalStepData[0].actions[0].subject)
						setInterMediateContent(finalStepData[0].actions[0].message)
						setLoadNewContent(true)
					}else{
						setActiveSteps({index: deleteStepIndex-1 , variation: 0})
						setCurrentSubject(finalStepData[deleteStepIndex-1].actions[0].subject)
						setInterMediateContent(finalStepData[deleteStepIndex-1].actions[0].message)
						setLoadNewContent(true)
					}
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setDeleteStepIndex(null)
			setSequenceLoaderApi(false)
			setDeleteLoaderApi(false)
			setDeleteWarningModal(false)
		})
	}

	const fillABContent	= () => {
		setSequenceLoaderApi(true)
		setFillABContentLoader(true)
		let abTestEmailSequenceData	= {team_id: currentTeamId, campaign_id: id, sequence_id: sequenceDetails[abTestStepIndex].uuid, uuid: uuidv4(), subject: sequenceDetails[abTestStepIndex].actions[0].subject, message: sequenceDetails[abTestStepIndex].actions[0].message}
		PostData("ms1", "api/v1/campaign/create-ab-test-email-sequence", abTestEmailSequenceData).then( result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					let finalStepData	= [...sequenceDetails]
					finalStepData[abTestStepIndex].ab_testing_flag	= true
					finalStepData[abTestStepIndex].actions.push(result.data.emailSequence)
					setSequenceDetails(finalStepData);
					setActiveSteps({index: abTestStepIndex, variation: 1})
					setCurrentSubject(finalStepData[abTestStepIndex].actions[1].subject)
					setInterMediateContent(finalStepData[abTestStepIndex].actions[1].message)
					setLoadNewContent(true)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setABTestModal(false)
			setSequenceLoaderApi(false)
			setFillABContentLoader(false)
			setABTestStepIndex(null)
		})
	}

	const dontFillABContent	= () => {
		setSequenceLoaderApi(true)
		setDontFillABContentLoader(true)
		let abTestEmailSequenceData	= {team_id: currentTeamId, campaign_id: id, sequence_id: sequenceDetails[abTestStepIndex].uuid, uuid: uuidv4(), subject: "", message: "<p></p>"}
		PostData("ms1", "api/v1/campaign/create-ab-test-email-sequence", abTestEmailSequenceData).then( result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					let finalStepData	= [...sequenceDetails]
					finalStepData[abTestStepIndex].ab_testing_flag	= true
					finalStepData[abTestStepIndex].actions.push(result.data.emailSequence)
					setSequenceDetails(finalStepData);
					setActiveSteps({index: abTestStepIndex, variation: 1})
					setCurrentSubject(finalStepData[abTestStepIndex].actions[1].subject)
					setInterMediateContent(finalStepData[abTestStepIndex].actions[1].message)
					setLoadNewContent(true)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setABTestModal(false)
			setSequenceLoaderApi(false)
			setDontFillABContentLoader(false)
			setABTestStepIndex(null)
		})
	}

	const stepVariationClickEvent = ( index, variation, type ) => {
		if( type === "winner" ){
			setWinnerABTest(index, variation)
		}else{
			deleteABTest(index, variation)
		}
	}

	const toggleDeleteABTestModal	= () => {
		setDeleteABTestValue({})
		setDeleteABTestModal(false)
	}

	const deleteSequenceStepVariation = () => {
		setDeleteABTestLoader(true)
		setSequenceLoaderApi(true)
		let type	= sequenceDetails[deleteABTestValue.index].type,
			url	= "api/v1/campaign/delete-email-sequence",
			deleteStepVariationData	= {team_id: currentTeamId, campaign_id: id, sequence_id: sequenceDetails[deleteABTestValue.index].uuid},
			variationIndex	= deleteABTestValue.variation === "A" ? 0 : 1;
		if( type === "email_template" ){
			url	= "api/v1/campaign/delete-email-sequence";
			deleteStepVariationData.email_sequence_id	= sequenceDetails[deleteABTestValue.index].actions[variationIndex].uuid;
		}		
		PostData("ms1", url, deleteStepVariationData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					let finalStepData	= [...sequenceDetails]
					finalStepData[deleteABTestValue.index].actions.splice(variationIndex, 1)
					finalStepData[deleteABTestValue.index].ab_testing_flag	= false
					finalStepData[deleteABTestValue.index].ab_test_winner	= ""
					setSequenceDetails(finalStepData)
					setActiveSteps({index: deleteABTestValue.index, variation: 0})
					setCurrentSubject(finalStepData[deleteABTestValue.index].actions[0].subject)
					setInterMediateContent(finalStepData[deleteABTestValue.index].actions[0].message)
					setLoadNewContent(true)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setDeleteABTestModal(false)
			setDeleteABTestLoader(false)
			setSequenceLoaderApi(false)
			setDeleteABTestValue({})
		})

	}

	const setWinnerABTest	= (index, variation) => {
		setSequenceLoaderApi(true)
		setEditorLoadSpinner(true)
		let updateSequenceData = { campaign_id: id, sequence_id: sequenceDetails[index].uuid, team_id: currentTeamId, values: [{ key: "ab_test_winner", value: variation }] }
		PostData("ms1", "api/v1/campaign/update-sequence", updateSequenceData).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					let finalStepData = [...sequenceDetails]
					finalStepData[index].ab_test_winner = variation
					setSequenceDetails(finalStepData);
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
			}
			setSequenceLoaderApi(false)
			setEditorLoadSpinner(false)
		})
	}

	const deleteABTest	= (index, variation) => {
		setDeleteABTestValue({index, variation})
		setDeleteABTestModal(true)
	}

	const selectStep = (index) => {
		setActiveSteps({index, variation: 0})
		setCurrentSubject(sequenceDetails[index].actions[0].subject)
		setInterMediateContent(sequenceDetails[index].actions[0].message)
		setLoadNewContent(true)
	}

	const selectStepVariation = ( e, index, variation ) => {
		e.stopPropagation()
		setActiveSteps({index, variation})
		setCurrentSubject(sequenceDetails[index].actions[variation].subject)
		setInterMediateContent(sequenceDetails[index].actions[variation].message)
		setLoadNewContent(true)
	}

	const setCampaignSenderId = (userId) => {
		setEditorLoadSpinner(true)
		setSequenceLoaderApi(true)
		let campaignUpdateData	= {team_id: currentTeamId, campaign_id: id, values:[{key: "sender_user_id", value: userId}]}
		PostData("ms1", "api/v1/campaign/update-campaign", campaignUpdateData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setCampaignDetails({
						...campaignDetails,
						sender_user_id: userId
					})
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message)
				}
			}
			setEditorLoadSpinner(false)
			setSequenceLoaderApi(false)
		})
	}

	const updateEmailSequence = ( key, value, index, variation ) => {
		if( sequenceDetails[index].actions[variation].subject !== value ){
			setEditorLoadSpinner(true)
			setSequenceLoaderApi(true)
			let url	= "api/v1/campaign/update-email-sequence",
				updateSequenceVariationData	= {team_id: currentTeamId, campaign_id: id, sequence_id: sequenceDetails[index].uuid};
			updateSequenceVariationData.email_sequence_id	= sequenceDetails[index].actions[variation].uuid
			updateSequenceVariationData.values					= [{key, value}]
			PostData("ms1", url, updateSequenceVariationData).then( result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						let finalStepData	= [...sequenceDetails]
						finalStepData[index].actions[variation][key]	= value
						setSequenceDetails(finalStepData)
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
				setEditorLoadSpinner(false)
				setSequenceLoaderApi(false)
			})
		}
	}

	const debouncedFetchData = useCallback(debounce(updateEmailSequence, 10000), [sequenceDetails]);

	const changeCurrentSubject = (value, e ) => {
		setCurrentSubject(value)
		debouncedFetchData( "subject", value, activeSteps.index, activeSteps.variation)
	}


	const toggleCustomVariable = () => {
		if( loadCustomVariable ){
			currentCursorPositionRef.current	= 0
		}
		setLoadCustomVariable((prevstate) => !prevstate)
	}

	const enterEmailSubjectPlaceholder = (value) => {
		toggleCustomVariable()
		if (emailSubjectInputRef.current !== null) {
			// Get the current cursor position from the ref
			const cursorPosition = currentCursorPositionRef.current;
			// Split the input value into two parts at the cursor position
			const beforeCursor = currentSubject.slice(0, cursorPosition);
			const afterCursor = currentSubject.slice(cursorPosition);
	
			// Insert the custom text at the cursor position
			const newInputValue = beforeCursor + value + afterCursor;
	
			// Update the input value state
			setCurrentSubject(newInputValue);
	
			// Move the cursor to the end of the inserted text
			const newCursorPosition = cursorPosition + value.length;
			currentCursorPositionRef.current = newCursorPosition;
			debouncedFetchData( "subject", newInputValue, activeSteps.index, activeSteps.variation)
		 }
	}

	const updateCurrentEmailMessage = ( value ) => {
		setCurrentMessage(value)
		debouncedFetchData("message", value, activeSteps.index, activeSteps.variation)
	}

	

	if (redirectFlag) {
		return <Navigate to={"/user/campaign/" + id + "/sequence/edit"} replace />
	}

	return (
		<>
			<div className={`campaignEditContentWrapper rsEditorMainWrapper tabSequence backgroundDotted ${fullscreenFlag ? "fullscreen" : ""} centered`}>
				<div className='left'>
					<div className='content'>
						<div className={`sequenceEditorLeft normal ${editorLoadSpinner ? "overlayLoadingWrapper" : ""} `}>
							{editorLoadSpinner &&
								<div className='loaderSpinner'>
									<Spinner size={"sm"} color='light' />
								</div>
							}
							<div className='rsTreeNavigation'>
								<div className='containerActions'>
									<div className='rsAction rsZoom minus' onClick={() => zoomIn()}>
										<i className="fa-solid fa-magnifying-glass-minus"></i>
									</div>
									<div className='rsAction rsZoom plus' onClick={() => zoomOut()}>
										<i className="fa-solid fa-magnifying-glass-plus"></i>
									</div>
									<div className='rsAction rsToggleFullScreen plus' onClick={() => setFullscreenFlag((prevstate) => !prevstate)}>
										{!fullscreenFlag ?
											<i className="fa-solid fa-up-right-and-down-left-from-center"></i>
											:
											<i className="fa-solid fa-down-left-and-up-right-to-center"></i>
										}
									</div>
								</div>
							</div>
							<div
								className='rsTree'
								ref={scrollableDivRef}
								onMouseDown={handleScrollDivMouseDown}
								onMouseMove={handleScrollDivMouseMove}
								onMouseUp={handleScrollDivMouseUp}
							>
								<div className='steps d-flex-col noGap' style={{ transform: `scale(${sequenceZoom})` }}>
									<div className='rsTreeStart'>
										<div className="label">Sequence start</div>
										<Dropdown isOpen={sequenceStartDropdown} toggle={() => setSequenceStartDropdown((prevstate) => !prevstate)} direction={"down"} className='dropdownTrigger'>
											<DropdownToggle tag="button" className='btn btnAction'>
												<i className="fa fa-ellipsis"></i>
											</DropdownToggle>
											<DropdownMenu className='campaignFilterDropdownMenu singleItem startSteps'>
												<div>
													<div className='dropdownRSMenuItems'>
														<div className='dropdownRSMenuItem rsShareSequence'>
															<i className="fa-solid fa-vials"></i>
															<div className="text">A/B test this sequence</div>
														</div>
													</div>
												</div>
											</DropdownMenu>
										</Dropdown>
									</div>
									<div className='stepVerticalSeparator d-flex-col noGap'>
										<div className='stepVerticalSeparatorLine'></div>
									</div>
									<div className='rsTreeSequence d-flex-col noGap'>
										{sequenceDetails && sequenceDetails.map((step, index) => {
											return (
												<React.Fragment key={index}>
													<div className='stepVerticalSeparator d-flex-col noGap'>
														{index !== 0 &&
															<div className='stepVerticalSeparatorLine'></div>
														}
														<div className='rsAddStepAtIndex' onClick={() => openNewMenu(index)}>
															<i className="fa-solid fa-plus"></i>
														</div>
														<div className='stepVerticalSeparatorLine'></div>
													</div>
													{step.delay_time > 0 &&
														<>
															<div className='rsTreeDelay d-flex-row'>
																<div className='flex-1'>								
																	<DelayDropdown value={step.delay_time} step={step} index={index} onUpdate={(index, value) => updateDelayTime(index, value)} />
																</div>
																<i className="fa-solid fa-trash" onClick={() => deleteDelayTime(index)}></i>
															</div>
															<div className='stepVerticalSeparator d-flex-col noGap'>
																<div className='stepVerticalSeparatorLine'></div>
																<div className='stepVerticalSeparatorLine'></div>
															</div>
														</>
													}
													<div className={`rsTreeSequenceStep ${step.ab_testing_flag ? "abTest" : ""} ${( !step.ab_testing_flag && ( step.actions[0].message === "<p></p>" || step.actions[0].message === "<p><br></p>" || !step.actions[0].message ) ) ? "error" : ""} ${ activeSteps.index === index ? "active" : "" } `} onClick={() => selectStep(index)}>
														<div className='d-flex-row noGap'>
															<div className='rsTreeSequenceStepItem'>
																<div className='stepLabel relative'>
																	<div className='stepNumber'>{index + 1}.</div>
																	<div className='channelIcon email'>
																		<i className="fa-regular fa-envelope"></i>
																	</div>
																	<div className='desc'>
																		<span className='labelType'>
																			{step.type === "email_template" && "Email"}
																		</span>																		
																		{( !step.ab_testing_flag && step.actions[0] && ( step.actions[0].message !== "<p></p>"  && step.actions[0].message !== "<p><br></p>" ) ) ?
																			<span className='descriptionType'>
																				<span className='descriptionTypeLabel'>
																					{step.actions[0].subject}
																				</span>
																			</span>
																		: ( !step.ab_testing_flag ) &&
																			<span className='stepError'>content is required</span>
																		}
																	</div>
																</div>
															</div>
															<div className='actions'>
																<span className='multiSenders'>
																	{campaignDetails.sender_user_id &&
																		<div className={`avatar uiUserPicture avatarPrimary${((campaignDetails.sender_user_id - 1) % 10)}`}>
																			{teamMailAccountData[campaignDetails.sender_user_id] ?
																				<>
																					{teamMailAccountData[campaignDetails.sender_user_id].profile_url ?
																						<img src={teamMailAccountData[campaignDetails.sender_user_id].profile_url} alt={teamMailAccountData[campaignDetails.sender_user_id].name} className='picture' />
																						:
																						<>
																							{teamMailAccountData[campaignDetails.sender_user_id].name.substr(0, 1)}
																						</>
																					}
																				</>
																			: teamUserData[campaignDetails.sender_user_id] &&
																				<>
																					{teamUserData[campaignDetails.sender_user_id].profile_url ?
																						<img src={teamUserData[campaignDetails.sender_user_id].profile_picture} alt={teamUserData[campaignDetails.sender_user_id].name} className='picture' />
																						:
																						<>
																							{teamUserData[campaignDetails.sender_user_id].first_name.substr(0, 1)}{teamUserData[campaignDetails.sender_user_id].last_name && teamUserData[campaignDetails.sender_user_id].last_name.substr(0, 1)}
																						</>
																					}
																				</>
																			}
																		</div>
																	}
																</span>
																<StepDropdown delay_time={step.delay_time} ab_testing_flag={step.ab_testing_flag} index={index} onClick={(index, key) => stepOptionClick(index, key)} />
															</div>
														</div>
														{step.ab_testing_flag &&
															<div className='abTestStepContainer'>
																<div className={`abTestRow abTestRowA ${( activeSteps.index === index && activeSteps.variation === 0 ) ? "active" : ""} ${( step.actions[0].message === "<p></p>"  || step.actions[0].message === "<p><br></p>" || !step.actions[0].message ) ? "error" : ""} `} onClick={(e) => selectStepVariation( e, index, 0)}>
																	<div>
																		<svg className="a letter" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path className="border" d="M1 4C1 3.02892 1.00212 2.40121 1.06431 1.9387C1.12262 1.50496 1.21677 1.36902 1.29289 1.29289C1.36902 1.21677 1.50496 1.12262 1.9387 1.06431C2.40121 1.00212 3.02892 1 4 1H28.3091C29.4291 1 30.162 1.00238 30.695 1.0779C31.2002 1.14948 31.3415 1.26444 31.4155 1.35115C31.4894 1.43787 31.5806 1.59555 31.5715 2.10575C31.5618 2.64401 31.4484 3.36809 31.2714 4.47397L28.5514 21.474C28.4187 22.3033 28.3321 22.8333 28.2192 23.221C28.1142 23.5818 28.019 23.6965 27.9434 23.7609C27.8678 23.8254 27.7396 23.9013 27.3667 23.948C26.9661 23.9982 26.429 24 25.5891 24H4C3.02892 24 2.40121 23.9979 1.9387 23.9357C1.50496 23.8774 1.36902 23.7832 1.29289 23.7071C1.21677 23.631 1.12262 23.495 1.06431 23.0613C1.00212 22.5988 1 21.9711 1 21V4Z" stroke="currentcolor" strokeWidth="2"></path>
																			<path className="letter" d="M13.7464 17H11.7776L15.3621 6.81818H17.6391L21.2286 17H19.2599L16.5404 8.90625H16.4608L13.7464 17ZM13.811 13.0078H19.1803V14.4893H13.811V13.0078Z" fill="currentcolor"></path>
																		</svg>
																	</div>
																	{step.ab_test_winner === "A" &&
																		<i className="fa-solid fa-trophy warningColor"></i>
																	}
																	<div className='contentCenter'>
																		Version A <br />
																		<span className="instructionRequired stepInstructionB">
																			{( step.actions[0].message === "<p></p>"  || step.actions[0].message === "<p><br></p>" || !step.actions[0].message ) && "Content is required" }
																		</span>
																	</div>
																	{step.ab_test_winner !== "A" &&
																		<div>
																			<StepVariationDropdown variation="A" index={index} abTestWinner={step.ab_test_winner} onClick={(index, variation, type) => stepVariationClickEvent(index, variation, type)} />
																		</div>
																	}
																</div>
																<div className={`abTestRow abTestRowB ${ ( activeSteps.index === index && activeSteps.variation === 1 ) ? "active" : ""} ${( step.actions[1].message === "<p></p>"  || step.actions[1].message === "<p><br></p>" || !step.actions[1].message ) ? "error" : ""} `} onClick={(e) => selectStepVariation( e, index, 1)}>
																	<div>
																		<svg className="b letter" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path className="border" d="M4.44855 3.52603C4.58125 2.69666 4.66791 2.16666 4.78076 1.77898C4.88579 1.41818 4.98099 1.30355 5.05659 1.23908C5.13219 1.17462 5.26042 1.09872 5.63329 1.05202C6.03393 1.00183 6.57096 1 7.41088 1H29C29.9711 1 30.5988 1.00212 31.0613 1.06431C31.495 1.12262 31.631 1.21677 31.7071 1.29289C31.7832 1.36902 31.8774 1.50496 31.9357 1.9387C31.9979 2.40121 32 3.02892 32 4V21C32 21.9711 31.9979 22.5988 31.9357 23.0613C31.8774 23.495 31.7832 23.631 31.7071 23.7071C31.631 23.7832 31.495 23.8774 31.0613 23.9357C30.5988 23.9979 29.9711 24 29 24H4.69088C3.57093 24 2.83802 23.9976 2.305 23.9221C1.79976 23.8505 1.65846 23.7356 1.58452 23.6488C1.51058 23.5621 1.41939 23.4045 1.42853 22.8942C1.43817 22.356 1.55161 21.6319 1.72855 20.526L4.44855 3.52603Z" stroke="currentcolor" strokeWidth="2"></path>
																			<path className="letter" d="M12.257 17V6.81818H16.3337C17.0828 6.81818 17.7075 6.92921 18.208 7.15128C18.7085 7.37334 19.0847 7.68158 19.3366 8.07599C19.5885 8.46709 19.7144 8.91785 19.7144 9.42827C19.7144 9.82599 19.6349 10.1757 19.4758 10.4773C19.3167 10.7756 19.0979 11.0208 18.8195 11.2131C18.5444 11.402 18.2296 11.5362 17.8749 11.6158V11.7152C18.2627 11.7318 18.6256 11.8411 18.9637 12.0433C19.3051 12.2455 19.5818 12.5289 19.7939 12.8935C20.0061 13.2547 20.1121 13.6856 20.1121 14.1861C20.1121 14.7263 19.9779 15.2086 19.7094 15.6328C19.4443 16.0537 19.0515 16.3868 18.5312 16.6321C18.0108 16.8774 17.3695 17 16.6072 17H12.257ZM14.4097 15.2401H16.1647C16.7646 15.2401 17.2021 15.1257 17.4772 14.897C17.7523 14.665 17.8898 14.3568 17.8898 13.9723C17.8898 13.6906 17.8219 13.442 17.686 13.2266C17.5501 13.0111 17.3562 12.8421 17.1043 12.7195C16.8557 12.5968 16.5591 12.5355 16.2144 12.5355H14.4097V15.2401ZM14.4097 11.0788H16.0056C16.3006 11.0788 16.5624 11.0275 16.7911 10.9247C17.0231 10.8187 17.2054 10.6695 17.338 10.4773C17.4739 10.285 17.5418 10.0547 17.5418 9.78622C17.5418 9.41832 17.4109 9.12169 17.1491 8.89631C16.8905 8.67093 16.5226 8.55824 16.0454 8.55824H14.4097V11.0788Z" fill="currentcolor"></path>
																		</svg>
																	</div>
																	{step.ab_test_winner === "B" &&
																		<i className="fa-solid fa-trophy warningColor"></i>
																	}
																	<div className='contentCenter'>
																		Version B <br />
																		<span className="instructionRequired stepInstructionA">
																		{( step.actions[1].message === "<p></p>"  || step.actions[1].message === "<p><br></p>" || !step.actions[1].message ) && "Content is required" }
																		</span>
																	</div>
																	{step.ab_test_winner !== "B" &&
																		<div>
																			<StepVariationDropdown variation="B" index={index} abTestWinner={step.ab_test_winner} onClick={(index, variation, type) => stepVariationClickEvent(index, variation, type)} />
																		</div>
																	}
																</div>
															</div>
														}
													</div>
												</React.Fragment>
											)
										})}
										<div className='stepVerticalSeparator d-flex-col noGap'>
											<div className='stepVerticalSeparatorLine'></div>
											<div className='rsAddStepAtIndex' onClick={() => openNewMenu((sequenceDetails.length))}>
												<i className="fa-solid fa-plus"></i>
											</div>
										</div>
									</div>
									{/* <div className='rsTreeSequence d-flex-col noGap'>
										<div className='stepVerticalSeparator d-flex-col noGap'>
											<div className='rsAddStepAtIndex'>
												<i className="fa-solid fa-plus"></i>
											</div>
											<div className='stepVerticalSeparatorLine'></div>
										</div>
										<div className='rsTreeDelay d-flex-row'>
											<div className='flex-1'>
												<DelayDropdown />
											</div>
											<i className="fa-solid fa-trash"></i>
										</div>
										<div className='stepVerticalSeparator d-flex-col noGap'>
											<div className='stepVerticalSeparatorLine'></div>
											<div className='stepVerticalSeparatorLine'></div>
										</div>
										<div className='rsTreeSequenceStep'>
											<div className='d-flex-row noGap'>
												<div className='rsTreeSequenceStepItem'>
													<div className='stepLabel relative'>
														<div className='stepNumber'>1.</div>
														<div className='channelIcon email'>
															<i className="fa-regular fa-envelope"></i>
														</div>
														<div className='desc'>
															<span className='labelType'>Email</span>
															<span className='descriptionType'>
																<span className='descriptionTypeLabel'>Send automatic email</span>
															</span>
														</div>
													</div>
												</div>
												<div className='actions'>
													<span className='multiSenders'></span>
													<StepDropdown />
												</div>
											</div>
											<div className='abTestStepContainer'>
												<div className='abTestRow abTestRowA'>
													<div>
														<svg className="a letter" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path className="border" d="M1 4C1 3.02892 1.00212 2.40121 1.06431 1.9387C1.12262 1.50496 1.21677 1.36902 1.29289 1.29289C1.36902 1.21677 1.50496 1.12262 1.9387 1.06431C2.40121 1.00212 3.02892 1 4 1H28.3091C29.4291 1 30.162 1.00238 30.695 1.0779C31.2002 1.14948 31.3415 1.26444 31.4155 1.35115C31.4894 1.43787 31.5806 1.59555 31.5715 2.10575C31.5618 2.64401 31.4484 3.36809 31.2714 4.47397L28.5514 21.474C28.4187 22.3033 28.3321 22.8333 28.2192 23.221C28.1142 23.5818 28.019 23.6965 27.9434 23.7609C27.8678 23.8254 27.7396 23.9013 27.3667 23.948C26.9661 23.9982 26.429 24 25.5891 24H4C3.02892 24 2.40121 23.9979 1.9387 23.9357C1.50496 23.8774 1.36902 23.7832 1.29289 23.7071C1.21677 23.631 1.12262 23.495 1.06431 23.0613C1.00212 22.5988 1 21.9711 1 21V4Z" stroke="#566F8F" strokeWidth="2"></path>
															<path className="letter" d="M13.7464 17H11.7776L15.3621 6.81818H17.6391L21.2286 17H19.2599L16.5404 8.90625H16.4608L13.7464 17ZM13.811 13.0078H19.1803V14.4893H13.811V13.0078Z" fill="#566F8F"></path>
														</svg>
													</div>
													<div className='contentCenter'>
														Version A <br />
														<span className="instructionRequired stepInstructionB"></span>
													</div>
													<div>
														<StepVariationDropdown variation="A" />
													</div>
												</div>
												<div className='abTestRow abTestRowB'>
													<div>
														<svg className="b letter" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path className="border" d="M4.44855 3.52603C4.58125 2.69666 4.66791 2.16666 4.78076 1.77898C4.88579 1.41818 4.98099 1.30355 5.05659 1.23908C5.13219 1.17462 5.26042 1.09872 5.63329 1.05202C6.03393 1.00183 6.57096 1 7.41088 1H29C29.9711 1 30.5988 1.00212 31.0613 1.06431C31.495 1.12262 31.631 1.21677 31.7071 1.29289C31.7832 1.36902 31.8774 1.50496 31.9357 1.9387C31.9979 2.40121 32 3.02892 32 4V21C32 21.9711 31.9979 22.5988 31.9357 23.0613C31.8774 23.495 31.7832 23.631 31.7071 23.7071C31.631 23.7832 31.495 23.8774 31.0613 23.9357C30.5988 23.9979 29.9711 24 29 24H4.69088C3.57093 24 2.83802 23.9976 2.305 23.9221C1.79976 23.8505 1.65846 23.7356 1.58452 23.6488C1.51058 23.5621 1.41939 23.4045 1.42853 22.8942C1.43817 22.356 1.55161 21.6319 1.72855 20.526L4.44855 3.52603Z" stroke="#566F8F" strokeWidth="2"></path>
															<path className="letter" d="M12.257 17V6.81818H16.3337C17.0828 6.81818 17.7075 6.92921 18.208 7.15128C18.7085 7.37334 19.0847 7.68158 19.3366 8.07599C19.5885 8.46709 19.7144 8.91785 19.7144 9.42827C19.7144 9.82599 19.6349 10.1757 19.4758 10.4773C19.3167 10.7756 19.0979 11.0208 18.8195 11.2131C18.5444 11.402 18.2296 11.5362 17.8749 11.6158V11.7152C18.2627 11.7318 18.6256 11.8411 18.9637 12.0433C19.3051 12.2455 19.5818 12.5289 19.7939 12.8935C20.0061 13.2547 20.1121 13.6856 20.1121 14.1861C20.1121 14.7263 19.9779 15.2086 19.7094 15.6328C19.4443 16.0537 19.0515 16.3868 18.5312 16.6321C18.0108 16.8774 17.3695 17 16.6072 17H12.257ZM14.4097 15.2401H16.1647C16.7646 15.2401 17.2021 15.1257 17.4772 14.897C17.7523 14.665 17.8898 14.3568 17.8898 13.9723C17.8898 13.6906 17.8219 13.442 17.686 13.2266C17.5501 13.0111 17.3562 12.8421 17.1043 12.7195C16.8557 12.5968 16.5591 12.5355 16.2144 12.5355H14.4097V15.2401ZM14.4097 11.0788H16.0056C16.3006 11.0788 16.5624 11.0275 16.7911 10.9247C17.0231 10.8187 17.2054 10.6695 17.338 10.4773C17.4739 10.285 17.5418 10.0547 17.5418 9.78622C17.5418 9.41832 17.4109 9.12169 17.1491 8.89631C16.8905 8.67093 16.5226 8.55824 16.0454 8.55824H14.4097V11.0788Z" fill="#566F8F"></path>
														</svg>
													</div>
													<div className='contentCenter'>
														Version B <br />
														<span className="instructionRequired stepInstructionA"></span>
													</div>
													<div>
														<StepVariationDropdown variation="B" />
													</div>
												</div>
											</div>
										</div>
										<div className='stepVerticalSeparator d-flex-col noGap'>
											<div className='stepVerticalSeparatorLine'></div>
											<div className='rsAddStepAtIndex'>
												<i className="fa-solid fa-plus"></i>
											</div>
											<div className='stepVerticalSeparatorLine'></div>
										</div>
										<div className='rsTreeSequenceStep'>
											<div className='d-flex-row noGap'>
												<div className='rsTreeSequenceStepItem'>
													<div className='stepLabel relative'>
														<div className='stepNumber'>2.</div>
														<div className='channelIcon conditional'>
															<i className="fa-solid fa-code-branch"></i>
														</div>
														<div className='desc'>
															<span className='labelType'>Has email address</span>
															<span className='descriptionType'>
																<span className='descriptionTypeLabel'></span>
															</span>
														</div>
													</div>
												</div>
												<div className='actions'>
													<span className='multiSenders'></span>
													<StepDropdown />
												</div>
											</div>
										</div>
										<div className='d-flex-col noGap'>
											<div className="rsTreeBranches">
												<div className="d-flex-row noGap">
													<div className="rsTreeBranchYes">
														<div className="spacer"></div>
														<div className="rsTreeBranch">
															<div className="rsTreeBranchLeft"></div>
															<div className="rsTreeBranchCenter">
																<hr />
																<div className="rsTreeBranchLabel rsTreeBranchLabelYes">Yes</div>
																<hr />
															</div>
															<div className="rsTreeBranchRight"></div>
														</div>
													</div>
													<div className="rsTreeBranchNo">
														<div className="rsTreeBranch">
															<div className="rsTreeBranchLeft"></div>
															<div className="rsTreeBranchCenter">
																<hr />
																<div className="rsTreeBranchLabel rsTreeBranchLabelNo">No</div>
																<hr />
															</div>
															<div className="rsTreeBranchRight"></div>
														</div>
														<div className="spacer"></div>
													</div>
												</div>
											</div>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='center'>
					<div className='sequenceEditor'>
						<div className='d-flex-row mvpBox margins'>
							<div className='stepItem relative'>
								{( sequenceDetails && sequenceDetails[activeSteps.index] && sequenceDetails[activeSteps.index].ab_testing_flag ) &&
									<>
										{activeSteps.variation === 0 ? 
											<svg className="a letter" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path className="border" d="M1 4C1 3.02892 1.00212 2.40121 1.06431 1.9387C1.12262 1.50496 1.21677 1.36902 1.29289 1.29289C1.36902 1.21677 1.50496 1.12262 1.9387 1.06431C2.40121 1.00212 3.02892 1 4 1H28.3091C29.4291 1 30.162 1.00238 30.695 1.0779C31.2002 1.14948 31.3415 1.26444 31.4155 1.35115C31.4894 1.43787 31.5806 1.59555 31.5715 2.10575C31.5618 2.64401 31.4484 3.36809 31.2714 4.47397L28.5514 21.474C28.4187 22.3033 28.3321 22.8333 28.2192 23.221C28.1142 23.5818 28.019 23.6965 27.9434 23.7609C27.8678 23.8254 27.7396 23.9013 27.3667 23.948C26.9661 23.9982 26.429 24 25.5891 24H4C3.02892 24 2.40121 23.9979 1.9387 23.9357C1.50496 23.8774 1.36902 23.7832 1.29289 23.7071C1.21677 23.631 1.12262 23.495 1.06431 23.0613C1.00212 22.5988 1 21.9711 1 21V4Z" stroke="currentcolor" strokeWidth="2"></path>
												<path className="letter" d="M13.7464 17H11.7776L15.3621 6.81818H17.6391L21.2286 17H19.2599L16.5404 8.90625H16.4608L13.7464 17ZM13.811 13.0078H19.1803V14.4893H13.811V13.0078Z" fill="currentcolor"></path>
											</svg>
										:
											<svg className="b letter" width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path className="border" d="M4.44855 3.52603C4.58125 2.69666 4.66791 2.16666 4.78076 1.77898C4.88579 1.41818 4.98099 1.30355 5.05659 1.23908C5.13219 1.17462 5.26042 1.09872 5.63329 1.05202C6.03393 1.00183 6.57096 1 7.41088 1H29C29.9711 1 30.5988 1.00212 31.0613 1.06431C31.495 1.12262 31.631 1.21677 31.7071 1.29289C31.7832 1.36902 31.8774 1.50496 31.9357 1.9387C31.9979 2.40121 32 3.02892 32 4V21C32 21.9711 31.9979 22.5988 31.9357 23.0613C31.8774 23.495 31.7832 23.631 31.7071 23.7071C31.631 23.7832 31.495 23.8774 31.0613 23.9357C30.5988 23.9979 29.9711 24 29 24H4.69088C3.57093 24 2.83802 23.9976 2.305 23.9221C1.79976 23.8505 1.65846 23.7356 1.58452 23.6488C1.51058 23.5621 1.41939 23.4045 1.42853 22.8942C1.43817 22.356 1.55161 21.6319 1.72855 20.526L4.44855 3.52603Z" stroke="currentcolor" strokeWidth="2"></path>
												<path className="letter" d="M12.257 17V6.81818H16.3337C17.0828 6.81818 17.7075 6.92921 18.208 7.15128C18.7085 7.37334 19.0847 7.68158 19.3366 8.07599C19.5885 8.46709 19.7144 8.91785 19.7144 9.42827C19.7144 9.82599 19.6349 10.1757 19.4758 10.4773C19.3167 10.7756 19.0979 11.0208 18.8195 11.2131C18.5444 11.402 18.2296 11.5362 17.8749 11.6158V11.7152C18.2627 11.7318 18.6256 11.8411 18.9637 12.0433C19.3051 12.2455 19.5818 12.5289 19.7939 12.8935C20.0061 13.2547 20.1121 13.6856 20.1121 14.1861C20.1121 14.7263 19.9779 15.2086 19.7094 15.6328C19.4443 16.0537 19.0515 16.3868 18.5312 16.6321C18.0108 16.8774 17.3695 17 16.6072 17H12.257ZM14.4097 15.2401H16.1647C16.7646 15.2401 17.2021 15.1257 17.4772 14.897C17.7523 14.665 17.8898 14.3568 17.8898 13.9723C17.8898 13.6906 17.8219 13.442 17.686 13.2266C17.5501 13.0111 17.3562 12.8421 17.1043 12.7195C16.8557 12.5968 16.5591 12.5355 16.2144 12.5355H14.4097V15.2401ZM14.4097 11.0788H16.0056C16.3006 11.0788 16.5624 11.0275 16.7911 10.9247C17.0231 10.8187 17.2054 10.6695 17.338 10.4773C17.4739 10.285 17.5418 10.0547 17.5418 9.78622C17.5418 9.41832 17.4109 9.12169 17.1491 8.89631C16.8905 8.67093 16.5226 8.55824 16.0454 8.55824H14.4097V11.0788Z" fill="currentcolor"></path>
											</svg>
										}
									</>
								}
								<div className='stepLabel relative'>
									<div className='stepNumber'>
										{activeSteps.index+1}.
									</div>
									<div className='channelIcon email'>
										<i className="fa-regular fa-envelope"></i>
									</div>
									<div className='desc'>
										<span className='labelType'>{sequenceDetails[activeSteps.index].type === "email_template" && "Email"}</span>
										<span className='descriptionType'>
											<span className='descriptionTypeLabel'>{sequenceDetails[activeSteps.index].type === "email_template" && "Send automatic email"}</span>
										</span>
									</div>
								</div>
								<div className='actions'>
									<span className='multiSenders'></span>
									<StepDropdown delay_time={sequenceDetails[activeSteps.index].delay_time} ab_testing_flag={sequenceDetails[activeSteps.index].ab_testing_flag} index={activeSteps.index} onClick={(index, key) => stepOptionClick(index, key)} />
								</div>
							</div>
						</div>
						<div className='d-flex-col editor emailEditor'>
							<div className='d-flex-col relative'>
								<div className='title relative'>
									<h4 className='d-flex-row align-items-center bold'><span>Sender for email steps <sup className="alertColor">*</sup></span> {Object.keys(teamMailAccountData).length === 0 && <Button className="rsConnectEmailProvider" onClick={() => setLoadMailAccountModal(true)}>Connect email provider</Button>}</h4>
								</div>
								<div>
									<div className='d-flex-row'>
										<MultipleSelectUserEmail onClick={(userId) => setCampaignSenderId(userId)} senderUserId={campaignDetails.sender_user_id} placeholder={"Select a sender"} />
									</div>
								</div>
							</div>
							<div className='d-flex-col flex-1'>
								<div className='d-flex-row xs align-items-center'>
									<h4 className='bold'>Email</h4>
								</div>
								<div className='textEdit rsCampaignName'>
									<div className='inputWrapper'>
										<Input type="text" className="rsTextEdit" placeholder={ activeSteps.index === 0 ? 'Subject' : "Leave the subject line field empty to use the previous email's subject line"} ref={emailSubjectInputRef} name="email_subject" value={currentSubject} onKeyDown={(e) => currentCursorPositionRef.current = e.target.selectionStart} onClick={(e) => currentCursorPositionRef.current = e.target.selectionStart} onChange={(e) => changeCurrentSubject(e.target.value, e)} />
										<Button className="btn btn-tertiary rsAddCustomVariable"  onClick={() => toggleCustomVariable()} aria-hidden="true"> <span>Add custom variable</span></Button>
										<div className='rsTextEditBorder'></div>
										{loadCustomVariable &&
											<div className="attributeContainer">
												<ul>
													{Object.keys(customVariables).map((variableKey, index) => {
														return (
															<li className={`${index === highlightVariable ? "highlight" : ""} `} onMouseEnter={() => setHighlightVariable(index)} onClick={() => enterEmailSubjectPlaceholder(`{{${customVariables[variableKey]}}}`)}><b>{variableKey}</b> {`{{${customVariables[variableKey]}}}`}</li>
														)
													})}
												</ul>
											</div>
										}
									</div>
								</div>
								<div className='emailTextEditor signatureTextEditor rsTextEditor'>
									{loadNewContent ?
										<DetailSkeleton />
									:
										<EmailEditor
											html={currentMessage ? currentMessage : "<p><br /></p>"}
											subject={currentSubject ? currentSubject : ""}
											reviewLeads={reviewLeads}
											senderUserId={campaignDetails.sender_user_id}
											onChange={(value) => updateCurrentEmailMessage(value)}
										/>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{openNewItemPopup &&
				<Modal
					size='xl'
					centered={true}
					isOpen={openNewItemPopup}
					toggle={toggleOpenNewItemPopup}
					className='sequenceMenuItemPopup'
				>
					<ModalBody>
						<Button className='btnModalClose' onClick={() => toggleOpenNewItemPopup()}>
							<i className="fa-solid fa-xmark"></i>
						</Button>
						<div className={`newStepMenu padded ${loaderApi ? "overlayLoadingWrapper" : ""}`}>
							{loaderApi &&
								<div className='loaderSpinner'>
									<Spinner size={"sm"} color='light' />
								</div>
							}
							<div className='d-flex-col align-items-center'>
								<button className={`btn rsSwitchBox rsToggleStepsConditions secondary ${stepOption !== "steps" ? "checked" : ""} `}>
									<span className="textLabel semiBold" onClick={() => setStepOption('steps')}>Steps</span>
									<span className="textLabel semiBold" onClick={() => setStepOption('conditions')} >Conditions</span>
								</button>
							</div>
							{stepOption !== 'steps' &&
								<div className='d-flex-col align-items-center bold textLight'>
									Add conditions to your sequence and create decisions branches to get the best results possible
								</div>
							}
							{stepOption === 'steps' ?
								<div className='d-flex-col'>
									<span className="subtitle">Automatic Steps</span>
									<div>
										<div className='rowTypes'>
											<div className='stepLabel relative rsAddStep' onClick={() => addNewStep('email')}>
												<div className='channelIcon email'>
													<i className="fa-regular fa-envelope"></i>
												</div>
												<div className='desc'>
													<span className='labelType'>Email</span>
													<span className='descriptionType'>
														<span className='descriptionTypeLabel'>Send automatic email</span>
													</span>
												</div>
											</div>
											<div className='stepLabel relative rsAddStep disabled'>
												<div className='channelIcon linkedinVisit linkedin'>
													<i className="fa-brands fa-linkedin"></i>
												</div>
												<div className='desc'>
													<span className='labelType'>Linkedin</span>
													<span className='descriptionType'>
														<span className='descriptionTypeLabel'>Visit profile</span>
													</span>
												</div>
											</div>
											<div className='stepLabel relative rsAddStep disabled'>
												<div className='channelIcon linkedinInvite linkedin'>
													<i className="fa-brands fa-linkedin"></i>
												</div>
												<div className='desc'>
													<span className='labelType'>Linkedin</span>
													<span className='descriptionType'>
														<span className='descriptionTypeLabel'>Invite only</span>
													</span>
												</div>
											</div>
											<div className='stepLabel relative rsAddStep disabled'>
												<div className='channelIcon linkedinInvite linkedin'>
													<i className="fa-brands fa-linkedin"></i>
												</div>
												<div className='desc'>
													<span className='labelType'>Linkedin</span>
													<span className='descriptionType'>
														<span className='descriptionTypeLabel'>Send Message</span>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className='d-flex-row marginBlockSteps'>
										<div className='d-flex-col flex-1'>
											<span className="subtitle">Manual execution</span>
											<div className='rowTypes'>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon phone'>
														<i className="fa-solid fa-phone"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Call</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'>Create a task</span>
														</span>
													</div>
												</div>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon manual'>
														<i className="fa-solid fa-list-check"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Manual task</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'>Create a task</span>
														</span>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex-col flex-1'>
											<span className="subtitle">Other steps</span>
											<div className='rowTypes'>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon api'>
														<i className="fa-solid fa-server"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>API</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'>Call an api</span>
														</span>
													</div>
												</div>
												<div className='stepLabel relative rsAddStep disabled'>
													<div className='channelIcon sendToAnotherCampaign'>
														<i className="fa-solid fa-share"></i>
													</div>
													<div className='desc'>
														<span className='labelType'>Send to another campaign</span>
														<span className='descriptionType'>
															<span className='descriptionTypeLabel'></span>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								:
								<div className='d-flex-col conditionsList'>
									<div className='d-flex-col'>
										<div className='d-flex-row'>
											<div className='d-flex-col flex-1'>
												<span className="subtitle">Lead information</span>
												<div className='rowTypes'>
													<div className='stepLabel relative rsAddStep disabled'>
														<div className='channelIcon conditional'>
															<i className="fa-solid fa-code-branch"></i>
														</div>
														<div className='desc'>
															<span className='labelType'>Has email address</span>
															<span className='descriptionType'>
																<span className='descriptionTypeLabel'></span>
															</span>
														</div>
													</div>
													<div className='stepLabel relative rsAddStep disabled'>
														<div className='channelIcon conditional'>
															<i className="fa-solid fa-code-branch"></i>
														</div>
														<div className='desc'>
															<span className='labelType'>Has Linkedin URL</span>
															<span className='descriptionType'>
																<i className="fa-brands fa-linkedin"></i>
																<span className='descriptionTypeLabel'>Linkedin</span>
															</span>
														</div>
													</div>
													<div className='stepLabel relative rsAddStep disabled'>
														<div className='channelIcon conditional'>
															<i className="fa-solid fa-code-branch"></i>
														</div>
														<div className='desc'>
															<span className='labelType'>Has phone number</span>
															<span className='descriptionType'>
																<span className='descriptionTypeLabel'></span>
															</span>
														</div>
													</div>
													<div className='stepLabel relative rsAddStep disabled'>
														<div className='channelIcon conditional'>
															<i className="fa-solid fa-code-branch"></i>
														</div>
														<div className='desc'>
															<span className='labelType'>Custom condition</span>
															<span className='descriptionType'>
																<span className='descriptionTypeLabel'></span>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='d-flex-col'>
										<span className="subtitle">Lead actions</span>
										<div className='rowTypes'>
											<div className='stepLabel relative rsAddStep disabled'>
												<div className='channelIcon conditional'>
													<i className="fa-solid fa-code-branch"></i>
												</div>
												<div className='desc'>
													<span className='labelType'>Opened email</span>
													<span className='descriptionType'>
														<span className='descriptionTypeLabel'></span>
													</span>
												</div>
											</div>
											<div className='stepLabel relative rsAddStep disabled'>
												<div className='channelIcon conditional'>
													<i className="fa-solid fa-code-branch"></i>
												</div>
												<div className='desc'>
													<span className='labelType'>Clicked on link in email</span>
													<span className='descriptionType'>
														<span className='descriptionTypeLabel'></span>
													</span>
												</div>
											</div>
											<div className='stepLabel relative rsAddStep disabled'>
												<div className='channelIcon conditional'>
													<i className="fa-solid fa-code-branch"></i>
												</div>
												<div className='desc'>
													<span className='labelType'>Unsubscribe from email</span>
													<span className='descriptionType'>
														<span className='descriptionTypeLabel'></span>
													</span>
												</div>
											</div>
											<div className='stepLabel relative rsAddStep disabled'>
												<div className='channelIcon conditional'>
													<i className="fa-solid fa-code-branch"></i>
												</div>
												<div className='desc'>
													<span className='labelType'>Accepted invite</span>
													<span className='descriptionType'>
														<i className="fa-brands fa-linkedin"></i>
														<span className='descriptionTypeLabel'>Linkedin</span>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							}
						</div>
					</ModalBody>
				</Modal>
			}

			{deleteWarningModal &&
				<Modal isOpen={deleteWarningModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation warningColor paddingRight10"></i>Warning</h2>
							<p>Do you want to delete this step?</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={deleteLoaderApi} onClick={() => toggleDeleteWarningModal()}>Cancel</Button>
							<Button className='md okWarningButton btn-warning' color='primary' disabled={deleteLoaderApi} onClick={() => deleteSequenceStep()}>
								{deleteLoaderApi &&
									<Spinner size={"sm"} />
								}
								Ok
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}

			{deleteABTestModal &&
				<Modal isOpen={deleteABTestModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation warningColor paddingRight10"></i>Warning</h2>
							<p>Do you want to delete this A/B test?</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={deleteABTestLoader} onClick={() => toggleDeleteABTestModal()}>Cancel</Button>
							<Button className='md okWarningButton btn-warning' color='primary' disabled={deleteABTestLoader} onClick={() => deleteSequenceStepVariation()}>
								{deleteABTestLoader &&
									<Spinner size={"sm"} />
								}
								Ok
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}

			{abTestModal &&
				<Modal isOpen={abTestModal} className='abTestModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-circle-info primaryColor paddingRight10"></i>Add A/B test email step</h2>
							<p>Life is made up of choices roundlister...<br />Do you want to fill message B with your current message?</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={dontFillABContentLoader || fillABContentLoader} onClick={() => toggleABTestModal()}>Do not A/B test</Button>
							<div className='d-flex gap30'>
								<Button className='md dontFillABContent' disabled={dontFillABContentLoader || fillABContentLoader} onClick={() => dontFillABContent()}>
									{dontFillABContentLoader &&
										<Spinner size={"sm"} />
									}
									Don't fill step B
								</Button>
								<Button className='md okWarningButton' color='primary' disabled={dontFillABContentLoader || fillABContentLoader} onClick={() => fillABContent()}>
									{fillABContentLoader &&
										<Spinner size={"sm"} />
									}
									Yes, fill step B
								</Button>
							</div>
						</div>
					</ModalFooter>
				</Modal>
			}
		</>
	);
}

export default SequenceEdit;