import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Auth from "./layouts/Auth";
import User from "./layouts/User";
import Campaign from "./layouts/Campaign";

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/auth/*" element={<Auth />} />
				<Route path="/user/campaign/:id/*" element={<Campaign />} />
				<Route path="/user/*" element={<User />} />				
				<Route path="*" element={<Navigate to="/auth/login" replace />} />
			</Routes>
		</BrowserRouter>
	)
}
export default App