import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap';

function CampaignsDropdown({ campaignObject, selectedCampaign, updateSelectedCampaign }) {
	const [stepMoreOption, setStepMoreOption] = useState(false),
		[totalCampaignIds, setTotalCampaignIds] = useState([]);
	useEffect(() => {
		setTotalCampaignIds(Object.keys(campaignObject))
	}, [campaignObject])

	const toggleAllCampaigns = () => {
		if (totalCampaignIds.length === selectedCampaign.length) {
			updateSelectedCampaign([])
		} else {
			updateSelectedCampaign(totalCampaignIds)
		}
	}

	const toggleCampaignIds = (campaignId) => {
		let selectedCampaignLocal = [...selectedCampaign]
		if (selectedCampaign.indexOf(campaignId) !== -1) {
			let removeIndex = selectedCampaignLocal.indexOf(campaignId);
			selectedCampaignLocal.splice(removeIndex, 1)
		} else {
			selectedCampaignLocal.push(campaignId)
		}
		updateSelectedCampaign(selectedCampaignLocal)
	}
	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => { setStepMoreOption((prevstate) => !prevstate); }} direction={"down"} className='rsDropdown'>
			<DropdownToggle tag="button" className='btn btnDropdown rsDropdownToggle'>
				<div className='dropdownValue maxWidth200 d-block'>
					{selectedCampaign.length === 0 ?
						<>Choose Users</>
					: totalCampaignIds.length === selectedCampaign.length ?
						<>All</>
					:
						<>
							{selectedCampaign.map((campaignId, index) => {
								return(
									<React.Fragment key={index}>
										{index !== 0 && <>, </>}
										{campaignObject[campaignId] &&
											<>{campaignObject[campaignId].name}</>
										}
									</React.Fragment>
								)
							})}
						</>
					}
				</div>
			</DropdownToggle>
			<DropdownMenu className='dropdownPicker'>
				<div className='uiDropdownItem rsSelectItem'>
					<div className="checkbox rsCheckbox">
						<Label className="js-prevent-label-bounce-event-click">
							<Input type='checkbox' checked={totalCampaignIds.length === selectedCampaign.length} onChange={() => toggleAllCampaigns()} />
						</Label>
					</div>
					All
				</div>
				{totalCampaignIds.map((campaignId, index) => {
					return (
						<div className='uiDropdownItem rsSelectItem' key={index}>
							<div className="checkbox rsCheckbox">
								<Label className="js-prevent-label-bounce-event-click">
									<Input type='checkbox' checked={selectedCampaign.indexOf(campaignId) !== -1} onChange={() => toggleCampaignIds(campaignId) } />
								</Label>
							</div>
							{campaignObject[campaignId].name}
						</div>
					)
				})}
			</DropdownMenu>
		</Dropdown>
	);
}

export default CampaignsDropdown;