import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Col, Row } from 'reactstrap';

const DetailSkeleton = (props) => {
	return (
		<React.Fragment>
			<SkeletonTheme baseColor="#f7fafc" highlightColor="#eff1ff">				
				<Row className="my-3 mx-0">
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={50} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={50} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={50} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={50} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
				<Row className='mx-0 mb-4'>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
					<Col className={"my-2 table-head-load"}>
						<Skeleton height={20} />
					</Col>
				</Row>
			</SkeletonTheme>
		</React.Fragment>
	);
}

export default DetailSkeleton;