import moment from 'moment';
import React, { useState } from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

function TimeStampToolTip({children, createdAt, innerClass}) {
	// const [tooltipOpen, setToolTipOpen] = useState(false)

	// const toggleToolTip = () => {
	// 	setToolTipOpen(!tooltipOpen)
	// }

	return (
		<Tooltip
			html={(
				<>
					<table>
						<thead>
							<tr>
								<th>UTC</th>
								<td>{moment.utc(createdAt).format('h:mm A MMMM DD, YYYY')}</td>
							</tr>
							<tr>
								<th>{new Date().toString().match(/\(([^\)]+)\)$/)[1].match(/\b(\w)/g)} • Your local time</th>
								<td>{moment(createdAt).format('h:mm A MMMM DD, YYYY')}</td>
							</tr>
						</thead>
					</table>
				</>	
			)}
			position="top"
			trigger="mouseenter"
			interactive
			className={innerClass}
			arrow
			size={"big"}
			style={{}}
		>
			{children}
		</Tooltip>
	);
}

export default TimeStampToolTip;