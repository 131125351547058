import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import RSToolTip from '../../../components/RSToolTip';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table, Label } from 'reactstrap';
import { ProductContext } from '../../../store';
import { PostData } from '../../../services/FetchData';
import { toast } from 'react-toastify';
import TimeAgo from 'timeago-react';
import DetailSkeleton from '../../../components/Skeleton/DetailSkeleton';
import Papa from 'papaparse';
import moment from 'moment';
import TimeStampToolTip from '../../../components/TimeStampToolTip';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReviewLeadSkeleton from '../../../components/Skeleton/ReviewLeadSkeleton';
import SmallSkeleton from '../../../components/Skeleton/SmallSkeleton';
import LeadScheduleToolTip from '../../../components/LeadScheduleToolTip';
import ViewLeadDropdown from '../../../components/ViewLeadDropdown';

function Campaign(props) {
	const { id, 
			//'*': wildcard 
		} = useParams(),
		[openRightModal, setOpenRightModal]	= useState(false),
		[rightModalContent, setRightModalContent]	= useState(""),
		[rightModalArray, setRightModalArray]	= useState([]),
		[viewLeadId, setViewLeadId]	= useState(null),
		//[searchText, setSearchText]	= useState(""),
		[campaignDetail, setCampaignDetail]	= useState({}),
		[sequences, setSequences]	= useState([]),
		[reportDetails, setReportDetails] = useState([]),
		[campaignRedirect, setCampaignRedirect]	= useState(false),
		[unSentLeadCount, setUnSentLeadCount]	= useState({}),
		[reportsLoader, setReportsLoader]	= useState(true),
		[currentTab, setCurrentTab]	= useState("All"),
		[emailModal, setEmailModal]	= useState(false),
		[modalEmailDetail, setModalEmailDetail]	= useState({}),
		[exportLoaderApi, setExportLoaderApi]	= useState(false),
		[hotLeads, setHotLeads]	= useState([]),
		[campaignStats, setCampaignStats]	= useState({}),
		[activityPage, setActivityPage]	= useState(1),
		[activityHasMore, setActivityHasMore]	= useState(true),
		[hotLeadPage, setHotLeadPage]	= useState(1),
		[hotLeadHasMore, setHotLeadHasMore]	= useState(true),
		[rightModalActivityPage, setRightModalActivityPage]	= useState(1),
		[rightModalActivityHasMore, setRightModalActivityHasMore]	= useState(true),
		[viewHotLeadModal, setViewHotLeadModal]	= useState(false),
		[viewHotLeadLoader, setViewHotLeadLoader]	= useState(false),
		[viewHotLead, setViewHotLead]	= useState([]),
		[viewHotLeadEmail, setViewHotLeadEmail]	= useState(null),
		[viewLead, setViewLead] = useState(null),
		[viewLeadOriginal, setViewLeadOriginal]	= useState(null),
		[viewLeadStatusLoaderApi, setViewLeadStatusLoaderApi]	= useState(false),
		[viewLeadTabSelected, setViewLeadTabSelected]	= useState('infos'),
		[editFieldKey, setEditFieldKey]	= useState(''),
		[viewLeadActivities, setViewLeadActivities]	= useState([]),
		[leadOtherCampaigns, setLeadOtherCampaigns]	= useState([]),
		[leadActivityLoader, setLeadActivityLoader]	= useState(false),
		[leadNotes, setLeadNotes]	= useState([]),
		[leadNote, setLeadNote]	= useState(""),
		[leadNotesLoaderApi, setLeadNotesLoaderApi]	= useState(false),
		[leadSentSchedules, setLeadSentSchedules]	= useState([]),
		[leadCurrentSchedule, setLeadCurrentSchedule]	= useState({}),
		[leadAwaitingSchedule, setLeadAwaitingSchedule]	= useState([]),
		[leadUnsubscribeModal, setLeadUnsubscribeModal]	= useState(false),
		[unsubscribeLeadLoaderApi, setUnsubscribeLeadLoaderApi]	= useState(false),
		[deleteWarningModal, setDeleteWarningModal] = useState(false),
		[viewLeadLoader, setViewLeadLoader]	= useState(false),
		[leadDetailSingleLoader, setLeadDetailSingleLoader]	= useState(false),
		[deleteLoaderApi, setDeleteLoaderApi]	= useState(false),
		[scrollPosition, setScrollPosition] = useState(0),
		[previousDataModal, setPreviousDataModal]	= useState(null),
		divScrollRef	= useRef(null),
		{
			currentTeamId,
			teamUserData
		}	= useContext(ProductContext)

	useEffect(() => {
		getCampaignReports()
		getAllActivities(1)
	}, [])

	useEffect(() => {
		if( viewLeadId ){
			getLeadDetails()
		}
	}, [viewLeadId])

	useEffect(() => {
		if( viewLeadOriginal){
			if(leadDetailSingleLoader ){
				getLeadActivities()
				getLeadNotes()
				getLeadSchedule()
				getLeadOtherCampaigns()
				setLeadDetailSingleLoader(false)
			}
		}
	}, [viewLeadOriginal, leadDetailSingleLoader])

	useEffect(() => {
		// Attach the scroll event listener to the div
		if (divScrollRef.current) {
			divScrollRef.current.addEventListener('scroll', handleScroll);
		}

		// Clean up the event listener when the component unmounts
		return () => {
			if (divScrollRef.current) {
				divScrollRef.current.removeEventListener('scroll', handleScroll);
			}
		}
	}, [openRightModal])

	const handleScroll = () => {
		if (divScrollRef.current) {
			setScrollPosition(divScrollRef.current.scrollTop)
		}
	}

	const toggleStatRightModal = () => {
		setRightModalActivityPage(1)
		setRightModalArray([])
		setHotLeads([])
		setRightModalActivityHasMore(true)
		setHotLeadPage(1)
		setHotLeadHasMore(true)
		setCurrentTab("All")
		setOpenRightModal(false)
		setRightModalContent(null)
		setViewLead(null)
		setViewLeadActivities([])
		setEditFieldKey(null)
		setViewLeadTabSelected("infos")
		setLeadNote("")
		setLeadNotesLoaderApi(false)
		setLeadDetailSingleLoader(false)
		setViewLeadId(null)
		setViewLeadOriginal(null)
		setViewLead(null)
	}

	const getCampaignReports = () => {
		let postData	= {campaign_id: id, team_id: currentTeamId}
		PostData("ms1", "api/v1/report/campaign", postData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					// let localSequences				= result.data.sequences,
					// 	localSequenceSchedule		= result.data.sequenceSchedule,
					// 	localSentDetails				= [],
					// 	localOpenedDetails			= [],
					// 	localClickedDetails			= [],
					// 	localRepliedDetails			= [],
					// 	localUniqueSent				= {},
					// 	localUniqueOpened				= {},
					// 	localUniqueClicked			= {},
					// 	localUniqueReplied			= {},
					// 	localSequenceStepsDetail	= {},
					// 	localUnSentLeadCount			= {},
					// 	hotLeadsOpenLocal				= {},
					// 	hotLeadsRepliedLocal			= {},
					// 	hotLeadsLinkClickedLocal	= {},
					// 	localReportDetails			= result.data.campaignReport;
					// for( let i = 0; i < localSequenceSchedule.length; i++ ){
					// 	let currentSequenceSchedule	= localSequenceSchedule[i];
					// 	if( typeof localUnSentLeadCount["Step"+currentSequenceSchedule.step] === "undefined" ){
					// 		localUnSentLeadCount["Step"+currentSequenceSchedule.step]	= 0
					// 	}
					// 	if( currentSequenceSchedule.done === false ){							
					// 		localUnSentLeadCount["Step"+currentSequenceSchedule.step]	+= 1
					// 	}
					// }
					// setUnSentLeadCount(localUnSentLeadCount)
					// for( let i = 0; i < localReportDetails.length; i++ ){
					// 	let currentReportDetail	= localReportDetails[i];
					// 	if( currentReportDetail.type === "Email sent" ){
					// 		localSentDetails.push(currentReportDetail);
					// 		if( typeof localUniqueSent[currentReportDetail.sequence_schedule_id] === "undefined" ){
					// 			localUniqueSent[currentReportDetail.sequence_schedule_id]	= 1
					// 			let seqStepIndex	= "Step"+currentReportDetail.step
					// 			if( typeof localSequenceStepsDetail[seqStepIndex] === "undefined" ){
					// 				localSequenceStepsDetail[seqStepIndex]	= {}
					// 			}
					// 			if( typeof localSequenceStepsDetail[seqStepIndex]["email_sent"] === "undefined" ){
					// 				localSequenceStepsDetail[seqStepIndex]["email_sent"]	= 0
					// 			}
					// 			localSequenceStepsDetail[seqStepIndex]["email_sent"]	+= 1
					// 		}
					// 	}
					// 	if( currentReportDetail.type === "Email opened" ){
					// 		localOpenedDetails.push(currentReportDetail);
					// 		if( typeof hotLeadsOpenLocal[currentReportDetail.lead_id] === "undefined" ){
					// 			hotLeadsOpenLocal[currentReportDetail.lead_id]	= {email: currentReportDetail.to_email, lead_id: currentReportDetail.lead_id, type: currentReportDetail.type, count: 1, steps:{}}
					// 		}else{
					// 			hotLeadsOpenLocal[currentReportDetail.lead_id]["count"]	+= 1;
					// 		}
					// 		if( typeof hotLeadsOpenLocal[currentReportDetail.lead_id].steps["Step"+(currentReportDetail.step+1)] === "undefined" ){
					// 			hotLeadsOpenLocal[currentReportDetail.lead_id].steps["Step"+(currentReportDetail.step+1)]	= 1
					// 		}else{
					// 			hotLeadsOpenLocal[currentReportDetail.lead_id].steps["Step"+(currentReportDetail.step+1)]	+= 1
					// 		}
					// 		if( typeof localUniqueOpened[currentReportDetail.sequence_schedule_id] === "undefined" ){
					// 			localUniqueOpened[currentReportDetail.sequence_schedule_id]	= 1
					// 			let seqStepIndex	= "Step"+currentReportDetail.step
					// 			if( typeof localSequenceStepsDetail[seqStepIndex] === "undefined" ){
					// 				localSequenceStepsDetail[seqStepIndex]	= {}
					// 			}
					// 			if( typeof localSequenceStepsDetail[seqStepIndex]["email_opened"] === "undefined" ){
					// 				localSequenceStepsDetail[seqStepIndex]["email_opened"]	= 0
					// 			}
					// 			localSequenceStepsDetail[seqStepIndex]["email_opened"]	+= 1
					// 		}
					// 	}
					// 	if( currentReportDetail.type === "Link clicked" ){
					// 		localClickedDetails.push(currentReportDetail);
					// 		if( typeof hotLeadsLinkClickedLocal[currentReportDetail.lead_id] === "undefined" ){
					// 			hotLeadsLinkClickedLocal[currentReportDetail.lead_id]	= {email: currentReportDetail.to_email, lead_id: currentReportDetail.lead_id, type: currentReportDetail.type, count: 1, steps: {}}
					// 		}else{
					// 			hotLeadsLinkClickedLocal[currentReportDetail.lead_id]["count"]	+= 1;
					// 		}
					// 		if( typeof hotLeadsLinkClickedLocal[currentReportDetail.lead_id].steps["Step"+(currentReportDetail.step+1)] === "undefined" ){
					// 			hotLeadsLinkClickedLocal[currentReportDetail.lead_id].steps["Step"+(currentReportDetail.step+1)]	= 1
					// 		}else{
					// 			hotLeadsLinkClickedLocal[currentReportDetail.lead_id].steps["Step"+(currentReportDetail.step+1)]	+= 1
					// 		}
					// 		if( typeof localUniqueClicked[currentReportDetail.sequence_schedule_id] === "undefined" ){
					// 			localUniqueClicked[currentReportDetail.sequence_schedule_id]	= 1
					// 			let seqStepIndex	= "Step"+currentReportDetail.step
					// 			if( typeof localSequenceStepsDetail[seqStepIndex] === "undefined" ){
					// 				localSequenceStepsDetail[seqStepIndex]	= {}
					// 			}
					// 			if( typeof localSequenceStepsDetail[seqStepIndex]["link_clicked"] === "undefined" ){
					// 				localSequenceStepsDetail[seqStepIndex]["link_clicked"]	= 0
					// 			}
					// 			localSequenceStepsDetail[seqStepIndex]["link_clicked"]	+= 1
					// 		}
					// 	}
					// 	if( currentReportDetail.type === "Email replied" ){
					// 		localRepliedDetails.push(currentReportDetail);
					// 		if( typeof hotLeadsRepliedLocal[currentReportDetail.lead_id] === "undefined" ){
					// 			hotLeadsRepliedLocal[currentReportDetail.lead_id]	= {email: currentReportDetail.to_email, lead_id: currentReportDetail.lead_id, type: currentReportDetail.type, count: 1, steps:{}}
					// 		}else{
					// 			hotLeadsRepliedLocal[currentReportDetail.lead_id]["count"]	+= 1;
					// 		}
					// 		if( typeof hotLeadsRepliedLocal[currentReportDetail.lead_id].steps["Step"+(currentReportDetail.step+1)] === "undefined" ){
					// 			hotLeadsRepliedLocal[currentReportDetail.lead_id].steps["Step"+(currentReportDetail.step+1)]	= 1
					// 		}else{
					// 			hotLeadsRepliedLocal[currentReportDetail.lead_id].steps["Step"+(currentReportDetail.step+1)]	+= 1
					// 		}
					// 		if( typeof localUniqueReplied[currentReportDetail.sequence_schedule_id] === "undefined" ){
					// 			localUniqueReplied[currentReportDetail.sequence_schedule_id]	= 1
					// 			let seqStepIndex	= "Step"+currentReportDetail.step
					// 			if( typeof localSequenceStepsDetail[seqStepIndex] === "undefined" ){
					// 				localSequenceStepsDetail[seqStepIndex]	= {}
					// 			}
					// 			if( typeof localSequenceStepsDetail[seqStepIndex]["email_replied"] === "undefined" ){
					// 				localSequenceStepsDetail[seqStepIndex]["email_replied"]	= 0
					// 			}
					// 			localSequenceStepsDetail[seqStepIndex]["email_replied"]	+= 1
					// 		}
					// 	}
					// }
					// hotLeadsOpenLocal	= Object.values(hotLeadsOpenLocal)
					// hotLeadsLinkClickedLocal	= Object.values(hotLeadsLinkClickedLocal)
					// hotLeadsRepliedLocal	= Object.values(hotLeadsRepliedLocal)
					// hotLeadsOpenLocal.sort((a, b) => b.count - a.count)
					// hotLeadsLinkClickedLocal.sort((a, b) => b.count - a.count)
					// hotLeadsRepliedLocal.sort((a, b) => b.count - a.count)
					// setHotLeadsOpen(hotLeadsOpenLocal)
					// setHotLeadsLinkClicked(hotLeadsLinkClickedLocal)
					// setHotLeadsReplied(hotLeadsRepliedLocal)
					// setUniqueSent(Object.keys(localUniqueSent).length)
					// setUniqueClicked(Object.keys(localUniqueClicked).length)
					// setUniqueOpened(Object.keys(localUniqueOpened).length)
					// setUniqueReplied(Object.keys(localUniqueReplied).length)
					// setOpenDetails(localOpenedDetails)
					// setSentDetails(localSentDetails)
					// setClickDetails(localClickedDetails)
					// setRepliedDetails(localRepliedDetails)
					// setSequences(localSequences)
					// setCampaignDetail(result.data.campaign)
					// setReportDetails(result.data.campaignReport)
					// setStepSequenceDetails(localSequenceStepsDetail)
					// setLeadsObject(result.data.leadsObj)
					setUnSentLeadCount(result.data.unsentStats)
					setCampaignStats(result.data.campaignStats)
					setSequences(result.data.sequences)
					setCampaignDetail(result.data.campaign)
					setReportsLoader(false)
				}else if( result.status === "failure" ){
					toast.error(result.message)
					if( typeof result.data.no_campaign !== "undefined" ){
						setCampaignRedirect(true)
					}
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const getAllActivities	= (page = null) =>  {
		if( page === null ){
			page = activityPage
		}
		let postData	= {campaign_id: id, team_id: currentTeamId, page: page, type: "all"}
		PostData("ms1", "api/v1/report/get-activity", postData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					if( activityPage !== 1 && result.data.activity.length < 20  ){
						setActivityHasMore(false)
					}else if( activityPage === 1 && result.data.activity.length < 40 ) {
						setActivityHasMore(false)
					}
					let reportDetailsLocal	= [...result.data.activity]
					if( page !== 1 ){
						reportDetailsLocal	= [
															...reportDetails,
															...result.data.activity
														]
					}
					setReportDetails(reportDetailsLocal)
					if( result.data.activity.length > 0 ){
						setActivityPage((prevState) => prevState + 1)
					}
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const getAllTypeActivities	= (type, page = null) =>  {
		if( page === null ){
			page	= rightModalActivityPage
		}
		console.log(rightModalActivityPage)
		let postData	= {campaign_id: id, team_id: currentTeamId, page, type}
		PostData("ms1", "api/v1/report/get-activity", postData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					if( rightModalActivityPage !== 1 && result.data.activity.length < 20  ){
						setRightModalActivityHasMore(false)
					}else if( rightModalActivityPage === 1 && result.data.activity.length < 40 ) {
						setRightModalActivityHasMore(false)
					}
					let intermediateArray	= [...rightModalArray]
					if( page === 1 ){
						intermediateArray	= []
					}
					let rightModalArrayLocal	= [
															...intermediateArray,
															...result.data.activity
														]
					setRightModalArray(rightModalArrayLocal)
					if( result.data.activity.length > 0 ){
						setRightModalActivityPage((prevState) => prevState + 1)
					}
				}else if( result.status === "failure" ){
					//toast.error(result.message)
				}else{
					//toast.error(result.message.message)
				}
			}
		})
	}

	const getAllHotLead	= (type, page = null) =>  {
		if( page === null ){
			page	= hotLeadPage
		}
		let postData	= {campaign_id: id, team_id: currentTeamId, page, type}
		PostData("ms1", "api/v1/report/get-hot-leads", postData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					if( hotLeadPage !== 1 && result.data.hotLeads.length < 20  ){
						setHotLeadHasMore(false)
					}else if( hotLeadPage === 1 && result.data.hotLeads.length < 40 ) {
						setHotLeadHasMore(false)
					}
					let intermediateArray	= [...hotLeads]
					if( page === 1 ){
						intermediateArray	= []
					}
					let hotLeadLocal	= [
												...intermediateArray,
												...result.data.hotLeads
											]
					setHotLeads(hotLeadLocal)
					if( result.data.hotLeads.length > 0 ){
						setHotLeadPage((prevState) => prevState + 1)
					}
				}else if( result.status === "failure" ){
					//toast.error(result.message)
				}else{
					//toast.error(result.message.message)
				}
			}
		})
	}

	const viewHotLeadFunc = (type, hotLead) => {
		setViewHotLeadModal(true)
		setViewHotLeadLoader(true)
		setViewHotLeadEmail(hotLead.to_email)
		let data	= {campaign_id: id, team_id: parseInt(currentTeamId), type, lead_id: hotLead.lead_id}
		PostData("ms1", "api/v1/report/view-hot-lead", data).then( result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setViewHotLead(result.data.hotLead)
				}
			}
			setViewHotLeadLoader(false)
		})
	}

	const loadRightModal	= (type)	=> {		
		// setRightModalActivityPage(1)
		// setRightModalActivityHasMore(true)
		// setRightModalArray([])
		// setHotLeads([])
		toggleStatRightModal()
		setTimeout(() => {
			setCurrentTab("All")
			setOpenRightModal(true)
			setRightModalContent(type)
			getAllTypeActivities(type, 1)
			if( type === "Email opened" || type === "Email replied" || type === "Link clicked" ){
				getAllHotLead(type, 1)
			}
		}, 500);
	}

	const toggleEmailModal	= () => {
		setEmailModal(false)
		setModalEmailDetail({})
	}

	const toggleViewHotLeadModal = () => {
		setViewHotLead([])
		setViewHotLeadLoader(false)
		setViewHotLeadModal(false)
		setViewHotLeadEmail(null)
	}

	const loadParticularEmail	= (index, type) => {
		if( type === "activities" ){
			setModalEmailDetail(reportDetails[index])
		}else if( type === "email_sent" ){
			setModalEmailDetail(rightModalArray[index])
		}else if( type === "email_replied" ){
			setModalEmailDetail(rightModalArray[index])
		}else if( type === "viewLeadActivities" ){
			setModalEmailDetail(viewLeadActivities[index])
		}
		setEmailModal(true)
	}

	const downloadExport	= () => {
		setExportLoaderApi(true)
		PostData("ms1", "api/v1/campaign/export", {team_id: currentTeamId, campaign_id: id}).then( result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					// Create CSV content
					const csvContent = Papa.unparse(result.data.csvData);

					// Create a Blob and generate a URL for it
					const blob = new Blob([csvContent], { type: 'text/csv' });
					const url = URL.createObjectURL(blob);
			
					// Create a temporary link and trigger a click to download the file
					const a = document.createElement('a');
					a.href = url;
					a.download = result.data.campaign.name.replace(/ /g, '_')+"_"+moment().format("MM-DD-YYYY-HH-mm")+'.csv';
					document.body.appendChild(a);
					a.click();
					// Cleanup
					document.body.removeChild(a);
					URL.revokeObjectURL(url);
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setExportLoaderApi(false)
		})
	}

	const downloadHotLeadExport = () => {
		setExportLoaderApi(true)
		let fileName				= campaignDetail.name.replace(/ /g, '_')+"_hot_lead_"+moment().format("MM-DD-YYYY-HH-mm")
		// 	exportLeadHead			= ["email", "firstName", "lastName", "picture", "phone", "linkedinUrl", "companyName", "companyDomain"];
		// if( rightModalContent === "emailOpened" ){
		// 	fileName	+= "-email_opened.csv"
		// }else if( rightModalContent === "linkClicked" ){
		// 	fileName	+= "-link_clicked.csv"
		// }else if( rightModalContent === "emailReplied" ){
		// 	fileName	+= "-email_replied.csv"
		// }
		// let csvData	= []
		// for( let i = 0; i < hotLeads.length; i++ ){
		// 	let currentHotLead	= hotLeads[i],
		// 		csvDataObject		= {},
		// 		currentLead			= leadsObject ? leadsObject[currentHotLead.lead_id] : {};
		// 	for( let j = 0; j < exportLeadHead.length; j++ ){
		// 		let currentHead	= exportLeadHead[j]
		// 		csvDataObject[currentHead]	= currentLead[currentHead] ? currentLead[currentHead] : "" 
		// 	}
		// 	if( rightModalContent === "emailOpened" ){
		// 		csvDataObject["Total Opened"]	= currentHotLead.count
		// 	}else if( rightModalContent === "linkClicked" ){
		// 		csvDataObject["Total Link Clicked"]	= currentHotLead.count
		// 	}else if( rightModalContent === "emailReplied" ){
		// 		csvDataObject["Total Email Replied"]	= currentHotLead.count
		// 	}
		// 	for( let j = 0; j < sequences.length; j++ ){
		// 		let currentStepNo	= "Step"+(j+1)
		// 		csvDataObject["Step "+(j+1)]	= ( currentHotLead.steps && currentHotLead.steps[currentStepNo] ) ? currentHotLead.steps[currentStepNo] : 0 
		// 	}
		// 	if( csvData.length === 0 ){
		// 		csvData.push(Object.keys(csvDataObject))
		// 	}
		// 	csvData.push(Object.values(csvDataObject))
		// }
		PostData("ms1", "api/v1/report/export-hot-leads", {team_id: currentTeamId, campaign_id: id, type: rightModalContent}).then( result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					if( result.data.csvData.length > 0 ){
						const csvContent = Papa.unparse(result.data.csvData);
						// Create a Blob and generate a URL for it
						const blob = new Blob([csvContent], { type: 'text/csv' });
						const url = URL.createObjectURL(blob);

						// Create a temporary link and trigger a click to download the file
						const a = document.createElement('a');
						a.href = url;
						a.download = fileName;
						document.body.appendChild(a);
						a.click();
						// Cleanup
						document.body.removeChild(a);
						URL.revokeObjectURL(url);
					}else{
						toast.error("No hot leads found")
					}
				}
			}
			setExportLoaderApi(false)
		})
	}

	const viewParticularLead	= (lead_id, previousOpenModal) => {
		setViewLeadId(lead_id)
		setRightModalContent("viewLead")
		setViewLeadLoader(true)
		setOpenRightModal(true)
		setLeadDetailSingleLoader(true)
		if( previousOpenModal ){
			setPreviousDataModal(previousOpenModal)
		}
	}

	const viewLeadClickEvent = (funcName) => {
		switch(funcName){
			case "unsubscribeLead":
				setLeadUnsubscribeModal(true)
				break;
			case "deleteLead":
				toggleLeadDeleteModal()
				break;
		}
	}

	const getLeadDetails = () => {
		PostData("ms1", "api/v1/campaign/get-lead", {lead_id: viewLeadId, campaign_id: id, team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					if( result.data.lead ){
						setViewLead(result.data.lead)
						setViewLeadOriginal(result.data.lead)
					}else{
						toggleStatRightModal()
					}
				}else if( result.status === "failure" ){
					toast.error(result.message)
					toggleStatRightModal()
				}else{
					toast.error(result.message.message)
					toggleStatRightModal()
				}
			}
			setViewLeadLoader(false)
		})
	}

	const getLeadActivities = () => {
		setLeadActivityLoader(true)
		PostData("ms1", "api/v1/campaign/lead-activities", {lead_id: viewLeadOriginal._id, campaign_id: id, team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setViewLeadActivities(result.data.activities)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setLeadActivityLoader(false)
		})
	}

	const getLeadNotes = () => {
		PostData("ms1", "api/v1/campaign/lead-notes", {lead_id: viewLeadOriginal._id, campaign_id: id, team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setLeadNotes(result.data.lead_notes)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const getLeadOtherCampaigns = () => {
		PostData("ms1", "api/v1/campaign/find-a-lead-other-campaign", {email: viewLeadOriginal.email, campaign_id: id, team_id: currentTeamId}).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					let leadOtherCampaignsLocal	= [],
						localResult	= result.data.campaignNames
					for( let i = 0; i < localResult.length; i++ ){
						leadOtherCampaignsLocal.push(localResult[i].name); 
					}
					setLeadOtherCampaigns(leadOtherCampaignsLocal)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const getLeadSchedule = () => {
		if( viewLeadOriginal.status === "Running" || viewLeadOriginal.status === "Paused" ){
			PostData("ms1", "api/v1/campaign/lead-schedules", {lead_id: viewLeadOriginal._id, campaign_id: id, team_id: currentTeamId}).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						let localResult	= result.data.leadSchedules,
							noEmailSent	= true,
							leadSentSchedulesLocal	= [],
							leadCurrentScheduleLocal	= {},
							leadAwaitingScheduleLocal	= [];
						for( let i = 0; i < localResult.length; i++ ){
							if( typeof localResult[i].done !== "undefined" && localResult[i].done ){
								leadSentSchedulesLocal.push(localResult[i])
								noEmailSent	= false
							}else if( typeof localResult[i].done !== "undefined" && !localResult[i].done && ( typeof localResult[i].schedule_date !== "undefined" || noEmailSent ) ){
								leadCurrentScheduleLocal	= localResult[i]
								noEmailSent	= false
							}else if( typeof localResult[i].done !== "undefined" && !localResult[i].done && typeof localResult[i].schedule_date === "undefined" ){
								leadAwaitingScheduleLocal.push(localResult[i])
							}
						}
						setLeadSentSchedules(leadSentSchedulesLocal)
						setLeadCurrentSchedule(leadCurrentScheduleLocal)
						setLeadAwaitingSchedule(leadAwaitingScheduleLocal)
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
			})
		}
	}

	const setEditFieldKeyFunc	= (key) => {
		if( key === "email" && viewLead.status !== "To review" ){
			return
		}
		setEditFieldKey(key)
	}

	const changeViewLeadValue = (value, key) => {
		setViewLead({
			...viewLead,
			[key]: value
		})
	}

	const updateViewLeadValue = () => {
		let updateLeadKey		= editFieldKey,
			updateLeadValue	=	viewLead[editFieldKey] ? viewLead[editFieldKey] : ""
		// if( updateLeadKey === "email" ){
		// 	for( let i = 0; i < leads.length; i++ ){
		// 		if( viewLeadOriginal["_id"] !== viewLead["_id"] && viewLeadOriginal["email"] === viewLead["email"] ){
		// 			setViewLead({
		// 				...viewLead,
		// 				[updateLeadKey]: leads[viewLeadIndex]["email"]
		// 			})
		// 			setEditFieldKey(null)
		// 			return
		// 		}
		// 	}
		// }
		if( viewLeadOriginal[editFieldKey] !== updateLeadValue ){
			PostData("ms1", "api/v1/campaign/update-lead", {key: updateLeadKey, value: updateLeadValue, lead_id: viewLead._id, campaign_id: id, team_id: currentTeamId}).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						setViewLeadOriginal(viewLead)
						toast.success("Lead updated")
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
			})
		}
		setEditFieldKey(null)
	}

	const toggleLeadDeleteModal = () => {
		setDeleteWarningModal(prevState => !prevState)
	}

	const addLeadNotes	= () => {
		setLeadNotesLoaderApi(true)
		let data	= {campaign_id: id, team_id: currentTeamId, notes: leadNote, lead_id: viewLead._id}
		PostData("ms1", "api/v1/campaign/add-lead-notes", data).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setLeadNotes([
						result.data.notes,
						...leadNotes
					])
					setLeadNote("")
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setLeadNotesLoaderApi(false)
		})
	}

	const toggleLeadUnsubscribeModal = () => {
		setLeadUnsubscribeModal((prevState) => !prevState)
	}

	const unsubscribeLead = () => {
		setUnsubscribeLeadLoaderApi(true)
		let data	= {campaign_id: id, team_id: currentTeamId, lead_id: viewLead._id}
		PostData("ms1", "api/v1/campaign/unsubscribe-lead", data).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setViewLead({
						...viewLead,
						status: "Unsubscribed"
					})
					setViewLeadOriginal({
						...viewLead,
						status: "Unsubscribed"
					})
					toast.success("lead has been added to unsubscribe list")
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			toggleLeadUnsubscribeModal()
			setUnsubscribeLeadLoaderApi(false)
		})
	}

	const pauseLead = () => {
		if( viewLeadOriginal.status === "Running" || viewLeadOriginal.status === "Paused" ){
			setViewLeadStatusLoaderApi(true)
			let data	= {campaign_id: id, team_id: currentTeamId, lead_id: viewLeadOriginal._id}
			PostData("ms1", "api/v1/campaign/pause-lead", data).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						let status	= "Paused"
						if( viewLeadOriginal["status"] === "Paused" ){
							status	= "Running"
						}
						setViewLead({
							...viewLead,
							status
						})
						setViewLeadOriginal({
							...viewLead,
							status
						})
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
				setViewLeadStatusLoaderApi(false)
			})
		}
	}

	const deleteLead = () => {
		setDeleteLoaderApi(true)
		let deleteLeadData = { campaign_id: id, team_id: currentTeamId, lead_id: viewLeadOriginal._id }
		PostData("ms1", "api/v1/campaign/delete-lead", deleteLeadData).then(result => {
			if (result !== "Invalid" && result !== undefined) {
				if (result.status === "success") {
					toast.success(result.message)
					getCampaignReports()
					getAllActivities(1)
					toggleStatRightModal()
				} else if (result.status === "failure") {
					toast.error(result.message)
				} else {
					toast.error(result.message.message)
				}
				toggleLeadDeleteModal()
				setDeleteLoaderApi(false)
			}
		})
	}

	const goToLeadStat = () => {
		setRightModalContent(previousDataModal)
		setLeadNote("")
		setLeadNotesLoaderApi(false)
		setLeadDetailSingleLoader(false)
		setViewLeadId(null)
		setViewLeadOriginal(null)
		setViewLead(null)
		setTimeout(() => {
			if( divScrollRef.current ){
				divScrollRef.current.scrollTop = parseInt(scrollPosition, 10) + 40
			}
		}, 500)
	}

	if( campaignRedirect ){
		return <Navigate to={"/user/campaigns"} replace />
	}
	
	return (
		<>
			<div className='contentBottomWrapper paperBackground'>
				{reportsLoader ?
					<DetailSkeleton />
				:
					<>
						<div className='contentBottomTopWrapper'>
							<div className='d-flex-row align-items-center justify-content-between'>
								<div className='title'>
									<div className='d-flex-col lg'>
										<div className='d-flex-row'>
											<h1 className='bold'>Campaigns reports</h1>
										</div>
									</div>
								</div>
								<div className='exportButtonWrapper'>
									<Button className='exportButton' disabled={exportLoaderApi} onClick={() => downloadExport()}>
										{exportLoaderApi &&
											<Spinner className='white' size={"sm"} />
										}
										<i className='fa fa-download'></i> Export
									</Button>
								</div>
							</div>
						</div>
						<div className='contentBottomMainContainer'>
							<div className='contentBottomMainCenter'>
								<div className='d-flex-col reports'>
									<div>
										<div className='stats statsDetailed positive'>
											<div className='statColumn'>
												<div className='statLine emailChannel'>
													<div className='channelIcon email sm pointer rsFilterChannel statHead'>
														<i className="fa-regular fa-envelope"></i>
													</div>
												</div>
											</div>
											<div className='statColumn'>
												<div className='statLine emailChannel'>
													<RSToolTip item={{ placement: 'bottom', text: `Number of leads you contacted <br />click to show related activities` }} id="statCellEmailSent">
														<div className='colorSent statCell' onClick={() => loadRightModal("Email sent")}>
															<ul className='statMetrics'>
																<li className='rsSelectType metric'>
																	<span className="statMetricsPercent sent bg">{campaignStats.Total["Email sent"]}</span>
																	<span className="statMetricsValue sent color500 bg100">
																		<img src={require("./../../../assets/images/sent.svg").default} className='img-fluid' alt='Sent' />
																		Sent
																	</span>
																</li>
															</ul>
														</div>
													</RSToolTip>
													<RSToolTip item={{ placement: 'bottom', text: `= Opened / Sent <br />click to show related activities` }} id="statCellEmailOpened">
														<div className='colorOpened statCell' onClick={() => loadRightModal("Email opened")}>
															<ul className='statMetrics'>
																<li className='rsSelectType metric'>															
																	<span className="statMetricsPercent opened bg">{campaignStats.Total["Email sent"] ? parseFloat((campaignStats.Total["Email opened"]/campaignStats.Total["Email sent"]) * 100).toFixed(2).replace(/\.0+$/,'') : "0"}%</span>
																	<span className="statMetricsValue opened color500 bg100">
																		<img src={require("./../../../assets/images/opened.svg").default} className='img-fluid' alt='Sent' />
																		{campaignStats.Total["Email opened"]} Opened
																	</span>
																</li>
															</ul>
														</div>
													</RSToolTip>
													<RSToolTip item={{ placement: 'bottom', text: `= Clicked / Sent <br />click to show related activities` }} id="statCellLinkClicked">
														<div className='colorClicked statCell' onClick={() => loadRightModal("Link clicked")}>
															<ul className='statMetrics'>
																<li className='rsSelectType metric'>
																	<span className="statMetricsPercent clicked bg">{campaignStats.Total["Email sent"] ? parseFloat((campaignStats.Total["Link clicked"]/campaignStats.Total["Email sent"]) * 100).toFixed(2).replace(/\.0+$/,'') : "0"}%</span>
																	<span className="statMetricsValue clicked color500 bg100">
																		<img src={require("./../../../assets/images/clicked.svg").default} className='img-fluid' alt='Clicked' />
																		{campaignStats.Total["Link clicked"]} Clicked
																	</span>
																</li>
															</ul>
														</div>
													</RSToolTip>
													<RSToolTip item={{ placement: 'bottom', text: `= Replied / Sent <br />click to show related activities` }} id="statCellReplied">
														<div className='colorReplied statCell' onClick={() => loadRightModal("Email replied")}>
															<ul className='statMetrics'>
																<li className='rsSelectType metric'>
																	<span className="statMetricsPercent replied bg">{campaignStats.Total["Email sent"] ? parseFloat((campaignStats.Total["Email replied"]/campaignStats.Total["Email sent"]) * 100).toFixed(2).replace(/\.0+$/,'') : "0"}%</span>
																	<span className="statMetricsValue replied color500 bg100">
																		<img src={require("./../../../assets/images/replied.svg").default} className='img-fluid' alt='Replied' />
																		{campaignStats.Total["Email replied"]} Replied
																	</span>
																</li>
															</ul>
														</div>
													</RSToolTip>
													<RSToolTip item={{ placement: 'bottom', text: `Manually mark your leads as "Interested" to get a clear idea of your campaigns performance<br />Mark as interested FAQ article <br />click to show related activities` }} id="statCellInterested">
														<div className='colorInterested statCell'>
															<ul className='statMetrics'>
																<li className='rsSelectType metric'>
																	<span className="statMetricsPercent interested bg">0%</span>
																	<span className="statMetricsValue interested color500 bg100">
																		<img src={require("./../../../assets/images/interested.svg").default} className='img-fluid' alt='Interested' />
																		0 Interested
																	</span>
																</li>
															</ul>
														</div>
													</RSToolTip>
												</div>
											</div>
										</div>
										<div className='d-flex-row campaignDetails'>
											<div className='sequences'>
												<div className='tableReports tableSmall'>
													<div className='tableColumn tableHeadY'>
														<div className='tableLine tableHeadX'>
															<h4 className="tableHeadTitle bold">Sequence details</h4>
														</div>
														{sequences.length > 0 &&
															<>
																{sequences.map((sequence, index) => {
																	return (
																		<div className='tableLine' key={index}>
																			<div className='stepType'>
																				{sequence.type === "email_template" &&
																					<>
																						<i className='stepType fa-regular fa-envelope'></i>
																						<div className='stepName'>Email</div>
																					</>
																				}
																			</div>
																			<div className='tableContentInfos'>
																				<div className='tableContentTitle'>
																				<Link to={`/user/campaign/${campaignDetail.uuid}/sequence/edit`}>Step {(sequence.order + 1 )}</Link>
																					{sequence.delay_time ?
																						<RSToolTip item={{ placement: 'top', text: `(${sequence.delay_time} day${sequence.delay_time > 1 ? "s": ""} later) ` }} id={`sequenceDelayTime${index}`}>
																							<small> (D+{sequence.delay_time})</small>
																						</RSToolTip>
																					:
																						<></>
																					}
																				</div>
																				<div className='tableContentSubTitle'>
																					<span></span>
																				</div>
																			</div>
																			<div className='tableContentToSend'>
																				{typeof unSentLeadCount["Step"+sequence.order] !== "undefined" ?
																					<>{unSentLeadCount["Step"+sequence.order]}</>
																				:
																					<>0</>
																				}
																			</div>
																		</div>
																	)
																})}
															</>
														}
													</div>
													<div className='tableColumn'>
														<div className='tableLine tableHeadX'>
															<ul className='tableStats'>
																<div className='channelIcon email sm statHead'>
																	<i className='fa-regular fa-envelope'></i>
																</div>
																<li className='tableStat'>
																	<div className='statName sent bg100 color500'>
																		<img src={require("./../../../assets/images/sent.svg").default} alt="Sent" />
																	</div>
																</li>
																<li className='tableStat'>
																	<div className='statName opened bg100 color500'>
																		<img src={require("./../../../assets/images/opened.svg").default} alt="Opened" />
																	</div>
																</li>
																<li className='tableStat'>
																	<div className='statName clicked bg100 color500'>
																		<img src={require("./../../../assets/images/clicked.svg").default} alt="Clicked" />
																	</div>
																</li>
																<li className='tableStat'>
																	<div className='statName replied bg100 color500'>
																		<img src={require("./../../../assets/images/replied.svg").default} alt="Replied" />
																	</div>
																</li>
																<li className='tableStat'>
																	<div className='statName interested bg100 color500'>
																		<img src={require("./../../../assets/images/interested.svg").default} alt="Interested" />
																	</div>
																</li>
															</ul>
														</div>
														{sequences.length > 0 &&
															<>
																{sequences.map((sequence, index) => {
																	return (
																		<div className='tableLine' key={index}>
																			<ul className='tableStats'>
																				<li className='tableStat sent colorSent pointer rsShowActivitiesLayoutRight'>
																					{campaignStats["Step"+sequence.order] && campaignStats["Step"+sequence.order]["Email sent"] ?
																						<span className="tableStatValue sent">
																							<span className="tableStatNumber">{campaignStats["Step"+sequence.order]["Email sent"]}</span>
																						</span>
																					:
																						<div className="nullPlaceholder"></div>
																					}
																				</li>
																				<li className='tableStat opened colorOpened pointer rsShowActivitiesLayoutRight'>
																					<div className='statMetrics'>
																						{( campaignStats["Step"+sequence.order] && campaignStats["Step"+sequence.order]["Email sent"] &&  campaignStats["Step"+sequence.order] && campaignStats["Step"+sequence.order]["Email opened"] ) ?
																							<div className='metric'>
																								<span className='statMetricsPercent opened bg'>{parseFloat((campaignStats["Step"+sequence.order]["Email opened"]/campaignStats["Step"+sequence.order]["Email sent"]) * 100).toFixed(2).replace(/\.0+$/,'')}%</span>
																								<span className='statMetricsValue opened color500 bg100'>{campaignStats["Step"+sequence.order]["Email opened"]}</span>
																							</div>
																						:
																							<div className='nullPlaceholder'></div>
																						}
																					</div>
																				</li>
																				<li className='tableStat clicked colorClicked pointer rsShowActivitiesLayoutRight'>
																					<div className='statMetrics'>
																						{( campaignStats["Step"+sequence.order] && campaignStats["Step"+sequence.order]["Email sent"] &&  campaignStats["Step"+sequence.order] && campaignStats["Step"+sequence.order]["Link clicked"] ) ?
																							<div className='metric'>
																								<span className='statMetricsPercent clicked bg'>{parseFloat((campaignStats["Step"+sequence.order]["Link clicked"]/campaignStats["Step"+sequence.order]["Email sent"]) * 100).toFixed(2).replace(/\.0+$/,'')}%</span>
																								<span className='statMetricsValue clicked color500 bg100'>{campaignStats["Step"+sequence.order]["Link clicked"]}</span>
																							</div>
																						:
																							<div className='nullPlaceholder'></div>
																						}
																					</div>
																				</li>
																				<li className='tableStat replied colorReplied pointer rsShowActivitiesLayoutRight'>
																					<div className='statMetrics'>
																						{( campaignStats["Step"+sequence.order] && campaignStats["Step"+sequence.order]["Email sent"] &&  campaignStats["Step"+sequence.order] && campaignStats["Step"+sequence.order]["Email replied"] ) ?
																							<div className='metric'>
																								<span className='statMetricsPercent replied bg'>{parseFloat((campaignStats["Step"+sequence.order]["Email replied"]/campaignStats["Step"+sequence.order]["Email sent"]) * 100).toFixed(2).replace(/\.0+$/,'')}%</span>
																								<span className='statMetricsValue replied color500 bg100'>{campaignStats["Step"+sequence.order]["Email replied"]}</span>
																							</div>
																						:
																							<div className='nullPlaceholder'></div>
																						}
																					</div>
																				</li>
																				<li className='tableStat clicked colorClicked pointer rsShowActivitiesLayoutRight'>
																					<div className='statMetrics'>
																						<div className="nullPlaceholder"></div>
																					</div>
																				</li>
																			</ul>
																		</div>
																	)
																})}
															</>
														}
														{/* <div className='tableLine'>
															<ul className='tableStats'>
																<li className='tableStat sent colorSent pointer rsShowActivitiesLayoutRight'>
																	<span className="tableStatValue sent">
																		<span className="tableStatNumber">3</span>
																	</span>
																</li>
																<li className='tableStat opened colorOpened pointer rsShowActivitiesLayoutRight'>
																	<div className='statMetrics'>
																		<div className='metric'>
																			<span className='statMetricsPercent opened bg'>66.67%</span>
																			<span className='statMetricsValue opened color500 bg100'>2</span>
																		</div>
																	</div>
																</li>
																<li className='tableStat clicked colorClicked pointer rsShowActivitiesLayoutRight'>
																	<div className='statMetrics'>
																		<div className="nullPlaceholder"></div>
																	</div>
																</li>
																<li className='tableStat replied colorReplied pointer rsShowActivitiesLayoutRight'>
																	<div className='statMetrics'>
																		<div className='metric'>
																			<span className='statMetricsPercent replied bg'>66.67%</span>
																			<span className='statMetricsValue replied color500 bg100'>2</span>
																		</div>
																	</div>
																</li>
																<li className='tableStat clicked colorClicked pointer rsShowActivitiesLayoutRight'>
																	<div className='statMetrics'>
																		<div className="nullPlaceholder"></div>
																	</div>
																</li>
															</ul>
														</div>
														<div className='tableLine'>
															<ul className='tableStats'>
																<li className='tableStat sent colorSent pointer rsShowActivitiesLayoutRight'>
																	<span className="tableStatValue sent">
																		<span className="tableStatNumber">2</span>
																	</span>
																</li>
																<li className='tableStat opened colorOpened pointer rsShowActivitiesLayoutRight'>
																	<div className='statMetrics'>
																		<div className="nullPlaceholder"></div>
																	</div>
																</li>
																<li className='tableStat clicked colorClicked pointer rsShowActivitiesLayoutRight'>
																	<div className='statMetrics'>
																		<div className="nullPlaceholder"></div>
																	</div>
																</li>
																<li className='tableStat replied colorReplied pointer rsShowActivitiesLayoutRight'>
																	<div className='statMetrics'>
																		<div className="nullPlaceholder"></div>
																	</div>
																</li>
																<li className='tableStat clicked colorClicked pointer rsShowActivitiesLayoutRight'>
																	<div className='statMetrics'>
																		<div className="nullPlaceholder"></div>
																	</div>
																</li>
															</ul>
														</div> */}
													</div>
												</div>
											</div>
											<div className='activities'>
												<div className='uiSection mvp'>
													<div className='header'>
														<div className='title'>Activities</div>
													</div>
													<div className='content' id="infiniteScrollWrapDiv">
														<div className='d-flex-col activities align-items-center'>
															<div className='d-flex-col content w-100'>
																<InfiniteScroll
																	dataLength={reportDetails.length}
																	next={() => getAllActivities()}
																	hasMore={activityHasMore}
																	loader={<div><DetailSkeleton /></div>}
																	scrollableTarget="infiniteScrollWrapDiv"
																	endMessage={
																		activityPage === 1 ?
																			<div className="emptyPlaceholder">
																				<div>
																					<img src={require("./../../../assets/images/no-result.svg").default} alt="No result" />
																					<div className="rsInfoMsg disabled">
																						<div className="flex-1">No activities found</div>
																					</div>
																				</div>
																			</div>
																		:
																			<p style={{ textAlign: "center" }}>
																				<b>Yay! You have seen it all</b>
																			</p>
																	}
																>
																	<>
																		{reportDetails.map((reportDetail, index) => {
																			return(
																				<article className="d-flex-row justify-content-center align-items-center activity compact" key={index}>
																					{reportDetail.type === "Email sent" ?
																						<img className="activityTypeIcon colorSent" src={require("./../../../assets/images/sent.svg").default} alt="Sent" />
																					: reportDetail.type === "Email opened" ?
																						<img className="activityTypeIcon colorOpened" src={require("./../../../assets/images/opened.svg").default} alt="Opened" />
																					: reportDetail.type === "Link clicked" ?
																						<img className="activityTypeIcon colorLinkClicked" src={require("./../../../assets/images/clicked.svg").default} alt="Clicked" />
																					: reportDetail.type === "Email replied" ?
																						<img className="activityTypeIcon colorReplied" src={require("./../../../assets/images/replied.svg").default} alt="Replied" />
																					: reportDetail.type === "Interested" &&
																						<img className="activityTypeIcon colorInterested" src={require("./../../../assets/images/interested.svg").default} alt="Interested" />
																					}
																					<div className="activityContainer">
																						<div className="d-flex-col noGap align-items-start activityCenter">
																							<div className="activityLeadName" onClick={() => viewParticularLead(reportDetail.lead_id)}>
																								<span className="rsLayoutRight rsActivitySelected">{reportDetail.to_email}</span>
																							</div>
																							<div className="d-flex-row sm activityStep">
																								<span className="sequence">
																									<Link to={`/user/campaign/${campaignDetail.uuid}/sequence/edit`}>Step {parseInt(reportDetail.step) + 1}</Link>
																								</span>
																								<div className="grey activityDate">
																									<span className="tippy fromNow">
																										<TimeStampToolTip createdAt={reportDetail.created_at} innerClass="timeAgoTooltip">
																											<TimeAgo
																												datetime={reportDetail.created_at}
																											/>
																										</TimeStampToolTip>
																									</span>
																								</div>
																							</div>
																							<div className="activityMessage">{reportDetail.type}</div>
																							{reportDetail.type === "Email sent" ?
																								<Button className="btn btn-tertiary rsShowActivityMessage" onClick={() => loadParticularEmail(index, "activities")}><i className="far fa-eye"></i><span>Show email sent</span></Button>
																							: reportDetail.type === "Email replied" &&
																								<Button className="btn btn-tertiary rsShowActivityMessage" onClick={() => loadParticularEmail(index, "activities")}><i className="far fa-eye"></i><span>Show email replay</span></Button>
																							}
																						</div>
																					</div>
																				</article>
																			)
																		})}
																	</>
																</InfiniteScroll>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				}
			</div>
			<div className={`rsRightModal rightLayout ${openRightModal ? "open" : ""}`}>
				{openRightModal &&
					<>
						{rightModalContent !== "viewLead" ?
							<>
								<div className='d-flex-col rightLayoutContent' id='rightContentScroll' ref={divScrollRef}>
									<div className='titleContainer align-items-start'>
										<div className='d-flex-row align-items-center rightLayoutContentLeft'>
											<div className='title'>
												<h2 className="bold">All steps · 
												{rightModalContent === "Email sent" ?
													<> Email sent</>
												: rightModalContent === "Email opened" ?
													<> Email opened</>
												: rightModalContent === "Link clicked" ?
													<> Clicked on link</>
												: rightModalContent === "Email replied" ?
													<> Email replied</>
												: rightModalContent === "interested" &&
													<> Email interested</>
												}
												</h2>
											</div>
										</div>
										<div className='d-flex-row align-items-center'>
											<Button className="btn btn-secondary btn-ico sm closeLayoutRight" onClick={() => toggleStatRightModal()}>
												<i className="fa-solid fa-xmark"></i>
											</Button>
										</div>
									</div>
									<div className='activitiesLayoutRight col'>
										{/* <div className='rsSearchInput'>
											<i className="fa-solid fa-magnifying-glass"></i>
											<Input type='text' placeholder='name, email, url...' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
											<i className="fa-solid fa-circle-xmark rsClearSearch" onClick={() => setSearchText('')}></i>
										</div> */}
										{( rightModalContent === "Email opened" || rightModalContent === "Link clicked" || rightModalContent === "Email replied" )&&
											<div className={`teamPageWrapper d-flex justify-content-between`}>
												<div className='rsTabsWrapper'>
													<div className={`rsTab ${currentTab === "All" ? "active" : ""}`} onClick={() => setCurrentTab("All")}>All</div>
													<div className={`rsTab ${currentTab === "HotLead" ? "active" : ""}`} onClick={() => setCurrentTab("HotLead")}>Hot Lead</div>
												</div>
												{(currentTab === "HotLead" && hotLeads.length > 0 ) &&
													<div className='exportButtonWrapper'>
														<Button className='exportButton' disabled={exportLoaderApi} onClick={() => downloadHotLeadExport()}>
															{exportLoaderApi &&
																<Spinner className='white' size={"sm"} />
															}
															<i className='fa fa-download'></i> Export
														</Button>
													</div>
												}
											</div>
										}
										{currentTab === "HotLead" ?
											<div className='activities'>
												<>
													<InfiniteScroll
														dataLength={hotLeads.length}
														next={() => getAllHotLead(rightModalContent)}
														hasMore={hotLeadHasMore}
														loader={<div><ReviewLeadSkeleton /></div>}
														scrollableTarget="rightContentScroll"
														endMessage={
															hotLeadPage === 1 ?
																<div className="emptyPlaceholder">
																	<div>
																		<img src={require("./../../../assets/images/no-result.svg").default} alt="No result" />
																		<div className="rsInfoMsg disabled">
																			<div className="flex-1">No hotlead found</div>
																		</div>
																	</div>
																</div>
															:
																<p style={{ textAlign: "center" }}>
																	<b>Yay! You have seen it all</b>
																</p>
														}
													>
														{hotLeads.map((hotLead, index) => {
															return (
																<article className="d-flex-row justify-content-center align-items-center activity" key={index}>
																	{rightModalContent === "Email opened" ?
																		<img className="activityTypeIcon colorOpened" src={require("./../../../assets/images/opened.svg").default} alt="Opened" />
																	: rightModalContent === "Link clicked" ?
																		<img className="activityTypeIcon colorClicked" src={require("./../../../assets/images/clicked.svg").default} alt="Clicked" />
																	: rightModalContent === "Email replied" &&
																		<img className="activityTypeIcon colorReplied" src={require("./../../../assets/images/replied.svg").default} alt="Replied" />
																	}														
																	<div className="activityContainer">
																		<div className="d-flex-col noGap align-items-start activityCenter">
																			<div className="activityLeadName"  onClick={() => viewParticularLead(hotLead.lead_id, rightModalContent)}>
																				<span className="rsLayoutRight rsActivitySelected">{hotLead.to_email}</span>													
																			</div>
																			<div className="d-flex-row sm activityStep">
																				<span className="sequence cursorPointer" onClick={() => viewHotLeadFunc(rightModalContent, hotLead)}>
																					{hotLead.count > 0 &&
																						<span>View details</span>
																					}
																					{/* {Object.keys(hotLead.steps).map((step, index) => {
																						return (
																							<React.Fragment key={index}>
																								{index !== 0 &&
																									<>, </>
																								}
																								{step} - {hotLead.steps[step]}
																							</React.Fragment>
																						)
																					})} */}
																				</span>											
																			</div>
																		</div>
																		<div className='d-flex-col noGap align-items-end'>
																			<div className="grey activityDate">
																				<span className="tippy fromNow">
																					{hotLead.count}
																				</span>
																			</div>
																		</div>
																	</div>
																</article>
															)
														})}
													</InfiniteScroll>
												</>
											</div>
										:
											<div className='activities'>
												<>
													<InfiniteScroll
														dataLength={rightModalArray.length}
														next={() => getAllTypeActivities(rightModalContent)}
														hasMore={rightModalActivityHasMore}
														loader={<div><ReviewLeadSkeleton /></div>}
														scrollableTarget="rightContentScroll"
														endMessage={
															rightModalActivityPage === 1 ?
																<div className="emptyPlaceholder">
																	<div>
																		<img src={require("./../../../assets/images/no-result.svg").default} alt="No result" />
																		<div className="rsInfoMsg disabled">
																			<div className="flex-1">No activities found</div>
																		</div>
																	</div>
																</div>
															:
																<p style={{ textAlign: "center" }}>
																	<b>Yay! You have seen it all</b>
																</p>
														}
													>
														{rightModalArray.map((rightModal, index) => {
															return (
																<article className="d-flex-row justify-content-center align-items-center activity" key={index}>
																	{rightModal.type === "Email sent" ?
																		<img className="activityTypeIcon colorSent" src={require("./../../../assets/images/sent.svg").default} alt="Sent" />
																	: rightModal.type === "Email opened" ?
																		<img className="activityTypeIcon colorOpened" src={require("./../../../assets/images/opened.svg").default} alt="Opened" />
																	: rightModal.type === "Link clicked" ?
																		<img className="activityTypeIcon colorClicked" src={require("./../../../assets/images/clicked.svg").default} alt="Clicked" />
																	: rightModal.type === "Email replied" ?
																		<img className="activityTypeIcon colorReplied" src={require("./../../../assets/images/replied.svg").default} alt="Replied" />
																	: rightModal.type === "Interested" &&
																		<img className="activityTypeIcon colorInterested" src={require("./../../../assets/images/interested.svg").default} alt="Interested" />
																	}
																	
																	<div className="activityContainer">
																		<div className="d-flex-col noGap align-items-start activityCenter">
																			<div className="activityLeadName"  onClick={() => viewParticularLead(rightModal.lead_id, rightModalContent)}>
																				<span className="rsLayoutRight rsActivitySelected">{rightModal.to_email}</span>													
																			</div>
																			<div className="d-flex-row sm activityStep">
																				<span className="sequence">
																					<Link to={`/user/campaign/${campaignDetail.uuid}/sequence/edit`}>Step {( rightModal.step + 1 )}</Link>
																					{/* <Link to={"/campaigns-next/cam_6wuX79dxbpFgePv5D/sequences/seq_mMq7X6bAqnxmh44Hj/edit"}>Step 1</Link> */}
																				</span>											
																			</div>
																			<div className="activityMessage">
																				{rightModalContent === "Email sent" ?
																						<> Email sent</>
																					: rightModalContent === "Email opened" ?
																						<> Email opened</>
																					: rightModalContent === "Link clicked" ?
																						<>
																							Clicked on link &nbsp;
																							{rightModal.redirect_url &&
																								<a href={`${rightModal.redirect_url}`} target='_blank' rel="noreferrer">{rightModal.redirect_url}</a>
																							}
																						</>
																					: rightModalContent === "Email replied" ?
																						<> Email replied</>
																					: rightModalContent === "interested" &&
																						<> Email interested</>
																				}
																			</div>										
																		</div>
																		<div className='d-flex-col noGap align-items-end'>
																			<div className="grey activityDate">
																				<span className="tippy fromNow">
																					<TimeStampToolTip createdAt={rightModal.created_at} innerClass="timeAgoTooltip">
																						<TimeAgo
																							datetime={rightModal.created_at}
																						/>
																					</TimeStampToolTip>
																				</span>
																			</div>
																			{rightModal.type === "Email sent" ?
																				<Button className="btn btn-tertiary rsShowActivityMessage" onClick={() => loadParticularEmail(index, "email_sent")}><i className="far fa-eye"></i><span>Show email sent</span></Button>
																			: rightModal.type === "Email replied" &&
																				<Button className="btn btn-tertiary rsShowActivityMessage" onClick={() => loadParticularEmail(index, "email_replied")} ><i className="far fa-eye"></i><span>Show email reply</span></Button>
																			}
																		</div>
																	</div>
																</article>
															)
														})}
													</InfiniteScroll>
												</>
											</div>
										}
									</div>
								</div>
							</>
						:
							<>
								{viewLeadLoader ?
									<DetailSkeleton />
								: (viewLead) &&	
									<div className='d-flex-col rightLayoutContent leadLayoutRight'>
										<div className='titleContainer align-items-center'>
											<div className='d-flex-row align-items-center rightLayoutContentLeft'>
												{rightModalArray.length > 0 &&
													<Button className="btn btnBack" onClick={() => goToLeadStat()}></Button>
												}
												<div className='title'>
													<div className='leadSummary d-flex-row'>
														<div className='leadPictureAndState'>
															{(viewLead.firstName || viewLead.lastName) &&
																<div className={`leadPicture avatarPrimary${parseInt(viewLead.firstName.concat(viewLead.lastName).length % 10)}`}>
																	{viewLead.firstName && viewLead.firstName.substr(0, 1)}{viewLead.lastName && viewLead.lastName.substr(0, 1)}
																</div>
															}
														</div>
														<div className='leadContact'>
															<div className='leadHeader'>
																<div className='leadName'>
																	<div className='leadNameOverflow'>{viewLead.firstName && viewLead.firstName} {viewLead.lastName && viewLead.lastName}</div>
																	{/* <div className='rsTag leadEmailStatus sm tagSuccess100'>
																		<span>deliverable</span>
																	</div> */}
																</div>
																<div className='leadIcons'></div>
															</div>
															<p className='leadListEmail'>{viewLead.email}</p>
														</div>
													</div>
												</div>
											</div>
											<div className='d-flex-row align-items-center'>
												<div>
													<ViewLeadDropdown lead={viewLead} onClick={(funcName) => viewLeadClickEvent(funcName)} />
												</div>
												<button className="btn btn-secondary btn-ico sm closeLayoutRight" onClick={() => toggleStatRightModal()}>
													<i className="fa-solid fa-xmark"></i>
												</button>
											</div>
										</div>
										<div className='d-flex-col leadLayoutRightNext'>
											{leadOtherCampaigns.length > 0 &&
												<div className='rsInfoMsg info'>
													<div className='flex-1'>
														<div>
															This lead is already present in &nbsp;
															<RSToolTip item={{ placement: 'bottom', text: `${leadOtherCampaigns.join("<br />")}` }} id="otherCampaignLeadError">
																<span className='link-underlined bold'>{leadOtherCampaigns.length} other campaigns</span>
															</RSToolTip>
														</div>
													</div>
												</div>
											}
											<div className="d-flex-col sm leadCampaignProgression">
												<div className='d-flex-row align-items-center justify-content-between'>
													<h4 className='bold'>{campaignDetail && campaignDetail.name}</h4>
													<div className='leadState'>
														{(viewLead.status === "Running" || viewLead.status === "Paused" ) ?
															<div className="rsSwitch customControl customSwitch rsCampaignsTogglePlayPause playPause">
																<Label className="customControlLabel hasLabel">
																	<Input type="checkbox" disabled={viewLeadStatusLoaderApi} checked={(viewLead.status && viewLead.status === "Running" )} onChange={() => pauseLead()} />
																	<span className="switchDisplay"></span>
																	<span className="rsLabel">Run lead</span>
																</Label>
															</div>
														: ( viewLead.status === "Ended" || viewLead.status === "Interested" || viewLead.status === "Unsubscribed" ) ?
															<>
																<i className="fa-solid fa-circle-check success"></i>
																<span>Lead ended</span>
															</>
														:
															<>
																<i className="fa-regular fa-clock error"></i>
																<span>Awaiting review this lead</span>
															</>
														}
													</div>
												</div>
												{(viewLead.status === "Running" || viewLead.status === "Paused" ) &&
													<div className='d-flex-row campaignTaskContainer'>
														{leadSentSchedules.length > 0 &&
															<LeadScheduleToolTip type="done" innerClass="taskWrapper" tasks={leadSentSchedules}>
																<ul className='doneTasks'>
																	{leadSentSchedules.map((sent, index) => {
																		return( 
																			<li className='task success'>
																				<i className="fa-regular fa-envelope-open" aria-hidden="true"></i>
																			</li>
																		)
																	})}
																</ul>
																<i className="separator fa-solid fa-caret-right"></i>
															</LeadScheduleToolTip>
														}
														{Object.keys(leadCurrentSchedule).length > 0 &&
															<LeadScheduleToolTip type="current" innerClass="currentTask task" tasks={leadCurrentSchedule}>
																<i className="fa-regular fa-envelope-open"></i> Send mail
															</LeadScheduleToolTip>
														}
														{leadAwaitingSchedule.length > 0 &&
															<LeadScheduleToolTip type="future" innerClass="taskWrapper" tasks={leadAwaitingSchedule}>
																<i className="separator fa-solid fa-caret-right"></i>
																<ul className="futureTasks">
																	<li className="task"><i className="fa-regular fa-envelope-open" aria-hidden="true"></i></li>
																</ul>
																{leadAwaitingSchedule.length > 1 &&
																	<div className="task wrapperRight"> +{(leadAwaitingSchedule.length - 1)} </div>
																}
															</LeadScheduleToolTip>
														}
													</div>
												}
											</div>
											<div className='leadTabs'>
												<div className={`leadTab ${viewLeadTabSelected === "infos" ? "selected" : ""}`} onClick={() => setViewLeadTabSelected("infos")}>Infos</div>
												<div className={`leadTab ${viewLeadTabSelected === "activities" ? "selected" : ""}`} onClick={() => setViewLeadTabSelected("activities")}>Activities</div>
												<div className={`leadTab ${viewLeadTabSelected === "notes" ? "selected" : ""}`} onClick={() => setViewLeadTabSelected("notes")}>Notes</div>
											</div>
											<div className='leadTabContent'>
												{viewLeadTabSelected === "infos" ?
													<div className='d-flex-col leadForm'>
														<div className='d-flex-col noGap leadFormSection'>
															<div className='leadFormSectionHeader'>
																<h4 className='bold'>General information</h4>
															</div>
															<div className='d-flex-row leadFormSectionContent leadGeneralInfo'>
																<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "firstName" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("firstName")}>
																	<label className="bold">First name</label>
																	<div className='d-flex-row sm align-items-center'>
																		{editFieldKey === "firstName" ?
																			<Input type='text' placeholder='First name' value={viewLead.firstName} onChange={(e) => changeViewLeadValue(e.target.value, "firstName")} onBlur={() => updateViewLeadValue()} />
																		:
																			<Label className={`flex-1 fieldValue ${viewLead.firstName ?  "filled" : ""}`}>{viewLead.firstName ? viewLead.firstName : "Add first name"}</Label>
																		}
																	</div>
																</div>
																<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "lastName" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("lastName")}>
																	<label className="bold">Last name</label>
																	<div className='d-flex-row sm align-items-center'>
																		{editFieldKey === "lastName" ?
																			<Input type='text' placeholder='Last name' value={viewLead.lastName} onChange={(e) => changeViewLeadValue(e.target.value, "lastName")} onBlur={() => updateViewLeadValue()} />
																		:
																			<Label className={`flex-1 fieldValue ${viewLead.lastName ?  "filled" : ""}`}>{viewLead.lastName ? viewLead.lastName : "Add last name"}</Label>
																		}
																	</div>
																</div>
															</div>
														</div>
														<div className='d-flex-col noGap leadFormSection'>
															<div className='leadFormSectionHeader'>
																<h4 className='bold'>Contact infos</h4>
															</div>
															<div className='d-flex-col leadFormSectionContent'>
																<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "email" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("email")}>
																	<label className="bold">Email address</label>
																	<div className='d-flex-row sm align-items-center'>
																		{editFieldKey === "email" ?
																			<Input type='text' placeholder='Email Address' value={viewLead.email} onChange={(e) => changeViewLeadValue(e.target.value, "email")} onBlur={() => updateViewLeadValue()} />
																		:
																			<Label className={`flex-1 fieldValue ${viewLead.email ?  "filled" : ""}`}>{viewLead.email ? viewLead.email : "Add email address"}</Label>
																		}
																	</div>
																</div>
																<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "phone" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("phone")}>
																	<label className="bold">Phone number</label>
																	<div className='d-flex-row sm align-items-center'>
																		{editFieldKey === "phone" ?
																			<Input type='text' placeholder='Phone number' value={viewLead.phone} onChange={(e) => changeViewLeadValue(e.target.value, "phone")} onBlur={() => updateViewLeadValue()} />
																		:
																			<Label className={`flex-1 fieldValue ${viewLead.phone ?  "filled" : ""}`}>{viewLead.phone ? viewLead.phone : "Add phone number"}</Label>
																		}
																	</div>
																</div>
																<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "linkedinUrl" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("linkedinUrl")}>
																	<label className="bold">Linkedin profile</label>
																	<div className='d-flex-row sm align-items-center'>
																		{editFieldKey === "linkedinUrl" ?
																			<Input type='text' placeholder='Linkedin profile' value={viewLead.linkedinUrl} onChange={(e) => changeViewLeadValue(e.target.value, "linkedinUrl")} onBlur={() => updateViewLeadValue()} />
																		:
																			<Label className={`flex-1 fieldValue ${viewLead.linkedinUrl ?  "filled" : ""}`}>{viewLead.linkedinUrl ? viewLead.linkedinUrl : "Add linkedin profile"}</Label>
																		}
																	</div>
																</div>
															</div>
														</div>
														<div className='d-flex-col noGap leadFormSection'>
															<div className='leadFormSectionHeader'>
																<h4 className='bold'>Additional Information</h4>
															</div>
															<div className='d-flex-col leadFormSectionContent'>
																<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "companyName" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("companyName")}>
																	<label className="bold">Company name</label>
																	<div className='d-flex-row sm align-items-center'>
																		{editFieldKey === "companyName" ?
																			<Input type='text' placeholder='Company name' value={viewLead.companyName} onChange={(e) => changeViewLeadValue(e.target.value, "companyName")} onBlur={() => updateViewLeadValue()} />
																		:
																			<Label className={`flex-1 fieldValue ${viewLead.companyName ?  "filled" : ""}`}>{viewLead.companyName ? viewLead.companyName : "Add company name"}</Label>
																		}
																	</div>
																</div>
																<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "companyDomain" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("companyDomain")}>
																	<label className="bold">Company domain</label>
																	<div className='d-flex-row sm align-items-center'>
																		{editFieldKey === "companyDomain" ?
																			<Input type='text' placeholder='Company domain' value={viewLead.companyDomain} onChange={(e) => changeViewLeadValue(e.target.value, "companyDomain")} onBlur={() => updateViewLeadValue()} />
																		:
																			<Label className={`flex-1 fieldValue ${viewLead.companyDomain ?  "filled" : ""}`}>{viewLead.companyDomain ? viewLead.companyDomain : "Add company domain"}</Label>
																		}
																	</div>
																</div>
																<div className={`d-flex-col xs rsLeadFormField ${editFieldKey === "photo" ? "focused" : ""} `} onClick={() => setEditFieldKeyFunc("photo")}>
																	<label className="bold">[Photo]</label>
																	<div className='d-flex-row sm align-items-center'>
																		{editFieldKey === "photo" ?
																			<Input type='text' placeholder='[Photo]' value={viewLead.photo} onChange={(e) => changeViewLeadValue(e.target.value, "photo")} onBlur={() => updateViewLeadValue()} />
																		:
																			<Label className={`flex-1 fieldValue ${viewLead.photo ?  "filled" : ""}`}>{viewLead.photo ? viewLead.photo : "Add [Photo]"}</Label>
																		}
																	</div>
																</div>
															</div>
														</div>
													</div>
												: viewLeadTabSelected === "activities" ? 
													<div className='activities'>
														{leadActivityLoader ?
															<DetailSkeleton />
														:
															<>
																{viewLeadActivities.length > 0 ?
																	<>
																		{viewLeadActivities.map((activity, index) => {
																			return(
																				<article className="d-flex-row justify-content-center align-items-center activity compact" key={index}>
																					{activity.type === "Email sent" ?
																						<img className="activityTypeIcon colorSent" src={require("./../../../assets/images/sent.svg").default} alt="Sent" />
																					: activity.type === "Email opened" ?
																						<img className="activityTypeIcon colorOpened" src={require("./../../../assets/images/opened.svg").default} alt="Opened" />
																					: activity.type === "Link clicked" ?
																						<img className="activityTypeIcon colorLinkClicked" src={require("./../../../assets/images/clicked.svg").default} alt="Clicked" />
																					: activity.type === "Email replied" ?
																						<img className="activityTypeIcon colorReplied" src={require("./../../../assets/images/replied.svg").default} alt="Replied" />
																					: activity.type === "Interested" &&
																						<img className="activityTypeIcon colorInterested" src={require("./../../../assets/images/interested.svg").default} alt="Interested" />
																					}
																					<div className="activityContainer">
																						<div className="d-flex-col noGap align-items-start activityCenter">
																							<div className="activityLeadName">
																								<span className="rsLayoutRight rsActivitySelected">{activity.to_email}</span>
																							</div>
																							<div className="d-flex-row sm activityStep">
																								<span className="sequence">
																									<Link to={`/user/campaign/${campaignDetail.uuid}/sequence/edit`}>Step {parseInt(activity.step) + 1}</Link>
																								</span>
																								<div className="grey activityDate">
																									<span className="tippy fromNow">
																										<TimeStampToolTip createdAt={activity.created_at} innerClass="timeAgoTooltip">
																											<TimeAgo
																												datetime={activity.created_at}
																											/>
																										</TimeStampToolTip>
																									</span>
																								</div>
																							</div>
																							<div className="activityMessage">{activity.type}</div>
																							{activity.type === "Email sent" ?
																								<Button className="btn btn-tertiary rsShowActivityMessage" onClick={() => loadParticularEmail(index, "viewLeadActivities")}><i className="far fa-eye"></i><span>Show email sent</span></Button>
																							: activity.type === "Email replied" &&
																								<Button className="btn btn-tertiary rsShowActivityMessage" onClick={() => loadParticularEmail(index, "viewLeadActivities")}><i className="far fa-eye"></i><span>Show email replay</span></Button>
																							}
																						</div>
																					</div>
																				</article>
																			)
																		})}
																	</>
																:
																	<div className="emptyActivities">
																		<img src={require("./../../../assets/images/empty-box.svg").default} alt="empty-box" />
																		<span>No activities found</span>
																	</div>
																}
															</>
														}
													</div>
												: viewLeadTabSelected === "notes" &&
													<div className='d-flex-col'>
														<h4 className="bold leadFormSectionHeader">Add a new note</h4>
														<div className='rsRichTextArea'>
															<div className='textEdit'>
																<div className='inputWrapper'>
																	<textarea className="rsUiEdit" rows="6" placeholder="What note do you want to add?" spellcheck="false" value={leadNote} onChange={(e) => setLeadNote(e.target.value)}></textarea>
																	<div className="rsTextEditBorder"></div>
																</div>
															</div>
															<div className='toolbar'>
																<Button color='primary' onClick={() => addLeadNotes()} disabled={leadNotesLoaderApi}>
																	{leadNotesLoaderApi &&
																		<Spinner size={"sm"} />
																	}
																	Publish
																</Button>
															</div>
														</div>
														<div>
															{leadNotes.length > 0 &&
																leadNotes.map((note, index) => {
																	return(
																		<div key={index} className='note d-flex-col sm'>
																			<div className="d-flex-row sm align-items-center">
																				{teamUserData[note.user_id] &&
																					<>
																						<div className={`avatar uiUserPicture avatarPrimary${((teamUserData[note.user_id].id - 1) % 10)}`}>
																							{teamUserData[note.user_id].profile_picture ?
																								<img src={teamUserData[note.user_id].profile_picture} alt={teamUserData[note.user_id].first_name} className='picture' />
																								:
																								<>
																									{teamUserData[note.user_id].first_name.substr(0, 1)}{teamUserData[note.user_id].last_name && teamUserData[note.user_id].last_name.substr(0, 1)}
																								</>
																							}
																						</div>
																						<div className="name">
																							{teamUserData[note.user_id].first_name} {teamUserData[note.user_id].last_name ? teamUserData[note.user_id].last_name : ""}
																							<span className="text-inactive"> · <span><TimeAgo datetime={note.created_at} /></span></span>
																						</div>
																					</>
																				}
																			</div>
																			<div className="d-flex-row noteContent textLight">
																				{note.notes}
																			</div>
																		</div>
																	)
																})
															}
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								}
							</>
						}
					</>
				}
			</div>
			{emailModal &&
				<Modal isOpen={emailModal} toggle={() => toggleEmailModal()} centered={true} className='activityMessageModal'>
					<ModalBody>
						<Button className='btnModalClose' onClick={() => toggleEmailModal ()}>
							<i className="fa-solid fa-xmark"></i>
						</Button>
						<div className="activityMessageTitle">{modalEmailDetail.type}</div>
						<div className="d-flex-col sm emailHeaders">
							<div>
								<span className="bold">From: </span> {modalEmailDetail.type !== "Email replied" ? modalEmailDetail.from_name : modalEmailDetail.to_name } <span className="grey">&lt;{modalEmailDetail.type !== "Email replied" ? modalEmailDetail.from_email : modalEmailDetail.to_email }&gt;</span>
							</div>
							<div>
								<span className="bold">To: </span> {modalEmailDetail.type !== "Email replied" ? modalEmailDetail.to_name : modalEmailDetail.from_name } <span className="grey">&lt;{modalEmailDetail.type !== "Email replied" ? modalEmailDetail.to_email : modalEmailDetail.from_email }&gt;</span>
							</div>
							<div>
								<span className="bold">Subject: {modalEmailDetail.subject}</span>
							</div>
						</div>
						<div className="activityMessageBody">
							<div dangerouslySetInnerHTML={{__html:modalEmailDetail.message}}></div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color='primary' onClick={() => toggleEmailModal()}>
							<span className='closeSpan'>Close</span>
						</Button>
					</ModalFooter>
				</Modal>
			}
			{viewHotLeadModal &&
				<Modal isOpen={viewHotLeadModal} toggle={() => toggleViewHotLeadModal()} centered={true} className="viewHotLeadModal">
					<ModalHeader>
						View Hot Lead {viewHotLeadEmail}
					</ModalHeader>
					<ModalBody>
						{viewHotLeadLoader ?
							<SmallSkeleton />
						:
							<Table>
								<thead>
									<tr>
										<th>Step</th>
										<th>
											{rightModalContent === "Email opened" ?
												<>No of open</>
											: rightModalContent === "Email replied" ?
												<>No of replied</>
											: rightModalContent === "Link clicked" &&
												<>No of link clicked</>
											}
										</th>
									</tr>
									{viewHotLead.map((lead, index) => {
										return(
											<tr key={index}>
												<td>Step {parseInt(lead.step) + 1 }</td>
												<td>{lead.count}</td>
											</tr>
										)
									})}
								</thead>
							</Table>
						}
					</ModalBody>
					<ModalFooter>
						<Button color='primary' onClick={() => toggleViewHotLeadModal()}>
							<span className='closeSpan'>Close</span>
						</Button>
					</ModalFooter>
				</Modal>
			}
			{leadUnsubscribeModal &&
				<Modal isOpen={leadUnsubscribeModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation warningColor paddingRight10"></i>Warning</h2>
							<p>Are you sure you want to add this lead to the unsubscribed list? This action can’t be undone.</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={unsubscribeLeadLoaderApi} onClick={() => toggleLeadUnsubscribeModal()}>Cancel</Button>
							<Button className='md okWarningButton btn-warning' color='primary' disabled={unsubscribeLeadLoaderApi} onClick={() => unsubscribeLead()}>
								{unsubscribeLeadLoaderApi &&
									<Spinner size={"sm"} />
								}
								Ok
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
			{deleteWarningModal &&
				<Modal isOpen={deleteWarningModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation warningColor paddingRight10"></i>Warning</h2>
							<p>Delete 1 lead from the campaign?</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={deleteLoaderApi} onClick={() => toggleLeadDeleteModal()}>Cancel</Button>
							<Button className='md okWarningButton btn-warning' color='primary' disabled={deleteLoaderApi} onClick={() => deleteLead()}>
								{deleteLoaderApi &&
									<Spinner size={"sm"} />
								}
								Ok
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
		</>
	);
}

export default Campaign;