import React, { useContext, useState } from 'react';
import {userRoutes} from './../Routes';
import { NavLink } from 'reactstrap';
import { ProductContext } from "../store";
import { Link, NavLink as NavLinkRRD } from "react-router-dom";

const SideBar = () => {
	const [submenuOpen, setSubmenuOpen]	= useState(null);

	const {
		userData,
		currentTeamId,
		teamsData,
		plansData,
		autoCloseClass,
		leftHoverClass,
		setLeftHoverClass
	}	= useContext(ProductContext)

	const handleMouseEnter = () => {
		setLeftHoverClass('over')
	}

	const handleMouseLeave = () => {
		setLeftHoverClass(null)
	}

	const toggleSubmenu = ( e, routeName) => {
		console.log(e)
		e.preventDefault()
		if( submenuOpen === routeName ){
			setSubmenuOpen(null)
		}else{
			setSubmenuOpen(routeName)
		}
	}
	
	return (
		<div
			className={`layoutLeftWrapper ${leftHoverClass ? leftHoverClass : ''} ${autoCloseClass ? autoCloseClass : ''}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>			
			<div className='scrollableArea noVisibleScrollbar'>
				<div className='menuItemsWrapper'>
					{userRoutes.map((userRoute, index) => {
						return (
							<React.Fragment key={index}>
								{userRoute.type === "mainMenu" &&
									<>
										{/* {userRoute.category && ( (index === 0) || (index > 0 && userRoutes[index-1].category !== userRoute.category) ) &&
											<div className='sectionTitle'>{userRoute.category}</div>
										} */}
										<NavLink
											to={userRoute.layout + userRoute.path}
											tag={NavLinkRRD}
											className={`navItem ${userRoute.itemClass ? userRoute.itemClass : ""} ${submenuOpen === userRoute.name ? "expanded" : ""}`}
											//activeClassName="active"
											onClick={() => setSubmenuOpen(userRoute.name)}
											key={index}
										>
											<i className="material-symbols-outlined">{userRoute.icon}</i>
											<span className='menuText'>{userRoute.name}</span>
											{typeof userRoute.submenu !== "undefined" &&
												<div className='expander rsToggleMenu' onClick={(e) => toggleSubmenu( e, userRoute.name )}>
													{submenuOpen === userRoute.name ?
														<i className="fa-solid fa-chevron-up"></i>
													:
														<i className="fa-solid fa-chevron-down"></i>
													}
												</div>
											}
										</NavLink>
										{userRoute.submenu &&
											<div className='navSubItems space'>
												{userRoute.submenu.map((menuItem, index) => {
													return(
														<NavLink
															to={menuItem.layout + menuItem.path}
															tag={NavLinkRRD}
															className={`navItem ${menuItem.itemClass ? menuItem.itemClass : ""}`}
															//activeClassName="active"
															key={index}
														>															
															<span>{menuItem.name}</span>
														</NavLink>
													)
												})}
											</div>
										}
									</>
								}
							</React.Fragment>
						)
					})}
				</div>
			</div>
			<div className='footerSidebar scrollShadow'>
				<div className='dropDownTrigger'>
					<div className='uiTeamDropDown'>
						<div className='uiTeamItem'>
							<div className='teamPicture'>
								<div className='avatar uiTeamPicture lg avatarSecondary0'>
									{(currentTeamId && teamsData[currentTeamId] && teamsData[currentTeamId].profile_picture ) ?
										<img className="picture" src={teamsData[currentTeamId].profile_picture} alt={teamsData[currentTeamId].name} />
									:
										<i className="fa-regular fa-building"></i>
									}
								</div>
							</div>
							<div className='team'>
								<div className='teamName'>
									{(currentTeamId && teamsData[currentTeamId] && teamsData[currentTeamId].name ) ?
										<>{teamsData[currentTeamId].name}</>
									:
										<>{userData.first_name}{userData.last_name && " "+userData.last_name}'s Team</>
									}
								</div>
								{/* <div className='teamDesc'>
									{( currentTeamId && teamsData[currentTeamId] && teamsData[currentTeamId].plan_id && plansData && plansData[teamsData[currentTeamId].plan_id] ) ?
										<>{plansData[teamsData[currentTeamId].plan_id].name}</>
									: <>Free plan</>
									}
									 - 1 Seats
								</div> */}
							</div>
						</div>
						{/* <i className="fa-solid fa-sort teamDropdownTrigger"></i> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default SideBar;