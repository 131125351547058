import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const ReviewLeadSkeleton = (props) => {
	return (
		<React.Fragment>
			<SkeletonTheme baseColor="#f7fafc" highlightColor="#eff1ff">
				<div className='leadLeft rsShowLeadReview'>
					<Skeleton height={20} className='circleSkeleton' />
					<Skeleton height={20} className='circleSkeleton' />
				</div>
			</SkeletonTheme>
		</React.Fragment>
	);
}

export default ReviewLeadSkeleton;