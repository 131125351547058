import { endOfDay, endOfMonth, endOfWeek, endOfYear, format, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import React, { useState } from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function DateRangeDropdown({ onDateChange }) {
	const [stepMoreOption, setStepMoreOption] = useState(false)
	const [selectionRange, setSelectionRange] = useState({
		startDate: subDays(new Date(), 6),
		endDate: new Date(),
		key: 'selection',
	})
	const onChangeEvent = (ranges) => {
		let localRanges = {
			startDate: ranges.selection.startDate,
			endDate: ranges.selection.endDate,
			key: 'selection',
		}
		setSelectionRange(localRanges)
		onDateChange(localRanges)
	}

	const staticRanges = createStaticRanges([
		{
			label: 'Today',
			range: () => ({
				startDate: startOfDay(new Date()),
				endDate: endOfDay(new Date())
			}),
		},
		{
			label: 'Yesterday',
			range: () => ({
				startDate: subDays(new Date(), 1),
				endDate: subDays(new Date(), 1),
			}),
		},
		{
			label: 'This Week',
			range: () => ({
				startDate: startOfWeek(new Date()),
				endDate: new Date(),
			}),
		},
		{
			label: 'Last 7 Days',
			range: () => ({
				startDate: subDays(new Date(), 6),
				endDate: new Date(),
			}),
		},
		{
			label: 'This Month',
			range: () => ({
				startDate: startOfMonth(new Date()),
				endDate: new Date(),
			}),
		},
		{
			label: 'This Year',
			range: () => ({
				startDate: startOfYear(new Date()),
				endDate: new Date(),
			}),
		},
	]);

	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => { setStepMoreOption((prevstate) => !prevstate); }} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btn-secondary btn-date-picker'>
				<i className="fa-regular fa-calendar"></i> {format(selectionRange.startDate, 'MM/dd/yyyy')} - {format(selectionRange.endDate, 'MM/dd/yyyy')}
			</DropdownToggle>
			<DropdownMenu className='dateRangeDropdownMenu'>
				<DateRangePicker
					ranges={[selectionRange]}
					onChange={(ranges) => onChangeEvent(ranges)}
					dateDisplayFormat="MM/dd/yyyy"
					inputRanges={[]}
					maxDate={new Date()}
					staticRanges={staticRanges}
				/>
			</DropdownMenu>
		</Dropdown>
	);
}

export default DateRangeDropdown;