import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

function RSToolTip(props) {
	const [tooltipOpen, setToolTipOpen]	= useState(false)

	const toggleToolTip = () => {
		setToolTipOpen(!tooltipOpen)
	}
	return (
		<>
			<span className="mr-1" id={'Tooltip-' + props.id}>
				{props.children}
			</span>
			<Tooltip className='toolTipCustom' placement={props.item.placement} isOpen={tooltipOpen} target={'Tooltip-' + props.id} toggle={toggleToolTip}>
				<div dangerouslySetInnerHTML={{__html: props.item.text}}></div>
			</Tooltip>
		</>
	);
}

export default RSToolTip;