import React from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function ScheduleOptionDropdown({scheduleCount, onClick, index}) {
    const [stepMoreOption, setStepMoreOption]	= useState(false);
	
	const onClickEvent = (type) => {
		setStepMoreOption(false)
		onClick(type, index)
	}

	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => {setStepMoreOption((prevstate) => !prevstate);}} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btnAction'>
				<i className="fa fa-ellipsis"></i>
			</DropdownToggle>
			<DropdownMenu className='campaignFilterDropdownMenu scheduleDropdown singleItem'>
				<div>
					<div className='dropdownRSMenuItems'>
						<div className={`dropdownRSMenuItem rsSkipLead`} onClick={() => onClickEvent("modify")}>
                            <i className="fa-solid fa-pen"></i>
                            <div className='text'>Modify this schedule</div>
						</div>
						<div className={`dropdownRSMenuItem rsDeleteLead alert-300 ${scheduleCount === 1 ? "disabled" : ""}`} onClick={() => onClickEvent("delete")}>
							<i className="fa-solid fa-trash"></i>
							<div className='text'>Delete this schedule</div>
						</div>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default ScheduleOptionDropdown;