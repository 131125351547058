import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EmailCustomToolbar from './components/EmailCustomToolbar';
import { Button, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import MultipleSelectUserEmail from '../MultipleSelectUserEmail';
import { array } from 'yup';
import { PostData } from '../../services/FetchData';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ProductContext } from '../../store';

function EmailEditor({html, onChange, senderUserId, subject, reviewLeads}) {
	const [personalizationModal, setPersonalization]	= useState(false),
	[previewSubject, setPreviewSubject]	= useState(''),
	[previewMessage, setPreviewMessage]	= useState(''),
	[testEmailAddress,setTestEmailAddress]	= useState(''),
	[activeItems, setActiveItems]	= useState([]),
	[activeItem, setActiveItem]	= useState({withoutLead: true}),
	[availableVariable, setAvailableVariable]	= useState({}),
	[localCustomVariableValue, setLocalCustomVariableValue]	= useState({}),
	[showLocalCustomVariable, setShowLocalCustomVariable]	= useState(false),
	[testEmailSendLoader, setTestEmailSendLoader]	= useState(false),
	[activateSendEmail, setActivateSendEmail]	= useState(true),
	[deviceOption, setDeviceOption]	= useState('desktop'),	
	[searchText, setSearchText]	= useState(''),
	[emailPreviewModal, setEmailPreviewModal]	= useState(false),
	[totalCustomVariables]	= useState({firstName: "", lastName: "", email: "", companyName: "", companyDomain: "", icebreaker: "", linkedinUrl: "", picture: "", phone: ""}),
	[customVariables] = useState({ "First name": "firstName", "Last name": "lastName", "Company name": "companyName", "Icebreaker": "icebreaker", "Linkedin url": "linkedinUrl",  "Picture": "picture", "Sender name": "sender.name", "Email": "email", "Company domain": "companyDomain", "Phone": "phone" }),
	[localHtml, setLocalHtml]	= useState(null),
		quillRef	= useRef(null),
		rangeRef	= useRef(null),
		toolbarRef	= useRef(null),
		{
			currentTeamId
		}	= useContext(ProductContext),
		{
			id,
		} = useParams();
	

	// Define custom font sizes
	var Size = Quill.import('attributors/style/size');
	Size.whitelist = ['8px', '9px', '10px', '11px', '12px', '13px', '14px', '16px', '18px', '20px', '24px', '30px', '36px', '48px', '60px', '72px', '96px'];
	Quill.register(Size, true);

	const Font = Quill.import('formats/font');
	// Extend the fonts whitelist
	Font.whitelist = ['arial', 'georgia', 'impact', 'tahoma', 'times-new-roman', 'verdana', 'lucida-console'];
	Quill.register(Font, true);

	// const modules = {
	// 	toolbar: [
	// 	  [{ 'font': Font.whitelist }],
	// 	  [{ 'size': Size.whitelist }],
	// 	  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
	// 	  [{ 'color': ['#000000', '#FF0000', '#00FF00', '#0000FF', '#F00F00', '#EFA023'] }],

	// 	  [{'list': 'ordered'}, {'list': 'bullet'}, 
	// 		{'indent': '-1'}, {'indent': '+1'}],
	// 	  ['link', 'image', 'video'],
	// 	  [{'align': []}],
	// 	  ['clean']
	// 	],
	// 	clipboard: {
	// 	  // toggle to add extra line breaks when pasting HTML:
	// 	  matchVisual: false,
	// 	}
	//  }

	const modules = {
		toolbar: {
			container: "#toolbar",
		}
	}

	const formats = [
		'font', 'size',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 'indent',
		'link', 'image', 'video', "color", 'align'
	]

	useEffect(() => {
		const button = document.getElementById('unsubscribeLinkButton');

		button.addEventListener('click', () => {
			const editor = quillRef.current.getEditor();
			const range = editor.getSelection();
			if (range) {
				const position = range.index;
				editor.insertText(position, 'Unsubscribe', 'link', '{{unsubscribeUrl}}');
			} else {
				const length = editor.getLength();
				editor.insertText(length - 1, 'Unsubscribe', 'link', '{{unsubscribeUrl}}');
			}
		});

		const addPersonalizationButton	= document.getElementById('addPersonalizationButton');

		addPersonalizationButton.addEventListener('click', () => {
			const editor = quillRef.current.getEditor();
			const range = editor.getSelection();
			rangeRef.current	= range
			setPersonalization(true)
		})

        const signatureButton = document.getElementById('signatureButton');
        signatureButton.addEventListener('click', () => {
			const editor = quillRef.current.getEditor();
			const range = editor.getSelection();
			if (range) {
				const position = range.index;
				editor.insertText(position, `{{signature}}`, 'font', true);
			} else {
				const length = editor.getLength();
				editor.insertText(length - 1, `{{signature}}`, 'font', true);
			}
		});		

		return () => {
			button.removeEventListener('click', () => { });
         	signatureButton.removeEventListener('click', () => {})
			addPersonalizationButton.removeEventListener('click', () => {})
		}
	}, []);

	useEffect(() => {
		if( document.querySelector('#toolbar') ){
			const linkButton = document.querySelector('#toolbar').querySelector('.ql-link');
			linkButton.addEventListener('click', () => {
				setTimeout(() => {
					const tooltip = document.querySelector('.ql-tooltip');

					if (tooltip) {
						const currentLeft	= parseInt(tooltip.style.left, 10);
						if ( currentLeft < 0){
							tooltip.style.left	= 0;
						}
					}
				}, 0);
			});
			const videoButton = document.querySelector('#toolbar').querySelector('.ql-video');
			videoButton.addEventListener('click', () => {
				setTimeout(() => {
					const tooltip = document.querySelector('.ql-tooltip');

					if (tooltip) {
						const currentLeft	= parseInt(tooltip.style.left, 10);
						if ( currentLeft < 0){
							tooltip.style.left	= 0;
						}
					}
				}, 0);
			});			
			return () => {
				// Cleanup
				linkButton.removeEventListener('click', () => { });
				videoButton.removeEventListener('click', () => { });
			};
		}
	}, []);

	useEffect(() => {
		setLocalHtml(html)
	}, [html])

	useEffect(() => {
		const linkAHrefs	= document.querySelector('.ql-editor').querySelectorAll('a');
		linkAHrefs.forEach(function (linkAHref) {
			linkAHref.addEventListener('click', () => {
				setTimeout(() => {
					const tooltip = document.querySelector('.ql-tooltip');
					if (tooltip) {
						const currentLeft	= parseInt(tooltip.style.left, 10);
						if ( currentLeft < 0){
							tooltip.style.left	= 0;
						}
					}
					const qlAction	= document.querySelector('.ql-action')
					if( qlAction ){
						qlAction.addEventListener('click', () => {
							setTimeout(() => {
								const tooltip = document.querySelector('.ql-tooltip');
								if (tooltip) {
									const currentLeft	= parseInt(tooltip.style.left, 10);
									if ( currentLeft < 0){
										tooltip.style.left	= 0;
									}
								}
							})
						})
					}
				}, 0);
			})
		})

		const emailPreviewButton	= document.getElementById('emailPreviewButton');
		emailPreviewButton.addEventListener('click', () => {
			addEmailPreviewModal()
		})

		return () => {
			linkAHrefs.forEach(function (linkAHref) {
				linkAHref.removeEventListener('click', () => {})
			})
			emailPreviewButton.removeEventListener('click', () => {})
		}
	}, [localHtml])

	useEffect(() => {
		if( isValidEmail(testEmailAddress) ){
			setActivateSendEmail(false)
		}else{
			setActivateSendEmail(true)
		}
	}, [testEmailAddress])

	const togglePersonalizationModal	= () => {
		setPersonalization((prevstate) => !prevstate)
	}

	const toggleEmailPreviewModal = () => {
		setPreviewMessage(null)
		setPreviewSubject(null)
		setAvailableVariable({})
		setLocalCustomVariableValue({})
		setActiveItem({withoutLead: true})
		setActiveItems([])
		setTestEmailAddress('')
		setEmailPreviewModal((prevstate) => !prevstate)
	}

	const addCustomVariable = (value) => {
		const editor = quillRef.current.getEditor();
		if (rangeRef.current) {
			const position = rangeRef.current.index;
			editor.insertText(position, `{{${customVariables[value]}}}`, 'font', true);
			rangeRef.current	= null
		} else {
			const length = editor.getLength();
			editor.insertText(length-1, `{{${customVariables[value]}}}`, 'font', true);
		}
		togglePersonalizationModal()
	}

	const editorChange = (content, _, source) => {		
		setLocalHtml(content)
		console.log(source)
		console.log(_)
		//if( source === "user" ){
			onChange(content)
		//}
	}

	const changeLocalCustomVariableValue	= (e) => {
		setLocalCustomVariableValue({
			...localCustomVariableValue,
			[e.target.name]: e.target.value
		})
	}

	const changeAvailableVariableValue	= (e) => {
		let localAvailableVariable	=	{
													...availableVariable,
													[e.target.name]: e.target.value
												},
			previewSubjectLocal	= subject,
			previewMessageLocal	= localHtml,
			availableVariableKeys	= Object.keys(localAvailableVariable);
		for( let i = 0; i < availableVariableKeys.length; i++ ){
			const placeholder	= `{{${availableVariableKeys[i]}}}`,
				value	= localAvailableVariable[availableVariableKeys[i]];
			previewSubjectLocal	= previewSubjectLocal.split(placeholder).join(value)
			previewMessageLocal	= previewMessageLocal.split(placeholder).join(value)
		}
		setPreviewMessage(previewMessageLocal)
		setPreviewSubject(previewSubjectLocal)
		setAvailableVariable(localAvailableVariable)
	}

	const addEmailPreviewModal = () => {
		let regex = /{{(.*?)}}/g,
			subjectMatches	= subject ? subject.match(regex) : [],
			messageMatches	= localHtml ? localHtml.match(regex) : [],
			previewMessageLocal	= localHtml,
			previewSubjectLocal	= subject,
			combinedArray	= [];
		if( Array.isArray(subjectMatches) && Array.isArray(messageMatches) ){
			combinedArray = Array.from(new Set([...subjectMatches, ...messageMatches]))
		}else if( Array.isArray(subjectMatches) ){
			combinedArray	= subjectMatches
		}else if( Array.isArray(messageMatches) ){
			combinedArray	= messageMatches
		}
		let finalPlaceholders	= [];
		for(let i = 0; i < combinedArray.length; i++ ){
			let currentDetails	= combinedArray[i],
				replacedString	= currentDetails.replace("{{", "").replace("}}", "")
			finalPlaceholders.push(replacedString)
			previewSubjectLocal = previewSubjectLocal.split(currentDetails).join("");
			previewMessageLocal = previewMessageLocal.split(currentDetails).join("");
		}
		let totalCustomVariablesKeys	= Object.keys(totalCustomVariables),
			availableVariableLocal		= {},
			customVariableValueLocal	= {};
		for( let i = 0; i < totalCustomVariablesKeys.length; i++ ){
			let currentTotalVariableKey	= totalCustomVariablesKeys[i];
			if( finalPlaceholders.indexOf(currentTotalVariableKey) !== -1 ){
				availableVariableLocal[currentTotalVariableKey]	= ""
			}else{
				customVariableValueLocal[currentTotalVariableKey]	= ""
			}
		}
		setAvailableVariable(availableVariableLocal)
		setLocalCustomVariableValue(customVariableValueLocal)		
		setPreviewMessage(previewMessageLocal)
		setPreviewSubject(previewSubjectLocal)
		setEmailPreviewModal(true)
	}

	const changeActiveItems = (type)	=> {
		let localActiveItems	= [...activeItems];
		if( localActiveItems.indexOf(type) !== -1 ){
			let activeItemsIndex	= localActiveItems.indexOf(type);
			localActiveItems.splice(activeItemsIndex, 1)
		}else{
			localActiveItems.push(type)
			if( type === "withoutLead" ){
				setActiveItem({withoutLead: true})
			}
		}		
		setActiveItems(localActiveItems)
	}

	const selectLead	= (index) => {
		let currentLead	= reviewLeads[index],
			previewSubjectLocal	= subject,
			previewMessageLocal	= localHtml;
		
		let availableVariableKeys	= Object.keys(availableVariable);
		for( let i = 0; i < availableVariableKeys.length; i++ ){
			const placeholder	= `{{${availableVariableKeys[i]}}}`,
				value	= currentLead[availableVariableKeys[i]];
			previewSubjectLocal	= previewSubjectLocal.split(placeholder).join(value)
			previewMessageLocal	= previewMessageLocal.split(placeholder).join(value)
		}
		setPreviewMessage(previewMessageLocal)
		setPreviewSubject(previewSubjectLocal)
		setActiveItem({lead: index})
	}

	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	const sendTestEmail	= () => {
		setTestEmailSendLoader(true)
		PostData("ms1", "api/v1/campaign/test-email", {subject: previewSubject, message: previewMessage, campaign_id: id, team_id: currentTeamId, email: testEmailAddress }).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					toast.success(result.message)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setTestEmailSendLoader(false)
		})
	}

	return (
		<>
			<ReactQuill
				ref={quillRef}
				value={html}
				theme="snow"
				modules={modules}
				formats={formats}
				onChange={editorChange}
			/>
			<EmailCustomToolbar />
			{personalizationModal &&
				<Modal isOpen={personalizationModal} toggle={togglePersonalizationModal} className='personalizationModal' centered={true}>
					<ModalHeader>
						<h3 className="bold"><i className="fa-solid fa-brush primaryColor"></i> Add personalization</h3>
						<i className="closeModal fa-solid fa-xmark" onClick={() => togglePersonalizationModal()}></i>
					</ModalHeader>
					<ModalBody>
						<div className='d-flex-col noGap list flex-1 liquidListContainer'>
							{Object.keys(customVariables).map((variableKey, index) => {
								return (
									<div className="d-flex-row align-items-center justify-content-between item relative" onClick={() => addCustomVariable(variableKey)} key={index}>
										<div>
											{variableKey === "Email" ?
												<i className="fa-solid fa-envelope"></i>
											: ( variableKey === "First name" || variableKey === "Last name" ) ?
												<i className="fa-solid fa-user"></i>
											: ( variableKey === "Company Name" || variableKey === "Company domain" ) ?
												<i className="fa-solid fa-building"></i>
											: variableKey === "Icebreaker" ?
												<i className="fa-solid fa-bomb"></i>
											: variableKey === "Linkedin url" ?
												<i className="fa-solid fa-link"></i>
											: variableKey === "Picture" ?
												<i className="fa-solid fa-image"></i>
											: variableKey === "Sender name" ?
												<i className="fa-solid fa-share"></i>
											: variableKey === "Phone" ?
												<i className="fa-solid fa-phone"></i>
											:
												<i className="fa-solid fa-pen"></i>
											}
											<span>{variableKey}</span>
										</div>
										<div className='colored'>
										 	{`{{${customVariables[variableKey]}}}`}
										</div>
									</div>
								)
							})}
						</div>
					</ModalBody>
				</Modal>
			}
			{emailPreviewModal &&
				<Modal isOpen={emailPreviewModal} toggle={toggleEmailPreviewModal} className='emailPreviewModal wide searchListStyleModal' centered={true}>
					<ModalBody>
						<Button className='btnModalClose' onClick={() => toggleEmailPreviewModal()}>
							<i className="fa-solid fa-xmark"></i>
						</Button>
						<div className='modalLeft'>
							<div className="header d-flex-col">
								<h2 className="bold">Email step preview</h2>
								<div className="d-flex-row">
									<div className='rsSearchInput'>
										<i className="fa-solid fa-magnifying-glass"></i>
										<Input type='text' placeholder='Lead name or email' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
										<i className="fa-solid fa-circle-xmark rsClearSearch" onClick={() => setSearchText('')}></i>
									</div>
								</div>
							</div>
							<div className='resultsList'>
								<div className='summary'>{reviewLeads.length} leads imported</div>
								<div className='list'>
									<div className={`item rsToggleMenu rsDefaultLead ${typeof activeItem.withoutLead !== "undefined" ? "active" : ""} ${activeItems.indexOf("withoutLead") !== -1 ? "expanded" : ""}`} onClick={() => changeActiveItems("withoutLead")}>
										<span>Preview without specific lead</span>
										<div className='expander right'></div>
									</div>
									<div className='d-flex-col subItems'>										
										{Object.keys(availableVariable).length > 0 && Object.keys(availableVariable).map((variable, index) => {
											return (
												<div className='d-flex-col sm' key={index}>
													<div className='highlightCustomVariable'>{`{{${variable}}}`}</div>
													<div className='textEdit'>
														<div className='inputWrapper'>
															<Input type="text" placeholder='Write custom data here...' className="rsTextEdit" name={variable} value={availableVariable && availableVariable[variable]} onChange={(e) => changeAvailableVariableValue(e)} />
															<div className='rsTextEditBorder'></div>
														</div>
													</div>
												</div>
											)
										})}
										<hr />
										<div className='d-flex-row justify-content-between align-items-center cursorPointer rsToggleExpanded' onClick={() => setShowLocalCustomVariable((prevState) => !prevState)}>
											<span className="bold">Other custom variables</span>
											{showLocalCustomVariable ?
												<i className='fa fa-caret-down'></i>
											:
												<i className='fa fa-caret-up'></i>
											}											
										</div>
										{showLocalCustomVariable &&
											<div className='d-flex-col'>
												{Object.keys(localCustomVariableValue).length > 0 && Object.keys(localCustomVariableValue).map((variable, index) => {
													return (
														<div className='d-flex-col sm' key={index}>
															<div className='highlightCustomVariable'>{`{{${variable}}}`}</div>
															<div className='textEdit'>
																<div className='inputWrapper'>
																	<Input type="text" placeholder='Write custom data here...' className="rsTextEdit" name={variable} value={localCustomVariableValue && localCustomVariableValue[variable]} onChange={(e) => changeLocalCustomVariableValue(e)} />
																	<div className='rsTextEditBorder'></div>
																</div>
															</div>
														</div>
													)
												})}
											</div>
										}
										{/* <div className='d-flex-col'>
											<div className='d-flex-col sm'>
												<div className='highlightCustomVariable'>{`{{email}}`}</div>
												<div className='textEdit'>
													<div className='inputWrapper'>
														<Input type="text" placeholder='Write custom data here...' className="rsTextEdit" name="email" value={localCustomVariableValue && localCustomVariableValue.email} onChange={(e) => changeLocalCustomVariableValue(e)} />
														<div className='rsTextEditBorder'></div>
													</div>
												</div>
											</div>
											<div className='d-flex-col sm'>
												<div className='highlightCustomVariable'>{`{{firstName}}`}</div>
												<div className='textEdit'>
													<div className='inputWrapper'>
														<Input type="text" className="rsTextEdit" placeholder='Write custom data here...' name="firstName" value={localCustomVariableValue && localCustomVariableValue.firstName} onChange={(e) => changeLocalCustomVariableValue(e)} />
														<div className='rsTextEditBorder'></div>
													</div>
												</div>
											</div>
											<div className='d-flex-col sm'>
												<div className='highlightCustomVariable'>{`{{lastName}}`}</div>
												<div className='textEdit'>
													<div className='inputWrapper'>
														<Input type="text" className="rsTextEdit" placeholder='Write custom data here...' name="lastName" value={localCustomVariableValue && localCustomVariableValue.lastName} onChange={(e) => changeLocalCustomVariableValue(e)} />
														<div className='rsTextEditBorder'></div>
													</div>
												</div>
											</div>
											<div className='d-flex-col sm'>
												<div className='highlightCustomVariable'>{`{{picture}}`}</div>
												<div className='textEdit'>
													<div className='inputWrapper'>
														<Input type="text" className="rsTextEdit" placeholder='Write custom data here...' name="picture" value={localCustomVariableValue && localCustomVariableValue.picture} onChange={(e) => changeLocalCustomVariableValue(e)} />
														<div className='rsTextEditBorder'></div>
													</div>
												</div>
											</div>
											<div className='d-flex-col sm'>
												<div className='highlightCustomVariable'>{`{{phone}}`}</div>
												<div className='textEdit'>
													<div className='inputWrapper'>
														<Input type="text" className="rsTextEdit" placeholder='Write custom data here...' name="phone" value={localCustomVariableValue && localCustomVariableValue.phone} onChange={(e) => changeLocalCustomVariableValue(e)} />
														<div className='rsTextEditBorder'></div>
													</div>
												</div>
											</div>
											<div className='d-flex-col sm'>
												<div className='highlightCustomVariable'>{`{{linkedinUrl}}`}</div>
												<div className='textEdit'>
													<div className='inputWrapper'>
														<Input type="text" className="rsTextEdit" placeholder='Write custom data here...' name="linkedinUrl" value={localCustomVariableValue && localCustomVariableValue.linkedinUrl} onChange={(e) => changeLocalCustomVariableValue(e)} />
														<div className='rsTextEditBorder'></div>
													</div>
												</div>
											</div>
											<div className='d-flex-col sm'>
												<div className='highlightCustomVariable'>{`{{companyName}}`}</div>
												<div className='textEdit'>
													<div className='inputWrapper'>
														<Input type="text" className="rsTextEdit" placeholder='Write custom data here...' name="companyName" value={localCustomVariableValue && localCustomVariableValue.companyName} onChange={(e) => changeLocalCustomVariableValue(e)} />
														<div className='rsTextEditBorder'></div>
													</div>
												</div>
											</div>
											<div className='d-flex-col sm'>
												<div className='highlightCustomVariable'>{`{{companyDomain}}`}</div>
												<div className='textEdit'>
													<div className='inputWrapper'>
														<Input type="text" className="rsTextEdit" placeholder='Write custom data here...' name="companyDomain" value={localCustomVariableValue && localCustomVariableValue.companyDomain} onChange={(e) => changeLocalCustomVariableValue(e)} />
														<div className='rsTextEditBorder'></div>
													</div>
												</div>
											</div>
										</div> */}
									</div>
									{reviewLeads.length > 0 &&
										<>
											<div className={`item rsToggleMenu ${activeItems.indexOf("lead") !== -1 ? "expanded" : ""}`} onClick={() => changeActiveItems("lead")}>
												<span>Leads without missing information</span>
												<div className='expander rsToggleMenu'></div>
											</div>
											<div className='subItems'>
												{reviewLeads.map((leads, index) => {
													return(
														<div className={`item rsSelectLead ${(typeof activeItem.lead !== "undefined" && activeItem.lead === index) ? "active" : ""}`} key={index} onClick={() => selectLead(index)}>
															<span className="emailTemplateName">{leads.email}</span>
														</div>
													)
												})}
											</div>
										</>
									}
								</div>
							</div>
						</div>
						<div className='modalRight d-flex-col flex-1'>
							<div className='modalRight emailPreview'>
								<div className='d-flex-col modalCenter'>
									<div className='title'>
										<h2 className="bold">Preview without specific lead</h2>
										<div className="subtitle">Preview your email by testing combinations of custom variables</div>
									</div>
									<div className='d-flex-col'>
										<h4 className="bold">Sender for this email step</h4>
										<div className='emailTemplateSender'>
											<MultipleSelectUserEmail disabled={true} onClick={(userId) => console.log(userId)} senderUserId={senderUserId} placeholder={"Select a sender"} />
										</div>
									</div>
									<div className='d-flex-row align-items-center justify-content-between'>
										<h4 className="bold">Email preview</h4>
										<button className={`btn rsSwitchBox rsToggleDevice secondary ${deviceOption !== "desktop" ? "checked" : ""} `}>
											<a className="textLabel semiBold" onClick={() => setDeviceOption('desktop')}><i className="fa-solid fa-desktop"></i></a>
											<a className="textLabel semiBold" onClick={() => setDeviceOption('mobile')} ><i className="fa-solid fa-mobile"></i></a>
										</button>
									</div>
									<div className={`d-flex-col ${deviceOption}`}>
										<div className='emailTemplatePreview'>
											{previewSubject}
										</div>
										<div className='emailTemplatePreview'>
											<div dangerouslySetInnerHTML={{__html: previewMessage}}></div>
										</div>
									</div>
								</div>
							</div>
							<div className='previewModalFooter'>
								<div className='input-group'>
									<div className='textEdit'>
										<Label>Email address</Label>
										<div className='inputWrapper'>
											<Input type="text" placeholder='' className="rsTextEdit" name="email" value={testEmailAddress} onChange={(e) => setTestEmailAddress(e.target.value)} />
											<div className='rsTextEditBorder'></div>
										</div>
									</div>
									<Button color='primary' className='md' disabled={activateSendEmail || testEmailSendLoader} onClick={() => sendTestEmail()}>
										{testEmailSendLoader &&
											<Spinner size={"sm"} />
										}
										Send email test
										<span className="material-symbols-outlined">send</span>
									</Button>
								</div>
							</div>
						</div>
					</ModalBody>
				</Modal>
			}
		</>
	);
}

export default EmailEditor;