import moment from 'moment';
import React from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

function LeadScheduleToolTip({ children, innerClass, tasks, type }) {
	return (
		<Tooltip
			html={(
				<>
					{(type === "done" || type === "future" ) ?
						<>
							{tasks.map((task, index) => {
								return(
									<div className="tooltipTaskLine" key={index}>
										<div className="stepIndex">Step {task.step+1}</div>
										<div className="channelIcon  sm email">
											<i className="fa-regular fa-envelope"></i>
										</div>
										<div className="stepDesc">
											<div className="stepTitle">Send email</div>
											{type === "done" ?
												<>
													<div className="stepSentAt">{moment(task.sent_on).format('h:mm A MMMM D, YYYY')}</div>
													<div className="stepStatus success">
														<i className="fa-solid fa-circle-check"></i>Done
													</div>
												</>
											:
												<>
													<div className="stepStatus future">
														<i className="fa-solid fa-circle-check"></i>Programmed
													</div>
												</>
											}
										</div>
									</div>
								)
							})}
						</>
					: type === "current" &&
						<div className="tooltipTaskLine">
							<div className="stepIndex">Step {tasks.step+1}</div>
							<div className="channelIcon  sm email">
								<i className="fa-regular fa-envelope"></i>
							</div>
							<div className="stepDesc">
								<div className="stepTitle">Send email</div>
								<div className="stepSentAt">{moment(tasks.sent_on).format('h:mm A MMMM D, YYYY')}</div>
								<div className="stepStatus current">
									<i className="fa-solid fa-circle-check"></i>In progress
								</div>
							</div>
						</div>
					}
				</>	
			)}
			position="top"
			trigger="mouseenter"
			className={innerClass}
			interactive
			arrow
			style={{}}
		>
			{children}
		</Tooltip>
		// <div className={`rsCustomToolTip ${innerClass}`}>
		// 	{children}
		// 	{type == "done" &&
		// 		<div className='tooltipOuterContent'>
		// 			<div className='tooltipContent'>
		// 				<div className="tooltipTaskLine">
		// 					<div className="stepIndex">Step 1</div>
		// 					<div className="channelIcon  sm email">
		// 						<i className="fa-regular fa-envelope"></i>
		// 					</div>
		// 					<div className="stepDesc">
		// 						<div className="stepTitle">Send email</div>
		// 						<div className="stepSentAt">12:22 PM January 24, 2024</div>
		// 						<div className="stepStatus success">
		// 							<i className="fa-regular fa-circle-check"></i>Done
		// 						</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 			<div className='tipContentSvgArrow'>
		// 				<svg width="16" height="6" xmlns="http://www.w3.org/2000/svg">
		// 					<path d="M0 6s1.796-.013 4.67-3.615C5.851.9 6.93.006 8 0c1.07-.006 2.148.887 3.343 2.385C14.233 6.005 16 6 16 6H0z"></path>
		// 				</svg>
		// 			</div>
		// 		</div>
		// 	}
		// </div>
	);
}

export default LeadScheduleToolTip;