import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification = props => {
	if (props.notify) {
		toast(props.message, {
			position: "bottom-right",
			autoClose: false,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true
		});
	}
	return (
		<ToastContainer position="top-right" autoClose={5000} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable theme="dark" />
	)
}
export default Notification;