import React, { useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SignatureCustomToolbar from './components/SignatureCustomToolbar';

function SignatureEditor(props) {

	const quillRef	= useRef(null),
		toolbarRef	= useRef(null);

	// Define custom font sizes
	var Size = Quill.import('attributors/style/size');
	Size.whitelist = ['8px', '9px', '10px', '11px', '12px', '13px', '14px', '16px', '18px', '20px', '24px', '30px', '36px', '48px', '60px', '72px', '96px'];
	Quill.register(Size, true);

	const Font = Quill.import('formats/font');
	// Extend the fonts whitelist
	Font.whitelist = ['arial', 'georgia', 'impact', 'tahoma', 'times-new-roman', 'verdana', 'lucida-console'];
	Quill.register(Font, true);

	// const modules = {
	// 	toolbar: [
	// 	  [{ 'font': Font.whitelist }],
	// 	  [{ 'size': Size.whitelist }],
	// 	  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
	// 	  [{ 'color': ['#000000', '#FF0000', '#00FF00', '#0000FF', '#F00F00', '#EFA023'] }],

	// 	  [{'list': 'ordered'}, {'list': 'bullet'}, 
	// 		{'indent': '-1'}, {'indent': '+1'}],
	// 	  ['link', 'image', 'video'],
	// 	  [{'align': []}],
	// 	  ['clean']
	// 	],
	// 	clipboard: {
	// 	  // toggle to add extra line breaks when pasting HTML:
	// 	  matchVisual: false,
	// 	}
	//  }

	const modules = {
		toolbar: {
			container: "#toolbar",
		}
	}

	const formats = [
		'font', 'size',
		'bold', 'italic', 'underline', 'strike', 'blockquote',
		'list', 'bullet', 'indent',
		'link', 'image', 'video', "color", 'align'
	]

	useEffect(() => {
		const button = document.getElementById('unsubscribeLinkButton');

		button.addEventListener('click', () => {
			const editor = quillRef.current.getEditor();
			const range = editor.getSelection();
			if (range) {
				const position = range.index;
				editor.insertText(position, 'Unsubscribe', 'link', '{{unsubscribeUrl}}');
			} else {
				const length = editor.getLength();
				editor.insertText(length - 1, 'Unsubscribe', 'link', '{{unsubscribeUrl}}');
			}
		});

		return () => {
			button.removeEventListener('click', () => { });
		}
	}, []);

	useEffect(() => {
		if( document.querySelector('#toolbar') ){
			const linkButton = document.querySelector('#toolbar').querySelector('.ql-link');
			linkButton.addEventListener('click', () => {
				setTimeout(() => {
					const tooltip = document.querySelector('.ql-tooltip');

					if (tooltip) {
						const currentLeft	= parseInt(tooltip.style.left, 10);
						if ( currentLeft < 0){
							tooltip.style.left	= 0;
						}
					}
				}, 0);
			});
			const videoButton = document.querySelector('#toolbar').querySelector('.ql-video');
			videoButton.addEventListener('click', () => {
				setTimeout(() => {
					const tooltip = document.querySelector('.ql-tooltip');

					if (tooltip) {
						const currentLeft	= parseInt(tooltip.style.left, 10);
						if ( currentLeft < 0){
							tooltip.style.left	= 0;
						}
					}
				}, 0);
			});
			return () => {
				// Cleanup
				linkButton.removeEventListener('click', () => { });
				videoButton.removeEventListener('click', () => { });
			};
		}
	}, []);

	return (
		<>
			<ReactQuill
				ref={quillRef}
				value={props.html}
				theme="snow"
				modules={modules}
				formats={formats}
				onChange={(value) => props.onChange(value) }
			/>
			<SignatureCustomToolbar />
		</>
	);
}

export default SignatureEditor;