import React from "react";
import { useState } from "react";
// import formats from './ToolbarOptions.js'
import { Button } from "reactstrap";

// const renderOptions = (formatData) => {
// 	const { className, options } = formatData;
// 	return (
		// <select className={className}>
		// 	<option selected="selected"></option>
		// 	{
		// 		options.map(value => {
		// 			return (
		// 				<option value={value}></option>
		// 			)
		// 		})
		// 	}
		// </select>
// 	)
// }
// const renderSingle = (formatData) => {
// 	const { className, value } = formatData;
// 	return (
// 		<button className={className} value={value}></button>
// 	)
// }

const SignatureCustomToolbar = () => {
	const [toolbarOption, setToolbarOption]			= useState(false),
		[toolbarVideoOption, setToolbarVideoOption]	= useState(false);

	const toggleMoreOption	= () => {
		setToolbarVideoOption(false)
		setToolbarOption(!toolbarOption)
	}

	const toggleMediaOption = () => {
		setToolbarOption(false)
		setToolbarVideoOption(!toolbarVideoOption)
	}

	return(
		<div id="toolbar">
			<div className={`moreParagraphOption ${toolbarOption ? "expanded" : ""}`}>
				<span className="ql-formats">
					<select className={"ql-font"}>
						<option selected="selected"></option>
						{
							['arial', 'georgia', 'impact', 'tahoma', 'times-new-roman', 'verdana', 'lucida-console'].map(value => {
								return (
									<option value={value}></option>
								)
							})
						}
					</select>
				</span>
				<span className="ql-formats">
					<select className={"ql-size"}>
						<option selected="selected"></option>
						{
							['8px', '9px', '10px', '11px', '12px', '13px', '14px', '16px', '18px', '20px', '24px', '30px', '36px', '48px', '60px', '72px', '96px'].map(value => {
								return (
									<option value={value}></option>
								)
							})
						}
					</select>
				</span>
				<span className="ql-formats">
					<button className={"ql-bold"}></button>
				</span>
				<span className="ql-formats">
					<button className={"ql-italic"}></button>
				</span>
				<span className="ql-formats">
					<button className={"ql-underline"}></button>
				</span>
				<span className="ql-formats">
					<select className={"ql-color"}>
						<option selected="selected"></option>
						{
							["#61BD6D", "#1ABC9C", "#54ACD2", "#2C82C9", "#9365B8", "#475577", "#CCCCCC", "#41A85F", "#00A885", "#3D8EB9", "#2969B0", "#553982", "#28324E", "#000000", "#F7DA64", "#FBA026", "#EB6B56", "#E25041", "#A38F84", "#EFEFEF", "#FFFFFF", "#FAC51C", "#F37934", "#D14841", "#B8312F", "#7C706B", "#D1D5D8"].map(value => {
								return (
									<option value={value}></option>
								)
							})
						}
					</select>
				</span>
				<span className="ql-formats">
					<select className={"ql-align"}>
						<option selected="selected"></option>
						{
							['center', 'justify', 'right'].map(value => {
								return (
									<option value={value}></option>
								)
							})
						}
					</select>
				</span>
				<span className="ql-formats">
					<button className={"ql-list"} value={"bullet"}></button>
				</span>
				<span className="ql-formats">
					<button className={"ql-list"} value={"ordered"}></button>
				</span>
			</div>
			<div className={`moreParagraphOption ${toolbarVideoOption ? "expanded" : ""}`}>
				<span className="ql-formats">
					<button className="ql-image"></button>
				</span>
				<span className="ql-formats">
					<button className="ql-video"></button>
				</span>
			</div>
			<span className="ql-formats">
				<Button className="textToolbarBtn" onClick={() => toggleMoreOption()}>
					<i className="fa-solid fa-t"></i>
				</Button>
			</span>
			<span className="ql-formats">
				<button className={"ql-link"}>
				</button>
			</span>
			<span className="ql-formats">
					<button id="unsubscribeLinkButton">
				<i className="fa-solid fa-user-xmark"></i>
				</button>
			</span>
			<span className="ql-formats">
				<Button className="textToolbarBtn" onClick={() => toggleMediaOption()}>
					<i className="fa-solid fa-ellipsis-vertical"></i>
				</Button>
			</span>
		</div>
	)
}
export default SignatureCustomToolbar;

// {
// 	formats.map(classes => {
// 		return (
// 			<span className="ql-formats">
// 				{
// 					classes.map(formatData => {
// 						return formatData.options ? renderOptions(formatData) : renderSingle(formatData)
// 					})
// 				}
// 			</span>
// 		)
// 	})
// }