import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { createRoot } from 'react-dom/client';
import VariableDropdown from '../../../components/VariableDropdown';
import { ProductContext } from '../../../store';
import { Navigate, useParams } from 'react-router-dom';
import { PostData } from '../../../services/FetchData';
import { toast } from 'react-toastify';

function LeadsEdit(props) {
	const [importModalFlag, setImportModalFlag] = useState(false),
		[redirectFlag, setRedirectFlag]	= useState(false),
		[importCurrentTab, setImportCurrentTab] = useState('csv'),
		[importStep, setImportStep]	= useState(1),
		inputFileRef	= useRef(null),
		[fileUploaded, setFileUploaded]	= useState(null),
		[fileUploadRowCount, setFileUploadRowCount]	= useState(0),
		[invalidEmailCount, setInvalidEmailCount]	= useState(0),
		[unsubscribeEmailCount, setUnsubscribeEmailCount]	= useState(0),
		[progressValue, setProgressValue]	= useState(0),
		[duplicateCampaignCount, setDuplicateCampaignCount]	= useState(0),
		[duplicateOtherCampaignCount, setDuplicateOtherCampaignCount]	= useState(0),
		[slicedData, setSlicedData]	= useState([]),
		[fileLoadedFlag, setFileLoadedFlag]	= useState(false),
		[uploadSuccessFlag, setUploadSuccessFlag]	= useState(false),
		[importDataColumn, setImportDataColumn]	= useState([]),
		[importDuplicateOption, setImportDuplicateOption]	= useState(null),
		[importLoaderApi, setImportLoaderApi]	= useState(false),
		[localCustomVariable]	= useState(["firstName", "lastName", "companyName", "icebreaker", "linkedinUrl", "picture", "email", "companyDomain", "phone" ]),
		progressValueRef	= useRef(0),
		progressInterRef	= useRef(null),
		{
			id,
			"*": wildcard
		} = useParams(),
		{
			campaignLeads,
			currentTeamId,
			setCampaignLeads
		} = useContext(ProductContext);
	
	useEffect(() => {
		if( campaignLeads.length > 0 ){
			setRedirectFlag(true)
		}
	}, [campaignLeads])

	useEffect(() => {
		if( fileUploaded ){
			continueFileUpload()
			progressInterRef.current = setInterval(() => {
				if (progressValueRef.current <= 90) {
					progressValueRef.current += 5;
					setProgressValue(progressValueRef.current)
				} else {
					clearInterval(progressInterRef.current)
				}
			}, 500);
			return () => clearInterval(progressInterRef.current);
		}
	}, [fileUploaded, progressInterRef, progressValueRef]);

	const toggleImportModal = () => {
		if( !importLoaderApi ){
			setImportModalFlag((prevState) => !prevState);
		}
	}

	const fileUpload = (event) => {
		let file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const content = e.target.result;

				// Split the content by lines (assuming it's a text file)
				const lines = content.split('\n');

				// Calculate the number of rows
				let numberOfRows = 0;
				for( let i = 1; i < lines.length; i++ ){
					if( lines[i] !== "" ){
						numberOfRows++
					}
				}
				// Update the state with the number of rows
				setFileUploadRowCount(numberOfRows);
			};

			// Read the file as text
			reader.readAsText(file);
			progressValueRef.current	 = 0
			setFileUploaded(file)
		}
		event.target.value = ""
	}

	const createFileUpload = () => {
		let inputFile = React.createElement('input', { type: 'file', name: 'myfile', id: 'myfile', ref: inputFileRef, accept: ".csv", onChange: (event) => { fileUpload(event) } })
		const container = document.getElementById('uploadFile');
		const root = createRoot(container); // createRoot(container!) if you use TypeScript
		root.render(inputFile);
		setTimeout(() => {
			inputFileRef.current.click()
		}, 200);
	}

	const openLeadModal = (type) => {
		setImportModalFlag(true)
		setImportStep(1)
		setImportCurrentTab(type)
	}

	const continueFileUpload = async () => {
		if (fileUploaded) {
			const formData = new FormData()
			formData.append('csv_file', fileUploaded)
			formData.append('campaign_id', id)
			formData.append('team_id', currentTeamId)
			PostData("ms1", "api/v1/campaign/import-csv-lead", formData, true).then((result) => {
				if ( result !== "Invalid" && result !== undefined ) {
					if (result.status === "success") {
						setDuplicateCampaignCount(result.data.duplicateInCurrentCampaign)
						setDuplicateOtherCampaignCount(result.data.duplicateOtherCampaign)
						setFileUploadRowCount(result.data.dataCount)
						setInvalidEmailCount(result.data.invalidEmailCount)
						setUnsubscribeEmailCount(result.data.unsubscribeEmailCount)
						let tempData			= result.data.data,
							localSlicedData	= {},
							localImportDataColumn	= [];
						for( let i = 0; i < tempData.length; i++ ){
							let tempDataObjectKeys	= Object.keys(tempData[i]).filter((obj) => obj.trim());
							for( let j = 0; j < tempDataObjectKeys.length; j++ ){
								if( typeof localSlicedData[tempDataObjectKeys[j]] === "undefined" ){
									localImportDataColumn.push(tempDataObjectKeys[j]);
									localSlicedData[tempDataObjectKeys[j]]	= {key: tempDataObjectKeys[j], values: tempData[i][tempDataObjectKeys[j]]}
									const index = localCustomVariable.findIndex(element => element.toLowerCase() === tempDataObjectKeys[j].replace(/\s/g, '').toLowerCase());
									localSlicedData[tempDataObjectKeys[j]]["db_key"]	= index !== -1 ? localCustomVariable[index] : "customVariable"
								}else{
									localSlicedData[tempDataObjectKeys[j]]["values"]	+= ", "+ tempData[i][tempDataObjectKeys[j]]
								}
							}
						}
						setImportDataColumn(localImportDataColumn)
						setSlicedData(localSlicedData)
						if (progressInterRef.current) {
							clearInterval(progressInterRef.current)
							progressInterRef.current = null
						}
						progressValueRef.current	= 100;
						setProgressValue(100)
						if( result.data.dataCount !== result.data.duplicateInCurrentCampaign ){
							setUploadSuccessFlag(true)
						}
					}else if( result.status	=== "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
				//setFileUploaded(null)
			})
		}
	}

	const gotoImportStep = (step) => {
		setImportStep(step)
	}

	const removeFileUploaded	= () => {
		setFileLoadedFlag(false)
		setFileUploadRowCount(0)
		setDuplicateCampaignCount(0)
		setDuplicateOtherCampaignCount(0)
		setInvalidEmailCount(0)
		setUnsubscribeEmailCount(0)
		setFileUploaded(null)
		setProgressValue(0)
		progressValueRef.current	= 0
		setSlicedData({})
		setUploadSuccessFlag(false)
	}

	const setVariableDBKey	= (dbKey, index) => {
		let localTempData	= {...slicedData}
		localTempData[index]["db_key"]	= dbKey
		setSlicedData(localTempData)
	}

	const toggleAllImportColumn	= (e) => {
		if( e.target.checked ){
			setImportDataColumn(Object.keys(slicedData))
		}else{
			setImportDataColumn([])
		}
	}

	const toggleImportColumn = (e, key) => {
		if( e.target.checked ){
			setImportDataColumn([...importDataColumn, key])
		}else{
			let localImportDataColumn	= [...importDataColumn],
				indexToSplice				= localImportDataColumn.indexOf(key);
			if( indexToSplice !== -1 ){
				localImportDataColumn.splice(indexToSplice, 1)
				setImportDataColumn(localImportDataColumn)
			}
		}
	}

	const validateImportStep2	= () => {
		let localSlicedData	= {...slicedData},
			localSlicedDataObjKey	= Object.keys(localSlicedData),
			dbKeyValue	= [];
		for( let i = 0; i < localSlicedDataObjKey.length; i++ ){
			if( importDataColumn.indexOf(localSlicedDataObjKey[i]) !== -1 ){
				dbKeyValue.push(localSlicedData[localSlicedDataObjKey[i]].db_key)
			}
		}
		if( dbKeyValue.indexOf("email") !== -1 ){
			gotoImportStep(3)
		}else{
			toast.error("Without email column you can't create a lead")
		}
	}

	const importFileUploadLead = () => {		
		if (fileUploaded) {
			setImportLoaderApi(true)
			const formData = new FormData()
			formData.append('csv_file', fileUploaded)
			formData.append('campaign_id', id)
			formData.append('team_id', currentTeamId)
			formData.append('import_column', JSON.stringify(importDataColumn))
			formData.append('import_column_map', JSON.stringify(slicedData))
			formData.append('duplicate_import_option', importDuplicateOption)
			formData.append('import_lead_count', importDuplicateOption === "campaign" ? ( fileUploadRowCount - duplicateCampaignCount - invalidEmailCount - unsubscribeEmailCount ) : ( fileUploadRowCount - duplicateOtherCampaignCount - invalidEmailCount - unsubscribeEmailCount ))
			PostData("ms1", "api/v1/campaign/import-csv-lead-final-step", formData, true).then((result) => {
				if ( result !== "Invalid" && result !== undefined ) {
					if (result.status === "success") {
						setFileUploaded(null)
						setRedirectFlag(true)
						setImportLoaderApi(false)
						setCampaignLeads(result.data.leadData)
					}else if( result.status	=== "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
				//setFileUploaded(null)
			})
		}
	}

	if( redirectFlag ){
		return <Navigate to={"/user/campaign/"+id+"/leads"} replace />
	}

	return (
		<>
			<div className={`campaignEditContentWrapper tabLeads`}>
				<div className='center'>
					<div className='campaignTabLeads importMethods'>
						<div className='d-flex-col sm align-items-center'>
							<h2>Choose a method to import your leads</h2>
							<span className="text-light">You can perform several different imports in the same campaign</span>
						</div>
						<div className='d-flex-col align-items-center'>
							<div className='d-flex-row'>
								<span className="method csv rsImportLeadsMethod" onClick={() => openLeadModal("csv")}>
									<span className="text-light">Import from</span>
									<span className="importMethodName">CSV</span>
								</span>
								<span className="method manually rsImportLeadsMethod disabled">
									<span className="text-light">Import manually</span>
									<span className="importMethodName">Manually</span>
								</span>
								<span className="method linkedin rsImportLeadsMethod disabled">
									<span className="text-light">Import from</span>
									<span className="importMethodName">LinkedIn</span>
								</span>
							</div>
							<div className='d-flex-row'>
								<span className="method campaign rsImportLeadsMethod disabled">
									<span className="text-light">Import from</span>
									<span className="importMethodName">Existing campaign</span>
								</span>
								<span className="method crm rsImportLeadsMethod disabled">
									<span className="text-light">Import from</span>
									<span className="importMethodName">CRM</span>
								</span>
								<span className="method zapier rsImportLeadsMethod disabled">
									<span className="text-light">Import from</span>
									<span className="importMethodName">Zapier</span>
								</span>
								<span className="method api rsImportLeadsMethod disabled">
									<span className="text-light">Import from</span>
									<span className="importMethodName">API</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id='uploadFile'></div>
			{importModalFlag &&
				<Modal size='xl' isOpen={importModalFlag} toggle={toggleImportModal} className='tabStyleModal campaignLeadsImportModal' backdrop={"static"}>
					<Button className='btnModalClose' onClick={() => toggleImportModal()}>
						<i className="fa-solid fa-xmark"></i>
					</Button>
					<ModalBody>
						<div className={`modalLeft ${importStep === 3 ? "d-none" : ""}`}>
							<div className='modalLeftHeading'>Import leads</div>
							<div className='modalLeftItemsWrapper'>
								<div className={`modalLeftItemWrap cursorPointer ${importCurrentTab === "csv" ? "active" : ""}`} onClick={() => setImportCurrentTab("csv")}>
									<i className="fa-solid fa-file-csv"></i>
									Import from CSV
								</div>
								<div className={`modalLeftItemWrap cursorPointer disabled ${importCurrentTab === "manually" ? "active" : ""}`} onClick={() => setImportCurrentTab("manually")}>
									<i className="fa-regular fa-hand"></i>
									Import emails manually
								</div>
								<div className={`modalLeftItemWrap disabled cursorPointer ${importCurrentTab === "crm" ? "active" : ""}`}>
									<i className="fa-solid fa-database"></i>
									Import from CRM
								</div>
								<div className={`modalLeftItemWrap disabled cursorPointer ${importCurrentTab === "campaign" ? "active" : ""}`}>
									<i className="fa-solid fa-bullhorn"></i>
									From an existing campaign
								</div>
								<div className={`modalLeftItemWrap disabled cursorPointer ${importCurrentTab === "linkedin" ? "active" : ""}`}>
									<img src={require('../../../assets/images/linkedin.svg').default} alt='Linkedin' />
									Import leads from LinkedIn
								</div>
								<div className={`modalLeftItemWrap disabled cursorPointer ${importCurrentTab === "zapier" ? "active" : ""}`}>
									<img src={require('../../../assets/images/zapier.svg').default} alt='Linkedin' />
									Import from Zapier
								</div>
								<div className={`modalLeftItemWrap disabled cursorPointer ${importCurrentTab === "api" ? "active" : ""}`}>
									<i className="fa-solid fa-gear"></i>
									Import from API
								</div>
							</div>
						</div>
						<div className='modalRight'>
							{importCurrentTab === "csv" &&
								importStep === 1 ?
									<>
										<div className='modalTop'>
											<div className='title'>
												1/3
												<img src={require('../../../assets/images/circle-filled-25.svg').default} className='importProgression' alt='Step Progression' />
												Upload a CSV
											</div>
										</div>
										<div className='modalCenter'>
											<div className='d-flex-col lg campaignLeadsImportCSV'>
												{fileUploaded ?
													<div className={`rsImportArea`}>
														<img src={require('../../../assets/images/csv-filigrane.svg').default} className='importAreaFiligrane' alt='CSV import icon' />
														<div>
															<label className="btn btn-tertiary importButton pointer-events-none">
																<input type="file" className="d-none" accept=".csv" name="csv-file" disabled={fileUploaded} />
																	Click to upload
															</label>
														</div>
														<div className="text-inactive">Only .csv files are accepted - Maximum 10,000 leads</div>
													</div>
												:
													<div className={`rsImportArea allowed`} onClick={() => createFileUpload()}>
														<img src={require('../../../assets/images/csv-filigrane.svg').default} className='importAreaFiligrane' alt='CSV import icon' />
														<div>
															<label className="btn btn-tertiary importButton pointer-events-none">
																<input type="file" className="d-none" accept=".csv" name="csv-file" disabled={fileUploaded} />
																	Click to upload
															</label>
														</div>
														<div className="text-inactive">Only .csv files are accepted - Maximum 10,000 leads</div>
													</div>
												}
												<div className="d-flex-col">
													{!fileUploaded ? 
														<div className="fakeSection height100Pixels"></div>
													:
														<>
															{(duplicateCampaignCount > 0 || invalidEmailCount > 0 || unsubscribeEmailCount > 0 ) &&
																<div className='importSummaryIssues'>
																	<div className='rsInfoMsg warning'>
																		<div className='flex-1'>
																			<div className='d-flex-row'>
																				<div className='d-flex-col noGap flex-1'>
																					{duplicateCampaignCount > 0 &&
																						<span><b>{duplicateCampaignCount}</b> lead{duplicateCampaignCount > 1 ? "s is " : " are "} already in this campaign</span>
																					}
																					{invalidEmailCount > 0 &&
																						<span><b>{invalidEmailCount}</b> lead{invalidEmailCount > 1 ? "s" : ""} have an invalid email address</span>
																					}
																					{unsubscribeEmailCount > 0 &&
																						<span><b>{unsubscribeEmailCount}</b> lead{unsubscribeEmailCount > 1 ? "s is " : " are "} in unsubscribe list</span>
																					}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															}
															<div className='uiSection importCSVData shadow mvp'>
																<div className='content'>
																	<i className="fa-solid fa-trash rsImportDataClear" disabled={fileLoadedFlag} onClick={() => removeFileUploaded()}></i>
																	<div className='d-flex-row'>
																		<button className='btn btn-ico btn-primary btn-success pointer-events-none'>
																			<i className="fa-solid fa-check"></i>
																		</button>
																		<div className='d-flex-col xs flex-1'>
																			<h4 className="importLeadsFileName">{fileUploaded.name}</h4>
																			<div className="importLeadsCount">{fileUploadRowCount} lead{fileUploadRowCount.length > 1 ? "s" : ""} found</div>
																			<div className='d-flex-row sm align-items-center'>
																				<div className='importProgressContainer flex-1'>
																					<div className='importProgressBar' style={{width: `${progressValue}%`}}></div>
																				</div>
																				<div className='importProgressPercentage'>{progressValue}%</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</>
													}
												</div>
											</div>
										</div>
										<div className='d-flex-row sm modalButtons align-items-center justify-content-end'>
											<span className="flex-1"></span>
											{!uploadSuccessFlag &&
												<span className='buttonInfoText'>
													<i className="fa-solid fa-circle-info"></i>
													Upload a CSV to start
												</span>
											}
											<Button color='primary' onClick={() => gotoImportStep(2)} disabled={(!uploadSuccessFlag || ( ( duplicateCampaignCount + invalidEmailCount + unsubscribeEmailCount ) === fileUploadRowCount ))}>Continue</Button>
										</div>
									</>
								: importStep === 2 ?
									<>
										<div className='modalTop'>
											<div className='title'>
												2/3
												<img src={require('../../../assets/images/circle-filled-70.svg').default} className='importProgression' alt='Step Progression' />
												Set up imported custom variables
											</div>
										</div>
										<div className='modalCenter'>
											<div className='d-flex-col lg campaignLeadsImportCSV'>
												<div className='d-flex-col csvPreview'>
													{(duplicateCampaignCount > 0 || invalidEmailCount > 0 || unsubscribeEmailCount > 0 ) &&
														<div className='importSummaryIssues'>
															<div className='rsInfoMsg warning'>
																<div className='flex-1'>
																	<div className='d-flex-row'>
																		<div className='d-flex-col noGap flex-1'>
																			{duplicateCampaignCount > 0 &&
																				<span><b>{duplicateCampaignCount}</b> lead{duplicateCampaignCount > 1 ? "s is " : " are "} already in this campaign</span>
																			}
																			{invalidEmailCount > 0 &&
																				<span><b>{invalidEmailCount}</b> lead{invalidEmailCount > 1 ? "s" : ""} have an invalid email address</span>
																			}
																			{unsubscribeEmailCount > 0 &&
																				<span><b>{unsubscribeEmailCount}</b> lead{unsubscribeEmailCount > 1 ? "s is " : " are "} in unsubscribe list</span>
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													}
													<div className='rsTable importCSVTable d-flex-col sm'>
														<div className='header'>
															<div className='headerItem checkbox'>
																<div className="checkbox rsCheckbox">
																	<Label className="js-prevent-label-bounce-event-click">
																		<Input type='checkbox' checked={importDataColumn.length === Object.keys(slicedData).length} onChange={(e) => toggleAllImportColumn(e)} />
																	</Label>
																</div>
															</div>
															<div className='headerItem'>Column name</div>
															<div className='headerItem'>variable roundsales type</div>
															<div className='headerItem'>Samples</div>
														</div>
														<div className='tableRows'>
															{Object.keys(slicedData).map((key, index) => {
																return (
																	<React.Fragment key={index}>
																		{key.trim() &&
																			<div className='tableRow checked'>
																				<div className='tableRowItem checkbox'>
																					<div className="checkbox rsCheckbox">
																						<Label className="js-prevent-label-bounce-event-click">
																							<Input type='checkbox' checked={importDataColumn.indexOf(key) !== -1} onChange={(e) => toggleImportColumn(e, key)} />
																						</Label>
																					</div>
																				</div>
																				<div className='tableRowItem'>{key}</div>
																				<div className='tableRowItemFlex'>
																					<VariableDropdown active={slicedData[key].db_key} index={key} onChange={(db_key, index) => setVariableDBKey(db_key, index)} />
																				</div>
																				<div className='tableRowItem'>{slicedData[key].values}</div>
																			</div>
																		}
																	</React.Fragment>
																)
															})}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex-row sm modalButtons align-items-center justify-content-end'>
											<span className="flex-1"></span>
											<Button color='secondary' onClick={() => gotoImportStep(1)}>Previous</Button>
											<Button color='primary rsContinue' onClick={() => validateImportStep2()}>Continue</Button>
										</div>
									</>
								:
									<>
										<div className='modalTop'>
											<div className='title'>
												3/3
												<img src={require('../../../assets/images/circle-filled-99.svg').default} className='importProgression' alt='Step Progression' />
												Manage duplicates
											</div>
										</div>
										<div className='modalCenter'>
											<div className='d-flex-col lg campaignLeadsImportCsv'>
												{duplicateCampaignCount > 0 &&
													<div className='importSummaryIssues'>
														<div className='rsInfoMsg warning'>
															<div className='flex-1'>
																<div className='d-flex-row'>
																	<div className='d-flex-col noGap flex-1'>
																		<span><b>{duplicateCampaignCount}</b> lead{duplicateOtherCampaignCount > 1 ? "s is " : " are "} already in this campaign</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												}
												<div className='d-flex-row'>
													<div className='uiSection width40Percents mvp'>
														<div className='content'>
															<div className='d-flex-col'>
																<div className='d-flex-row align-items-center'>
																	<div className='checkbox rsRadio rsDeDuplicateChange'>
																		<label>
																			<input type='radio' onChange={() => setImportDuplicateOption("campaign")} name="deduplicate" className='rsDeDuplicateChange' checked={importDuplicateOption === "campaign"} />
																		</label>
																	</div>
																	<div className='d-flex-col xs flex-1'>
																		<div className='d-flex-row'>
																			<span className='flex-1 bold'>Don't import leads that are already in this campaign</span>																		
																		</div>
																		<span className="text-light">{duplicateCampaignCount === 0 ? "All leads will be imported" : `${duplicateCampaignCount} lead${duplicateCampaignCount > 1 ? "s" : ""} will not be imported`}</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className='uiSection flex-1 mvp'>
														<div className='content'>
															<div className='d-flex-col'>
																<div className='d-flex-row align-items-center'>
																	<div className='checkbox rsRadio rsDeDuplicateChange'>
																		<label>
																			<input type='radio' onChange={() => setImportDuplicateOption("otherCampaign")} name="deduplicate" className='rsDeDuplicateChange' checked={importDuplicateOption === "otherCampaign"} />
																		</label>
																	</div>
																	<div className='d-flex-col xs flex-1'>
																		<div className='d-flex-row'>
																			<span className='flex-1 bold'>Don't import leads that are already in your roundsales team (no matter the campaign)</span>
																		</div>
																		<span className="text-light">{duplicateOtherCampaignCount === 0 ? "All leads will be imported" : `${duplicateOtherCampaignCount} lead${duplicateOtherCampaignCount > 1 ? "s" : ""} will not be imported`}</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex-row sm modalButtons align-items-center justify-content-end'>
											<span className="flex-1"></span>
											<Button color='secondary' disabled={importLoaderApi} onClick={() => gotoImportStep(2)}>Previous</Button>
											<Button color='primary' disabled={!importDuplicateOption || importLoaderApi} onClick={() => importFileUploadLead()}>
												{importLoaderApi &&
													<Spinner size={"sm"} />
												}
												Import {importDuplicateOption === "campaign" ? ( fileUploadRowCount - duplicateCampaignCount - invalidEmailCount - unsubscribeEmailCount ) : ( fileUploadRowCount - duplicateOtherCampaignCount - invalidEmailCount - unsubscribeEmailCount ) }  leads
											</Button>
										</div>
									</>
							}
						</div>
					</ModalBody>
				</Modal>
			}
		</>
	);
}

export default LeadsEdit;