import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function VariableDropdown({index, active, onChange}) {
	const [openRoundSalesVariable, setOpenRoundSalesVariable]	= useState(false),
		[customVariables] = useState({  "customVariable": "Custom Variable", "firstName": "First name", "lastName": "Last name", "email": "Email", "companyName": "Company name", "linkedinUrl": "Linkedin url", "companyDomain": "Company domain", "phone": "Phone", "picture": "Picture", "icebreaker": "Icebreaker" }),
		[localActive, setLocalActive]	= useState(active)
	
	useEffect(() => {
		setLocalActive(active)
	}, [])
	
	const selectedCustomVariable = (key) => {
		setLocalActive(key)
		setOpenRoundSalesVariable(false)
		onChange(key, index)
	}

	return (
		<Dropdown isOpen={openRoundSalesVariable} toggle={() => setOpenRoundSalesVariable((prevstate) => !prevstate)} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="div" className='rsDropdown'>
				<button className='btn btnDropdown rsDropdownToggle'>
					<div className='dropdownValue'>
						<span>{customVariables[localActive]}</span>
					</div>
				</button>
			</DropdownToggle>
			<DropdownMenu className='dropdownPicker'>
				{Object.keys(customVariables).map((key, index) => {
					return (
						<div key={index} className='uiDropdownItem' onClick={() => selectedCustomVariable(key)}>{customVariables[key]}</div>
					)
				})}
			</DropdownMenu>
		</Dropdown>
	);
}

export default VariableDropdown;