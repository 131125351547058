import Cookies from "js-cookie";

const StatusValidation = response => {  
    switch (response.status) {
      case 200:
        return response.json();
      case 401:
        // alert('Login session expired!');
        localStorage.clear();
        Cookies.remove('_round_sales_token');
        window.location = "/user/logout";
        break;
      case 403:
        //alert('Login session expired!');
        localStorage.clear();
        Cookies.remove('_round_sales_token');
        window.location = "/user/logout";
        break;
      case 422:
        return (response.json().then((result) => {
          return {
            status: 'server_error',
            message: result,
          }
        }));
      case 400:
        return (response.json().then((result) => {
          return {
            status: 'api_error',
            message: result,
          }
        }));
      case 404:
        return (response.json().then((result) => {
          return {
            status: 'not_found_error',
            message: result
          }
        }))
      case 500:
        return (response.json().then((result) => {
          return {
            status: 'application_error',
            message: result,
          }
        }));
      default:
        return "Invalid";
    }
  };
  
  export default StatusValidation;