import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProductContext } from '../../store';
import { Button } from 'reactstrap';

const Dashboard = (props) => {
	const [emailConnected, setEmailConnected]	= useState(false),
		[customTrackingDomain, setCustomTrackingDomain]	= useState(false),
		[campaignCreated, setCampaignCreated]	= useState(false),
		{
			userData,
			setAccountSettingFlag,
			setAccountSettingTab,
			userMailAccount
		}	= useContext(ProductContext)

	const openEmailProvider = () => {
		setAccountSettingTab("emailProvider")
		setAccountSettingFlag(true)
	}

	return (
		<div className='contentBottomWrapper paperBackground'>
			<div className='contentBottomTopWrapper'>
				<div className='d-flex-row align-items-center'>
					<div className='title'>
						<h1>Dashboard</h1>
					</div>
				</div>
			</div>
			<div className='contentBottomMainContainer'>
				<div className='contentBottomMainCenter'>
					<div className='d-flex-col lg'>
						<div className='dashboardPageSection d-flex-col lg'>
							<div className='d-flex-row dashboardActionShortcut'>
								<Link className='titleShortcut tasks'>
									<img src={require("../../assets/images/no-tasks.svg").default} alt="tasks" className='img-fluid' />
									<div className='contentCenter'>
										<span className='title'>My due tasks</span>
										<span className='description'>No task for today</span>
									</div>
									<div className="separator"></div>
									<i className="fa-solid fa-arrow-right"></i>
								</Link>
								<div className='titleShortcut emailProvider' onClick={() => openEmailProvider()}>
									<img src={require("./../../assets/images/provider.svg").default} alt='email provider' className='img-fluid' />
									<div className='contentCenter'>
										<span className='title'>My email provider</span>
										<span className='description'>
											{userMailAccount ?
												<span className="connected">
													<i className="fa fa-check-circle"></i> Connected
												</span>
											:
												<span className="disconnected">
													<i className="fa fa-times-circle"></i> Not connected
												</span>
											}
										</span>
									</div>
									<div className="separator"></div>
									<i className="fa-solid fa-arrow-right"></i>
								</div>
								<div className='flex-1'></div>
							</div>
							<div className='block dashboardChecklist'>
								<div className='header'>
									<h4 className="bold block-title">Get ready to start reaching out to your audience, {userData.first_name} {userData.last_name && userData.last_name}!</h4>
									<button className="btn btnModalClose rs-collapse">
            						<i className="fa-solid fa-caret-up"></i>
        							</button>
								</div>
								<div className='d-grid col3'>
									<div className={`uiSection ${userData.email_verified ? "sectionChecked" : ""}`}>
										<div className='header bordered'>
											<div className='title'>
												<div className='checkContainer'>
													{userData.email_verified ?
														<i className="fa fa-circle-check" aria-hidden="true"></i>
													:
														<div className="checkedStep">1</div>
													}
												</div>
												Verify your email address
											</div>
										</div>
										<div className='content'>
											<div className='d-flex-col justify-content-between'>
												{userData.email_verified ?
													<>
														<div className='grey'>Your email is correctly verified</div>
														<Button color='secondary'><i className="fa fa-check"></i> Email verified</Button>
													</>
												:
													<>
														<div className="grey">Go to your inbox and you will see our email.</div>
														<Button color='primary' className='md'>Resend email</Button>
													</>
												}
											</div>
										</div>
									</div>
									<div className={`uiSection ${userMailAccount ? "sectionChecked" : ""}`} onClick={() => openEmailProvider()}>
										<div className='header bordered'>
											<div className='title'>
												<div className='checkContainer'>
													{userMailAccount ?
														<i className="fa fa-circle-check" aria-hidden="true"></i>
													:
														<div className="checkedStep">2</div>
													}
												</div>
												Connect your sender email
											</div>
										</div>
										<div className='content'>
											<div className='d-flex-col justify-content-between'>
												{userMailAccount ?
													<>
														<div className='grey'>Your email provider is connected.</div>
														<Button color='secondary'><i className="fa fa-check"></i> Provider connected</Button>
													</>
												:
													<>
														<div className="grey">Tell us which email you wanna use to send campaigns from.</div>
														<Button color='primary' className='md'>Connect email provider</Button>
													</>
												}
											</div>
										</div>
									</div>
									<div className={`uiSection ${customTrackingDomain ? "sectionChecked" : ""}`}>
										<div className='header bordered'>
											<div className='title'>
												<div className='checkContainer'>
													{customTrackingDomain ?
														<i className="fa fa-circle-check" aria-hidden="true"></i>
													:
														<div className="checkedStep">3</div>
													}
												</div>
												Set custom tracking domain
											</div>
										</div>
										<div className='content'>
											<div className='d-flex-col justify-content-between'>
												{customTrackingDomain ?
													<>
														<div className='grey'>Your email provider is connected.</div>
														<Button color='secondary'><i className="fa fa-check"></i> Provider connected</Button>
													</>
												:
													<>
														<div className="grey">Extremely important step to boost open rates.</div>
														<Button color='primary' className='md'>Set custom tracking domain</Button>
													</>
												}
											</div>
										</div>
									</div>
									<div className={`uiSection ${userData.gdpr_check ? "sectionChecked" : ""}`}>
										<div className='header bordered'>
											<div className='title'>
												<div className='checkContainer'>
													{userData.gdpr_check ?
														<i className="fa fa-circle-check" aria-hidden="true"></i>
													:
														<div className="checkedStep">4</div>
													}
												</div>
												GDPR check
											</div>
										</div>
										<div className='content'>
											<div className='d-flex-col justify-content-between'>
												{userData.gdpr_check ?
													<>
														<div className='grey'>You have agreed with our GDPR policy.</div>
														<Button color='secondary'><i className="fa fa-check"></i> GDPR agreed</Button>
													</>
												:
													<>
														<div className="grey">Before sending emails, make sure your prospect's data sources is GDPR compliant.</div>
														<Button color='primary' className='md'>Agree to GDPR</Button>
													</>
												}
											</div>
										</div>
									</div>
									<div className={`uiSection ${campaignCreated ? "sectionChecked" : ""}`}>
										<div className='header bordered'>
											<div className='title'>
												<div className='checkContainer'>
													{campaignCreated ?
														<i className="fa fa-circle-check" aria-hidden="true"></i>
													:
														<div className="checkedStep">5</div>
													}
												</div>
												Create your first campaign
											</div>
										</div>
										<div className='content'>
											<div className='d-flex-col justify-content-between'>
												{campaignCreated ?
													<>
														<div className='grey'>You have created your first RoundSales campaign.</div>
														<Button color='secondary'><i className="fa fa-check"></i> Campaign created</Button>
													</>
												:
													<>
														<div className="grey">Create your sequence and start getting more replies.</div>
														<Button color='primary' className='md'>Create a campaign</Button>
													</>
												}
											</div>
										</div>
									</div>
									<div className={`uiSection`}>
										<div className='header bordered'>
											<div className='title'>
												<div className='checkContainer'>
													<div className="checkedStep">6</div>
												</div>
												Try Linkedin extension
											</div>
										</div>
										<div className='content'>
											<div className='d-flex-col justify-content-between'>
												<div className="grey">Add and enrich leads from Linkedin to RoundSales campaigns.</div>
												<Button color='primary' className='md'>Get RoundSales extension</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;