import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function StepDropdown(props) {
	const [stepMoreOption, setStepMoreOption]	= useState(false)
	
	const clickEvent	= (type) => {
		setStepMoreOption(false)
		props.onClick(props.index, type);
	}

	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => setStepMoreOption((prevstate) => !prevstate)} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btnAction'>
				<i className="fa fa-ellipsis"></i>
			</DropdownToggle>
			<DropdownMenu className={`campaignFilterDropdownMenu stepDropdownMenu ${props.ab_testing_flag ? "" : "withABTest"} ${props.delay_time ? "" : "withWait"} `}>
				<div>
					<div className='dropdownRSMenuItems'>
						<div className='dropdownRSMenuItem rsShareSequence' onClick={() => clickEvent("duplicate")}>
							<i className="fa-regular fa-clone"></i>
							<div className="text">Duplicate</div>
						</div>
						{!props.ab_testing_flag &&
							<div className='dropdownRSMenuItem rsDuplicateCampaign' onClick={() => clickEvent("ABTest")}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
									<path d="M64 96c-17.7 0-32 14.3-32 32V384c0 17.7 14.3 32 32 32H576c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H64zM0 128C0 92.7 28.7 64 64 64H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zm176 32c6.5 0 12.4 4 14.9 10.1l64 160c3.3 8.2-.7 17.5-8.9 20.8s-17.5-.7-20.8-8.9L216.4 320l-.4 0H136l-.4 0-8.8 21.9c-3.3 8.2-12.6 12.2-20.8 8.9s-12.2-12.6-8.9-20.8l64-160c2.4-6.1 8.3-10.1 14.9-10.1zm0 59.1L148.4 288h55.1L176 219.1zM288 176c0-8.8 7.2-16 16-16h56c30.9 0 56 25.1 56 56c0 12.1-3.8 23.3-10.4 32.5C421.5 258.4 432 276 432 296c0 30.9-25.1 56-56 56H304c-8.8 0-16-7.2-16-16V320 256 192 176zm72 96H320v48h56c13.3 0 24-10.7 24-24s-10.7-24-24-24H360zm0-32c13.3 0 24-10.7 24-24s-10.7-24-24-24H320v48h40z"/>
								</svg>
								<div className="text">A/B test this step</div>
							</div>
						}
						{(!props.delay_time || (props.delay_time && props.delay_time === 0) ) &&
							<div className='dropdownRSMenuItem rsArchiveCampaign' onClick={() => clickEvent("waitTime")}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path d="M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM240 112V256c0 5.3 2.7 10.3 7.1 13.3l96 64c7.4 4.9 17.3 2.9 22.2-4.4s2.9-17.3-4.4-22.2L272 247.4V112c0-8.8-7.2-16-16-16s-16 7.2-16 16z"/>
								</svg>
								<div className="text">Add a waiting time before this step</div>
							</div>
						}
						<div className='dropdownRSMenuItem alert-300 separator rsDeleteCampaign' onClick={() => clickEvent("deleteStep")}>
							<i className="fa-solid fa-trash"></i>
							<div className="text">Delete this step</div>
						</div>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default StepDropdown;