import React from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function LeadReviewAllDropdown({leadCount, onClick}) {
	const [stepMoreOption, setStepMoreOption] = useState(false)
	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => { setStepMoreOption((prevstate) => !prevstate); }} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btn-ico btn-primary'>
				<i className="fa fa-ellipsis"></i>
			</DropdownToggle>
			<DropdownMenu className='campaignFilterDropdownMenu stepDropdownMenu reviewAllButtons'>
				<div>
					<div className='dropdownRSMenuItems'>
						<div className='dropdownRSMenuItem rsReviewLead' onClick={() => { setStepMoreOption(false);onClick();}}>
							<i className="fa-solid fa-arrow-down-short-wide"></i>
							<div className='text'>Review all leads ({leadCount})</div>
						</div>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default LeadReviewAllDropdown;