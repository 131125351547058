import React from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function LeadReviewDropdown({index, onClick}) {
	const [stepMoreOption, setStepMoreOption] = useState(false)

	const onClickEvent = (type) => {
		setStepMoreOption(false)
		onClick(type, index)
	}

	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => { setStepMoreOption((prevstate) => !prevstate); }} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btnAction'>
				<i className="fa fa-ellipsis"></i>
			</DropdownToggle>
			<DropdownMenu className='campaignFilterDropdownMenu stepDropdownMenu leadReviewDropdown singleItem'>
				<div>
					<div className='dropdownRSMenuItems'>
						<div className='dropdownRSMenuItem rsReviewLead' onClick={() => onClickEvent("review")}>
							<i className="fa-regular fa-paper-plane"></i>
							<div className='text'>Review</div>
						</div>
						<div className='dropdownRSMenuItem rsSkipLead' onClick={() => onClickEvent("skip")}>
							<i className="fa-solid fa-forward"></i>
							<div className='text'>Skip</div>
						</div>
						<div className='dropdownRSMenuItem alert-300 separator' onClick={() => onClickEvent("delete")}>
							<i className="fa-solid fa-trash"></i>
							<div className="text">Delete this lead</div>
						</div>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default LeadReviewDropdown;