import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function StepVariationDropdown({variation, index, abTestWinner, onClick}) {
	const [stepMoreOption, setStepMoreOption] = useState(false)

	const clickEvent	= (type) => {
		setStepMoreOption(false)
		onClick(index, variation, type);
	}

	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => setStepMoreOption((prevstate) => !prevstate)} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btnAction'>
				<i className="fa fa-ellipsis"></i>
			</DropdownToggle>
			<DropdownMenu className={`campaignFilterDropdownMenu stepDropdownMenu ${!abTestWinner ? "withStepVariation" : ""}`}>
				<div>
					<div className='dropdownRSMenuItems'>
						{!abTestWinner &&
							<div className='dropdownRSMenuItem rsShareSequence' onClick={() => clickEvent('winner')}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
									<path d="M312 378.4c4-1.6 8.4-3.6 13.1-6.1c33.8-6 95.5-22 149.5-63.7C530.1 265.7 576 196.5 576 88V64H552 446.4c.3-5.2 .5-10.6 .7-16c.6-15.3 .9-31.3 .9-48H400 176 128c0 16.7 .3 32.7 .9 48c.2 5.4 .4 10.8 .7 16H24 0V88C0 196.5 45.9 265.7 101.5 308.6c53.9 41.7 115.7 57.6 149.5 63.7c4.7 2.5 9.1 4.5 13.1 6.1V464H184 160v48h24H288 392h24V464H392 312V378.4zM445.2 270.6c-17.5 13.6-36.3 24-54.4 32c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-6 78.4-41.3 127.3-81.9 158.6zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-18.1-8-36.8-18.4-54.4-32C90.2 239.3 54.9 190.4 48.9 112zm128-64H399.1c-6.1 153.1-40.8 224.7-68 257.3c-14.7 17.7-28.1 25-36 28.2c-3.1 1.2-5.6 1.9-7.2 2.3c-1.6-.3-4.1-1-7.2-2.3c-7.9-3.1-21.2-10.5-36-28.2C217.7 272.7 183 201.1 176.9 48z" />
								</svg>
								<div className="text">Choose step variation {variation}</div>
							</div>
						}
						<div className='dropdownRSMenuItem alert-300 separator rsDeleteCampaign' onClick={() => clickEvent('delete')}>
							<i className="fa-solid fa-trash"></i>
							<div className="text">Delete this step</div>
						</div>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default StepVariationDropdown;