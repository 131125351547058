import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { ProductContext } from '../store';
import { PostData } from '../services/FetchData';
import { toast } from 'react-toastify';
import RSToolTip from './RSToolTip';
import ScheduleOptionDropdown from './ScheduleOptionDropdown';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import ScheduleTimeDropdown from './ScheduleTimeDropdown';

const initialSchedule	= {
	name: "Your Local Timezone",
	timezone: Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Calcutta" ? "Asia/Kolkata" : Intl.DateTimeFormat().resolvedOptions().timeZone,
	schedule_weeks: [1, 2, 3, 4, 5],
	time_start: "09:00",
	time_end: "18:00",
	reach_new_lead: 20
}

function CampaignTopBar(props) {
	const { id, '*': wildcard }	= useParams(),
		[campaignMoreOption, setCampaignMoreOption]	= useState(false),
		[redirectFlag, setRedirectFlag]	= useState(false),
		[campaignNameApiLoader, setCampaignNameApiLoader]	= useState(false),
		[campaignStatusApiLoader, setCampaignStatusApiLoader]	= useState(false),
		[campaignSettingModal, setCampaignSettingModal]	= useState(false),
		[campaignSettingTab, setCampaignSettingTab]	= useState("campaign"),
		[addNewFlag, setAddNewFlag]	= useState(false),
		[scheduleDetails, setScheduleDetails]	= useState(null),
		[scheduleDays]	= useState([7, 1, 2, 3, 4, 5, 6]),
		[scheduleLoaderApi, setScheduleLoaderApi]	= useState(false),
		[editScheduleId, setEditScheduleId]	= useState(null),
		[deleteScheduleModal, setDeleteScheduleModal]	= useState(false),
		[deleteScheduleId, setDeleteScheduleId]	= useState(null),
		[deleteLoaderApi, setDeleteLoaderApi]	= useState(false),
		[timeOptions]	= useState({
												"00:00": "0:00 AM",
												"00:30": "0:30 AM",
												"01:00": "1:00 AM",
												"01:30": "1:30 AM",
												"02:00": "2:00 AM",
												"02:30": "2:30 AM",
												"03:00": "3:00 AM",
												"03:30": "3:30 AM",
												"04:00": "4:00 AM",
												"04:30": "4:30 AM",
												"05:00": "5:00 AM",
												"05:30": "5:30 AM",
												"06:00": "6:00 AM",
												"06:30": "6:30 AM",
												"07:00": "7:00 AM",
												"07:30": "7:30 AM",
												"08:00": "8:00 AM",
												"08:30": "8:30 AM",
												"09:00": "9:00 AM",
												"09:30": "9:30 AM",
												"10:00": "10:00 AM",
												"10:30": "10:30 AM",
												"11:00": "11:00 AM",
												"11:30": "11:30 AM",
												"12:00": "12:00 PM",
												"12:30": "12:30 PM",
												"13:00": "1:00 PM",
												"13:30": "1:30 PM",
												"14:00": "2:00 PM",
												"14:30": "2:30 PM",
												"15:00": "3:00 PM",
												"15:30": "3:30 PM",
												"16:00": "4:00 PM",
												"16:30": "4:30 PM",
												"17:00": "5:00 PM",
												"17:30": "5:30 PM",
												"18:00": "6:00 PM",
												"18:30": "6:30 PM",
												"19:00": "7:00 PM",
												"19:30": "7:30 PM",
												"20:00": "8:00 PM",
												"20:30": "8:30 PM",
												"21:00": "9:00 PM",
												"21:30": "9:30 PM",
												"22:00": "10:00 PM",
												"22:30": "10:30 PM",
												"23:00": "11:00 PM",
												"23:30": "11:30 PM",
												"23:59": "11:59 PM",
											}),
		[campaignSettingHeadings] = useState({
			'campaign': "Campaign settings",
			'report': "Report settings",
			'schedule': "Schedules",
		}),
		{
			campaignDetails,
			teamUserData,
			setCampaignDetails,
			currentTeamId,
			sequenceLoaderApi,
			setSchedules,
			schedules,
			reviewLeads
		}	= useContext(ProductContext),
		[campaignLocalDetails, setCampaignLocalDetails]	= useState({})

	useEffect(() => {
		if( campaignDetails && !campaignDetails.archive_status ){
			setCampaignLocalDetails({...campaignDetails});
		}else{
			setRedirectFlag(true)
		}
	}, [campaignDetails])
	
	const changeCampaignDetails	= ( value, index ) => {
		setCampaignLocalDetails({
			...campaignLocalDetails,
			[index]:value
		})
	}

	const changeScheduleDetails	= (value, index) => {
		setScheduleDetails({
			...scheduleDetails,
			[index]: value
		})
	}

	const clearScheduleDetails = (name) => {
		setScheduleDetails({
			...scheduleDetails,
			[name]: ""
		})
	}

	const updateCampaignName = () => {
		if( campaignDetails.name !== campaignLocalDetails.name ){
			setCampaignNameApiLoader(true)
			let data	= {campaign_id: campaignDetails.uuid, team_id: currentTeamId, name: campaignLocalDetails.name};
			PostData("ms1", "api/v1/campaign/update-name", data ).then(result => {
				if( result !== "Invalid" && result !== undefined ){
					if( result.status === "success" ){
						setCampaignDetails({
							...campaignDetails,
							name: campaignLocalDetails.name
						});
					}else if( result.status === "failure" ){
						toast.error(result.message)
					}else{
						toast.error(result.message.message)
					}
				}
				setCampaignNameApiLoader(false)
			})
		}
	}

	const updateCampaignStatus = () => {
		setCampaignStatusApiLoader(true)
		let data	= {campaign_id: campaignDetails.uuid, team_id: currentTeamId, status: !campaignDetails.status};
		PostData("ms1", "api/v1/campaign/update-status", data ).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setCampaignDetails({
						...campaignDetails,
						status: !campaignDetails.status
					});
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setCampaignStatusApiLoader(false)
		})
	}

	const goBackCampaignPage = () => {
		setRedirectFlag(true)
	}

	const toggleCampaignSettingModal = () => {
		setCampaignSettingModal((prevState) => !prevState)
	}

	const calculateDayPerLeads = () => {
		let startTimeHourMinutes	= scheduleDetails.time_start ? scheduleDetails.time_start : "00:00",
			endTimeHourMinutes		= scheduleDetails.time_end ? scheduleDetails.time_end : "00:00",
			start = new Date(0, 0, 0, startTimeHourMinutes.split(":")[0], startTimeHourMinutes.split(":")[1]),
			end = new Date(0, 0, 0, endTimeHourMinutes.split(":")[0], endTimeHourMinutes.split(":")[0]),
			diffInMilliseconds = end - start,
			diffInMinutes	= Math.floor(diffInMilliseconds / (1000 * 60));
		return parseInt( diffInMinutes / scheduleDetails.reach_new_lead);
	}

	const updateCampaignDetails	= (e) =>  {
		let name	= e.target.name,
			value	= e.target.checked,
			campaignData	= {team_id: currentTeamId, campaign_id: id, values:[{key: name, value}]}
		PostData("ms1", "api/v1/campaign/update-campaign", campaignData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setCampaignDetails({
						...campaignDetails,
						[name]: value
					})
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
		})
	}

	const addNewSchedule = () => {
		setScheduleDetails(initialSchedule)
		setAddNewFlag(true)
	}

	const changeScheduleValues = (value) => {
		const localScheduleDetails	= {...scheduleDetails},
			localScheduleWeeks	= localScheduleDetails.schedule_weeks;
		if( localScheduleWeeks.indexOf(value) !== -1 ){
			localScheduleWeeks.splice(localScheduleWeeks.indexOf(value), 1)
		}else{
			localScheduleWeeks.push(value)
		}
		setScheduleDetails({
			...scheduleDetails,
			"schedule_weeks": localScheduleWeeks
		})
	}

	const createNewSchedule = () => {
		setScheduleLoaderApi(true)
		let scheduleData	= {team_id: currentTeamId, campaign_id: id, ...scheduleDetails}
		PostData("ms1", "api/v1/campaign/create-schedule", scheduleData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					setSchedules([...schedules, result.data.schedule])
					toast.success(result.message)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setScheduleLoaderApi(false)
			setAddNewFlag(false)
			setScheduleDetails(null)
		})
	}

	const editSchedule = () => {
		setScheduleLoaderApi(true)
		let { campaign_id, ...withoutCampaignId }	= scheduleDetails
		let scheduleData	= {team_id: currentTeamId, campaign_id: id, schedule_id: editScheduleId, ...withoutCampaignId}
		PostData("ms1", "api/v1/campaign/update-schedule", scheduleData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					let editScheduleIndex	= -1
					for( let i = 0; i < schedules.length; i++ ){
						if( editScheduleId === schedules[i].id ){
							editScheduleIndex	= i;
							break;
						}
					}
					if( editScheduleIndex !== -1 ){
						let localSchedules	= [...schedules]
						localSchedules[editScheduleIndex]	= scheduleDetails
						setSchedules(localSchedules)
					}
					toast.success(result.message)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setScheduleLoaderApi(false)
			setEditScheduleId(null)
			setScheduleDetails(null)
		})
	}

	const scheduleDropdownClick = ( type, index ) => {
		if( type === "modify" ){
			setScheduleDetails({
				...schedules[index],
				schedule_weeks: schedules[index].schedule_weeks ? schedules[index].schedule_weeks : []
			})
			setEditScheduleId(schedules[index].id)
		}else{
			setDeleteScheduleId(schedules[index].id)
			setDeleteScheduleModal(true)
		}
	}

	const toggleDeleteScheduleModal = () => {
		setDeleteScheduleModal(false)
		setDeleteScheduleId(null)
	}

	const deleteSchedule = () => {
		setDeleteLoaderApi(true)
		let deleteScheduleData	= {team_id: currentTeamId, campaign_id: id, schedule_id: deleteScheduleId}
		PostData("ms1", "api/v1/campaign/delete-schedule", deleteScheduleData).then(result => {
			if( result !== "Invalid" && result !== undefined ){
				if( result.status === "success" ){
					let deleteScheduleIndex	= -1
					for( let i = 0; i < schedules.length; i++ ){
						if( deleteScheduleId === schedules[i].id ){
							deleteScheduleIndex	= i;
							break;
						}
					}
					if( deleteScheduleIndex !== -1 ){
						let localSchedules	= [...schedules]
						localSchedules.splice(deleteScheduleIndex, 1)
						setSchedules(localSchedules)
					}
					toast.success(result.message)
				}else if( result.status === "failure" ){
					toast.error(result.message)
				}else{
					toast.error(result.message.message)
				}
			}
			setDeleteLoaderApi(false)
			toggleDeleteScheduleModal()			
		})
	}

	if( redirectFlag ){
		return <Navigate to={"/user/campaigns"} replace />
	}

	return (
		<>
			<div className='campaignTopBarMenu'>
				<div className='campaignEditCloseBtn' onClick={() => goBackCampaignPage()}>
					<span className='rsCloseCampaignEditor'>
						<i className="fa fa-times"></i>
					</span>
				</div>
				<div className='campaignTopBarContent d-flex-row align-items-center'>
					<div className='campaignNameWrapper d-flex-row align-items-center'>
						{(campaignDetails && campaignDetails.running_status === "draft" ) ?
							<i className="cursor-pointer fa-solid fa-pen-ruler draftIcon textInactive" aria-expanded="false"></i>
						: (campaignDetails && campaignDetails.running_status === "running" ) ?
							<i className="fa-solid fa-calendar running textInactive" aria-expanded="false"></i>
						: (campaignDetails && campaignDetails.running_status === "in error" ) &&
							<RSToolTip item={{ placement: 'bottom', text: `<i className="fa-solid fa-circle-exclamation alert-color"></i> There is an issue with ${ (teamUserData && campaignDetails && teamUserData[campaignLocalDetails.sender_user_id]) ? `<b>${teamUserData[campaignLocalDetails.sender_user_id].first_name + ( teamUserData[campaignLocalDetails.sender_user_id].last_name ? " " + teamUserData[campaignLocalDetails.sender_user_id].last_name : "" )}</b> DNS` : "" }` }} id="errorSendingTooltip">
								<i className="cursor-pointer fa-solid fa-triangle-exclamation error" aria-expanded="false"></i>
							</RSToolTip>
						}
						<div className='textEdit rsCampaignName'>
							<div className='inputWrapper'>
								<Input disabled={campaignNameApiLoader} type="text" className="rsTextEdit" name="campaign_name" value={campaignLocalDetails && campaignLocalDetails.name} onChange={(e) => changeCampaignDetails(e.target.value, "name")} maxLength={70} size={24} onBlur={() => updateCampaignName()} />
								<div className='rsTextEditBorder'></div>
							</div>
						</div>
						<div className="rsSwitch customControl customSwitch rsCampaignsTogglePlayPause playPause">
							<Label className="customControlLabel">
								<Input type="checkbox" disabled={campaignStatusApiLoader} checked={campaignDetails && campaignDetails.status} onChange={() => updateCampaignStatus()} />
								<span className="switchDisplay"></span>
								<span className="rsLabel"></span>
							</Label>
						</div>
						<Button className='btn-tertiary rsCampaignEditSetting' onClick={() => toggleCampaignSettingModal()}>
							<i className="fa-solid fa-gear"></i>
						</Button>
						<Dropdown isOpen={campaignMoreOption} toggle={() => setCampaignMoreOption((prevstate) => !prevstate)} direction={"down"} className='dropdownTrigger'>
							<DropdownToggle tag="button" className='btn btnAction'>
								<i className="fa fa-ellipsis"></i>
							</DropdownToggle>
							<DropdownMenu className='campaignFilterDropdownMenu'>
								<div>
									<div className='dropdownRSMenuItems'>
										<div className='dropdownRSMenuItem rsShareSequence'>
											<i className="fa fa-share-nodes"></i>
											<div className="text">Share link</div>
										</div>
										<div className='dropdownRSMenuItem rsDuplicateCampaign'>
											<i className="fa-regular fa-copy"></i>
											<div className="text">Duplicate</div>
										</div>
										<div className='dropdownRSMenuItem rsArchiveCampaign'>
											<i className="fa-solid fa-box-archive"></i>
											<div className="text">Archive</div>
										</div>
										<div className='dropdownRSMenuItem alert-300 separator rsDeleteCampaign'>
											<i className="fa-solid fa-trash"></i>
											<div className="text">Delete Campaign</div>
										</div>
									</div>
								</div>
							</DropdownMenu>
						</Dropdown>
					</div>
					<div className='campaignTabsBarContent'>
						<Link to={`/user/campaign/${id}/sequence/generate`} className={`tab ${(wildcard === "sequence/generate" || wildcard === "sequence/edit" ) ? "active" : ""} `} disabled={sequenceLoaderApi}>
							{sequenceLoaderApi ?
								<Spinner size={"sm"} />
							:
								<span className='numberCircle'>1</span> 
							} Sequence
						</Link>
						<Link to={`/user/campaign/${id}/leads/edit`} className={`tab ${(wildcard === "leads/edit" || wildcard === "leads" ) ? "active" : ""} `}>
							<span className='numberCircle'>2</span> Leads
						</Link>
						<Link to={`/user/campaign/${id}/review`} className={`tab ${wildcard === "review" ? "active" : ""} ${(reviewLeads.length === 0 || !campaignDetails.sender_user_id )  ? "disabled" : ""}`}>
							<span className='numberCircle'>3</span> Review & Launch
							{reviewLeads.length > 0 &&
								<span className='reviewCount'>{reviewLeads.length}</span>
							}
						</Link>
						{/* <Button color='primary' className='md rsNextStep'>
							Next Step
							<i className="fa-solid fa-chevron-right"></i>
						</Button> */}
					</div>
				</div>
			</div>
			{campaignSettingModal &&
				<Modal isOpen={campaignSettingModal} toggle={toggleCampaignSettingModal} className={"accountSettingModal campaignSettingModal"} centered={true}>
					<ModalBody>
						<div className='accountSettingModalLeftWrapper'>
							<div className="modalLeftHeading">Settings</div>
							<div className="modalLeftItemsWrapper">
								<div className={`modalLeftItemWrap cursorPointer ${campaignSettingTab === "campaign" ? "active" : ""}`} onClick={() => setCampaignSettingTab("campaign")}>
									<i className="fa-solid fa-bullhorn"></i> Campaign Settings
								</div>
								<div className={`modalLeftItemWrap cursorPointer ${campaignSettingTab === "report" ? "active" : ""}`} onClick={() => setCampaignSettingTab("report")}>
									<i className="fa-solid fa-chart-simple"></i> Report Settings
								</div>
								<div className={`modalLeftItemWrap cursorPointer ${campaignSettingTab === "schedule" ? "active" : ""}`} onClick={() => setCampaignSettingTab("schedule")}>
									<i className="fa-regular fa-calendar"></i> Schedule
								</div>
							</div>
						</div>
						<div className='accountSettingModalRightWrapper'>
							<div className='userSettingModalRightTopWrap'>
								<div className='modalRightTitle'>
									{campaignSettingHeadings[campaignSettingTab]}
								</div>
								<Button className='btnModalClose' onClick={() => toggleCampaignSettingModal()}>
									<i className="fa-solid fa-xmark"></i>
								</Button>
							</div>
							<div className='userSettingModalCenterWrap'>
								{campaignSettingTab === "campaign" ?
									<div className='d-flex-col lg campaignSettingBase'>
										<div className='d-flex-col sm'>
											<div className="semiBold relative">
												Stop the campaign for people that
											</div>
											<div className='uiSection width70Percents mvp'>
												<div className='content'>
													<div className='d-flex-col'>
														<div className='d-flex-row'>
															<div className="rsSwitch customControl customSwitch">
																<Label className="customControlLabel hasLabel">
																	<Input type="checkbox" name='stop_on_email_replied'  checked={campaignDetails && campaignDetails.stop_on_email_replied} onChange={(e) => updateCampaignDetails(e)} />
																	<span className="switchDisplay"></span>
																	<span className="rsLabel">Reply by email or LinkedIn message</span>
																</Label>
															</div>
														</div>
														<hr />
														<div className='d-flex-row'>
															<div className="rsSwitch customControl customSwitch">
																<Label className="customControlLabel hasLabel">
																	<Input type="checkbox" name='stop_on_link_clicked' checked={campaignDetails && campaignDetails.stop_on_link_clicked} onChange={(e) => updateCampaignDetails(e)} />
																	<span className="switchDisplay"></span>
																	<span className="rsLabel">Click on link</span>
																</Label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='d-flex-col sm'>
											<div className='d-flex-row'>
												<div className="checkbox rsCheckbox bordered rsAutoPausedLeads">
													<Label className="js-prevent-label-bounce-event-click">
														<Input type='checkbox' name="pause_same_company_people" checked={campaignDetails && campaignDetails.pause_same_company_people} onChange={(e) => updateCampaignDetails(e)} />
														<span>
															<b>Also pause people from the same company</b>
															<br />When somebody stops receiving a campaign, people from the same company will also stop receiving it.
														</span>
													</Label>
												</div>
											</div>
										</div>
										<div className='d-flex-col sm'>
											<div className="semiBold relative">Create a new task when people</div>
											<div className='uiSection width70Percents mvp'>
												<div className='content'>
													<div className='d-flex-col'>
														<div className='d-flex-row'>
															<div className="rsSwitch customControl customSwitch">
																<Label className="customControlLabel hasLabel">
																	<Input type="checkbox" name='new_task_by_replied' checked={campaignDetails && campaignDetails.new_task_by_replied} onChange={(e) => updateCampaignDetails(e)} />
																	<span className="switchDisplay"></span>
																	<span className="rsLabel">Reply by email or LinkedIn message</span>
																</Label>
															</div>
														</div>
														<hr />
														<div className='d-flex-row'>
															<div className="rsSwitch customControl customSwitch">
																<Label className="customControlLabel hasLabel">
																	<Input type="checkbox" name='new_task_by_on_link_clicked'  checked={campaignDetails && campaignDetails.new_task_by_on_link_clicked} onChange={(e) => updateCampaignDetails(e)} />
																	<span className="switchDisplay"></span>
																	<span className="rsLabel">Click on link</span>
																</Label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								: campaignSettingTab === "report" ?
									<div className='d-flex-col lg campaignSettingBase'>
										<div className='d-flex-col sm'>
											<div className="semiBold relative">Custom tracking</div>
											<div className='uiSection width70Percents mvp'>
												<div className='content'>
													<div className='d-flex-col'>
														<div className='d-flex-row'>
															<div className="rsSwitch customControl customSwitch">
																<Label className="customControlLabel hasLabel">
																	<Input type="checkbox" name='track_email_opens' checked={campaignDetails && campaignDetails.track_email_opens} onChange={(e) => updateCampaignDetails(e)} />
																	<span className="switchDisplay"></span>
																	<span className="rsLabel">Track email opens</span>
																</Label>
															</div>
														</div>
														<hr />
														<div className='d-flex-row'>
															<div className="rsSwitch customControl customSwitch">
																<Label className="customControlLabel hasLabel">
																	<Input type="checkbox" name='track_link_clicks' checked={campaignDetails && campaignDetails.track_link_clicks} onChange={(e) => updateCampaignDetails(e)} />
																	<span className="switchDisplay"></span>
																	<span className="rsLabel">Track link clicks</span>
																</Label>
															</div>
														</div>
														<hr />
														<div className='d-flex-row'>
															<div className="rsSwitch customControl customSwitch">
																<Label className="customControlLabel hasLabel">
																	<Input type="checkbox" name='track_replies' checked={campaignDetails && campaignDetails.track_replies} onChange={(e) => updateCampaignDetails(e)} />
																	<span className="switchDisplay"></span>
																	<span className="rsLabel">Track replies</span>
																</Label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								: campaignSettingTab === "schedule" &&
									<div className='d-flex-col editSchedulesCampaign'>
										<div className='d-flex-row align-items-center'>
											<h3>Your campaign schedules</h3>
											<RSToolTip item={{ placement: 'bottom', text: 'Your default schedule is automatically linked to your local timezone and based on working days.' }} id="editScheduleToolTip">
												<i className="fa-solid fa-circle-info"></i>
											</RSToolTip>
										</div>
										{schedules.map((schedule, index) => {
											return(
												<React.Fragment key={index}>
													{schedule.id !== editScheduleId ?
														<div className="scheduleRow campaign" key={index}>
															<div className="block name">
																<span className="title">Name</span>
																<span className="value">{schedule.name}</span>
															</div>
															<div className="block days">
																<span className="title">Days</span>
																<span className="value">
																	{schedule.schedule_weeks ? schedule.schedule_weeks.length : 0}/7
																	{schedule.schedule_weeks &&
																		<RSToolTip item={{ placement: 'bottom', text: schedule.schedule_weeks.toString()}} id={`editScheduleDays${index}`}>
																			<i className="fa-solid fa-circle-info"></i>
																		</RSToolTip>
																	}
																</span>
															</div>
															<div className="block start">
																<span className="title">Start at</span>
																<span className="value">{timeOptions[schedule.time_start]}</span>
															</div>
															<div className="block end">
																<span className="title">End at</span>
																<span className="value">{timeOptions[schedule.time_end]}</span>
															</div>
															<div className="block send">
																<span className="title">Send every</span>
																<span className="value">{schedule.reach_new_lead} min.</span>
															</div>
															<div className="block timezone">
																<span className="title">Timezone</span>
																<span className="value overflow-ellipsis">
																	{allTimezones[schedule.timezone]}
																</span>
															</div>
															<ScheduleOptionDropdown index={index} scheduleCount={schedules.length} onClick={(type, index) => scheduleDropdownClick(type, index)} />
														</div>
													:
														<div className='d-flex-col lg editScheduleTemplate'>
															<div>
																<h4 className='bold'>Schedule name</h4>
																<div className='textEdit rsScheduleName'>
																	<div className='inputWrapper'>
																		<Input type="text" className="rsTextEdit" name="schedule_name" value={scheduleDetails && scheduleDetails.name} onChange={(e) => changeScheduleDetails(e.target.value, "name")} maxLength={70} size={24} />
																		<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearScheduleDetails("name")}></i>
																		<div className='rsTextEditBorder'></div>
																	</div>
																</div>
															</div>
															<div>
																<h4 className='bold'>Based on this timezone</h4>
																<TimezoneSelect
																	value={scheduleDetails.timezone}
																	onChange={(value) => changeScheduleDetails(value.value, 'timezone')}
																	className='scheduleTimezone'
																/>
															</div>
															<div className='containerDays'>
																<h4 className="bold">Send these days</h4>
																<div className='d-flex-row scheduleWeeks'>
																	{scheduleDays.map((scheduleDay, index) => {
																		return( 
																			<div className="checkbox rsCheckbox rsCheckFilter" key={index}>
																				<Label className="js-prevent-label-bounce-event-click">
																					<Input type='checkbox' checked={(scheduleDetails && scheduleDetails.schedule_weeks ) && scheduleDetails.schedule_weeks.indexOf(scheduleDay) !== -1} onChange={() => changeScheduleValues(scheduleDay)} />
																					<span>
																						{scheduleDay === 7 ?
																							<>Sunday</>
																						: scheduleDay === 1 ?
																							<>Monday</>
																						: scheduleDay === 2 ?
																							<>Tuesday</>
																						: scheduleDay === 3 ?
																							<>Wednesday</>
																						: scheduleDay === 4 ?
																							<>Thursday</>
																						: scheduleDay === 5 ?
																							<>Friday</>
																						: scheduleDay === 6 &&
																							<>Saturday</>
																						}
																					</span>
																				</Label>
																			</div>
																		)
																	})}
																</div>
															</div>
															<div className='d-flex-col'>
																<div className='containerReachLeads'>
																	<div>
																		<h4 className="bold">Between</h4>
																		<ScheduleTimeDropdown value={scheduleDetails.time_start} index={"time_start"} onClick={(index, value) => changeScheduleDetails(value, index)} />
																	</div>
																	<div>
																		<h4 className="bold">And</h4>
																		<ScheduleTimeDropdown value={scheduleDetails.time_end} index={"time_end"} onClick={(index, value) => changeScheduleDetails(value, index)} />
																	</div>
																	<div>
																		<h4 className="bold">Reach a new lead every</h4>
																		<InputGroup className='uiStepper'>
																			<Button className='btn-stepper btn-ico rsModifyStep' disabled={scheduleDetails.reach_new_lead <= 2}  onClick={() => changeScheduleDetails( (scheduleDetails.reach_new_lead - 1), "reach_new_lead")} ><i className='fa-solid fa-minus'></i></Button>
																			<Input type='number' className='rsEmailLimit' name='emailsLimit' min="0" value={scheduleDetails.reach_new_lead} />
																			<Button className='btn-stepper btn-ico rsModifyStep' onClick={() => changeScheduleDetails( (scheduleDetails.reach_new_lead + 1), "reach_new_lead")} ><i className='fa-solid fa-plus'></i></Button>															
																		</InputGroup> Minutes
																	</div>
																</div>
																<div className='rsInfoMsg info'>
																	<div className="flex-1">This schedule will reach out to <b>{calculateDayPerLeads()} new leads per day.</b></div>
																</div>
																<Button color='primary' disabled={scheduleLoaderApi} onClick={() => editSchedule()}>
																	{scheduleLoaderApi &&
																		<Spinner size={"sm"} />
																	}
																	<i className='fa-solid fa-save'></i> Save changes
																</Button>
															</div>
														</div>
													}
												</React.Fragment>
											)
										})}
										{addNewFlag ?
											<div className='d-flex-col lg editScheduleTemplate'>
												<div>
													<h4 className='bold'>Schedule name</h4>
													<div className='textEdit rsScheduleName'>
														<div className='inputWrapper'>
															<Input type="text" className="rsTextEdit" name="schedule_name" value={scheduleDetails && scheduleDetails.name} onChange={(e) => changeScheduleDetails(e.target.value, "name")} maxLength={70} size={24} />
															<i className="fa-solid fa-circle-xmark rsClear" onClick={() => clearScheduleDetails("name")}></i>
															<div className='rsTextEditBorder'></div>
														</div>
													</div>
												</div>
												<div>
													<h4 className='bold'>Based on this timezone</h4>
													<TimezoneSelect
														value={scheduleDetails.timezone}
														onChange={(value) => changeScheduleDetails(value.value, 'timezone')}
														className='scheduleTimezone'
													/>
												</div>
												<div className='containerDays'>
													<h4 className="bold">Send these days</h4>
													<div className='d-flex-row scheduleWeeks'>
														{scheduleDays.map((scheduleDay, index) => {
															return( 
																<div className="checkbox rsCheckbox rsCheckFilter" key={index}>
																	<Label className="js-prevent-label-bounce-event-click">
																		<Input type='checkbox' checked={scheduleDetails && scheduleDetails.schedule_weeks.indexOf(scheduleDay) !== -1} onChange={() => changeScheduleValues(scheduleDay)} />
																		<span>
																			{scheduleDay === 7 ?
																				<>Sunday</>
																			: scheduleDay === 1 ?
																				<>Monday</>
																			: scheduleDay === 2 ?
																				<>Tuesday</>
																			: scheduleDay === 3 ?
																				<>Wednesday</>
																			: scheduleDay === 4 ?
																				<>Thursday</>
																			: scheduleDay === 5 ?
																				<>Friday</>
																			: scheduleDay === 6 &&
																				<>Saturday</>
																			}
																		</span>
																	</Label>
																</div>
															)
														})}
													</div>
												</div>
												<div className='d-flex-col'>
													<div className='containerReachLeads'>
														<div>
															<h4 className="bold">Between</h4>
															<ScheduleTimeDropdown value={scheduleDetails.time_start} index={"time_start"} onClick={(index, value) => changeScheduleDetails(value, index)} />
														</div>
														<div>
															<h4 className="bold">And</h4>
															<ScheduleTimeDropdown value={scheduleDetails.time_end} index={"time_end"} onClick={(index, value) => changeScheduleDetails(value, index)} />
														</div>
														<div>
															<h4 className="bold">Reach a new lead every</h4>
															<InputGroup className='uiStepper'>
																<Button className='btn-stepper btn-ico rsModifyStep' disabled={scheduleDetails.reach_new_lead <= 2}  onClick={() => changeScheduleDetails( (scheduleDetails.reach_new_lead - 1), "reach_new_lead")} ><i className='fa-solid fa-minus'></i></Button>
																<Input type='number' className='rsEmailLimit' name='emailsLimit' min="0" value={scheduleDetails.reach_new_lead} />
																<Button className='btn-stepper btn-ico rsModifyStep' onClick={() => changeScheduleDetails( (scheduleDetails.reach_new_lead + 1), "reach_new_lead")} ><i className='fa-solid fa-plus'></i></Button>															
															</InputGroup> Minutes
														</div>
													</div>
													<div className='rsInfoMsg info'>
														<div className="flex-1">This schedule will reach out to <b>{calculateDayPerLeads()} new leads per day.</b></div>
													</div>
													<Button color='primary' disabled={scheduleLoaderApi} onClick={() => createNewSchedule()}>
														{scheduleLoaderApi &&
															<Spinner size={"sm"} />
														}
														<i className='fa-solid fa-plus'></i> Add this schedule
													</Button>
												</div>
											</div>
										: ( !addNewFlag && !editScheduleId ) &&
											<Button color='secondary' onClick={() => addNewSchedule()} className='align-self-start'><i className="fa-regular fa-calendar-plus"></i>Create a new schedule</Button>
										}
									</div>
								}
							</div>
						</div>
					</ModalBody>
				</Modal>
			}
			{deleteScheduleModal &&
				<Modal isOpen={deleteScheduleModal} className='deleteWarningModal' centered={true}>
					<ModalBody>
						<div className='deleteWarningContent'>
							<h2 className='title mb-3'><i className="fa-solid fa-triangle-exclamation warningColor paddingRight10"></i>Warning</h2>
							<p>Delete schedule from the campaign?</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='d-flex justify-content-between flex-1'>
							<Button className='btn-tertiary cancelWarningButton' disabled={deleteLoaderApi} onClick={() => toggleDeleteScheduleModal()}>Cancel</Button>
							<Button className='md okWarningButton btn-warning' color='primary' disabled={deleteLoaderApi} onClick={() => deleteSchedule()}>
								{deleteLoaderApi &&
									<Spinner size={"sm"} />
								}
								Ok
							</Button>
						</div>
					</ModalFooter>
				</Modal>
			}
		</>
	);
}

export default CampaignTopBar;