import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap';

function UsersDropdown({userObject, selectedUser, updateSelectedUser}) {
	const [stepMoreOption, setStepMoreOption]	= useState(false),
		[totalUserIds, setTotalUserIds]	= useState([]);
	useEffect(() => {
		setTotalUserIds(Object.keys(userObject))
	}, [userObject])

	const toggleAllUser = () => {
		if( totalUserIds.length === selectedUser.length ){
			updateSelectedUser([])
		}else{
			updateSelectedUser(totalUserIds)
		}
	}

	const toggleUserIds = (userId) => {
		let selectedUserLocal	= [...selectedUser]
		if( selectedUser.indexOf(userId) !== -1 ){			
			let removeIndex	= selectedUserLocal.indexOf(userId);
			selectedUserLocal.splice(removeIndex, 1)
		}else{
			selectedUserLocal.push(userId)
		}
		updateSelectedUser(selectedUserLocal)
	}

	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => { setStepMoreOption((prevstate) => !prevstate); }} direction={"down"} className='rsDropdown'>
			<DropdownToggle tag="button" className='btn btnDropdown rsDropdownToggle'>
				<div className='dropdownValue maxWidth200 d-block'>
					{selectedUser.length === 0 ?
						<>Choose Users</>
					: totalUserIds.length === selectedUser.length ?
						<>All</>
					:
						<>
							{selectedUser.map((userId, index) => {
								return(
									<React.Fragment key={index}>
										{index !== 0 && <>, </>}
										{userObject[userId] &&
											<>{userObject[userId].first_name} {userObject[userId].last_name ? userObject[userId].last_name : ""}</>
										}
									</React.Fragment>
								)
							})}
						</>
					}
				</div>
			</DropdownToggle>
			<DropdownMenu className='dropdownPicker'>
				<div className='uiDropdownItem rsSelectItem'>
					<div className="checkbox rsCheckbox">
						<Label className="js-prevent-label-bounce-event-click">
							<Input type='checkbox' checked={totalUserIds.length === selectedUser.length} onChange={() => toggleAllUser()} />
						</Label>
					</div>
					All
				</div>
				{totalUserIds.map((userId, index) => {
					return (
						<div className='uiDropdownItem rsSelectItem' key={index}>
							<div className="checkbox rsCheckbox">
								<Label className="js-prevent-label-bounce-event-click">
									<Input type='checkbox' checked={selectedUser.indexOf(userId) !== -1} onChange={() => toggleUserIds(userId) } />
								</Label>
							</div>
							{userObject[userId].first_name} {userObject[userId].last_name ? userObject[userId].last_name : ""}
						</div>
					)
				})}				
			</DropdownMenu>
		</Dropdown>
	);
}

export default UsersDropdown;