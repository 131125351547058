import React from 'react';
import { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

function LeadsReviewDropdown({leadsCount, onClick}) {
    const [stepMoreOption, setStepMoreOption]	= useState(false);
	
	const onClickEvent = (type) => {
		setStepMoreOption(false)
		onClick(type)
	}

	return (
		<Dropdown isOpen={stepMoreOption} toggle={() => {setStepMoreOption((prevstate) => !prevstate);}} direction={"down"} className='dropdownTrigger'>
			<DropdownToggle tag="button" className='btn btnAction'>
				<i className="fa fa-ellipsis"></i>
			</DropdownToggle>
			<DropdownMenu className='campaignFilterDropdownMenu stepDropdownMenu leadReviewDropdown singleItem'>
				<div>
					<div className='dropdownRSMenuItems'>
						<div className={`dropdownRSMenuItem rsSkipLead ${leadsCount === 0 ? "disabled" : ""} `} onClick={() => onClickEvent("skip")}>
							<i className="fa-solid fa-forward"></i>
							<div className='text'>Skip {leadsCount} lead</div>
						</div>
						<div className={`dropdownRSMenuItem rsDeleteLead alert-300 ${leadsCount === 0 ? "disabled" : ""}`} onClick={() => onClickEvent("delete")}>
							<i className="fa-solid fa-trash"></i>
							<div className="text">Delete {leadsCount} lead</div>
						</div>
					</div>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
}

export default LeadsReviewDropdown;